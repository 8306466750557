import { useEffect, useState } from 'react';
import { navigationRef } from '../pages/RootNavigation';
import { HouseholdRemovedService } from '../services/HouseholdRemovedService';
import useBoundStore from '../store/store';
import { DdLogs } from '../services/SPDataDogService';

export const useHouseholdRemovedRedirect = (): ((data: boolean) => void) => {
  const [allowCheck, setAllowCheck] = useState(false);
  const { households, loadingHouseholds } = useBoundStore(s => ({
    households: s.householdStore.households,
    loadingHouseholds: s.householdStore.loadingHouseholds,
  }));
  const isAuthenticated = useBoundStore(s => s.accountStore.isAuthenticated());
  const { user } = useBoundStore(s => s.accountStore);

  useEffect(() => {
    if (
      allowCheck &&
      navigationRef.isReady() &&
      !loadingHouseholds &&
      isAuthenticated
    ) {
      HouseholdRemovedService.checkRemovedHousehold(user, households).then(
        isRemovedHouseholds => {
          if (isRemovedHouseholds) {
            setTimeout(() => {
              navigationRef.navigate('HouseholdRemoved');
            }, 1000);
          }
        },
      );
      DdLogs.info('useHouseholdRemovedRedirect - useEffect - checkRemovedHousehold');

    }
  }, [loadingHouseholds, households, allowCheck, isAuthenticated]);

  return data => {
    setAllowCheck(data);
  };
};
