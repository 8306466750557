import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { DeviceType } from '@constants/Device';
import useAreProductsPresent from '@hooks/useAreProductsPresent';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import atoms from '@styles/atoms';
import colors from '@styles/colors';
import text from '@styles/text';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet } from 'react-native';
import { RootStackParamList } from 'src/pages';
import useBoundStore from 'src/store/store';

type Props = {
  isPets: boolean;
};

const EmptyPlaceholder: React.FC<Props> = ({ isPets }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { activeHousehold } = useBoundStore(s => s.householdStore);
  const areProductsPresent = useAreProductsPresent();
  const userHasAdminAccess = useUserHasAdminAccess();

  const actions = useMemo(() => {
    const data = {
      pets: {
        buttonText: t('add_pet'),
        message: t('no_pets_pet_carousel_title'),
        navigate: () => {
          navigate('FlowNavigation', {
            screen: 'AddPets',
            params: { step: areProductsPresent ? 1 : 0 } as any,
          });
        },
      },
      devices: {
        buttonText: t('add_product'),
        message: t('empty_household'),
        navigate: () => {
          navigate('FlowNavigation', { screen: 'SelectProduct' });
        },
      },
    };

    return data[isPets ? 'pets' : 'devices'];
  }, [isPets, areProductsPresent, t]);

  const redirect = () => {
    if (!activeHousehold) {
      navigate('HouseholdNavigation', { screen: 'ChooseSetup' });
    } else {
      actions.navigate();
    }
  };

  return (
    <SpView style={styles.container}>
      <SpText style={[text.defaultText, styles.text]}>{actions.message}</SpText>
      {(!activeHousehold || userHasAdminAccess) &&
      <SpRoundedHeaderButton
        backgroundColor={colors.primary.color}
        title={actions.buttonText}
        h={56}
        stylesForContainer={atoms.maxWidth}
        onPress={redirect}
      />}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colors.white.color,
    borderRadius: 24,
    gap: 24,
    width: Dimensions.get('window').width * 0.76,
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
  },
});

export default memo(EmptyPlaceholder);
