import { StyleSheet } from 'react-native';
import React from 'react';
import { SpView } from '@atoms/SpView';

export const NameFormWrap = (children: JSX.Element) => (
  <SpView style={styles.stepWrapper}>{children}</SpView>
);

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '20%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
});

export default NameFormWrap;
