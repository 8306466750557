export enum AccountMenu {
  FirstName = 'first_name',
  LastName = 'last_name',
  EmailAddress = 'email_address',
  Language = 'language_id',
  WeightUnits = 'weight_units',
  TimeFormat = 'time_format',
  Country = 'country_id',
  MarketingOptIn = 'marketing_opt_in',
}

export enum PetBioMenu {
  Name = 'name',
  Species = 'species_id',
  Gender = 'gender',
  Spayed = 'spayed',
  DateOfBirth = 'date_of_birth',
  BreedID = 'breed_id',
  BreedID2 = 'breed_id2',
  Weight = 'weight',
  Comments = 'comments',
}
