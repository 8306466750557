import { StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { DeviceType, PetBowlFoodType, SubstanceType } from '@constants/Device';
import useBoundStore from 'src/store/store';
import WiFiDeviceAPI from '@api/WifiDeviceConnector';
import { useTranslation } from 'react-i18next';
import { DeviceCerberusControlModel, DeviceModel } from '@models/Device';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import EssenceCreated from '@atoms/EssenceCreated';
import { useDeviceById } from '@hooks/useDeviceById';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { navigateToProducts } from '@utils/navigationShortcuts';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import { SpView } from '@atoms/SpView';
import { SpLoading } from '@atoms/SpLoading';
import StepFormCreator from '../../../../../components/molecules/StepFormCreator';
import useValidation from '../../../../validation';
import InstructionStep1 from '../../../DogBowl/InstructionStep1';
import WiFiConnectToDevice from '../../../DogBowl/WiFiConnectToDevice';
import WiFiSSIDSelect from '../../../DogBowl/WiFiSSIDSelect';
import WiFiPasswordInput from '../../../DogBowl/WiFiPasswordInput';
import PairingToHouseholdStep from '../../../DogBowl/PairingToHouseholdStep';
import PairingFailed from '../../../DogBowl/PairingFailed';
import ThereSeemsToBeAProblem from '../../../DogBowl/ThereSeemsToBeAProblem';
import ThereSeemsToBeAProblemContactCS from '../../../DogBowl/ThereSeemsToBeAProblemContactCS';

import Paired from '../../../DogBowl/Paired';

import NameForm from '../../../Generic/NameForm';
import NameFormWrap from '../../../DogBowl/NameFormWrap';
import { SubstanceTypes } from '../../../../Device/molecules/DeviceBowlSetup/SubstanceTypes';
import { FoodTypes } from '../../../../Device/molecules/DeviceBowlSetup/FoodTypes';
import { FlowStackParamList } from '../../../index';
import AssignPet from '../../../Generic/AssignPet';
import { InsertBatteries } from '../../../DogBowl/InsertBatteries';
import { openModal as openModalBox } from '../../../../../services/ModalBox';
import { CompleteSetupProduct } from '../../../Generic/CompleteSetupProduct';
import { ConfigureCDBCarouselWater } from '../../../DogBowl/ConfigureCDBCarouselWater';
import { ConfigureCDBCarouselFood } from '../../../DogBowl/ConfigureCDBCarouselFood';
import { FirstConfigureProduct } from '../../../DogBowl/FirstConfigureProduct';
import LocalDevicePermission from '../../../DogBowl/TriggerLocalDevicePopup';
import InstructionStep2 from '../../../DogBowl/InstructionStep2';
import { navigationRef } from '../../../../RootNavigation';
import { InstructionStep3 } from '../../../DogBowl/InstructionStep3';
import { AnalyticsService } from '../../../../../services/AnalyticsService';
import WeHaveNotManageToConnectToCDB from '../../../DogBowl/WeHaveNotManageToConnectToCDB';
import HowToFactoryResetYourCDB from '../../../DogBowl/HowToFactoryResetYourCDB';

type Props = NativeStackScreenProps<FlowStackParamList, 'AddDogBowl'>;

export enum stepsEnums {
  InstructionStep1 = 1,
  InsertBatteries,
  LocalDevicePermission,
  InstructionStep2,
  InstructionStep3,
  WiFiConnectToDevice,
  WiFiSSIDSelect,
  WiFiPasswordInput,
  PairingToHouseholdStep,
  Paired,
  NameForm,
  CompleteSetupProduct,
  AssignPetUI,
  SubstanceTypes,
  FoodTypes,
  FirstConfigureProduct,
  ConfigureCDBCarousel,
  SetupComplete,
  ThereSeemsToBeAProblem,
  ThereSeemsToBeAProblemContactCS,
  WeHaveNotManageToConnectToCDB,
  HowToFactoryResetYourCDB,
  PairingFailed,


}

const AddDogBowl = ({ route }: Props) => {
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const { commonNameSchema } = useValidation();
  const { t } = useTranslation();
  const { loadDevice, updateNameRequest, getDeviceById, assignPetRequest } =
    useBoundStore(s => s.deviceStore);

  const {
    setSettingUpWiFiDevice,
    startSearchForProduct,
    connectedToWiFiDevice,
    loading,
    error,
    stopSearch,
    setTimeoutForWiFiProduct,
    getNetworks,
    startScanDevice,
    startCheckDeviceStatus,
    deviceSSIDS,
    targetNetworkID,
    targetNetworkPassword,
    setTargetNetworkPassword,
    WiFiDeviceConnectedToServer,
    WiFiDeviceConnectedToNetwork,
    connectDeviceToWifi,
    success,
    pairedDeviceDeviceID,
    startPairDeviceToHousehold,
    reset,
  } = useBoundStore(s => s.WiFiDeviceStore);
  const { updateCerberusControl } = useBoundStore(s => s.deviceStore);

  // const pairedDeviceDeviceID = 1915;
  const pets = usePetsByHousehold();
  const device = useDeviceById(pairedDeviceDeviceID);
  const allPets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, allPets);
  const navigation = useNavigation<NavigationProp<FlowStackParamList>>();

  const [submitValues, setSubmitValues] = useState<any>();
  const [step, setStep] = useState<stepsEnums>(route.params?.step || 1);
  const [substanceType, setSubstanceType] = useState(SubstanceType.water);
  const [selectedDevice, setSelectedDevice] = useState<DeviceModel | undefined>(
    null,
  );
  const [lastBatteriesTemplate, setLastBatteriesTemplate] = useState(false);
  const [lastCDBConnectTemplate, setLastCDBConnectTemplate] = useState(false);
  const [lastCDBCarouselFoodTemplate, setLastCDBCarouselFoodTemplate] =
    useState(false);
  const [lastCDBCarouselWaterTemplate, setLastCDBCarouselWaterTemplate] =
    useState(false);
  const [numberTemplates, setNumberTemplates] = useState(0);
  const [numberCDBConnectTemplates, setNumberCDBConnectTemplates] = useState(0);
  const [numberWaterTemplates, setNumberWaterTemplates] = useState(0);
  const [numberFoodTemplates, setNumberFoodTemplates] = useState(0);
  const [searchForDeviceAttempts, setSearchForDeviceAttempts] = useState(0);
  const [returnToStep, setReturnToStep] = useState(0);
  const [foodType, setFoodType] = useState(PetBowlFoodType.wet);
  const [bowlFlashingOrange, setBowlFlashingOrange] = useState(null);
  const [assignedPets, setAssignedPets] = useState([]);
  const [retryConnectToDevice, setRetryConnectToDevice] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const changeCerberusFoodTypeAction = useCallback(
    async (type: PetBowlFoodType) => {
      const control = { food_type: type } as DeviceCerberusControlModel;
      setFoodType(control.food_type);
      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      setSelectedDevice(deviceObj);
      await updateCerberusControl(selectedDevice.id, control);
    },
    [setFoodType, foodType, selectedDevice, pairedDeviceDeviceID],
  );
  const changeSubstanceTypeAction = useCallback(
    async (type: SubstanceType) => {
      const control = {
        substance_type: type,
      } as DeviceCerberusControlModel;
      setSubstanceType(type);
      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      setSelectedDevice(deviceObj);
      await updateCerberusControl(deviceObj.id, control);
    },
    [setSubstanceType, substanceType, selectedDevice, pairedDeviceDeviceID],
  );
  useEffect(() => {
    AnalyticsService.logEvent(`AddDogBowl - reset`);

    reset();
  }, []);
  useEffect(() => {
    AnalyticsService.logEvent(`AddDogBowl - step - ${stepsEnums[step]}`, { step, stepName: stepsEnums[step] });
  }, [step]);

  useEffect(() => {
    setSettingUpWiFiDevice(true);
    return () => setSettingUpWiFiDevice(false);
  }, [setSettingUpWiFiDevice]);

  useEffect(() => {
    if (route.params?.step) {
      setStep(route.params?.step);
      AnalyticsService.logEvent('AddDogBowl - useEffect - route.params?.step', { step: route.params?.step });
    }
  }, [route.params?.step]);

  useEffect(() => {
    return () => {
      stopSearch(true);
      reset();
    };
  }, []);

  useEffect(() => {
    if (pairedDeviceDeviceID) {

      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      if (deviceObj) {
        setSelectedDevice(deviceObj);
        AnalyticsService.logEvent('AddDogBowl - useEffect - pairedDeviceDeviceID');

      }
    }
  }, [pairedDeviceDeviceID, getDeviceById, setSelectedDevice, selectedDevice]);

  // effect to start looking for devices when ever the step changes to step 5 so long as the number of attempts is less than 3

  useEffect(() => {
    if (step === stepsEnums.WiFiConnectToDevice) {
      if (connectedToWiFiDevice) {
        AnalyticsService.logEvent('AddDogBowl - useEffect - WiFiConnectToDevice');

        return;
      }
      // increment search attempts and start searching
      if (!loading && searchForDeviceAttempts < 3) {
        if (error) {

          setReturnToStep(stepsEnums.InstructionStep3);
          setStep(stepsEnums.ThereSeemsToBeAProblem);
          stopSearch();
          AnalyticsService.logEvent('AddDogBowl - useEffect - searchForDeviceAttempts - ThereSeemsToBeAProblemContactCS');

          return;
        }
        setSearchForDeviceAttempts(searchForDeviceAttempts + 1);
        setTimeoutForWiFiProduct(6000);
        startSearchForProduct('CDB');
        setRetryConnectToDevice(false);
        AnalyticsService.logEvent('AddDogBowl - useEffect - searchForDeviceAttempts - searchAgain');


      }
      if (!loading && searchForDeviceAttempts >= 3 && error) {
        setStep(stepsEnums.ThereSeemsToBeAProblemContactCS);
        AnalyticsService.logEvent('AddDogBowl - useEffect - searchForDeviceAttempts >=3 - ThereSeemsToBeAProblem');

        stopSearch();
      }
    }
  }, [step, loading, searchForDeviceAttempts, connectedToWiFiDevice, retryConnectToDevice]);

  useEffect(() => {
    if (step === stepsEnums.WiFiSSIDSelect) {
      startScanDevice();
      AnalyticsService.logEvent('AddDogBowl - useEffect - WiFiSSIDSelect');

    }
    if (step === stepsEnums.SubstanceTypes) {
      changeSubstanceTypeAction(SubstanceType.water);
      AnalyticsService.logEvent('AddDogBowl - useEffect - SubstanceTypes');

    }
    if (step === stepsEnums.FoodTypes) {
      changeCerberusFoodTypeAction(PetBowlFoodType.dry);
      AnalyticsService.logEvent('AddDogBowl - useEffect - FoodTypes');

    }

    if (step === stepsEnums.AssignPetUI) {
      const deviceObj = getDeviceById(pairedDeviceDeviceID);
      setSelectedDevice(deviceObj);
      AnalyticsService.logEvent('AddDogBowl - useEffect - AssignPetUI');

    }
  }, [step, pairedDeviceDeviceID]);

  useEffect(() => {
    // if(step === 7){
    //   console.log(`step 7, will check if loading: ${loading}, error: ${error}, success: ${success}"`)
    //  }
    if (step === stepsEnums.WiFiPasswordInput && !loading && !error && success) {
      AnalyticsService.logEvent('AddDogBowl - useEffect - WiFiPasswordInput - success');

      setTimeout(() => {
        setReturnToStep(stepsEnums.WiFiPasswordInput);
        startCheckDeviceStatus();
        setStep(stepsEnums.PairingToHouseholdStep);
      }, 300);
    }
  }, [step, setStep, success, error, loading]);

  useEffect(() => {
    if (step === stepsEnums.PairingToHouseholdStep) {
      // console.log(`Step 8, pairedDeviceDeviceID: ${pairedDeviceDeviceID}, loading: ${loading}, error: ${error},  WiFiDeviceConnectedToServer: ${WiFiDeviceConnectedToServer}` )
    }
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !pairedDeviceDeviceID &&
      !loading &&
      !error &&
      WiFiDeviceConnectedToServer
    ) {
      // console.log(
      //   "on step 8, but no paired device id, will pair to household in 6 seconds",
      //   step,
      //   pairedDeviceDeviceID,
      //   loading,
      //   error
      // );
      AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep');

      WiFiDeviceAPI.closeConnection().then(() => {

        setTimeout(() => {
          setReturnToStep(stepsEnums.WiFiSSIDSelect);
          startPairDeviceToHousehold();
        }, 6000);
      });
    }
    // We stopped loading, there was an error before we could connect to wifi so its probably a problem with the SSID or Password
    if (step === stepsEnums.PairingToHouseholdStep && !loading && error && !WiFiDeviceConnectedToNetwork) {
      // console.log("error connecting to wifi, will go to step 7")
      // error was a timeout, probably issue connecting to dog bowl
      if (error === 'ECONNABORTED') {
        AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - error - ECONNABORTED');
        setStep(stepsEnums.PairingFailed);
      } else {
        AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - error');
        setStep(stepsEnums.WiFiPasswordInput);
      }
    }
    // We stopped loading, there was an error after we connected to wifi so its probably a problem with the server
    if (step === stepsEnums.PairingToHouseholdStep && !loading && error && WiFiDeviceConnectedToNetwork) {
      // console.log("error connecting to server, will go to step 20")
      setStep(stepsEnums.PairingFailed);
      AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - error - WiFiDeviceConnectedToNetwork');
    }
    // We stopped loading, there was an error before we connected to wifi so its probably a problem with the creds or network
    if (step === stepsEnums.PairingToHouseholdStep && !loading && !error && !WiFiDeviceConnectedToNetwork) {
      setStep(stepsEnums.PairingFailed);
      AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - error - !WiFiDeviceConnectedToNetwork');
    }
    // if connecting is successful then go to paired page
    if (step === stepsEnums.PairingToHouseholdStep && pairedDeviceDeviceID) {
      setStep(stepsEnums.Paired);
      AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - success');
    }
  }, [
    step,
    setStep,
    pairedDeviceDeviceID,
    error,
    loading,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToServer,
  ]);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        AnalyticsService.logEvent('AddDogBowl - onSubmit - updateNameRequest');
        await updateNameRequest(pairedDeviceDeviceID, {
          name: submitValues.name,
        });
        await loadDevice(true);
        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, pairedDeviceDeviceID],
  );

  const assignPetsHandler = useCallback(() => {
    AnalyticsService.logEvent('AddDogBowl - assignPetsHandler');
    assignedPets.forEach(async (pet, index) => {
      await assignPetRequest(pairedDeviceDeviceID, pet.pet.tag_id);
    });
  }, [assignedPets, pairedDeviceDeviceID]);

  const onOpenGetHelpModal = useCallback(() => {
    AnalyticsService.logEvent('AddDogBowl - onOpenGetHelpModal', { step });
    switch (step) {
      case stepsEnums.InstructionStep1:
      case stepsEnums.InsertBatteries:
        navigation.navigate('CDBHelpInsertBatteries');
        break;
      case stepsEnums.LocalDevicePermission:
      case stepsEnums.InstructionStep2:
        navigation.navigate('CDBHelpPairingMode');
        break;
      case stepsEnums.InstructionStep3:
        navigation.navigate('CDBHelpWifiNetwork');
        break;
      case stepsEnums.WiFiSSIDSelect:
        navigation.navigate('HavingIssuesFindingWiFi');
        break;
      case stepsEnums.PairingFailed:
        navigation.navigate('CDBHelpFailedPairing');
        break;
      // case stepsEnums.: {
      //   Linking.openURL('https://www.surepetcare.com/support');
      // }
    }
    return false;
  }, [openModalBox, step]);

  const headerProps = {
    withLeaveButton: true,
    withRightButton: true,
    rightButtonText: t('get_help'),
    rightButtonAction: () => {
      onOpenGetHelpModal();
    },
  };

  const enableInsertBatteriesContinue = (indexTemplate: number) => {
    setLastBatteriesTemplate(indexTemplate === numberTemplates - 1);
  };

  const enableCDBConnectContinue = (indexTemplate: number) => {
    setLastCDBConnectTemplate(indexTemplate === numberCDBConnectTemplates - 1);
  };

  const enableConfigureCDBCarouselWaterContinue = (indexTemplate: number) => {
    setLastCDBCarouselWaterTemplate(indexTemplate === numberWaterTemplates - 1);
  };

  const enableConfigureCDBCarouselFoodContinue = (indexTemplate: number) => {
    setLastCDBCarouselFoodTemplate(indexTemplate === numberFoodTemplates - 1);
  };

  const PlacementInfoWrapper = useCallback((children: JSX.Element) => {
    return <SpView flex={1}>{children}</SpView>;
  }, []);

  const AssignPetUI = useMemo(() => {
    if (!selectedDevice) return <SpLoading />;
    return (
      <AssignPet
        device={selectedDevice}
        data={petsWithTag}
        title={t('assign_pet_title')}
        subtitle={t('assign_pet_subtitle')}
        shouldPassData
        activeIncompatibility={false}
        setAssignedPets={setAssignedPets}
        isSetupFlow
        saveButtonAction={() => {
          flowNavigation.navigate('CreatePetProfile');
        }}
      />
    );
  }, [selectedDevice, petsWithTag]);

  const stopSearchLeaveAction = useCallback(() => {
    stopSearch(true);
    navigation.goBack();
  }, [navigation, stopSearch]);


  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      steps={{
        [stepsEnums.InstructionStep1]: {
          ui: InstructionStep1,
          customHeaderProps: { ...headerProps, withRightButton: false },
          backBottomButton: true,
        },
        [stepsEnums.InsertBatteries]: {
          ui: InsertBatteries,
          props: {
            enableInsertBatteriesContinue,
            setNumberItems: setNumberTemplates,
          },
          customHeaderProps: headerProps,
          backBottomButton: true,
          wrap: PlacementInfoWrapper,
          buttonDisabled: !lastBatteriesTemplate,
        },
        [stepsEnums.LocalDevicePermission]: {
          ui: LocalDevicePermission,
          customHeaderProps: headerProps,
          backBottomButton: true,
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - LocalDevicePermission - interceptor');
            setTimeoutForWiFiProduct(300);
            startSearchForProduct('CDB', 1);
            setTimeout(() => {
              stopSearch(true);
            }, 1000);

            return true;
          },

        },
        [stepsEnums.InstructionStep2]: {
          ui: InstructionStep2,
          customHeaderProps: headerProps,
          backBottomButton: true,
        },
        [stepsEnums.InstructionStep3]: {
          ui: InstructionStep3,
          props: {
            enableCDBConnectContinue,
            setNumberItems: setNumberCDBConnectTemplates,
            setStep,
            screenActive: step === stepsEnums.InstructionStep3,
          },
          customHeaderProps: headerProps,
          hideButton: true,
          backBottomButton: true,
          hideBackBottomButton: false,
        },
        [stepsEnums.WiFiConnectToDevice]: {
          ui: WiFiConnectToDevice({
            connectedToWiFiDevice,
            setRetryConnectToDevice,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideButton: !connectedToWiFiDevice,
          interceptor: () => {
            if (connectedToWiFiDevice) {
              AnalyticsService.logEvent('AddDogBowl - WiFiConnectToDevice - interceptor - true');

              return true;
            }
            AnalyticsService.logEvent('AddDogBowl - WiFiConnectToDevice - interceptor - false');

            return false;
          },
        },
        [stepsEnums.WiFiSSIDSelect]: {
          ui: WiFiSSIDSelect({
            deviceSSIDS,
            targetNetworkID,
            loading,
            currentStep: step === 6,
          }),
          customHeaderProps: {
            ...headerProps,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: deviceSSIDS.length === 0,
          interceptor: () => {
            if (deviceSSIDS.length > 0 || targetNetworkID === 999) {
              AnalyticsService.logEvent('AddDogBowl - WiFiSSIDSelect - interceptor - true');

              return true;
            }
            AnalyticsService.logEvent('AddDogBowl - WiFiSSIDSelect - interceptor - false');

            // startSearch();
            return false;
          },
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            if (routeName === 'SelectSSID') {
              navigationRef.goBack();
              return true;
            }
            if (routeName === 'AddDogBowl') {
              stopSearchLeaveAction();
              return true;
            }
            return false;
          },
        },
        [stepsEnums.WiFiPasswordInput]: {
          ui: WiFiPasswordInput({
            SelectedWiFiSSID: deviceSSIDS[targetNetworkID]?.ssid || '',
            setTargetNetworkPassword,
            targetNetworkPassword,
            loading,
            error: error ? t(String(error)) : null,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideButton: loading,
          buttonDisabled: targetNetworkPassword?.length < 5,
          interceptor: () => {
            if (!targetNetworkPassword) {
              AnalyticsService.logEvent('AddDogBowl - WiFiPasswordInput - interceptor - false');

              return false;
            }
            AnalyticsService.logEvent('AddDogBowl - WiFiPasswordInput - interceptor - connectDeviceToWifi');

            connectDeviceToWifi();
            return false;
          },
        },

        [stepsEnums.PairingToHouseholdStep]: {
          // @ts-ignore
          ui: PairingToHouseholdStep({
            isLoading: loading,
          }),
          hideButton: true,
          customHeaderProps: {
            withLeaveButton: false,
            goBackFunc: () => {
              setStep(stepsEnums.WiFiPasswordInput);
              stopSearch();
            },
          },
        },
        [stepsEnums.Paired]: {
          // @ts-ignore
          ui: Paired,
          props: { getHelpAction: onOpenGetHelpModal },
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
        },

        [stepsEnums.NameForm]: {
          ui: NameForm,
          props: {
            setName,
            label: t('product_name'),
            title: t('name_dogbowl_title'),
            subtitle: t('subtitle {{productName}} {{example}}', {
              example: t('name_dogbowl_example'),
              productName: t('dog_bowl_connect'),
            }),
            subtitle2: t('help_if_have_more_products'),
            disableKeyboardAwoiding: true,
            titleStyles: { marginTop: 0 },
          },
          keyboardAwoidingProps: {
            androidAdjustType: 'force',
            extraScrollHeight: 100,
          },
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          wrap: NameFormWrap,
          formik: {
            schema: commonNameSchema(),
            names: ['name'],
          },
          extraScrollHeightAndroid: 100,
          buttonDisabled: name === '',
        },
        [stepsEnums.CompleteSetupProduct]: {
          ui: (
            <CompleteSetupProduct
              productId={DeviceType.Cerberus}
              onLoadedCallback={onSubmit}
              deviceId={pairedDeviceDeviceID}
              hideButton
            />
          ),
          interceptor: () => {
            const deviceObj = getDeviceById(pairedDeviceDeviceID);
            setSelectedDevice(deviceObj);
            if (pets.length === 0) {
              AnalyticsService.logEvent('AddDogBowl - CompleteSetupProduct - interceptor - pets.length === 0');

              // This push causes CDB-1924? step 13-> create -> step 13-> create
              flowNavigation.navigate('CreatePetProfile', {
                cdb: true,
                device_id: pairedDeviceDeviceID,
              });
              return false;
            }
            AnalyticsService.logEvent('AddDogBowl - CompleteSetupProduct - interceptor - true');
            return true;
          },
        },
        [stepsEnums.AssignPetUI]: {
          ui: AssignPetUI,
          interceptor: async () => {
            AnalyticsService.logEvent('AddDogBowl - AssignPetUI - interceptor');
            await assignPetsHandler();
            return true;
          },
        },
        [stepsEnums.SubstanceTypes]: {
          ui: SubstanceTypes({
            active: substanceType,
            onChange: changeSubstanceTypeAction,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          backBottomButton: true,
        },
        [stepsEnums.FoodTypes]: {
          ui: FoodTypes({
            active: foodType,
            onChange: changeCerberusFoodTypeAction,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          backBottomButton: true,
          skipStep: substanceType === SubstanceType.water,
        },
        [stepsEnums.FirstConfigureProduct]: {
          ui: FirstConfigureProduct,
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          backBottomButton: true,
        },
        [stepsEnums.ConfigureCDBCarousel]: {
          ui:
            substanceType === SubstanceType.water
              ? ConfigureCDBCarouselWater
              : ConfigureCDBCarouselFood,
          props:
            substanceType === SubstanceType.water
              ? {
                enableConfigureCDBCarouselWaterContinue,
                setNumberItems: setNumberWaterTemplates,
              }
              : {
                enableConfigureCDBCarouselFoodContinue,
                setNumberItems: setNumberFoodTemplates,
              },
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
          },
          backBottomButton: true,
          buttonDisabled:
            substanceType === SubstanceType.water
              ? !lastCDBCarouselWaterTemplate
              : !lastCDBCarouselFoodTemplate,
        },
        [stepsEnums.SetupComplete]: {
          ui: (
            <View style={[styles.center, { marginTop: 112 }]}>
              <EssenceCreated msg={t('setup_complete')} initialValue />
            </View>
          ),
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - SetupComplete - interceptor');
            navigateToProducts();
            return false;
          },
        },
        [stepsEnums.PairingFailed]: {
          // @ts-ignore
          ui: PairingFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - PairingFailed - interceptor');
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },

        [stepsEnums.ThereSeemsToBeAProblem]: {
          // @ts-ignore
          ui: ThereSeemsToBeAProblem,
          props: {
            bowlFlashingOrange,
            setIsFlashingOrange: setBowlFlashingOrange,
          },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: bowlFlashingOrange === null,
          interceptor: () => {
            AnalyticsService.logEvent('AddDogBowl - ThereSeemsToBeAProblem - interceptor');

            setStep(bowlFlashingOrange ? returnToStep : stepsEnums.WeHaveNotManageToConnectToCDB);
            setBowlFlashingOrange(null);
            setBowlFalshingOrange(null);
            return false;
          },
        },
        [stepsEnums.ThereSeemsToBeAProblemContactCS]: {
          // @ts-ignore
          ui: ThereSeemsToBeAProblemContactCS,
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          hideButton: true,
        },
        [stepsEnums.WeHaveNotManageToConnectToCDB]: {
          // @ts-ignore
          ui: WeHaveNotManageToConnectToCDB,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          hideProgressHeader: true,
          buttonText: t('try_again'),
          interceptor: () => {
            setStep(stepsEnums.HowToFactoryResetYourCDB);
            return false;
          },
        },
        [stepsEnums.HowToFactoryResetYourCDB]: {
          // @ts-ignore
          ui: HowToFactoryResetYourCDB,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          hideProgressHeader: true,
          interceptor: () => {
            setStep(stepsEnums.InstructionStep2);
            return false;
          },
        },
        // 9: {
        //   // @ts-ignore
        //   ui: WeFailedToSetup,
        //   customHeaderProps: {...headerProps, withRightButton: false},
        //   hideProgressHeader: true,
        //   props:{},
        //   buttonText: t('setup_cdb_failed_to_pair_button'),
        // },
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  stepWrapper: {},
  formWrapper: { paddingHorizontal: 24 },
  center: {
    alignItems: 'center',
  },
});

export default AddDogBowl;
