import { SpTermsCheckboxState } from '@molecules/SpTermsCheckboxes';
import { useCallback, useEffect, useState } from 'react';
import useBoundStore from '../store/store';
import i18n from '@utils/i18n';

export const useTermsAndConditions = () => {
  const languages = useBoundStore(s => s.languageStore.languages);
  const [language, setLanguage] = useState(null);
  const [confirmSendSelect, setConfirmSendSelect] = useState(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [terms, setTerms] = useState<SpTermsCheckboxState>({
    endUserLicenceAgreement: false,
    privacyPolicy: false,
  });
  const isCloseToBottom = useCallback(
    ({ layoutMeasurement, contentOffset, contentSize }: any) => {
      return (
        layoutMeasurement.height + contentOffset.y >= contentSize.height - 250
      );
    },
    [],
  );

  useEffect(() => {
    setLanguage(languages.find(lang => lang.code === i18n.language)?.id || 0);
  }, [languages, i18n.language]);

  return {
    language,
    terms,
    confirmSendSelect,
    isScrolledToBottom,
    setLanguage,
    setConfirmSendSelect,
    setIsScrolledToBottom,
    setTerms,
    isCloseToBottom,
  };
};
