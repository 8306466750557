import {
  endOfMonth,
  endOfWeek,
  formatDistanceToNowStrict,
  startOfMonth,
  startOfWeek,
  Locale,
} from 'date-fns';
import { TimeService } from 'src/services/TimeService';
import * as Locales from 'date-fns/locale';

export const getDateRangeOfWeek = (
  date: number,
): { start: number; end: number } => {
  return {
    start: startOfWeek(date, { weekStartsOn: 1 }).getTime(),
    end: endOfWeek(date, { weekStartsOn: 1 }).getTime(),
  };
};

export const getDateRangeOfMonth = (
  date: number,
): { start: number; end: number } => {
  return {
    start: startOfMonth(date).getTime(),
    end: endOfMonth(date).getTime(),
  };
};

export const getDuration = (locationAt: string | number, addSuffix = true) => {
  return formatDistanceToNowStrict(
    typeof locationAt === 'string' ? new Date(locationAt) : locationAt,
    {
      addSuffix,
    },
  ).replace('minutes', 'mins');
};

// formatDuration(
//   intervalToDuration({
//     start: parseISO(locationAt),
//     end: new Date(),
//   }),
//   {
//     delimiter: ', ',
//     format: ['days', 'hours', 'minutes'],
//   },
// );

export const getSingleDuration = (
  locationAt: string | number,
  shouldNotRound = false,
) => {
  const difference = new Date(Date.now() - +locationAt);

  if (shouldNotRound && difference.getUTCHours() > 0) {
    const minutes = difference.getMinutes();
    locationAt = +locationAt + 1000 * 60 * minutes;
  }
  const duration = getDuration(locationAt);
  const result = duration.split(',')[0].trim() || '0 seconds';

  return result;
};

export const getLocale = (languageCode: string): Locale => {
  // possible country codes ["cs", "de", "nl", "pl", "pt", "es", "fi", "fr", "hu", "it", "ja", "ro", "ru", "sv", "zh", "pt-br", "en"]
  const data = Locales[languageCode as keyof typeof Locales];
  if (data) {
    return data;
  }
  switch (languageCode) {
    case 'en':
      return Locales.enGB;
    case 'pt-br':
      return Locales.ptBR;
    case 'zh':
      return Locales.zhCN;
    default:
      return Locales.enUS;
  }
};

export const getEndTimeDay = (dateUNIX: number) => {
  const date = new Date(dateUNIX);
  date.setHours(23, 59, 59, 999);
  return date.getTime();
};

export const getStartTimeDay = (dateUNIX: number) => {
  const date = new Date(dateUNIX);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export function getSecondsDifference(date1: Date, date2: Date) {
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  const difference = Math.abs(endDate - startDate);
  const seconds = Math.floor(difference / 1000);

  return seconds;
}
