import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpView } from '@atoms/SpView';
import { useGetAllPetReports } from '@hooks/useGetAllPetReports';
import { usePetDevices } from '@hooks/usePetDevices';
import { PetStatisticsModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import CalendarModal from '@molecules/CalendarModal';
import SpDashbooardSkeleton from '@molecules/SpDashbooardSkeleton';
import { useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { isToday } from 'date-fns';
import { format } from 'date-fns-tz';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';

import Card from './Card';
import DateCarousel from './DateCarousel';
import { WeeklyTrend } from './WeeklyTrend';
import { ReportType, ReportTypeIconWithProps } from '../../constants/ReportType';
import PetChangeModal from '../PetDashboardHeaderMolecules/PetChangeModal';
import PetOptionsModal from '../PetDashboardHeaderMolecules/PetOptionsModal';
import AllPetsBottomSheet from '../PetDashboardReport/AllPetsBottomSheet';
import { PetsHeader } from '../PetsHeader';
import { Tabs } from '../Tabs';

enum CardType {
  drinking,
  feeding,
  movement,
}

interface PetDashboardSummaryProps {
  pet: PetModel;
  dateRange: number[];
  selectedDate: number;
  onChangeDate: (data: number) => void;
  petStatistic: PetStatisticsModel;
  petStatisticsLoading: boolean;
}

export const Dashboard = ({
  selectedDate,
  dateRange,
  pet,
  onChangeDate,
  petStatistic,
  petStatisticsLoading,
}: PetDashboardSummaryProps) => {
  const navigation = useNavigation();
  const [activeTab, setActiveTab] = useState(null);
  const tabs = useGetAllPetReports(pet);
  const [type] = useState<CardType>(CardType.drinking);
  const [petsBottomSheetShown, setPetsBottomSheetShown] = useState(false);

  const { t } = useTranslation();
  const warningDates: { [key: string]: { [key: string]: string[] } } = {
    feeding: {
      feeding_reduced: ['2023-05-03', '2023-05-01'],
    },
    drinking: {
      drinking_reduced: ['2023-04-26', '2023-05-01'],
      drinking_elevated: ['2023-04-28', '2023-05-02'],
    },
    movement: {
      movement_reduced: ['2023-04-22', '2023-05-04'],
    },
  };
  const petDevices = usePetDevices(pet);
  const convertedDate = format(new Date(selectedDate), 'yyyy-MM-dd');

  const searchWarnings = useMemo(() => {
    const allWarningsDates: any[] = [];
    let todayWarningDate;
    const obj = warningDates[CardType[type]];
    Object.keys(obj).forEach((prop) => {
      if (prop) {
        allWarningsDates.push(...obj[prop]);
        if (obj[prop].includes(convertedDate)) {
          todayWarningDate = {
            [prop]: obj[prop][obj[prop].indexOf(convertedDate)],
          };
        }
      }
    });
    return [allWarningsDates, todayWarningDate];
  }, [type, warningDates, selectedDate, convertedDate]);

  const renderTab = useCallback(
    (item: ReportType) => {
      const Icon = ReportTypeIconWithProps[item];
      return (
        <SpView>
          <Icon
            viewBox="0 0 24 26"
            color={activeTab === item ? undefined : 'rgba(38, 58, 67, 0.3)'}
          />
        </SpView>
      );
    },
    [activeTab],
  );

  useEffect(() => {
    if (activeTab) return;
    if (tabs.length) {
      setActiveTab(tabs[0]);
    } else {
      setActiveTab(null);
    }
  }, [JSON.stringify(tabs)]);

  const isNotAssigned = petDevices.length === 0;
  const isAtThisDay = isToday(selectedDate);
  const showReturnToToday = !isToday(selectedDate);

  return (
    <>
      <PetsHeader
        activePet={pet}
        name="PetDashboardSummary"
        navigation={navigation}
        openPetsBottomSheet={() => setPetsBottomSheetShown(true)}>
        <DateCarousel
          dateRange={dateRange}
          selectedDate={convertedDate}
          setSelectedDate={onChangeDate}
          warningDates={searchWarnings[0] as string[]}
        />
      </PetsHeader>
      <SpView style={{ height: 74, backgroundColor: 'white', zIndex: -1 }} />
      <LinearGradient
        start={{ x: 3, y: 2 }}
        end={{ x: 3, y: 0 }}
        colors={['#e4e2e4', '#FFF']}
        style={{ height: 20, zIndex: -1 }}
      />
      <PetChangeModal modal="PetDashboardSummaryChangePet" />
      <PetOptionsModal />
      <CalendarModal
        modal="PetDashboardSummaryCalendar"
        selectedDate={convertedDate}
        setSelectedDate={onChangeDate}
        warningDates={searchWarnings[0] as string[]}
      />
      <>
        {tabs && tabs.length > 1 && (
          <Tabs data={tabs} active={activeTab} renderItem={renderTab} onChange={setActiveTab} />
        )}
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <SpView testID="PetDashboardSummary" style={styles.container}>
            {petStatisticsLoading ? (
              <SpDashbooardSkeleton />
            ) : (
              <SpView style={styles.cardWrapper}>
                <Card
                  petStatistic={petStatistic}
                  activeTab={activeTab}
                  isNotAssigned={isNotAssigned}
                  petId={pet.id}
                  isAtThisDay={isAtThisDay}
                />
              </SpView>
            )}
            <SpView paddingBottom={20}>
              {!petStatisticsLoading && (
                <WeeklyTrend
                  activeTab={activeTab}
                  petStatistic={petStatistic}
                  selectedDate={selectedDate}
                />
              )}
              {showReturnToToday && (
                <SpRoundedHeaderButton
                  title={t('return_to_today')}
                  stylesForContainer={styles.buttonContainer}
                  backgroundColor={colors.greyText.color}
                  onPress={() => {
                    onChangeDate(+new Date());
                  }}
                />
              )}
            </SpView>
          </SpView>
        </ScrollView>
      </>
      {petsBottomSheetShown && (
        <AllPetsBottomSheet
          petId={pet.id}
          setIsShown={setPetsBottomSheetShown}
          resetTab={() => setActiveTab(null)}
        />
      )}
    </>
  );
};

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white.color,
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: windowHeight < 810 ? '1%' : 15,
    paddingBottom: windowHeight < 810 ? '0.1%' : 30,
  },
  cardWrapper: {
    paddingHorizontal: 24,
  },
  buttonContainer: {
    alignSelf: 'center',
    marginTop: 20,
    width: '85%',
  },
});
