import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import React, { PropsWithChildren } from 'react';

import { SafeAreaView, ScrollView, StyleSheet, View, Text } from 'react-native';
import { LayoutFooter } from './LayoutFooter';

interface LayoutProps {
  withBackButton?: boolean;
  nextButtonText: string;
  isLoading?: boolean;
  disabled?: boolean;
  onNext: () => void;
}

export const Layout = ({
  withBackButton = false,
  isLoading = false,
  disabled = false,
  nextButtonText,
  onNext,
  children,
}: PropsWithChildren<LayoutProps>) => {
  return (
    <SafeAreaView style={styles.view}>
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps="handled"
      >
        <SpView flex={1}>{children}</SpView>
        <SpView style={styles.block} />
      </ScrollView>
      <LayoutFooter
        withBackButton={withBackButton}
        nextButtonText={nextButtonText}
        onNext={onNext}
        isLoading={isLoading}
        disabled={disabled}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 19,
  },
  block: {
    height: 46,
    width: '100%',
  },
});
