import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';
import { AppImages } from '@constants/AppImages';

export const FirstConfigureProduct = () => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.container}>
      <SpView style={styles.container}>
        <SpView>
          <SpText style={styles.title}>{t('using_the_bowl')}</SpText>
        </SpView>
        <SpView>
          <SpText style={styles.description}>
            {t('before_run_through_cdb')}
          </SpText>
        </SpView>
        <SpView>
          <Image style={styles.img} source={AppImages.cdbConfigureInitital} />
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 100,
    alignItems: 'center',
    maxHeight: '85%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  description: {
    fontSize: 14,
    paddingBottom: 20,
    textAlign: 'center',
  },
  img: {
    alignItems: 'center',
  },
});
