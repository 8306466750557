import qs from 'qs';
import Http from './Http';

class InviteApi {
  static readonly httpParams = {};

  static getInvites() {
    return Http.get(`/api/invite`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static getInviteById(inviteId: number) {
    return Http.get(`/api/invite/${inviteId}`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static acceptInvite(inviteCode: string) {
    return Http.post(`/api/invite/${inviteCode}/accept`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static rejectInvite(inviteCode: string) {
    return Http.post(`/api/invite/${inviteCode}/reject`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default InviteApi;
