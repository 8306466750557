import React, { useMemo } from 'react';
import { StyleSheet, Image, Text } from 'react-native';
import textStyles from '@styles/text';
import atomStyles from '@styles/atoms';
import { useTranslation } from 'react-i18next';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SpView } from '@atoms/SpView';
import { navigationRef } from 'src/pages/RootNavigation';
import useBoundStore from 'src/store/store';
import { MergedInterfaces } from '../../../store/models';
import { ReactSetState } from './WhatIsChanging';

type Props = {
  setStep: ReactSetState<number>;
  daysToExpire: number;
};

const Announcement: React.FC<Props> = ({ setStep, daysToExpire }) => {
  const { t } = useTranslation();
  const { updateProperty } = useBoundStore(
    (state: MergedInterfaces) => state.UIStore,
  );
  const doItNow = daysToExpire <= 0;

  const daysLeft = useMemo(() => {
    return (daysToExpire || 0) >= 0 ? daysToExpire : 0;
  }, [daysToExpire]);

  const pressHandler = async () => {
    if (doItNow) {
      navigationRef.navigate('RemoveAccountNavigation');
    } else {
      updateProperty({ allowTemporaryViewAppBasedOnTerms: true });
      navigationRef.navigate('DashboardNavigation', { screen: 'Pets' });
    }
  };

  return (
    <SpView flex={1} paddingVertical={19}>
      <SpView alignItems="center">
        <Image source={require('assets/logo-black.png')} style={styles.image} />
      </SpView>
      <SpText style={textStyles.title}>{t('terms_change_notify')}</SpText>
      <SpText style={[textStyles.body, styles.description]}>
        {t('terms_change_description')}
      </SpText>
      <SpText style={[textStyles.loadingText, styles.affect]}>
        {t('terms_change_affect')}
      </SpText>
      <SpText style={[textStyles.body, styles.subDescription]}>
        {t('terms_change_affect_description')}
      </SpText>
      <Text style={[styles.warning, styles.megaTop]}>
        {t('terms_change_days_left')}
      </Text>
      <Text style={[styles.warning, styles.smallTop]}>{daysLeft || 0}</Text>
      <SpView style={styles.footer}>
        <SpRoundedHeaderButton
          title={t('continue')}
          backgroundColor={colors.primary.color}
          h={56}
          stylesForContainer={styles.max}
          onPress={() => setStep(curr => curr + 1)}
        />
        <TouchableOpacity onPress={pressHandler}>
          <SpText style={[textStyles.loadingText, styles.link]}>
            {t(!doItNow ? 'review_later' : 'deleteAccount')}
          </SpText>
        </TouchableOpacity>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  description: {
    marginVertical: 24,
    color: colors.greyText.color,
  },
  max: {
    width: '100%',
    marginTop: 64,
  },
  image: {
    width: 60,
    height: 88,
    marginTop: 36,
    marginBottom: 39,
  },
  affect: {
    fontSize: 16,
    marginTop: 0,
  },
  subDescription: {
    marginTop: 12,
    color: colors.greyText.color,
  },
  megaTop: {
    marginTop: 36,
  },
  warning: {
    color: colors.errorRed.color,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  smallTop: {
    marginTop: 10,
  },
  link: {
    fontSize: 16,
    marginTop: 26,
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
});

export default Announcement;
