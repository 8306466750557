import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { View } from 'react-native';
import { testProperties } from '@utils/testProperties';

interface SpPlusIconProps {
  color?: string;
}

export const SpPlusIcon = ({ color }: SpPlusIconProps) => (
  <View {...testProperties('faPlus', 'icon')}>
    <FontAwesomeIcon color={color} size={25} icon={faPlus} />
  </View>
);
