import { SpLoading } from '@atoms/SpLoading';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';
import { useCustomToast } from '@hooks/useToast';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect } from 'react';

import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { DrinkingCard } from '../molecules/DrinkingDevice/DrinkingCard';

interface DrinkingDeviceWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const DrinkingDeviceWrapper = ({ data, deviceId }: DrinkingDeviceWrapperProps) => {
  const toast = useCustomToast();
  const { loadingDevice, error, success, resetStatusFields, updateDrinkingTare } = useBoundStore(
    (state: MergedInterfaces) => {
      const { deviceStore } = state;
      return {
        loadingDevice: deviceStore.loadingDevice,
        error: deviceStore.error,
        success: deviceStore.success,
        resetStatusFields: deviceStore.resetStatusFields,
        updateDrinkingTare: deviceStore.updateDrinkingTare,
      };
    },
  );

  const deviceStatus = useDeviceStatusHook(deviceId, true);
  useDeviceStatusParse(deviceStatus);

  const userHasWriteAccess = useUserHasWriteAccess();

  const changeZeroAction = useCallback(() => {
    updateDrinkingTare(data.id);
  }, [data, updateDrinkingTare]);

  useEffect(() => {
    if (error) {
      toast.show({
        description: i18n.t('sorry_try_again'),
        isError: true,
      });
    }

    if (success) {
      toast.show({
        description: i18n.t('felaqua_zeroed'),
      });
    }

    return () => resetStatusFields();
  }, [error, success]);

  useEffect(() => {
    return () => resetStatusFields();
  }, []);

  if (loadingDevice) {
    return <SpLoading />;
  }

  return (
    <DrinkingCard
      data={data}
      userHasWriteAccess={userHasWriteAccess}
      onChangeZero={changeZeroAction}
    />
  );
};
