import { DeviceModel } from '@models/Device';
import i18n from '@utils/i18n';
import React, { useMemo } from 'react';

export const getTimelineDeviceName = (devices: DeviceModel[]) => {
  if (!devices || !devices.length) {
    return i18n.t('timeline_device_is_deleted');
  }

  return devices[0].name || devices[0].serial_number || devices[0].mac_address;
};

export const useTimelineDeviceName = (devices: DeviceModel[]) => {
  return useMemo(() => {
    return getTimelineDeviceName(devices);
  }, [devices]);
};
