import { UserModel } from '@models/User';

export class AnalyticsService {
  static async setUserProperties(
    properties: Record<string, string>,
  ): Promise<void> {
    // await analytics().setUserProperties(properties);
  }

  static async setUserId(id: number): Promise<void> {
    // await analytics().setUserId(id.toString());
  }

  static async logAccount(
    user: UserModel,
    type: 'login' | 'signup',
  ): Promise<void> {
    try{
      // await analytics().logEvent(type, { id: user.id });

    }
    catch(err){
      console.log(err)
    }
  }

  static async logLogout(user: UserModel): Promise<void> {
    try{
      // await analytics().logEvent('log_out', { id: user.id });
    }
    catch(err){
      console.log(err)
    }
  }

  static async logNetworkError(
    url: string,
    status: number,
    rawData: unknown,
    jwt: string,
  ): Promise<void> {
    try{
    const data =
      typeof rawData === 'object'
        ? JSON.stringify(rawData)
        : rawData?.toString() || '';
    // await analytics().logEvent('network_error', { url, status, data, jwt });
    } catch(err){
      console.log(err)
    }
  }

  static async logScreenView(name: string): Promise<void> {
    try{
    // await analytics().logScreenView({
    //   screen_name: name,
    //   screen_class: name,
    // });
  } catch(err){
    console.log(err)
  }
  }

  static async logEvent(name: string, params: Record<string, unknown> = null) {
    try{
    // await analytics().logEvent(name, params);
    } 
    catch(err){
    console.log(err)
    }  
  }
}
