import { GroupReportPointModel } from '@models/ReportModel';
import { endOfDay, subDays } from 'date-fns';
import React, { useMemo } from 'react';

export function useCurrentAndPreviousDays<T>(
  activeDate: number,
  data: GroupReportPointModel<T>[],
  viewedDays: number
): {
  currentDay: GroupReportPointModel<T>;
  rangeData: GroupReportPointModel<T>[];
} {
  return useMemo(() => {
    const currentDay = data.find(item => item.date === activeDate) || null;
    const rangeData: GroupReportPointModel<T>[] = [];

    new Array(viewedDays).fill(true).forEach((_, i) => {
      const prevDate = endOfDay(subDays(activeDate, i)).getTime();
      const prevDayFromList = data.find(item => item.date === prevDate);

      if (prevDayFromList) {
        rangeData.push(prevDayFromList);
      }
    });

    rangeData.unshift(currentDay)

    return {
      currentDay,
      rangeData,
    };
  }, [activeDate, data, viewedDays]);
}
