import { SpLoading } from '@atoms/SpLoading';
import { SpView } from '@atoms/SpView';
import { DeviceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useMemo, useState } from 'react';
import {
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
} from 'react-native';
import colors from '@styles/colors';
import { DeviceModel } from '@models/Device';
import useBoundStore from '../../store/store';
import { DrinkingDeviceWrapper } from './components/DrinkingDeviceWrapper';
import { FeederDeviceWrapper } from './components/FeederDeviceWrapper';
import { HubDeviceWrapper } from './components/HubDeviceWrapper';
import { PetDoorCatFlapDeviceWrapper } from './components/PetDoorCatFlapDeviceWrapper';
import { DeviceStackParamList } from './index';
import { CerberusWrapper } from './components/CerberusWrapper';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';

export const DeviceDetails = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceDetails'>) => {
  const { id } = route.params;
  const [refreshControlLoading, setRefreshControlLoading] = useState(false);
  const device: DeviceModel = useDeviceById(id);
  const deviceStoreData = useBoundStore(state => ({
    loading: state.deviceStore.loadingDevice,
    load: state.deviceStore.loadDevice,
    getHousehold: state.householdStore.getHouseholds,
    loadingControl: state.deviceStore.loadingControl
  }));
  const productId = device?.product_id;
  const deviceStatus = useDeviceStatusHook(id, true)
  useDeviceStatusParse(deviceStatus);

  const onRefresh = () => {
    setRefreshControlLoading(true);
    deviceStoreData.getHousehold();
    deviceStoreData.load(true);

  };

  const deviceSection = useMemo(() => {
    if (refreshControlLoading) {
      return null;
    }

    if (
      productId === DeviceType.PetDoorConnect ||
      productId === DeviceType.CatFlapConnect
    ) {
      return <PetDoorCatFlapDeviceWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.FeederConnect) {
      return <FeederDeviceWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.Cerberus) {
      return <CerberusWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.FelaquaConnect) {
      return <DrinkingDeviceWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.Hub) {
      return <HubDeviceWrapper deviceId={id} data={device} />;
    }

    return <SpLoading />;
  }, [device, refreshControlLoading, productId]);

  useEffect(() => {
    if (!deviceStoreData.loading) {
      setRefreshControlLoading(false);
    }
  }, [deviceStoreData.loading]);

  return (
    <SafeAreaView style={styles.fullHeight}>
      <ScrollView
        contentContainerStyle={styles.fullHeight}
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={onRefresh} />
        }
      >
        <SpView paddingHorizontal={18} flex={1}>
          {deviceSection}
        </SpView>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  fullHeight: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
});
