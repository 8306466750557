import { testProperties } from '@utils/testProperties';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { SpProfileImage } from '../../../components/atoms/SpProfileImage';
import { UserModel } from '../../../models/User';

export interface AccountOverviewProps {
  user: UserModel;
}

export const AccountOverview = ({ user }: AccountOverviewProps) => {
  return (
    <View style={styles.card}>
      <SpProfileImage
        isUser
        width={92}
        height={92}
        imageUrl={user?.photo?.location}
      />
      <View style={styles.details}>
        <Text
          {...testProperties('account name', 'text')}
          numberOfLines={1}
          style={styles.name}
        >{`${user?.first_name} ${user?.last_name}`}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  details: {
    marginLeft: 12,
    flexDirection: 'column',
    flex: 1,
  },
  name: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
    marginBottom: 12,
  },
});
