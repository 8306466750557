/* eslint-disable class-methods-use-this */
import { lens } from '@dhmk/zustand-lens';
import { StateCreator } from 'zustand';
import TagApi from '@api/TagApi';
import { MergedInterfaces, TagSlice } from '../models';

const createTagSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  TagSlice
> = (set, get) => {
  return {
    tagStore: lens((subSet, subGet, api) => ({
      tagData: [],
      loadingTag: false,
      activeTag: null,
      setActiveTag: (tagId: number) => {
        const activeTag = subGet().getTagById(tagId);
        subSet({ activeTag });
      },
      getTagById: (tagId: number) => {
        const { tagData } = subGet();
        return tagData.filter(item => item.id === tagId)[0];
      },
      loadTag: async (force = false) => {
        if (!force && subGet().tagData.length) {
          return;
        }
        subSet({ loadingTag: true });
        try {
          const tagData = await TagApi.getTags();
          subSet({
            tagData,
          });
        } catch ({ response }) {
          subSet({ loadingTag: false });
        }
        subSet({ loadingTag: false });
      },
    })),
  };
};

export default createTagSlice;
