import { Pressable, StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import { SpView } from '@atoms/SpView';
import { SpProfileImage } from '@atoms/SpProfileImage';
import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { Switch } from 'react-native-switch';
import noop from '@utils/noop';
import { testProperties } from '@utils/testProperties';
import atomStyles from '@styles/atoms';
import LoadingSpinner from '@atoms/Loader';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';

type IndoorModePetItemType = {
  imageUrl: string;
  assigned?: boolean;
  indoorModeEnabled?: boolean;
  name: string;
  created?: string;
  withAssignedIcon?: boolean;
  handlePress?: () => void;
  isIndoorMode?: boolean;
  isAlternativeStyle?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isIncompatible?: boolean;
  id?: number
};

const IndoorModePetItem = ({
  imageUrl,
  assigned,
  indoorModeEnabled,
  name,
  id,
  handlePress,
  created = null,
  withAssignedIcon = false,
  isIndoorMode = false,
  isAlternativeStyle = false,
  isDisabled = false,
  isLoading = false,
  isIncompatible = false,
}: IndoorModePetItemType) => {
  const { t } = useTranslation();
  const loadingControl = useBoundStore(s => s.deviceStore.loadingControl)
  const check = useMemo(() => {
    let result = true;
    if (loadingControl.tag_profiles) {
      loadingControl.tag_profiles.forEach(item => item.tag_id === id ? result = false : null);
    }
    return result;
  }, [loadingControl, id])
  

  return isIncompatible ? (
    <Pressable
      accessible={false}
      style={[styles.container, styles.isLoading]}
      onPress={handlePress}
    >
      <SpView style={styles.petItem}>
        <SpView marginRight={16} {...testProperties(`${name}`, `PetImage`)}>
          <SpProfileImage width={48} height={48} imageUrl={imageUrl} />
        </SpView>
        <SpView style={styles.petDetails}>
          <SpText style={styles.name}>{name}</SpText>
          <SpView>
            <SpText style={styles.time}>{t('incompatible_pet')}</SpText>
          </SpView>
        </SpView>
      </SpView>
      <View style={!assigned ? atomStyles.alternativeCheckbox : null}>
        <FontAwesomeIcon
          size={32}
          icon={faArrowRight}
          color={colors.greyDisabled.color}
        />
      </View>
    </Pressable>
  ) : (
    <Pressable
      accessible={false}
      style={[
        styles.container,
        isLoading ? styles.isLoading : {},
        isDisabled ? styles.isLoading : {},
        isIncompatible ? styles.isLoading : {},
      ]}
      onPress={isDisabled ? noop : handlePress}
    >
      <SpView style={styles.petItem}>
        <SpView marginRight={16} {...testProperties(`${name}`, `PetImage`)}>
          <SpProfileImage width={48} height={48} imageUrl={imageUrl} />
        </SpView>
   
        {indoorModeEnabled && (!loadingControl.tag_profiles || check) ? (
          <>
            <SpView style={styles.petDetails}>
              <SpText style={styles.name}>{name}</SpText>
              <SpText style={styles.enabled}>
                {indoorModeEnabled && t('enabled')}
              </SpText>
            </SpView>
            <SpView>
              <SpText style={styles.time}>{created}</SpText>
            </SpView>
          </>
        ) : (
          <SpView style={styles.petDetails}>
            <SpText style={styles.name}>{name}</SpText>
        
          </SpView>
        )}
       
      </SpView>

      {/* {!isIndoorMode &&
        !isAlternativeStyle &&
        (withAssignedIcon ? (
          <View
            {...testProperties(
              assigned ? 'faCheckCircle' : 'faCirclePlus',
              `icon`,
            )}
            style={!assigned ? atomStyles.alternativeCheckbox : null}
          >
            <FontAwesomeIcon
              size={32}
              icon={assigned ? faCheckCircle : faCircle}
              color={assigned ? colors.primary.color : colors.white.color}
            />
          </View>
        ) : (
          <View {...testProperties('faAngleRight', 'icon')}>
            <FontAwesomeIcon
              size={20}
              icon={faAngleRight}
              color={colors.greyText.color}
            />
          </View>
        ))} */}

      {isAlternativeStyle && (
        <SpView
          {...testProperties('faCheckCircle', `icon_${name || ''}`)}
          style={atomStyles.alternativeCheckbox}
        >
          {isLoading && <LoadingSpinner size="small" />}
          {assigned && !isLoading && (
            <FontAwesomeIcon
              size={32}
              icon={faCheckCircle}
              color={colors.primary.color}
            />
          )}
        </SpView>
      )}
      {isIndoorMode && (!loadingControl.tag_profiles || check) ? (
        <Switch
          value={indoorModeEnabled}
          onValueChange={handlePress}
          disabled={false}
          renderActiveText={false}
          renderInActiveText={false}
          backgroundActive={colors.green.color}
          backgroundInactive={colors.greyBorder.color}
          circleSize={25}
          circleBorderWidth={0}
          containerStyle={{
            minHeight: 32,
          }}
          innerCircleStyle={{
            width: 25,
            height: 25,
            backgroundColor: colors.white.color,
          }}
          switchBorderRadius={18}
          switchLeftPx={3}
          switchRightPx={3}
          {...testProperties(`${name} pet`,'switch')}
        />
      ) : <LoadingSpinner/>}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 17,
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    marginBottom: 25,
  },
  petItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  petDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  name: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
  },
  time: {
    fontWeight: 'bold',
    fontSize: 12,
    display: 'flex',
  },
  enabled: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.green.color,
  },
  isLoading: {
    opacity: 0.6,
  },
});

export default IndoorModePetItem;
