import { AppImages } from '@constants/AppImages';
import { PetDoorLockingMode } from '@constants/Device';
import i18n from '@utils/i18n';
import { ImageRequireSource } from 'react-native';

export const LockOptionInfo: Record<
  PetDoorLockingMode,
  { icon: ImageRequireSource; label: string }
> = {
  [PetDoorLockingMode.outside_only]: {
    icon: AppImages.lockOptionOutside,
    label: i18n.t('kept_outside'),
  },
  [PetDoorLockingMode.both]: {
    icon: AppImages.lockOptionBoth,
    label: i18n.t('locked_both_ways'),
  },
  [PetDoorLockingMode.inside_only]: {
    icon: AppImages.lockOptionInside,
    label: i18n.t('kept_inside'),
  },
  [PetDoorLockingMode.normal]: null,
  [PetDoorLockingMode.curfew]: null,
};

export const UnlockOptionInfo: Record<
  PetDoorLockingMode,
  { icon: ImageRequireSource; label: string }
> = {
  [PetDoorLockingMode.outside_only]: {
    icon: AppImages.lockOptionOutside,
    label: i18n.t('lock_status_locked_outside_info'),
  },
  [PetDoorLockingMode.both]: {
    icon: AppImages.lockOptionBoth,
    label: i18n.t('lock_status_locked_both_info'),
  },
  [PetDoorLockingMode.inside_only]: {
    icon: AppImages.lockOptionInside,
    label: i18n.t('lock_status_locked_inside_info'),
  },
  [PetDoorLockingMode.normal]: null,
  [PetDoorLockingMode.curfew]: null,
};
