import { SpView } from '@atoms/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import GradientButton from '@atoms/GradientButton';
import colors from '@styles/colors';
import LoadingSpinner from '@atoms/Loader';
import ZeroSVG from '@atoms/SvgIcons/ZeroSVG';
import noop from '@utils/noop';
import { FeederCardModal } from '../../constants/FeederCard';
import useBoundStore from '../../../../store/store';

interface FooterActionsProps {
  onAction: (data: FeederCardModal) => void;
}

export const FeederFooterActions = ({ onAction }: FooterActionsProps) => {
  const { t } = useTranslation();
  const updateZeroScalesLoading = useBoundStore(
    state => state.deviceStore.updateZeroScalesLoading,
  );

  return (
    <SpView style={styles.column}>
      <GradientButton
        disabled={updateZeroScalesLoading}
        isLoading={updateZeroScalesLoading}
        colorsGradient={['rgba(0, 189, 136, 0.4)', 'rgba(45, 181, 142, 0.4)']}
        borderRadius={18}
        text={t('zero')}
        icon={
          <ZeroSVG
            color={colors.greyText.color}
            width={45}
            height={45}
            viewBox="0 0 28 28"
          />
        }
        action={() => onAction(FeederCardModal.BowlType)}
      />
      {updateZeroScalesLoading && (
        <SpView style={styles.loadingSpinner}>
          <LoadingSpinner color="#ffffff" size="large" />
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  column: {
    paddingHorizontal: 10,
    alignItems: 'center',
    marginVertical: 20,
  },
  loadingSpinner: {
    position: 'absolute',
    height: '100%',
    justifyContent: 'center',
  },
});
