import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import React from 'react';
import colors from '@styles/colors';
import { SpText } from '@atoms/SpText';
import ProgressLine from './ProgressLine';
import { testProperties } from '@utils/testProperties';

interface ProgressHeaderProps {
  title: string;
  step: number;
  stepsCount: number;
  style?: StyleProp<ViewStyle> | undefined;
  isShowShadow?: boolean | undefined;
}

const ProgressHeader = ({
  title,
  step,
  stepsCount,
  style,
  isShowShadow,
}: ProgressHeaderProps) => {
  return (
    <View
      {...testProperties('ProgressBar', 'Wrapper')}
      style={[
        styles.header,
        step === stepsCount && styles.headerLastStep,
        style || {},
      ]}
    >
      <SpText style={styles.title}>{title}</SpText>
      <View
        {...testProperties(`${title}`, 'ProgressBarLineWrapper')}
        style={{
          paddingHorizontal: 19,
          width: '100%',
          backgroundColor: '#ffffff',
          zIndex: 1,
        }}
      >
        <ProgressLine
          {...testProperties(`${title}`, 'ProgressBar')}
          step={step}
          steps={stepsCount}
        />
      </View>
      <View style={[isShowShadow ? styles.shadowStyle : { height: 32 }]} />
    </View>
  );
};

export default ProgressHeader;

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    paddingTop: 8,
    zIndex: 2,
  },
  headerLastStep: {
    marginTop: 48,
  },
  title: {
    color: colors.greyDisabled.color,
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
    marginBottom: 8,
  },
  shadowStyle: {
    height: 32,
    width: '100%',
    backgroundColor: 'rgb(255,255,255)',
    shadowColor: 'lightgrey',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
  },
});
