import { SpProgressBar } from '@atoms/SpProgressBar';
import { SpView } from '@atoms/SpView';
import DrinkingInactiveSVG from '@atoms/SvgIcons/DrinkingInactiveSVG';
import colors from '@styles/colors';
import React from 'react';

interface FelaquaStatusProps {
  fillLevel: number[];
}
export const FelaquaStatus = ({ fillLevel }: FelaquaStatusProps) => {
  return (
    <SpView flex={1}>
      <SpView flex={1} marginRight={6}>
        <SpProgressBar
          value={fillLevel[0]}
          width={20}
          indicatorColors={['#83daf0', '#45acd9']}
          rotate
        />
        <SpView marginTop={8}>
          <DrinkingInactiveSVG opacity={1} fill={colors.black.color} width={20} height={20} />
        </SpView>
      </SpView>
    </SpView>
  );
};
