import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';

type HavingIssuesFindingWiFiProps = {};

const HavingIssuesFindingWiFi: React.FC<HavingIssuesFindingWiFiProps> = () => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.container}>
      <SpView style={styles.paragraphText}>

        <SpText color='#fff' size='sm' style={styles.textTitle}>
          Having issues finding your Home wifi?
        </SpText>
        <SpText color='#fff' size='sm' style={styles.paragraph}>
          Check the following before seeking further help:
        </SpText>
      </SpView>

      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText style={styles.textTitle} color={'white'}>
            2.4GHz only
          </SpText>
        </SpView>
        <SpView>
          <SpText style={styles.textDescription} color={'white'}>
            Make sure your Wi-Fi network is 2.4GHz, unfortunately the Dog Bowl
            Connect does not support 5GHz networks.
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText style={styles.textTitle} color={'white'}>
            Check connection
          </SpText>
        </SpView>
        <SpView>
          <SpText style={styles.textDescription} color={'white'}>
            Make sure your Wi-fi router is turned on and has a good connection.
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText style={styles.textTitle} color={'white'}>
            Router distance
          </SpText>
        </SpView>
        <SpView>
          <SpText style={styles.textDescription} color={'white'}>
            Try getting closer to your Wi-Fi router
          </SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.helpScreenColor.color,
    color: 'white',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  paragraph: {
    color: 'white',
    fontSize: 16,
    marginTop: 15,
  },
  paragraph2: {
    color: 'white',
    fontSize: 16,
    marginTop: 55,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 20,
    marginBottom: 5,
  },
  paragraphDesc: {
    color: 'white',
    fontSize: 14,
  },
  link: {
    color: 'white',
    marginTop: 25,
    fontWeight: 'bold',
    fontSize: 16,
  },
  question: {
    fontSize: 20,
  },
  wrapperIconInText: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 20,
  },
  iconStyle: {
    backgroundColor: colors.helpScreenColor.color,
    flex: 1,
  },
  textIcon: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    fontSize: 22,
  },
  textTitle: {
    fontSize: 18,
    fontFamily: 'Rubik_SemiBold',
    marginBottom: 8,
  },
  textDescription: {
    fontSize: 16,
  },
  paragraphText: {
    marginBottom: 30,
  },
});
export default HavingIssuesFindingWiFi;
