import { SpSuccessTick } from '@molecules/SpSuccessTick';
import colors from '@styles/colors';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, ViewStyle } from 'react-native';
import LoadingSpinner from '@atoms/Loader';
import { SpText } from '@atoms/SpText';
import i18 from '@utils/i18n';
import { DeviceType } from '@constants/Device';
import { useTranslation } from 'react-i18next';
import { SpView } from './SpView';

interface IEssenceCreatedProps {
  msg: string;
  onSubmit?: (arg: (arg: boolean) => void) => void;
  err?: string;
  initialValue?: boolean;
  containerStyle?: ViewStyle;
  productId?: number;
}

const EssenceCreated = ({
  msg,
  onSubmit,
  err,
  initialValue = true,
  containerStyle = {},
  productId = null,
}: IEssenceCreatedProps) => {
  const [created, setCreated] = useState(initialValue);
  const { t } = useTranslation();

  useEffect(() => {
    if (onSubmit) {
      onSubmit(setCreated);
    }
  }, [setCreated]);

  const suspenceLoader = useMemo(() => {
    if (created) {
      return <SpSuccessTick text={msg} />;
    }

    if (err) {
      return <Text style={styles.error}>{err}</Text>;
    }

    return <LoadingSpinner color={colors.primary.color} />;
  }, [created, err]);

  return (
    <>
      <SpView style={[styles.container, containerStyle]}>
        {suspenceLoader}
      </SpView>
      <SpView style={styles.textDown}>
        {productId && productId === DeviceType.FelaquaConnect && (
          <SpText size="xl">{t('lets_add_pet_now')}</SpText>
        )}
      </SpView>
    </>
  );
};

export default EssenceCreated;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: colors.greyText.color,
    marginTop: 12,
    fontFamily: 'Rubik_Medium',
  },
  error: {
    fontSize: 12,
    color: 'red',
    textAlign: 'center',
    marginTop: 20,
  },
  createdContainer: {
    alignItems: 'center',
  },
  textDown: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 300,
  },
});
