import { lastScreenOptions } from '@constants/Screen';
import { HouseholdModel } from '@models/Household';
import CustomHeader from '@molecules/CustomHeader';
import { CommonActions } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useBoundStore from '../../store/store';
import { navigationRef } from '../RootNavigation';
import ResetPassword from './ResetPassword';
import { ConfirmStep } from './ConfirmStep';
import { ConfirmRemoveHouseholdDialog } from './ConfirmRemoveHouseholdDialog';
import { ConfirmRemoveHouseholdDialogContextProvider } from './contexts/ConfirmRemoveHouseholdDialogContext';
import { OwnerStep } from './OwnerStep';
import { OwnerMultipleStep } from './OwnerMultipleStep';
import { PreviewStep } from './PreviewStep';
import { RemoveAccountInformationDialog } from './RemoveAccountInformationDialog';
import { RemoveHouseholdInformationDialog } from './RemoveHouseholdInformationDialog';
import { RemoveSuccess } from './RemoveSuccess';

export type RemoveAccountParamList = {
  PreviewStep: undefined;
  ConfirmStep: {
    selectedHouseholds: HouseholdModel[];
  };
  OwnerStep: {
    selectedHouseholds: HouseholdModel[];
  };
  OwnerMultipleStep: {
    selectedHouseholds: HouseholdModel[];
  };
  RemoveAccountInformationDialog: undefined;
  RemoveHouseholdInformationDialog: undefined;
  ConfirmRemoveHouseholdDialog: undefined;
  RemoveSuccess: undefined;
  ResetPassword: {
    selectedHouseholds: HouseholdModel[];
  };
};

const Stack = createStackNavigator<RemoveAccountParamList>();

export default function RemoveAccountNavigation() {
  const { t } = useTranslation();
  const isAuthenticated = useBoundStore(s => s.accountStore.isAuthenticated());

  if (!isAuthenticated) {
    return null;
  }

  return (
    <ConfirmRemoveHouseholdDialogContextProvider>
      <Stack.Navigator initialRouteName="PreviewStep">
        <Stack.Screen
          name="PreviewStep"
          options={{
            header: () =>
              CustomHeader({
                withTitle: false,
                withLeaveButton: true,
                withArrowBack: false,
              }),
          }}
          component={PreviewStep}
        />
        <Stack.Screen
          name="ConfirmStep"
          options={{
            header: () =>
              CustomHeader({
                withTitle: false,
                withLeaveButton: true,
                withArrowBack: false,
              }),
          }}
          component={ConfirmStep}
        />
        <Stack.Screen
          name="OwnerStep"
          options={{
            header: () =>
              CustomHeader({
                withTitle: false,
                withLeaveButton: true,
                withArrowBack: false,
              }),
          }}
          component={OwnerStep}
        />
        <Stack.Screen
          name="OwnerMultipleStep"
          options={{
            header: () =>
              CustomHeader({
                withTitle: false,
                withLeaveButton: true,
                withArrowBack: false,
              }),
          }}
          component={OwnerMultipleStep}
        />
        <Stack.Screen
          name="RemoveAccountInformationDialog"
          options={{ ...lastScreenOptions() }}
          component={RemoveAccountInformationDialog}
        />
        <Stack.Screen
          name="RemoveHouseholdInformationDialog"
          options={{ ...lastScreenOptions() }}
          component={RemoveHouseholdInformationDialog}
        />
        <Stack.Screen
          name="ConfirmRemoveHouseholdDialog"
          options={{ ...lastScreenOptions() }}
          component={ConfirmRemoveHouseholdDialog}
        />
        <Stack.Screen
          name="RemoveSuccess"
          options={{
            headerShown: false,
            header: () => CustomHeader({ withTitle: false }),
          }}
          component={RemoveSuccess}
        />
        <Stack.Screen
          name="ResetPassword"
          options={{
            headerShown: true,
            header: () => CustomHeader({ withTitle: false }),
          }}
          component={ResetPassword}
        />
      </Stack.Navigator>
    </ConfirmRemoveHouseholdDialogContextProvider>
  );
}
