import {Pressable} from 'react-native';
import React, { ReactNode } from 'react';
import { SpCenter } from '@atoms/SpCenter';
import { testProperties } from '@utils/testProperties';

interface SpRoundIconProps {
  icon: JSX.Element | ReactNode;
  action?: () => void;
  size?: number;
  color?: string;
  componentName?: string;
}

export const SpRoundIcon = ({
  icon,
  action,
  size = 10,
  color = 'gray',
  componentName,
}: SpRoundIconProps) => (
  <Pressable
    {...testProperties('icon', 'button', componentName)}
    onPress={action}
  >
    <SpCenter
      height={size * 4}
      width={size * 4}
      borderRadius={size * 4}
      backgroundColor={color}
    >
      {icon}
    </SpCenter>
  </Pressable>
);