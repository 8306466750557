/* eslint-disable consistent-return */
import { SpLoading } from '@atoms/SpLoading';
import { PetBowlFoodType, SubstanceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { useCustomToast } from '@hooks/useToast';
import { DeviceCerberusControlModel } from '@models/Device';
import { StackScreenProps } from '@react-navigation/stack';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { SpText } from '@atoms/SpText';
import SpTitle from '@molecules/SpTitle';
import noop from '@utils/noop';
import { MergedInterfaces } from '../../store/models';
import useBoundStore from '../../store/store';
import { DeviceStackParamList } from './index';
import { DeviceSettingContainer } from './molecules/DeviceSettingContainer';
import { FoodTypes } from './molecules/DeviceBowlSetup/FoodTypes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const DeviceFoodSetup = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceFoodSetup'>) => {
  const { show } = useCustomToast();
  const { id } = route.params;
  const device = useDeviceById(id);
  const { t } = useTranslation();
  const deviceNavigation =
    useNavigation<NavigationProp<DeviceStackParamList>>();
  const { loadingDevice, updateCerberusControl, resetStatusFields } =
    useBoundStore(s => s.deviceStore);

  const [foodType, setFoodType] = useState(
    (device?.control as DeviceCerberusControlModel).food_type || 1,
  );

  const changeCerberusFoodTypeAction = useCallback(
    ($event: PetBowlFoodType) => {
      const control = { food_type: $event } as DeviceCerberusControlModel;
      setFoodType(control.food_type);
    },
    [],
  );

  const onChange = useCallback(async () => {
    const control = { food_type: foodType } as DeviceCerberusControlModel;
    const result = await updateCerberusControl(device.id, control);

    if (!result) {
      show({ description: t('error_update_substance_type'), isError: true });
      return;
    }
    show({ isError: false, description: t('success_update_substance_type') });

    return !loadingDevice
      ? deviceNavigation.navigate('DeviceSetting', { id: device.id })
      : null;
  }, [foodType, loadingDevice]);

  const onBack = useCallback(() => {
    deviceNavigation.goBack();
  }, [])

  useEffect(() => {
    return () => {
      resetStatusFields();
    };
  }, []);

  if (loadingDevice || !device) {
    return <SpLoading />;
  }

  return (
    <DeviceSettingContainer showMarginTop>
      <SpView style={styles.mainWrapper}>
        <SpView>
          <SpTitle text="Select Food Type" align="center" />
        </SpView>
        <SpView style={styles.list}>
          <FoodTypes
            active={foodType}
            onChange={changeCerberusFoodTypeAction}
          />
        </SpView>
      </SpView>
      <SpView style={styles.buttons}>
      <Pressable
        onPress={onBack}
        style={[styles.backBottomButton]}
      >
        <FontAwesomeIcon
          color={colors.greyText.color}
          size={28}
          icon={faChevronLeft}
         />
      </Pressable>
      <SpRoundedHeaderButton
        backgroundColor={colors.primary.color}
        h={56}
        title={t('continue')}
        onPress={onChange}
        isLoading={loadingDevice}
        stylesForContainer={styles.button}
      />
      </SpView>
    </DeviceSettingContainer>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    maxHeight: 400,
    height: '100%'
  },
  backBottomButton: {
    width: 56,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 14,
    borderColor: '#919ba0',
    backgroundColor: 'white',
  },
  button: {
    flexGrow: 1,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-24%',
    gap: 20
  },
});
