import { PetBowlFoodType } from '@constants/Device';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { TimelineEventModel } from '@models/Timeline';
import { WeightFrameModel, WeightModel } from '@models/Weight';
import { filter, get, map } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFoodTypeTranslation } from '../../hooks/useFoodTypeTranslation';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { useTimelinePetName } from '../../hooks/useTimelinePetName';
import { TimelineCard } from './TimelineCard';
import { TimelinePetPhoto } from './TimelinePetPhoto';

export const Feeding = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const petName = useTimelinePetName(event);
  const foodTypeTranslation = useFoodTypeTranslation();

  const { convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode,
  );

  const getSingleBowlText = (
    total: number,
    isIncrease: boolean,
    foodType: PetBowlFoodType,
  ) => {
    const key = isIncrease
      ? 'notification_feeding_increased'
      : 'notification_feeding_decreased';

    return t(key, {
      devicename: deviceName,
      pet: petName,
      foodtype: foodTypeTranslation(foodType),
      gram: convertWithUnits(total, true),
    }).replace(/\s+/g, ' ');
  };

  const handleDualBowl = (data: any, weight: WeightModel) => {
    const total = getTotalWeight(weight?.frames);
    const isIncrease = total > 0;

    const leftFoodType = get(data, 'weight.food_type.0');
    const leftFrame = filter(weight.frames, f => f.index === 0)[0];
    let leftWeight = -Math.round(leftFrame.change);
    leftWeight = leftWeight <= 0 ? 0 : leftWeight;

    const rightFoodType = get(data, 'weight.food_type.1');
    const rightFrame = filter(weight.frames, f => f.index === 1)[0];
    let rightWeight = -Math.round(rightFrame.change);
    rightWeight = rightWeight <= 0 ? 0 : rightWeight;

    if (leftWeight === 0 || rightWeight === 0) {
      return getSingleBowlText(
        leftWeight || rightWeight,
        isIncrease,
        leftWeight ? leftFoodType : rightFoodType,
      );
    }

    const key = isIncrease
      ? `notification_feeding_increased_dual`
      : `notification_feeding_decreased_dual`;

    return t(key, {
      devicename: deviceName,
      pet: petName,
      gram1: convertWithUnits(leftWeight, true),
      foodtype1: foodTypeTranslation(leftFoodType),
      gram2: convertWithUnits(rightWeight, true),
      foodtype2: foodTypeTranslation(rightFoodType),
    });
  };

  const getTotalWeight = (frames: WeightFrameModel[]): number => {
    if (!frames) {
      return 0;
    }

    const totalInitial = map(frames, f => f.current_weight - f.change).reduce(
      (c, f) => c + f,
    );
    const totalFinal = map(frames, f => f.current_weight).reduce(
      (c, f) => c + f,
    );
    return Math.round(totalFinal - totalInitial);
  };

  const handleSingleBowl = (data: any, weight: WeightModel) => {
    const foodType = get(data, 'weight.food_type.0');
    const total = getTotalWeight(weight?.frames);

    return getSingleBowlText(-total, total > 0, foodType);
  };

  const text = useMemo(() => {
    const data = JSON.parse(event.data);
    const weight = event.weights ? event.weights[0] : null;
    const weightCount = get(data, 'weight.count') || 1;

    if (weightCount !== 2) {
      return handleSingleBowl(data, weight);
    }

    return handleDualBowl(data, weight);
  }, [event, deviceName, userWeightUnits, petName, t]);

  return (
    <TimelineCard
      active={active}
      text={text}
      date={event.created_at}
      image={<TimelinePetPhoto active={active} event={event} />}
    />
  );
};
