import { NotificationEventModel } from '@models/Notification';
import Http from './Http';

class NotificationApi {
  static getEvents(): Promise<NotificationEventModel[]> {
    return Http.get(`/api/notification/`).then(response => response.data.data);
  }
}

export default NotificationApi;
