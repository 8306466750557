import colors from '@styles/colors';
import { format } from 'date-fns-tz';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import CalendarStrip, { IDayComponentProps } from 'react-native-calendar-strip';

interface PetDashboardSummaryDateCarouselProps {
  selectedDate: string;
  dateRange: number[];
  setSelectedDate: (data: number) => void;
  warningDates?: string[];
}

const allowInsights = false;

const DateCarousel = ({
  selectedDate,
  dateRange,
  setSelectedDate,
  warningDates,
}: PetDashboardSummaryDateCarouselProps) => {
  const [dateValue, setDateValue] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const calendarStripRef = useRef(null);
  useEffect(() => {
    setStartingDate(new Date(selectedDate));
  }, []);

  useEffect(() => {
    setDateValue(new Date(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (calendarStripRef !== null) {
      calendarStripRef.current.state.selectedDate = new Date(selectedDate);
    }
  }, [dateValue, selectedDate, setSelectedDate]);

  const handleOnDateSelected = dateObj => {
    setSelectedDate(dateObj.valueOf());
    setDateValue(new Date(dateObj.valueOf()));
  };

  const dayComponent = useCallback(
    (props: IDayComponentProps) => {
      const dayObj = new Date(props.date.toJSON());
      const dateToFormat = format(dayObj, 'yyyy-MM-dd');
      const dateNum = format(dayObj, 'd');
      const dayShortName = format(dayObj, 'EEEEEE');
      const isActive = dateToFormat === selectedDate;
      const isWarning = warningDates.includes(dateToFormat);
      const isWarningActive = isActive && isWarning && allowInsights;

      return (
        <TouchableOpacity
          onPress={() => {
            setSelectedDate(dayObj.getTime());
            setDateValue(dayObj);
          }}
          hitSlop={{ left: 3, right: 3, top: 5, bottom: 5 }}
          style={[
            styles.dayComponentContainer,
            {
              backgroundColor: isActive ? colors.secondary.color : 'transparent',
            },
            isWarningActive && { backgroundColor: colors.orangeWarning.color },
          ]}
        >
          <View
            style={[
              styles.dayComponentNumWrap,
              {
                borderWidth: isWarning ? 2 : 0,
                width: isWarning ? 32 : 30,
                height: isWarning ? 32 : 30,
                marginTop: isWarning ? 0 : 1,
              },
              isWarningActive && { borderWidth: 0 },
            ]}
          >
            <Text style={[styles.fontSize16, { color: colors.greyText.color }]}>
              {dateNum}
            </Text>
          </View>
          <Text
            style={[
              styles.fontSize16,
              {
                color: isActive ? colors.white.color : colors.greyText.color,
              },
            ]}
          >
            {dayShortName}
          </Text>
        </TouchableOpacity>
      );
    },
    [warningDates, selectedDate],
  );

  return (
    <CalendarStrip
      ref={calendarStripRef}
      scrollable
      style={styles.container}
      calendarColor={colors.white.color}
      showMonth={false}
      maxDayComponentSize={72}
      dayComponentHeight={72}
      responsiveSizingOffset={-5.7}
      dayComponent={dayComponent}
      showDayName={false}
      leftSelector={[]}
      rightSelector={[]}
      minDate={new Date(dateRange[0])}
      maxDate={new Date(dateRange[1])}
      startingDate={startingDate}
      scrollToOnSetSelectedDate
      onDateSelected={handleOnDateSelected}
    />
  );
};

export default DateCarousel;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  dayComponentContainer: {
    height: 72,
    width: 45,
    borderRadius: 35,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 4,
    paddingBottom: 8,
    marginLeft: 23,
  },
  dayComponentNumWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white.color,
    borderRadius: 50,
    borderColor: colors.orangeWarning.color,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 8,
  },
  fontSize16: {
    fontSize: 16,
  },
});
