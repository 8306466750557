import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { PetStatisticsModel } from '@models/Device';
import { getSingleDuration } from '@utils/date';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useTotalConsumption from './useTotalConsumption';

export function useSummaryMovementCard(data: PetStatisticsModel['movement']) {
  const { t } = useTranslation();
  const { timeToFormat, convertDateToTime } = useTimeTransform();
  const userTimeFormat = useUserTimeUnits();

  const outside = useTotalConsumption(data?.time_outside || 0);

  const entered = useMemo(() => {
    return data?.last_entry ? timeToFormat(convertDateToTime(data.last_entry), userTimeFormat) : '';
  }, [data, userTimeFormat]);

  const timeAgo = useMemo(() => {
    return data?.last_entry ? getSingleDuration(data.last_entry, true) : '-';
  }, [data]);

  return {
    title: t('last_entered'),
    timeAgo,
    time: entered,
    leftLabel: outside,
    leftValue: t('time_outside'),
    rightLabel: data?.entries || 0,
    rightValue: t('entries'),
  };
}
