/* eslint-disable react/jsx-no-useless-fragment */
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useCurrentHub } from '@hooks/useCurrentHub';
import { useDevicesWithoutHubByHousehold } from '@hooks/useDevicesWithoutHubByHousehold';
import { DeviceModel } from '@models/Device';
import SpDevicesSkeletonLoader from '@molecules/SpDevicesSkeletonLoader';
import {
  NavigationProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, ScrollView } from 'react-native';
import { SpCenter } from '@atoms/SpCenter';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import atoms from '@styles/atoms';
import colors from '@styles/colors';
import { DeviceType } from '@constants/Device';
import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { RootStackParamList } from '../../index';
import { DeviceHierarchy } from '../molecules/DeviceHierarchy/DeviceHierarchy';
import EmptyPlaceholder from '../components/EmptyPlaceholder';

export const Products = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const products = useDevicesWithoutHubByHousehold();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const hubs = useCurrentHub();
  const [initialLoad, setInitialLoad] = useState(false);
  const deviceStoreData = useBoundStore(state => ({
    loading: state.deviceStore.loadingDevice,
    load: state.deviceStore.loadDevice,
    getHousehold: state.householdStore.getHouseholds
  }));
  const timeLineStoreData = useBoundStore((state: MergedInterfaces) => ({
    loading: state.timelineStore.loading,
    load: state.timelineStore.getByHousehold,
    events: state.timelineStore.events,
    reset: state.timelineStore.reset,
  }));

  useEffect(() => {
    deviceStoreData.load();

    return () => {
      timeLineStoreData.reset();
    };
  }, []);

  useEffect(() => {
    if (activeHousehold?.id) {
      timeLineStoreData.load(activeHousehold.id);
    }
  }, [activeHousehold]);

  const goToDevice = useCallback(
    (item: DeviceModel) => {
      navigation.dispatch(
        StackActions.push('DeviceNavigation', {
          screen: 'DeviceDetails',
          params: {
            id: item.id,
          },
        }),
      );
    },
    [navigation],
  );

  const cerberusDevices = useMemo(() => {
    return (hubs || []).filter(hub => hub.product_id === DeviceType.Cerberus);
  }, [hubs]);

  const generateProducts = useCallback(
    (hubId: number) => {
      return products.filter(product => product.parent_device_id === hubId);
    },
    [products],
  );

  if (
    (deviceStoreData.loading || timeLineStoreData.loading) &&
    initialLoad === false
  ) {
    setInitialLoad(true);
    return <SpDevicesSkeletonLoader />;
  }
  const onRefresh = () => {
    deviceStoreData.load(true);
    deviceStoreData.getHousehold()

  };
  const nav = () =>
    navigation.navigate('FlowNavigation', { screen: 'SelectProduct' });
  return (
    <>
      {hubs.length === 0 && (
        <SpCenter flex={1}>
          <EmptyPlaceholder isPets={false} />
        </SpCenter>
      )}

      {hubs.length >= 1 &&
        products.length +
        cerberusDevices.length ===
          0 && (
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={false} onRefresh={onRefresh} />
            }
          >
            <SpView paddingVertical={16} paddingHorizontal={20}>
              <DeviceHierarchy
                products={generateProducts(hubs[0].id)}
                hub={hubs[0]}
                goToDevice={goToDevice}
              />
              <SpRoundedHeaderButton
                backgroundColor={colors.primary.color}
                title={t('add_product')}
                h={56}
                stylesForContainer={[atoms.maxWidth, { marginVertical: 32 }]}
                onPress={nav}
              />
            </SpView>
          </ScrollView>
        )}
      {hubs.length >= 1 &&
        products.length +
        cerberusDevices.length >
          0 && (
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={false} onRefresh={onRefresh} />
            }
          >
            {hubs.map(hub => (
              <SpView paddingVertical={16} paddingHorizontal={20} key={hub.id}>
                <DeviceHierarchy
                  products={generateProducts(hub.id)}
                  hub={hub}
                  goToDevice={goToDevice}
                />
              </SpView>
            ))}
          </ScrollView>
        )}
    </>
  );
};
