import { endOfDay, formatISO, getDate, isAfter, isBefore, parseISO, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useMemo } from "react";
import useBoundStore from "src/store/store";

export const useConvertDateToHousehold = (data) => {
        const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
        const offset = activeHousehold.timezone.utc_offset;
        useMemo(() => {
            const convertToLocal = (date) => {
                const toHHTimeZone = utcToZonedTime(date, activeHousehold.timezone.timezone);
                return toHHTimeZone;
            }
    
            if (offset >= 0) {
            const severalDaysEvents = [];
            let nextDayPoints = [];
            data?.forEach(elem => {
                const beforeNewPoints = [];
                if (nextDayPoints?.length) {
                    const eventsDate = endOfDay(parseISO(nextDayPoints[0].from));
                    if (getDate(elem.date) === getDate(eventsDate)) {
                        beforeNewPoints.push(...nextDayPoints);
                    } else {
                        const event = {
                            date: eventsDate,
                            points: nextDayPoints,
                        }
                        severalDaysEvents.push(event);
                    }
                    nextDayPoints = [];
                }
                const filteredCurrDayPoints = [];
                elem.points.forEach(item => {
                    const fromToCompare = startOfDay(parseISO(item.from));
                    const convertedFromToCompare = startOfDay(convertToLocal(item.from))
    
                    const convertedFrom = formatISO(convertToLocal(item.from))
                    const convertedTo = formatISO(convertToLocal(item.to))
                    item.from = convertedFrom;
                    item.to = convertedTo;
    
                    if (isAfter(convertedFromToCompare, fromToCompare)) {
                        nextDayPoints.push(item);
                    } else {
                        filteredCurrDayPoints.push(item);
                    }
                })
                elem.points = [...beforeNewPoints, ...filteredCurrDayPoints];
            })
    
            data = [...data, ...severalDaysEvents];
             data.sort((a, b) => a.date - b.date);
            } else {
                const severalDaysEvents = [];
            for (let i = data?.length - 1; i >= 0; i--) {
                const filteredCurrDayPoints = [];
                const afterNewPoints = [];
                let prevDayPoints = [];
                if (prevDayPoints?.length) {
                    const eventsDate = endOfDay(parseISO(prevDayPoints[0].to))
                    if (getDate(data[i].date) === getDate(eventsDate)) {
                        afterNewPoints.push(...prevDayPoints);
                    } else {
                        const event = {
                            date: eventsDate,
                            points: prevDayPoints,
                        }
                        severalDaysEvents.push(event);
                    }
                    prevDayPoints = [];
                }

                for (let j = 0; j < data[i]?.points?.length; j++) {
                    const item = data[i].points[j];
                    const toToCompare = startOfDay(parseISO(item.to))
                    const convertedToToCompare = startOfDay(convertToLocal(item.to));

                    const convertedTo = formatISO(convertToLocal(item.to))
                    const convertedFrom = formatISO(convertToLocal(item.from))

                    item.to = convertedTo;
                    item.from = convertedFrom;

                    if (isBefore(convertedToToCompare, toToCompare)) {
                        prevDayPoints.push(item);
                    } else {
                        filteredCurrDayPoints.push(item);
                    }
                }
                data[i].points = [...filteredCurrDayPoints, ...afterNewPoints];
            }


            data = [...data, ...severalDaysEvents];
            data.sort((a, b) => a.date - b.date);

            }
            
        }, [data])
        return undefined;
    }
