/* eslint-disable no-restricted-syntax */
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NativeSyntheticEvent, TextLayoutEventData, TextStyle } from 'react-native';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';

type TextLayoutEvent = NativeSyntheticEvent<TextLayoutEventData>;

const useAdaptiveFontSize = <T extends Record<string, number>>(
  data: T,
  deps: unknown[],
  reduction: Record<string, number> = {},
): [T, (e: TextLayoutEvent) => void, TextStyle] => {
  const DEFAULT_REDUCTION = 20;
  const [sizes, setSizes] = useState<Record<string, number>>(data);
  const [currDeps, setCurrDeps] = useState<unknown[]>([]);
  const firstValues = useRef(data);
  const [opacity, setOpacity] = useState(0);
  const code = useCurrentLanguageCode();
  const layoutHandler = (eve: TextLayoutEvent) => {
    const { length } = eve.nativeEvent.lines;
    const coeffecient = length <= 1 ? 0 : 1;
    if (coeffecient === 0) setOpacity(1);
    setSizes(curr => {
      const newValue = { ...curr };
      Object.keys(curr).forEach((key: keyof T) => {
        // @ts-ignore
        (newValue[key] as number) = curr[key] - coeffecient;
      });
      return newValue;
    });
  };

  useEffect(() => {
    if (deps.length === 0 || currDeps.length === 0) {
      setCurrDeps(deps);
      return;
    }
    if (!isEqual(deps, currDeps)) {
      setOpacity(0);
      setSizes(firstValues.current);
    }
    setCurrDeps(deps);
  }, deps);

  const redactedSizes = useMemo((): Record<string, number> => {
    return Object.keys(sizes).reduce((acc, curr) => {
      const normalReductionCoeff = reduction[code] || DEFAULT_REDUCTION;
      // @ts-ignore
      const reductionCoeff =
        // @ts-ignore
        sizes[curr] <= 1 ? 100 : (sizes[curr] / data[curr]) * 100;
      // @ts-ignore
      acc[curr] =
        reductionCoeff > normalReductionCoeff
          ? data[curr] * ((100 - normalReductionCoeff) / 100)
          : sizes[curr];
      return acc;
    }, {}) as Record<string, number>;
  }, [sizes, reduction, code]);
  // @ts-ignore
  return [redactedSizes, layoutHandler, { opacity, alignItems: 'flex-end' }];
};

export default useAdaptiveFontSize;
