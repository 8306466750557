import React from 'react';
import { SpMenuButton, SpMenuButtonProps } from '@atoms/SpMenuButton';
import { StyleSheet, View } from 'react-native';
import { testProperties } from '@utils/testProperties';
import { useTranslation } from 'react-i18next';

interface SpButtonStackProps {
  buttons: SpMenuButtonProps[];
}

export const SpButtonStack = ({ buttons }: SpButtonStackProps) => {
  const { t } = useTranslation();
  return (
    <>
      {buttons.map((button, index) => (
        <View
          style={[styles.container, index !== 0 && { marginTop: 12 }]}
          key={index}
        >
          <SpMenuButton {...button} />
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  container: { width: '100%', height: 60 },
});
