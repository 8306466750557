/* eslint-disable react/jsx-no-useless-fragment */
import { SpZeroIcon } from '@atoms/RoundIcons/SpZeroIcon';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { DeviceFeederTareType } from '@constants/Device';
import colors from '../../styles/colors';
import noop from '@utils/noop';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import {
  Image,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
} from 'react-native';

export interface ZeroScalesActionModel {
  id: DeviceFeederTareType;
  text: string;
  image: ImageSourcePropType[];
}

interface ZeroScalesActionsProps {
  buttons: ZeroScalesActionModel[];
  textStyle?: React.CSSProperties;
  onSelect: (data: DeviceFeederTareType) => void;
}

export const SpZeroScalesActions = ({
  buttons,
  textStyle = null,
  onSelect,
}: ZeroScalesActionsProps) => {
  return (
    <SpVStack space={10}>
      {buttons.map(item => {
        return (
          <Pressable
            onPress={() => onSelect(item.id)}
            key={item.id}
            style={styles.button}
          >
            <SpText
              size="xl"
              fontFamily="Rubik_Medium"
              style={[{ flex: 6 }, textStyle]}
            >
              {item.text}
            </SpText>
            <SpView style={styles.images} flex={item.image.length}>
              {item.image.map((image, ind) => (
                <Image
                  key={ind}
                  style={styles.bowlImage}
                  source={image}
                  {...testProperties('bowlImage', 'image')}
                />
              ))}
            </SpView>
            <SpZeroIcon action={noop} />
          </Pressable>
        );
      })}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: colors.secondary3.color,
    borderRadius: 16,
  },
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
});
