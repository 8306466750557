import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import React from 'react';
import { StyleSheet } from 'react-native';

export const PlaceholderText = ({ text }: { text: string }) => {
  return (
    <SpView style={styles.container}>
      <SpText color={colors.greyText.color} fontFamily="Rubik_SemiBold">
        {text}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: colors.grayBottomSheetHeader.color,
    borderRadius: 16,
  },
});
