import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const LockSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/Svg" width={30} height={28} {...props}>
    <Path
      fill={props?.color || '#263A43'}
      fillRule="nonzero"
      d="M23.442 11.597h-1.088V7.624C22.354 3.42 19.055 0 15 0S7.646 3.42 7.646 7.624v3.973H6.558c-.86 0-1.558.723-1.558 1.615V26.385C5 27.277 5.697 28 6.558 28h16.884c.86 0 1.558-.723 1.558-1.615V13.212c0-.892-.697-1.615-1.558-1.615Zm-6.784 11.568h-3.316l.593-2.932a2.076 2.076 0 0 1-.925-1.738c0-1.14.89-2.063 1.99-2.063s1.99.924 1.99 2.063c0 .733-.37 1.372-.925 1.738l.593 2.932Zm3.072-11.568h-9.46V7.624c0-2.704 2.122-4.904 4.73-4.904 2.608 0 4.73 2.2 4.73 4.904v3.973Z"
    />
  </Svg>
);
export default LockSVG;
