import colors from '@styles/colors';
import React from 'react';
import { StyleProp, StyleSheet } from 'react-native';
import { SpView } from './SpView';

interface ISpDividerProps {
  color?: string;
  style?: StyleProp<unknown>;
}

export const SpDivider = ({
  color = colors.greyAccent.color,
  style,
}: ISpDividerProps) => {
  return <SpView style={[styles.divider, { borderTopColor: color }, style]} />;
};

const styles = StyleSheet.create({
  divider: {
    height: 1,
    borderTopWidth: 1,
  },
});
