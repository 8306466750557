import { GroupReportPointModel, MappedReportModel } from '@models/ReportModel';
import { groupEventsByMonthRangeWithGaps } from '@utils/report';
import { subDays } from 'date-fns';
import React, { useMemo } from 'react';
import { ReportType } from '../constants/ReportType';

export function useVisitHistoryData(
  data: MappedReportModel,
  type: ReportType,
): {
  events: { date: number; data: GroupReportPointModel<unknown>[] }[];
  label: string;
} {
  return useMemo(() => {
    const today = Date.now();
    const lastDate = subDays(today, 180).getTime();

    // @ts-ignore
    let events: GroupReportPointModel<unknown> = [];
    let label = '';

    switch (type) {
      case ReportType.Feeding:
        // @ts-ignore
        events = data?.feeding || [];
        label = 'eating';
        break;
      case ReportType.Drinking:
        // @ts-ignore
        events = data?.drinking || [];
        label = 'drinks';
        break;
      case ReportType.Movement:
        // @ts-ignore
        events = data?.movement || [];
        label = 'entries';
        break;
    }

    return {
      events: groupEventsByMonthRangeWithGaps(
        // @ts-ignore
        events,
        lastDate,
        today,
      ),
      label,
    };
  }, [type, data]);
}
