import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReportRangeType } from "../constants/ReportRangeType";
import { useWeightConversion } from '@hooks/useWeightConversion';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';

const useTickFormatting = (rangeType: ReportRangeType ) => {
  const { t } = useTranslation();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode,
  );
  const calculateBarOpacity = useCallback(
    (index: number) => {
      switch (rangeType) {
        case ReportRangeType.OneDay:
          return index <= 3 ? 0.6 : 1;
        case ReportRangeType.SevenDays:
          return index <= 0 ? 0.6 : 1;
        case ReportRangeType.TwentyEightDays:
          return index <= 3 ? 0.6 : 1;
        case ReportRangeType.SixMonths:
          return index <= 0 ? 0.6 : 1;
        case ReportRangeType.OneYear:
          return index <= 1 ? 0.6 : 1;
        default:
          return index <= 0 ? 0.6 : 1;
      }
    },
    [rangeType]
  );

  const convertTicksToDuration = useCallback(
    (ticks: number) => {
      const days = Math.floor(ticks / 86400);
      const hours = Math.floor((ticks % 86400) / 3600);
      const minutes = Math.floor((ticks % 3600) / 60);
      const seconds = Math.floor(ticks % 60);
      return days > 0
        ? `${days}${t("days_unit_1")} ${hours}${t("hours_unit_1")} `
        : hours > 0
        ? `${hours}${t("hours_unit_1")} ${minutes}${t("minutes_unit_1")} `
        : `${minutes}${t("minutes_unit_1")} ${seconds}${t("seconds_unit_1")}`;
    },
    [t]
  );



  return { calculateBarOpacity, convertTicksToDuration};
};

export default useTickFormatting;
