import { StyleSheet } from 'react-native';

const images = StyleSheet.create({
  imageAddCerberusStep1: {
    position: 'relative',
    width: 200,
    height: 120,
    marginTop: 32,
    marginBottom: 32,
  },
  imageAddCerberusStep1Icon1: {
    position: 'relative',
    width: 40,
    height: 20,
    marginTop: 15,
    marginLeft: 10,
  },
  imageAddCerberusStep1Icon2: {
    position: 'relative',
    width: 30,
    height: 30,
    marginTop: 10,
    marginLeft: 10,
  },
  imageAddCerberusStep2: {
    position: 'relative',
    width: 140,
    height: 160,
    marginTop: 12,
    marginBottom: 12,
  },
  imageAddCerberusStep2b: {
    position: 'relative',
    width: 225,
    height: 120,
    marginTop: 32,
    marginBottom: 32,
  },
  imageAddCerberusStep3: {
    position: 'relative',
    width: 195,
    height: 155,
    marginTop: 32,
    marginBottom: 32,
  },
});

export default images;
