import { SpView } from '@atoms/SpView';
import { WeightUnits } from '@constants/WeightUnits';
import React from 'react';
import { DeviceModel } from '@models/Device';
import { DeviceStatusHeader } from '../DeviceStatusHeader';
import { MenuActions } from './MenuActions';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import colors from '@styles/colors';
import { SpDivider } from '@atoms/SpDivider';
import PetLastActionTime from 'src/pages/Dashboard/molecules/PetCarousel/PetLastActionTime';
import { SpProgressBar } from '@atoms/SpProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { SpText } from '@atoms/SpText';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { faClock, faDroplet } from '@fortawesome/free-solid-svg-icons';

interface CerberusCardProps {
  data: DeviceModel;
}

export const CerberusCard = ({ data }: CerberusCardProps) => {
  const userHasWriteAccess = true
  const online = true
  const { t } = useTranslation();
  const percent = [20]
  return (
    <SpView flex={1}>
      <DeviceStatusHeader data={data} />
      {userHasWriteAccess && online && (
        <SpView marginTop={2}>
          <DeviceSettingMenuItem data={data?.id} />
        </SpView>
      )}
      {online && (
            <SpView
              marginTop={18}
              backgroundColor={colors.white.color}
              alignItems="center"
              style={styles.container}
              paddingVertical={10}
            >
              <SpView
                minHeight={32}
                minWidth={172}
                style={styles.progressBarContainer}
              >
                <SpProgressBar
                  indicatorColors={['#45acd9', '#83daf0']}
                  value={percent[0]}
                  width={200}
                  borderRadius={16}
                />
              </SpView>
              <SpView flexDirection="row" alignItems="center" >
                <FontAwesomeIcon
                  icon={faDroplet}
                  size={16}
                  color={colors.greyText.color}
                />
                <SpText style={styles.title}>{t('water_remaining')}</SpText>
              </SpView>
              <SpText style={styles.percent}>{percent}%</SpText>

              <SpView marginTop={32} width="100%">
                <SpDivider
                  style={styles.divider}
                  color={colors.greyBorder.color}
                />
                <PetLastActionTime
                  icon={faClock}
                  data={{ last_consumption: new Date()}}
                  title={t('last_filled')}
                  withTimeMarker
                  rightSideFlexEnd
                />
                <SpDivider
                  style={styles.divider}
                  color={colors.greyBorder.color}
                />
              </SpView>
            </SpView>
          )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  title: {
    marginLeft: 4,
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    paddingBottom: 1,
  },
  container: {
    position: 'absolute',
    flex: 1,
    height: '55%',
    paddingHorizontal: 19,
    bottom: 0,
    left: -18,
    right: -18,
    paddingTop: 28,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: -9,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    border: 'none',

    elevation: 8,
  },
  progressBarContainer: { position: 'absolute', top: -16 },
  percent: {
    fontFamily: 'Rubik_Medium',
    fontSize: 32,
    lineHeight: 32,
    color: colors.greyText.color,
    marginTop: 8,
  },
  divider: {
    width: '100%',
  },
});
