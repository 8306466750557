import { SpInput } from '@atoms/SpInput';
import React, { useCallback, useEffect } from 'react';

import { Pressable, StyleSheet } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import colors from '@styles/colors';
import { SpText } from '@atoms/SpText';
import SpErrorBadge from '@atoms/SpErrorBadge';
import { testProperties } from '@utils/testProperties';
import useBoundStore from 'src/store/store';
import { LoginFormModel } from '../../../models/Auth';
import { LoginStackParamList } from '..';

interface LoginFormTemplateProps {
  error: string;
  onSubmit: (data: LoginFormModel) => void;
}

const LoginForm = ({ error, onSubmit }: LoginFormTemplateProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<LoginStackParamList>>();
  const { resetLoginError } = useBoundStore(state => state.accountStore);

  useEffect(() => {
    resetLoginError();
  }, []);

  const { control, handleSubmit } = useForm<LoginFormModel>({
    defaultValues: {
      email_address: '',
      password: '',
      device_id: '',
    },
    mode: 'onBlur',
  });

  const onReset = useCallback(() => {
    navigation.navigate('RequestResetPassword');
  }, []);

  const onChangeHandler = useCallback(
    (val: string, onChange: (val: string) => void) => {
      onChange(val);
      if (error) {
        resetLoginError();
      }
    },
    [error],
  );

  return (
    <SpView
      flexDirection="column"
      alignContent="center"
      style={[styles.container, !error && styles.withoutErrorOffset]}
    >
      <SpView>
        {error && <SpErrorBadge text={t('login_error')} />}
        <SpVStack space={20}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <SpInput
                additionalTestLabel={'Email'}
                label={t('email')}
                onChangeText={val => onChangeHandler(val, onChange)}
                value={value}
                keyboardType="email-address"
                placeholder={t('type_here')}
              />
            )}
            name="email_address"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <SpInput
                additionalTestLabel={'Password'}
                label={t('password')}
                onChangeText={val => onChangeHandler(val, onChange)}
                placeholder={t('type_here')}
                value={value}
                password
              />
            )}
            name="password"
          />
          <Pressable
            {...testProperties('forgot your password', t('button'))}
            style={styles.forgotWrap}
            onPress={onReset}
          >
            <SpText
              style={styles.forgotText}
              align="right"
              color={colors.btnBlue.color}
            >
              {t('forgot_your_password')}
            </SpText>
          </Pressable>
        </SpVStack>
      </SpView>
      <SpRoundedHeaderButton
        onPress={handleSubmit(onSubmit)}
        backgroundColor={colors.primary.color}
        stylesForContent={styles.buttonContent}
        stylesForContainer={[styles.buttonContainer]}
        textStyles={styles.buttonText}
        title={t('login')}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  error: {
    color: 'red',
    fontSize: 12,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  withoutErrorOffset: {
    marginTop: 42,
  },
  buttonContent: {
    height: 56,
  },
  buttonContainer: {
    marginBottom: 34,
    paddingTop: 'auto',
    marginTop: 10,
  },
  buttonText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    color: colors.white.color,
  },
  forgotWrap: { marginLeft: 'auto' },
  forgotText: {
    fontFamily: 'Rubik_Medium',
    textDecorationLine: 'underline',
    textDecorationColor: colors.primary.color,
  },
});

export default LoginForm;
