import { navigationRef } from 'src/pages/RootNavigation';

export const navigateToPets = () => {
  navigationRef.current?.reset({
    index: 0,
    routes: [{ name: 'DashboardNavigation', params: { screen: 'Pets' } }],
  });
};

export const navigateToProducts = () => {
  navigationRef.current?.reset({
    index: 0,
    routes: [{ name: 'DashboardNavigation', params: { screen: 'Products' } }],
  });
};

export const handleBackPressWrap = (cb: () => void) => {
  cb()
  return true
}

