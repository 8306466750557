import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface PointDetailsItemProps {
  leftLabel: string;
  leftValue: string;
  centerLabel?: string;
  centerValue?: string;
  rightLabel: string;
  rightValue: string;
}

export const EventDetailsCard = ({
  leftLabel,
  leftValue,
  centerLabel,
  centerValue,
  rightLabel,
  rightValue,
}: PointDetailsItemProps) => {
  return (
    <View style={[styles.renderItemWrapper]}>
      {leftLabel && (
        <SpView style={[styles.center, styles.flexStart]} flex={1}>
          <SpText
            size="sm"
            fontFamily="Rubik_Medium"
            color={colors.toxicGreen.color}
          >
            {leftLabel}
          </SpText>
          <SpText
            color={colors.white.color}
            numberOfLines={1}
            adjustsFontSizeToFit
          >
            {leftValue}
          </SpText>
        </SpView>
      )}
      {centerLabel && (
        <SpView style={styles.center} flex={2}>
          <SpText
            size="sm"
            fontFamily="Rubik_Medium"
            color={colors.toxicGreen.color}
          >
            {centerLabel}
          </SpText>
          <SpText
            color={colors.white.color}
            adjustsFontSizeToFit
            numberOfLines={1}
          >
            {centerValue}
          </SpText>
        </SpView>
      )}
      {rightLabel && (
        <SpView style={[styles.center, styles.flexEnd]} flex={1}>
          <SpText
            size="sm"
            fontFamily="Rubik_Medium"
            color={colors.toxicGreen.color}
          >
            {rightLabel}
          </SpText>
          <SpText
            color={colors.white.color}
            numberOfLines={1}
            adjustsFontSizeToFit
          >
            {rightValue}
          </SpText>
        </SpView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  renderItemWrapper: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: colors.greyText.color,
    paddingVertical: 16,
    paddingHorizontal: 20,
    gap: 2,
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  center: {
    alignItems: 'center',
  },
  flexEnd: {
    alignItems: 'flex-end',
  },
});
