import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpAlert from '@utils/alert';
import noop from '@utils/noop';
import PreferencesSVG from '@atoms/SvgIcons/PreferencesSVG';
import colors from '@styles/colors';
import NotificationFilledSVG from '@atoms/SvgIcons/NotifcationFilledSVG';
import PersonalSVG from '@atoms/SvgIcons/PersonalSVG';
import { useDeviceId } from '@hooks/useDeviceId';
import useToastEffect from '@hooks/useToastEffect';
import { AccountStackParamList } from '.';
import useBoundStore from '../../store/store';
import { RootStackParamList } from '../index';
import { AccountMenu } from './molecules/AccountMenu';

export const Account = () => {
  const { t } = useTranslation();
  const { logout, user } = useBoundStore(state => state.accountStore);
  const { resetTours, toursHasBeenReset, updateProperty } = useBoundStore(
    ({ tourStore }) => {
      return {
        resetTours: tourStore.resetTours,
        toursHasBeenReset: tourStore.toursHasBeenReset,
        updateProperty: tourStore.updateProperty,
      };
    },
  );
  const navigation = useNavigation<NavigationProp<AccountStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const deviceId = useDeviceId();

  const actions = [
    {
      label: t('personal'),
      iconSVG: (
        <PersonalSVG
          color={colors.greyText.color}
          width={20}
          height={20}
          viewBox="0 0 16 16"
        />
      ),
      action: () => {
        navigation.navigate('Personal');
      },
    },
    {
      label: t('preferences'),
      iconSVG: (
        <PreferencesSVG
          color={colors.greyText.color}
          width={20}
          height={20}
          viewBox="0 0 16 16"
        />
      ),
      action: () => {
        navigation.navigate('Preferences');
      },
    },
    {
      label: t('notifications'),
      iconSVG: (
        <NotificationFilledSVG
          color={colors.greyText.color}
          width={20}
          height={20}
          viewBox="0 0 16 16"
        />
      ),
      action: () => {
        navigation.navigate('Notifications');
      },
    },
    // Removed until further notice 16/02/23
    // {
    //   label: t('sounds'),
    //   icon: faVolumeHigh,
    //   action: () => {
    //     navigation.navigate('Sounds');
    //   },
    // },
  ];

  const handleLogout = () => {
    SpAlert(t('logout'), t('logout_message'), [
      {
        text: t('logout'),
        onPress: () => logout(deviceId),
        style: 'destructive',
      },
      {
        text: t('cancel'),
        onPress: () => null,
        style: 'cancel',
      },
    ]);
  };

  const handleRemoveAccount = useCallback(() => {
    rootNavigation.navigate('RemoveAccountNavigation');
  }, []);

  const handleResetTour = () => {
    SpAlert(t('reset_tour_small_t'), t('reset_tour_message'), [
      { text: t('reset'), onPress: resetTours, style: 'destructive' },
      {
        text: t('cancel'),
        onPress: noop,
        style: 'cancel',
      },
    ]);
  };

  useToastEffect({
    resetter: () => updateProperty('toursHasBeenReset', 'none'),
    successCondition: toursHasBeenReset === 'success',
    errorCondition: toursHasBeenReset === 'error',
    successToastMessage: t('success_reset_tours'),
    errorToastMessage: t('error_reset_tours'),
  });

  return (
    <View style={styles.container}>
      <AccountMenu
        logout={handleLogout}
        menuItems={actions}
        user={user}
        removeAccount={handleRemoveAccount}
        handleResetTour={handleResetTour}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
});
