import { SpInput } from '@atoms/SpInput';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { useDeviceId } from '@hooks/useDeviceId';
import { useCustomToast } from '@hooks/useToast';
import { useNavigation } from '@react-navigation/native';
import { checkPasswordValidity } from '@utils/checkPasswordValidity';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import useBoundStore from 'src/store/store';
import colors from 'src/styles/colors';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import PasswordForm from 'src/pages/Register/forms/PasswordForm';
import i18n from '@utils/i18n';
import { testProperties } from '@utils/testProperties';
import useValidation from 'src/pages/validation';
import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';

const currentPasswordSchema = yup.object({
  currentPassword: yup.string().required('this_field_cannot_be_empty'),
});

const AccountPasswordWrapper = () => {
  const { passwordStepSchema } = useValidation();
  const { user, updateUser, setUser } = useBoundStore(
    state => state.accountStore,
  );
  const deviceId = useDeviceId();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const toast = useCustomToast();
  const navigation = useNavigation();

  const { t } = useTranslation();

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const formik = useFormik({
    validationSchema: passwordStepSchema.concat(currentPasswordSchema),
    initialValues: {
      currentPassword: '',
      password: '',
      retypePassword: '',
    },
    onSubmit: () => {
      onUpdatePassword();
    },
    validateOnChange: validateAfterSubmit,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const onSubmit = useCallback(async () => {
    formik.handleSubmit();
  }, []);

  const onUpdatePassword = useCallback(async () => {
    setSaveButtonLoading(true);
    let token: string | false = '';
    try {
      token = await checkPasswordValidity(deviceId, user.email_address, {
        formik,
        setError,
      });
    } catch {}
    if (token) {
      setUser({ user, token });
      if (formik.values.currentPassword === formik.values.password) {
        toast.show({
          description: i18n.t('change_password_error_same'),
          isError: true,
        });
        setSaveButtonLoading(false);
        return;
      }
      user.password = formik.values.password;
      try {
        await updateUser(user);
        toast.show({
          description: i18n.t('change_password_success'),
        });
        setTimeout(() => {
          navigation.goBack();
        }, 2000);
      } catch (err) {
        toast.show({ description: t('error_password'), isError: true });
      } finally {
        setSaveButtonLoading(false);
      }
    }
    setSaveButtonLoading(false);
  }, [formik, user, toast, t]);

  useEffect(() => {
    if (formik.submitCount > 0) setValidateAfterSubmit(true);
  }, [formik.submitCount]);

  return (
    <SafeAreaView style={styles.container}>
      <CustomKeyboardAvoidingView
        androidAdjustType="custom"
        extraScrollHeight={Platform.OS === 'ios' ? 50 : 200}
        keyboardShouldPersistTaps="always"
      >
        <ScrollView
          contentContainerStyle={styles.scrollView}
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="on-drag"
        >
          <TouchableWithoutFeedback
            accessible={false}
            onPress={() => dismissKeyboard()}
          >
            <View style={styles.inner}>
              <Text
                {...testProperties(t('reset_password'), 'title text')}
                style={styles.title}
              >
                {t('reset_password')}
              </Text>

              <SpInput
                label={t('current_password')}
                value={formik.values.currentPassword}
                onChangeText={text => {
                  setError(null);
                  formik.handleChange('currentPassword')(text);
                }}
                password
                error={t(formik.errors.currentPassword) || t(error)}
              />

              <View style={styles.passwordFormWrapper}>
                <PasswordForm formik={formik} label={t('new_password')} />
              </View>
              <View style={styles.buttonWrap}>
                <SpRoundedHeaderButton
                  {...testProperties(
                    t('save'),
                    t('button'),
                    'AccountPasswordWrapper',
                  )}
                  backgroundColor={colors.primary.color}
                  h={56}
                  title={t('save')}
                  onPress={onSubmit}
                  isLoading={saveButtonLoading}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </CustomKeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: colors.white.color },
  scrollView: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    paddingHorizontal: 19,
    flex: 1,
  },
  title: {
    color: colors.greyText.color,
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    paddingVertical: 24,
  },
  buttonWrap: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 34,
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
  passwordFormWrapper: {
    marginTop: 40,
  },
});

export default AccountPasswordWrapper;
