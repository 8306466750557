import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const LightSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <G fill="#263A43" fillRule="nonzero">
      <Path d="M14 0c.703 0 1.273.57 1.273 1.273v2.545a1.273 1.273 0 1 1-2.546 0V1.273C12.727.57 13.297 0 14 0ZM14 6.94a7.06 7.06 0 1 0 0 14.12 7.06 7.06 0 0 0 0-14.12ZM5.93 4.13a1.273 1.273 0 1 0-1.8 1.8L5.95 7.748a1.273 1.273 0 1 0 1.8-1.8L5.93 4.13ZM0 14c0-.703.57-1.273 1.273-1.273h2.545a1.273 1.273 0 1 1 0 2.546H1.273C.57 15.273 0 14.703 0 14ZM7.748 22.052a1.273 1.273 0 1 0-1.8-1.8L4.13 22.07a1.273 1.273 0 1 0 1.8 1.8l1.818-1.818ZM14 22.91c.703 0 1.273.569 1.273 1.272v2.545a1.273 1.273 0 1 1-2.546 0v-2.545c0-.703.57-1.273 1.273-1.273ZM22.052 20.252a1.273 1.273 0 0 0-1.8 1.8l1.818 1.818a1.273 1.273 0 0 0 1.8-1.8l-1.818-1.818ZM22.91 14c0-.703.569-1.273 1.272-1.273h2.545a1.273 1.273 0 1 1 0 2.546h-2.545c-.703 0-1.273-.57-1.273-1.273ZM23.87 5.93a1.273 1.273 0 1 0-1.8-1.8l-1.818 1.818a1.273 1.273 0 1 0 1.8 1.8L23.87 5.93Z" />
    </G>
  </Svg>
);
export default LightSVG;
