import { useTranslation } from 'react-i18next';
import { SpView } from '@atoms/SpView';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';

const ProductsControlSlide = () => {
  const squares = useMemo(() => new Array(3).fill(''), []);
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.squareWrapper}>
        {squares.map((item, index) => (
          <React.Fragment key={`${index}`}>
            <SpView style={styles.topSmallSquare} />
            <SpView style={styles.botSmallSquare} />
          </React.Fragment>
        ))}
      </SpView>
      <SpTitle align="center" text={t('product_controls')} />
      <SpText style={styles.contentText}>
        {t('product_controls_tour_text')}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    lineHeight: 24,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  squareWrapper: {
    marginTop: 51,
    marginBottom: 4.5,
    flexDirection: 'row',
    columnGap: 16,
    justifyContent: 'center',
  },
  square: {
    width: 92,
    height: 92,
    opacity: 0.4,
    borderRadius: 11,
    rowGap: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  topSmallSquare: {
    height: 24,
    width: 30,
    backgroundColor: '#263a43',
    opacity: 0.5,
    borderRadius: 10.4,
  },
  botSmallSquare: {
    width: 48,
    height: 12,
    opacity: 0.5,
    backgroundColor: '#263a43',
    borderRadius: 10.4,
  },
});

export default ProductsControlSlide;
