import { SpView } from '@atoms/SpView';
import i18n from '@utils/i18n';
import React, { useMemo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import GradientButton from '@atoms/GradientButton';
import colors from '@styles/colors';

import { PetDoorQuickActions } from '@constants/QuickActions';
import LockSVG from '@atoms/SvgIcons/LockSVG';
import UnlockSVG from '@atoms/SvgIcons/UnlockSVG';
import CurfewEnabledSVG from '@atoms/SvgIcons/CurfewEnabledSVG';
import { getNestedProperty } from '@utils/getNestedProperty';
import useBoundStore from '../../../../store/store';

const ICON_SIZE = Math.min(Dimensions.get('screen').height * 0.05, 45);
interface FooterActionsProps {
  onAction: (data: PetDoorQuickActions) => void;
}

export const PetDoorFooterActions = ({ onAction }: FooterActionsProps) => {
  const { updateLockUnlockLoading, updateCurfewLoading, loadingControl } = useBoundStore(
    state => state.deviceStore,
  );
  const buttons = [
    {
      icon: [
        <LockSVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 28 28"
        />,
        <UnlockSVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 28 28"
        />,
      ],
      text: `${i18n.t('locks')}`,
      action: () => {
        return onAction(PetDoorQuickActions.lock);
      },
      loading: updateLockUnlockLoading,
    },
    {
      icon: (
        <CurfewEnabledSVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 30 30"
        />
      ),
      text: i18n.t('curfew'),
      action: () => onAction(PetDoorQuickActions.curfew),
      loading: loadingControl.curfew || updateCurfewLoading,
    },
  ];
  return (
    <SpView style={styles.actions}>
      {buttons.map((item, index) => {
        return (
          <SpView style={styles.column} key={index}>
            <GradientButton
              colorsGradient={[
                'rgba(0, 189, 136, 0.4)',
                'rgba(45, 181, 142, 0.4)',
              ]}
              borderRadius={18}
              text={item.text}
              icon={item.icon}
              action={item.action}
              key={`${index}${item.text}`}
              isLoading={item.loading}
              disabled={item.loading}
            />
          </SpView>
        );
      })}
    </SpView>
  );
};

const styles = StyleSheet.create({
  actions: {
    marginHorizontal: -10,
    flexDirection: 'row',
    paddingBottom: 42,
    paddingTop: 27,
  },
  row: {
    flexDirection: 'row',
    marginHorizontal: -10,
  },
  column: {
    paddingHorizontal: 10,
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '20%',
  },
  button: {
    padding: 20,
    backgroundColor: 'transparent',
  },
});
