import { SpView } from '@atoms/SpView';
import { StyleSheet } from 'react-native';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React from 'react';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import ShareSVG from '@atoms/SvgIcons/ShareSVG';

const PetReportsSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.iconWrapper}>
        <ShareSVG width={74} height={74} viewBox="0 0 24 24" />
      </SpView>
      <SpTitle align="center" text={t('pet_reports')} />
      <SpText style={styles.contentText}>{t('pet_reports_tour_text')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  graphLine: {
    backgroundColor: '#2db58e',
    width: 10,
    borderTopLeftRadius: 126,
    borderTopRightRadius: 126,
  },
  doorIcon: {
    width: 40,
    height: 40,
    position: 'absolute',
    bottom: -20,
  },
  iconWrapper: {
    marginTop: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default PetReportsSlide;
