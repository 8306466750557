import { DeviceEventEmitter, StyleSheet } from 'react-native';
import React, { useCallback, useEffect } from 'react';
import SpModal from '@molecules/SpModal';
import { SpText } from '@atoms/SpText';
import { useTranslation } from 'react-i18next';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';

const InfoModal = () => {
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      DeviceEventEmitter.removeAllListeners('deactivate_info_modal');
    };
  }, []);

  const deactivateButton = useCallback(async () => {
    await DeviceEventEmitter.emit('deactivate_info_modal');
  }, []);

  return (
    <SpModal
      backdropColor="transparent"
      modalName="InfoModal"
      onModalCloseHandler={deactivateButton}
    >
      <SpView style={styles.modal}>
        <SpText align="center" style={styles.title}>
          {t('lid_delay_title')}
        </SpText>
        <SpText align="center" style={styles.paragraph}>
          {t('lid_delay_paragraph1')}
        </SpText>
        <SpText align="center" style={styles.paragraph}>
          {t('lid_delay_paragraph2')}
        </SpText>
      </SpView>
    </SpModal>
  );
};

export default InfoModal;

const styles = StyleSheet.create({
  modal: {
    backgroundColor: colors.greyText.color,
    paddingHorizontal: 27,
    paddingTop: 16,
    borderRadius: 10,
    marginTop: 64,
  },
  title: {
    fontFamily: 'Rubik_Medium',
    marginBottom: 16,
    fontSize: 16,
    color: colors.white.color,
  },
  paragraph: {
    fontFamily: 'Rubik',
    color: colors.white.color,
    fontSize: 16,
    marginBottom: 16,
  },
});
