import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { AppImages } from '@constants/AppImages';
import { SpCarouselWithSteps } from '@molecules/CarouselWithSteps';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ImageSourcePropType, StyleSheet } from 'react-native';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = 280;

interface ConfigureCDBCarouselFoodProps {
  enableConfigureCDBCarouselFoodContinue: (data: number) => void;
  setNumberItems: (data: number) => void;
}

export const ConfigureCDBCarouselFood = ({
  enableConfigureCDBCarouselFoodContinue,
  setNumberItems,
}: ConfigureCDBCarouselFoodProps) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        text: t('setup_configure_food_cdb_carousel_1'),
        image: AppImages.cdbConfigureFoodCarousel1,
      },
      {
        text: t('setup_configure_food_cdb_carousel_2'),
        image: AppImages.cdbConfigureFoodCarousel2,
      },
      {
        text: t('setup_configure_food_cdb_carousel_3'),
        image: AppImages.cdbConfigureFoodCarousel3,
      },
      {
        text: t('setup_configure_food_cdb_carousel_4'),
        image: AppImages.cdbConfigureFoodCarousel4,
      },
    ];
  }, []);

  useEffect(() => {
    setNumberItems(items.length);
  }, []);

  const renderCartItem = useCallback(
    (props: {
      item: {
        text: string;
        image: ImageSourcePropType;
      };
    }) => {
      return (
        <SpView accessible style={styles.item}>
          <Image
            accessible
            style={styles.image}
            source={props.item.image}
            resizeMode="contain"
            {...testProperties(`${props.item.text}`, 'image')}
          />
          <SpText
            style={[styles.text, { marginBottom: 24 }]}
            size="sm"
            color={colors.black.color}
          >
            {props.item.text}
          </SpText>
        </SpView>
      );
    },
    [],
  );

  return (
    <SpView style={styles.container}>
      <SpView>
        <SpText style={styles.title}>{t('using_the_bowl')}</SpText>
      </SpView>
      <SpView>
        <SpText style={styles.description}>
          {t('follow_new_dog_bowl_connect')}
        </SpText>
      </SpView>
      <SpCarouselWithSteps
        height="75%"
        renderItem={renderCartItem}
        data={items}
        sliderWidth={SLIDER_WIDTH}
        slideWidth={ITEM_WIDTH}
        onLastTemplate={enableConfigureCDBCarouselFoodContinue}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 100,
    alignItems: 'center',
    maxHeight: '85%',
  },
  text: {
    marginTop: 8,
    textAlign: 'center',
    fontFamily: 'Rubik_SemiBold',
  },
  image: {
    width: 240,
    height: 140,
  },
  item: {
    flex: 1,
    padding: 20,
    justifyContent: 'space-around',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    marginBottom: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  description: {
    fontSize: 14,
    paddingBottom: 20,
    textAlign: 'center',
  },
});
