/* eslint-disable react/destructuring-assignment */
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React, { memo, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import textStyles from '@styles/text';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretDown, faCaretUp, faCheckCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import atomStyles from '@styles/atoms';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';

type SingleProps = {
  type: 'single';
};

type DoubleProps = {
  type: 'double';
  isNormal: boolean;
};

type Props = {
  percentage: number;
  data: string;
  isIncreasing: boolean;
  dataType: string;
} & (SingleProps | DoubleProps);

const DrinkingAndFeedingStatistics: React.FC<Props> = props => {
  const { t } = useTranslation();
  const allowInsights = false;

  return (
    <SpView style={styles.mainContainer}>
      {props.type === 'double' && allowInsights ? (
        <SpView style={atomStyles.rowContainer}>
          <AvgStatusComponent {...props} data={props.data} />
          <SpView style={atomStyles.statusCardContainer}>
            <SpText style={[textStyles.longTitle, styles.title]}>
              {t(
                props.isNormal
                  ? 'drinking_normal_card_title'
                  : 'change_in_drinking_card_title',
              )}
            </SpText>
            <SpView style={[styles.iconWrapper, styles.rightWrapper]}>
              <FontAwesomeIcon
                color={
                  props.isNormal
                    ? colors.green.color
                    : colors.orangeWarning.color
                }
                size={32}
                icon={props.isNormal ? faCheckCircle : faCircleExclamation}
              />
            </SpView>
          </SpView>
        </SpView>
      ) : (
        <AvgStatusComponent {...props} data={props.data} />
      )}
    </SpView>
  );
};

const AvgStatusComponent = ({
                              isIncreasing,
                              data,
                              percentage,
                              type,
                              dataType,
                            }) => {
  const { t } = useTranslation();
  const [size, sizeLayout, opacityStyle] = useAdaptiveFontSize(
    {
      value: 28,
      type: 20,
    },
    [data, dataType],
  );

  const values = useMemo(() => {
    // split by first space
    return {
      value: data.substring(0, data.indexOf(' ')),
      dataType: data.substring(data.indexOf(' ') + 1),
    };
  }, [data]);

  return (
    <SpView
      style={[
        atomStyles.statusCardContainer,
        type === 'single' && styles.smallContainer,
      ]}
    >
      <SpView>
        <SpText style={textStyles.smallTitle}>{t('average_short')}</SpText>
        <SpText
          style={[
            textStyles.title,
            styles.mainDataText,
            { fontSize: size.value },
            opacityStyle,
          ]}
          onTextLayout={sizeLayout}
        >
          {values.value}
          <SpText
            style={[
              textStyles.title,
              styles.mainDataText,
              styles.smallSize,
              { fontSize: size.type },
            ]}
          >
            {values.dataType}
          </SpText>
        </SpText>
        <SpText
          style={[textStyles.smallTitle, { color: colors.secondary.color }]}
        >
          {`${percentage}% ${t(isIncreasing ? 'more' : 'less')}`}
        </SpText>
      </SpView>
      <SpView
        style={[
          styles.iconWrapper,
          styles.leftWrapper,
          { backgroundColor: colors.secondary.color },
        ]}
      >
        <FontAwesomeIcon
          size={32}
          icon={isIncreasing ? faCaretUp : faCaretDown}
          color={colors.white.color}
        />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  iconWrapper: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: colors.white.color,
    position: 'absolute',
  },
  title: {
    marginBottom: 0,
    padding: 3,
  },
  rightWrapper: {
    right: -16,
  },
  leftWrapper: {
    left: -16,
  },
  mainDataText: {
    marginLeft: 0,
    lineHeight: undefined,
  },
  smallSize: {
    marginLeft: 4,
  },
  mainContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallContainer: {
    width: '38%',
    flex: undefined,
  },
});

export default memo(DrinkingAndFeedingStatistics);
