import { useTranslation } from 'react-i18next';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { AppImages } from '@constants/AppImages';


const PetDataDashboardSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <Image style={styles.image} source={AppImages.catAvatar} />
      <SpTitle align="center" text={t('pet_dashboard_tour_title')} />
      <SpText style={styles.contentText}>{t('pet_dashboard_tour_text')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 120,
    height: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 16,
  },
  contentText: {
    fontSize: 16,
    lineHeight: 24,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
});

export default PetDataDashboardSlide;
