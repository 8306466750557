import { ImageRequireSource } from 'react-native';
import { AppImages } from './AppImages';
import { DeviceType } from './Device';

export const DeviceImages: Record<DeviceType, ImageRequireSource> = {
  [DeviceType.CatFlapConnect]: AppImages.catFlap,
  [DeviceType.PetDoorConnect]: AppImages.petDoor,
  [DeviceType.FeederConnect]: AppImages.feeder,
  [DeviceType.Cerberus]: AppImages.cerberus,
  [DeviceType.FelaquaConnect]: AppImages.poseidon,
  [DeviceType.Hub]: AppImages.hub,
  [DeviceType.Repeater]: null,
};

export const DeviceTimelineImages: Record<DeviceType, ImageRequireSource> = {
  [DeviceType.CatFlapConnect]: AppImages.catFlapTimelineIcon,
  [DeviceType.PetDoorConnect]: AppImages.petDoorTimelineIcon,
  [DeviceType.FeederConnect]: AppImages.feederTimelineIcon,
  [DeviceType.Cerberus]: AppImages.cerberus,
  [DeviceType.FelaquaConnect]: AppImages.poseidonTimelineIcon,
  [DeviceType.Hub]: AppImages.hubTimelineIcon,
  [DeviceType.Repeater]: null,
};
