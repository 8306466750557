import { StyleSheet } from 'react-native';

const colors = StyleSheet.create({
  black: {
    color: '#000',
  },
  toxicGreen: {
    color: '#2db58e',
  },
  greyAccent: {
    color: 'rgba(38, 58, 67, 0.15)',
  },
  greyBorder: {
    color: 'rgba(38, 58, 67, 0.25)',
  },
  greyOpacity: {
    color: 'rgba(38, 58, 67, 0.5)',
  },
  greyDisabled: {
    color: 'rgba(38, 58, 67, 0.75)',
  },
  greySmallTitle: {
    color: '#919ba0',
  },
  headerBorderColor: {
    color: '#d9d9d9',
  },
  greyText: {
    color: '#263a43',
  },
  placeholderTextColor: {
    color: '#8d979d',
  },
  labelTextColor: {
    color: '#7e898e',
  },
  placeholderGrey: {
    color: '#717b80',
  },
  btnBlue: {
    color: '#4392f1',
  },
  blueBackgroundOpacity: {
    color: 'rgba(67, 146, 241, 0.1)',
  },
  errorRed: {
    color: '#ed0055',
  },
  white: {
    color: '#ffffff',
  },
  orange: {
    color: '#edbb00',
  },
  orangeWarning: {
    color: '#ffa239',
  },
  green: {
    color: '#2db58e',
  },
  whiteBlue: {
    color: '#94c1f7',
  },
  warningPillContainer: {
    color: 'rgba(255, 162, 57, 0.2)',
  },
  warningPill: {
    color: 'rgba(247, 166, 74, 0.4)',
  },
  normalPillContainer: {
    color: 'rgba(45, 181, 142, 0.2)',
  },
  normalPill: {
    color: 'rgba(45, 181, 142, 0.2)',
  },
  lightBlue: {
    color: '#e1edfd',
  },
  lightGrey: {
    color: '#263a430d',
  },
  darkGrey: {
    color: '#37474f',
  },
  blue: {
    color: '#4fb4dd',
  },
  grayBottomSheetHeader: {
    color: 'rgba(38, 58, 67, 0.1)',
  },
  cardShadow: {
    color: 'rgba(38, 58, 67, 0.4)',
  },
  smallGreen: {
    color: '#256b57',
  },
  greyInput: {
    color: 'rgba(38, 58, 67, 0.1)',
  },
  lightBlack: {
    color: '#263a43',
  },
  pastelGreen: {
    color: '#c0e8dd',
  },
  lightGreyDisabled: {
    color: 'rgba(38, 58, 67, 0.3)',
  },
  helpScreenColor: {
    color: '#263a43',
  },
  darkturquoise: {
    color: '#03A2B1'
  },
  primary: {
    color: '#03A2B1'
  },
  secondary: {
    color: '#5D71C2'
  },
  secondary2: {
    color: '#ADB8E0'
  },
  secondary3: {
    color: '#D6DBEF'
  },
  secondary4: {
    color: '#ECEDF7'
  },
  primaryBackgroundOpacity: {
    color: 'rgba(3,162,177, 0.1)',
  },

});

export default colors;
