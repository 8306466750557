import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { AppImages } from '@constants/AppImages';
import { SpCarouselWithSteps } from '@molecules/CarouselWithSteps';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ImageSourcePropType, StyleSheet } from 'react-native';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = 280;

interface InstructionCarouselProps {
  enableInstructionCarouselContinue: (data: number) => void;
  setNumberItems: (data: number) => void;
}

type CarouselElem = {
  goodText: string;
  goodImage: ImageSourcePropType;
  badText: string;
  badImage: ImageSourcePropType;
  goodTextId: string;
  badTextId: string;
};

export const PlacementInfo = ({
  enableInstructionCarouselContinue,
  setNumberItems,
}: InstructionCarouselProps) => {
  const { t } = useTranslation();

  const items = useMemo<CarouselElem[]>(() => {
    return [
      {
        goodText: t('setup_felaqua_connect_instruction_10'),
        goodImage: AppImages.felaquaPlacementGood1,
        goodTextId: 'ConsiderPuttingTheFelaqua',
        badText: t('setup_felaqua_connect_instruction_11'),
        badImage: AppImages.felaquaPlacementBad1,
        badTextId: 'Don’TPlaceOnAn',
      },
      {
        goodText: t('setup_felaqua_connect_instruction_12'),
        goodImage: AppImages.felaquaPlacementGood2,
        badText: t('setup_felaqua_connect_instruction_13'),
        badImage: AppImages.felaquaPlacementBad2,
        goodTextId: 'PositionItAtLeast',
        badTextId: 'Don’TPlaceUpAgainst',
      },
      {
        goodText: t('setup_felaqua_connect_instruction_14'),
        goodImage: AppImages.felaquaPlacementGood3,
        badText: t('setup_felaqua_connect_instruction_15'),
        badImage: AppImages.felaquaPlacementBad3,
        goodTextId: 'PlaceOnAFlat',
        badTextId: 'Don’TPlaceOnA',
      },
    ];
  }, []);

  useEffect(() => {
    setNumberItems(items.length);
  }, []);

  const renderCartItem = useCallback(({ item }: { item: CarouselElem }) => {
    return (
      <SpView accessible style={styles.item}>
        <Image
          accessible
          style={styles.image}
          source={item.goodImage}
          resizeMode="contain"
          {...testProperties(`${item.goodText}`, 'image')}
        />
        <SpText
          style={[styles.text, { marginBottom: 24 }]}
          size="sm"
          color={colors.green.color}
          {...testProperties(item.goodTextId, 'Text')}
        >
          {item.goodText}
        </SpText>
        <Image
          accessible
          style={styles.image}
          resizeMode="contain"
          source={item.badImage}
          {...testProperties(`${item.badText}`, 'image')}
        />
        <SpText
          style={styles.text}
          size="sm"
          color={colors.errorRed.color}
          {...testProperties(item.badTextId, 'Text')}
        >
          {item.badText}
        </SpText>
      </SpView>
    );
  }, []);

  return (
    <SpView style={styles.container}>
      <SpCarouselWithSteps
        renderItem={renderCartItem}
        data={items}
        sliderWidth={SLIDER_WIDTH}
        slideWidth={ITEM_WIDTH}
        onLastTemplate={enableInstructionCarouselContinue}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 16,
    alignItems: 'center',
    maxHeight: '85%',
  },
  text: {
    marginTop: 8,
    textAlign: 'center',
    fontFamily: 'Rubik_SemiBold',
  },
  image: {
    width: 240,
    height: 140,
  },
  item: {
    height: '100%',
    padding: 20,
    justifyContent: 'space-around',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    marginBottom: 80,
  },
});
