import BothWaysSVG from '@atoms/SvgIcons/BothWaysSVG';
import IndoorsSVG from '@atoms/SvgIcons/IndoorsSVG';
import OutdoorsSVG from '@atoms/SvgIcons/OutdoorsSVG';
import UnlockSVG from '@atoms/SvgIcons/UnlockSVG';
import colors from '@styles/colors';
import i18n from '@utils/i18n';
import React from 'react';
import { PetDoorLockingMode } from './Device';
import { QuickActionButton } from './Navigation';

export const LockDoorActions: QuickActionButton[] = [
  {
    icon: (
      <IndoorsSVG
        color={colors.greyText.color}
        width={22}
        height={22}
        viewBox="0 0 16 16"
      />
    ),
    text: i18n.t('lock_home_status_in'),
    id: PetDoorLockingMode.inside_only,
  },
  {
    icon: (
      <OutdoorsSVG
        color={colors.greyText.color}
        width={22}
        height={22}
        viewBox="0 0 16 16"
      />
    ),
    text: i18n.t('lock_home_status_out'),
    id: PetDoorLockingMode.outside_only,
  },
  {
    icon: (
      <BothWaysSVG
        color={colors.greyText.color}
        width={22}
        height={22}
        viewBox="0 0 17 17"
      />
    ),
    text: i18n.t('lock_home_status_both'),
    id: PetDoorLockingMode.both,
  },
];

export const UnlockDoorActions: QuickActionButton[] = [
  {
    icon: (
      <UnlockSVG
        color={colors.greyText.color}
        width={26}
        height={26}
        viewBox="0 0 28 28"
      />
    ),
    text: i18n.t('unlock'),
    id: PetDoorLockingMode.normal,
  },
];
