import { DeviceModel } from '@models/Device';
import { PetModel, PetWithTagModel } from '@models/Pet';
import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';

interface PetWithTagModelAssigned extends PetWithTagModel {
  isAssigned: boolean;
}

export const usePetsWithTag = (
  device: DeviceModel,
  pets: PetModel[],
): PetWithTagModelAssigned[] => {
  return useMemo(() => {
    if (device?.tags && pets) {
      return pets.map(pet => {
        const isAssigned = (device.tags || []).some(
          item => item?.id === pet?.tag_id,
        );
        const tag = (device.tags || []).find(item => item?.id === pet?.tag_id);
        const newPet = cloneDeep(pet);
        if (isAssigned && tag?.profile) {
          newPet.profile = tag.profile;
        }
        return { pet: newPet, tag, isAssigned };
      });
    }

    return [];
  }, [device, pets]);
};
