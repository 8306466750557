import { useTimeTransform } from '@hooks/useTimeTransform';
import { GroupReportPointModel } from '@models/ReportModel';
import { meanBy, sumBy } from 'lodash-es';
import { useMemo } from 'react';

const useAvarageGrouping = (
  data: GroupReportPointModel<Record<string, any> & { duration: number }>[],
): [number, ReturnType<typeof convertSecondToTwoDigitFormat>] => {
  const grouped = useMemo(
    () =>
      data.reduce((acc, point) => {
        // here we ignore days with no data
        if (!point?.points) return acc;

        const sumDuration = sumBy(point.points, 'duration');
        const { length } = point.points;

        if (length === 0 || sumDuration === 0) return acc;
        acc.push({ activity: length, duration: sumDuration });

        return acc;
      }, []),
    [data],
  );

  const { convertSecondToTwoDigitFormat } = useTimeTransform();
  const averageActivity = Math.round(
    grouped.length ? meanBy(grouped, item => item.activity) : 0,
  );
  const duration = convertSecondToTwoDigitFormat(
    grouped.length ? meanBy(grouped, item => item.duration) : 0,
  );

  return [averageActivity, duration];
};

export default useAvarageGrouping;
