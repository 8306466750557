import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const PersonalSVG = (props: SvgProps) => (
  <Svg width={16} height={16} {...props}>
    <G fill="#263A43" fillRule="nonzero">
      <Path d="M8.001 8.308c2.234 0 4.06-1.868 4.06-4.154C12.062 1.868 10.236 0 8.002 0c-2.234 0-4.06 1.868-4.06 4.154C3.94 6.44 5.766 8.308 8 8.308ZM14.97 11.628a3.9 3.9 0 0 0-.408-.762c-.815-1.233-2.074-2.05-3.493-2.25a.734.734 0 0 0-.514.128A4.208 4.208 0 0 1 8 9.596c-.922 0-1.809-.29-2.553-.852a.654.654 0 0 0-.515-.127 5 5 0 0 0-3.493 2.25c-.16.235-.302.507-.408.761a.394.394 0 0 0 .018.345c.142.254.319.508.479.725.248.345.514.653.815.944.249.254.532.49.816.725A7.915 7.915 0 0 0 7.983 16a7.915 7.915 0 0 0 4.824-1.633c.283-.217.567-.471.815-.725a8.32 8.32 0 0 0 1.295-1.67c.089-.108.106-.235.053-.344Z" />
    </G>
  </Svg>
);
export default PersonalSVG;
