import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const ZeroSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={30} height={28} {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="m26.139 2.17 1.724 1.892c.2.22.184.558-.035.758L24.65 7.7A11.426 11.426 0 0 1 26.53 14c0 6.352-5.162 11.501-11.531 11.501-2.725 0-5.23-.943-7.203-2.519l-3.18 2.883a.536.536 0 0 1-.756-.036l-1.724-1.891a.536.536 0 0 1 .035-.758L5.35 20.3A11.426 11.426 0 0 1 3.47 14C3.469 7.648 8.63 2.499 15 2.499c2.725 0 5.23.943 7.203 2.519l3.18-2.883a.536.536 0 0 1 .756.036Zm-4.068 7.87L10.376 20.643A8.085 8.085 0 0 0 15 22.087c4.478 0 8.108-3.62 8.108-8.087 0-1.439-.377-2.79-1.037-3.96ZM15 5.912c-4.478 0-8.108 3.62-8.108 8.087 0 1.439.377 2.79 1.037 3.96L19.624 7.357A8.085 8.085 0 0 0 15 5.913Z"
    />
  </Svg>
);
export default ZeroSVG;
