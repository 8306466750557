import { AppImages } from '@constants/AppImages';
import { SpEditMenuItemValue } from '@molecules/SpEditMenuItemValue';
import { capitalize } from '@utils/capitalize';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';

export const BothBowls = () => {
  const { t } = useTranslation();
  return (
    <>
      <SpEditMenuItemValue value={capitalize(t('half_bowls'))} />
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 20,
    height: 10,
  },
});
