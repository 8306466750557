import qs from 'qs';
import { TagModel } from '@models/Tag';
import Http from './Http';

class TagApi {
  static getTags(): Promise<TagModel[]> {
    return Http.get(`/api/tag`, {
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default TagApi;
