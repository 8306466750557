import React, { useCallback, useState } from 'react';
import { Dimensions, StatusBar, StatusBarStyle, StyleSheet, TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import { useNavigation } from '@react-navigation/native';
import { Carousel } from 'react-native-snap-carousel';
import { SpText } from '@atoms/SpText';
import { useTranslation } from 'react-i18next';
import SlidesIndicator from '@molecules/SlidesIndicator';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { testProperties } from '@utils/testProperties';
import TourComponentWrapper from './components';
import { SpLinearGradient } from '@atoms/SpLinearGradient';

const Tour = ({ route }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [viewedCount, setViewedCount] = useState([0]);
  const WINDOW_WIDTH = Dimensions.get('window').width;
  const isCarousel = React.useRef(null);
  const insets = useSafeAreaInsets();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const handleClose = () => {
    navigation.goBack();
  };

  const slideItem = useCallback(({ item, index }) => {
    return <TourComponentWrapper key={index} component={item} />;
  }, []);

  const handleSnapToItem = e => {
    if (
      viewedCount.length < route.params.components.length &&
      !viewedCount.includes(e)
    ) {
      const newArray = [...viewedCount, e];
      setViewedCount(newArray);
    }
  };
 
  return (
    <SpView style={styles.container}>
      <StatusBar
        barStyle={'dark-content' as StatusBarStyle}
        backgroundColor="#d5f1ec"
        translucent
      />
      <SpLinearGradient
        style={[
          styles.container,
          { paddingTop: insets.top, paddingBottom: insets.bottom },
        ]}
        colors={['#d5f1ec', '#ffffff']}
        start={{ x: 0.0, y: 0.2 }}
        end={{ x: 0.0, y: 0.5 }}
      >
        <SpView style={styles.headerWrapper}>
          <TouchableOpacity onPress={handleClose}>
            <View
              style={{ width: 24, height: 24 }}
              {...testProperties(`Close`, 'Button', '', true)}
            >
              <FontAwesomeIcon
                color={colors.greyText.color}
                size={24}
                icon={faClose}
              />
            </View>
          </TouchableOpacity>
        </SpView>
        <SpView style={{ flex: 1 }}>
          <Carousel
            onScrollIndexChanged={index => {
              handleSnapToItem(index);
              setCurrentSlideIndex(index);
            }}
            layout="default"
            vertical={false}
            ref={isCarousel}
            firstItem={0}
            keyExtractor={(item, index) => `${index}`}
            data={route.params?.components || []}
            scrollEnabled={route.params?.components?.length > 1}
            renderItem={slideItem}
            sliderWidth={WINDOW_WIDTH}
            itemWidth={WINDOW_WIDTH}
          />
        </SpView>
        <SpView style={styles.bottomSection}>
          <SlidesIndicator
            style={styles.slidesContainer}
            isShow={route.params?.components.length > 1}
            items={route.params?.components || []}
            activeSlideIndex={currentSlideIndex}
          />
          {route.params?.components.length > 1 &&
            !(viewedCount.length === route.params?.components.length) && (
              <SpText style={styles.text}>{t('swipe_to_learn_more')}</SpText>
            )}
          {viewedCount.length === route.params?.components.length && (
            <SpRoundedHeaderButton
              onPress={() => navigation.goBack()}
              backgroundColor={colors.primary.color}
              stylesForContainer={styles.okButton}
              h={56}
              title={t('ok_thanks')}
            />
          )}
        </SpView>
        </SpLinearGradient>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#ffffff',
  },
  bottomSection: {
    marginBottom: 50,
  },
  slidesContainer: {
    marginBottom: 25,
  },
  text: {
    textAlign: 'center',
  },
  headerWrapper: {
    paddingHorizontal: 19,
    paddingVertical: 18,
  },
  okButton: {
    paddingHorizontal: 19,
    marginBottom: -34,
  },
});

export default Tour;
