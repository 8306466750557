import React from 'react';
import { StyleSheet, Platform, Dimensions } from 'react-native';
import textStyles from '@styles/text';
import { useTranslation } from 'react-i18next';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpView } from '@atoms/SpView';
import { ScrollView } from 'react-native-gesture-handler';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { ReactSetState } from './WhatIsChanging';

type Props = {
  setStep: ReactSetState<number>;
};

const EndUserLicence: React.FC<Props> = ({ setStep }) => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.gap}>
      <TouchableOpacity style={styles.cross} onPress={() => setStep(2)}>
        <FontAwesomeIcon
          icon={faXmark}
          size={24}
          color={colors.greyText.color}
        />
      </TouchableOpacity>
      <SpText style={textStyles.title}>{t('last_agreement')}</SpText>
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator>
        <SpText style={styles.scrollText}>{t('large_terms')}</SpText>
      </ScrollView>
      <SpRoundedHeaderButton
        title={t('ok_thanks')}
        backgroundColor={colors.primary.color}
        h={56}
        stylesForContainer={styles.width}
        onPress={() => setStep(2)}
      />
    </SpView>
  );
};

const height = Dimensions.get('window').height * 0.6;
const styles = StyleSheet.create({
  gap: {
    paddingVertical: 20,
  },
  max: {
    flex: 1,
  },
  width: {
    width: '100%',
    marginTop: 36,
  },
  scrollView: {
    borderWidth: 1,
    borderRadius: 15,
    borderColor: colors.placeholderGrey.color,
    padding: 10,
    marginTop: 24,
    maxHeight: height,
    minHeight: height,
  },
  scrollText: {
    color: colors.greyText.color,
    fontSize: 16,
    fontFamily: 'Rubik',
    paddingRight: 10,
    paddingBottom: Platform.OS === 'android' ? 10 : 0,
    lineHeight: 20,
  },
  cross: {
    marginBottom: 18,
  },
});


export default EndUserLicence;
