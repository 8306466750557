import { Image, ImageSourcePropType, Pressable, StyleSheet } from 'react-native';
import React, { ReactNode } from 'react';
import colors from '@styles/colors';
import { SpView } from '@atoms/SpView';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { SpText } from '@atoms/SpText';
import { testProperties } from '@utils/testProperties';

interface ITypeButtonProps {
  text: string;
  image?: ImageSourcePropType;
  svg?: ReactNode;
  onPress: () => void;
  isActive: boolean;
  isBowl?: boolean;
  leftAlign?: boolean;
}

const TypeButton = ({
  text,
  image,
  svg,
  onPress,
  isActive,
  isBowl,
  leftAlign,
}: ITypeButtonProps) => {
  return (
    <Pressable
      accessible={false}
      style={[
        styles.container,
        isActive && styles.activeContainer,
        leftAlign && {
          alignItems: 'center',
          flexDirection: 'row',
          paddingTop: 0,
          height: 80,
        },
      ]}
      onPress={onPress}
    >
      {svg && <SpView style={styles.svg}>{svg}</SpView>}
      {image && (
        <Image
          source={image}
          style={[styles.image, isBowl && { marginTop: -12 + -11, height: 52 }]}
          resizeMode="contain"
          {...testProperties(text, 'image')}
        />
      )}
      <SpText
        color={colors.greyText.color}
        fontFamily={isBowl && 'Rubik_Medium'}
        size="xl"
        bold
        style={{
          marginTop: isBowl ? 17 : 4,
        }}
      >
        {text}
      </SpText>
      {isActive && (
        <SpView
          {...testProperties('icon', `${text || ''}`, 'FaCheckCircle')}
          style={styles.activeIconContainer}
        >
          <FontAwesomeIcon
            size={36}
            icon={faCheckCircle}
            color={colors.primary.color}
          />
        </SpView>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    height: 100,
    alignItems: 'center',
    paddingTop: 12,
  },
  activeIconContainer: {
    position: 'absolute',
    right: -10,
    top: -10,
    zIndex: 2,
    backgroundColor: colors.white.color,
    borderRadius: 18,
  },
  image: {
    height: 44,
  },
  svg: {
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeContainer: {
    borderColor: colors.primary.color,
    backgroundColor: colors.primaryBackgroundOpacity.color,
  },
});

export default TypeButton;
