import {
  DeviceCatFlapCurfew,
  DevicePetDoorCurfew,
  UpdateCurfewRequestParamsModel,
} from '@models/Device';
import { format, utcToZonedTime } from 'date-fns-tz';
import storeCreator from 'src/store/store';

type DateTypes = Date | string | number;

export class TimeService {
  private static householdTimezoneOffset: number = null;

  static refreshTimezone() {
    const { activeHousehold } = storeCreator.getState().householdStore;
    const offset = activeHousehold?.timezone?.utc_offset || 0;
    this.householdTimezoneOffset = offset;
    if (this.householdTimezoneOffset !== 0) {
      this.householdTimezoneOffset *= 1000;
    }
  }

  static passTimeToUTCTime(time: string): string {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    return format(utcToZonedTime(date, 'UTC'), 'kk:mm', {
      timeZone: 'UTC',
    });
  }

  static passDateToUTCTime(date: string | Date): string {
    return format(utcToZonedTime(date, 'UTC'), 'kk:mm', {
      timeZone: 'UTC',
    });
  }

  static passCurfewLocalToUTCTime(data: UpdateCurfewRequestParamsModel) {
    if (data.curfew.constructor === Array) {
      data.curfew = data.curfew.map((time: DeviceCatFlapCurfew) => {
        time.lock_time = TimeService.passTimeToUTCTime(time.lock_time);
        time.unlock_time = TimeService.passTimeToUTCTime(time.unlock_time);
        return time;
      });
    } else {
      (data.curfew as DevicePetDoorCurfew).lock_time =
        TimeService.passTimeToUTCTime(
          (data.curfew as DevicePetDoorCurfew).lock_time,
        );
      (data.curfew as DevicePetDoorCurfew).unlock_time =
        TimeService.passTimeToUTCTime(
          (data.curfew as DevicePetDoorCurfew).unlock_time,
        );
    }
    return data;
  }

  static passTimeToLocalTime(time: string): string {
    const [utcHours, utcMinutes] = time.split(':');
    const currentUTCTime = new Date();
    currentUTCTime.setUTCHours(Number(utcHours));
    currentUTCTime.setUTCMinutes(Number(utcMinutes));

    const localTime = this.formatInTimeZone(currentUTCTime, 'HH:mm');
    return localTime;
  }

  // eslint-disable-next-line class-methods-use-this
  static formatInTimeZone(date: Date, fmt: string): string {
    return format(utcToZonedTime(date, 'UTC'), fmt, { timeZone: 'UTC' });
  }

  static convertDateWithoutLocalTimezone(date: Date, milliseconds = 0): Date {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const tzOffset = date.getTimezoneOffset();
    return new Date(date.valueOf() + (minutes + tzOffset) * 60 * 1000);
  }

  static convertOrUnconvertDateByUTC<T extends DateTypes>(
    date: T,
    convertUtcToZonedTime: boolean,
  ): number {
    this.refreshTimezone();

    if (!date && date === 0) {
      return null;
    }

    const calculatedOffset = this.householdTimezoneOffset
      ? this.householdTimezoneOffset * (convertUtcToZonedTime ? 1 : -1)
      : 0;

    if (typeof date === 'string') {
      return TimeService.convertDateWithoutLocalTimezone(
        new Date(date),
        calculatedOffset,
      ).getTime();
    }

    if (typeof date === 'number') {
      const newDate = new Date(date + calculatedOffset);
      return +newDate;
    }

    const newDate = new Date(+date + calculatedOffset);

    return +newDate;
  }

  static convertOrUnconvertDateByUTCOld<T extends DateTypes>(
    date: T,
    convertUtcToZonedTime: boolean,
  ): T {
    this.refreshTimezone();

    const calculatedOffset = this.householdTimezoneOffset
      ? this.householdTimezoneOffset * (convertUtcToZonedTime ? 1 : -1)
      : 0;

    if (typeof date === 'string') {
      const newDate = new Date(+new Date(date) + calculatedOffset);
      return newDate.toISOString() as T;
    }

    if (typeof date === 'number') {
      const newDate = new Date(date + calculatedOffset);
      return +newDate as T;
    }

    const newDate = new Date(+date + calculatedOffset);

    return newDate as T;
  }

  static getTimezonnedCurrentDate(): number {
    this.refreshTimezone();

    if (this.householdTimezoneOffset === null) {
      return Date.now();
    }
    return this.convertOrUnconvertDateByUTC<Date>(new Date(), true);
  }

  static calculateOffset(utcStringFromBackend: string): number {
    this.refreshTimezone();
    if (!utcStringFromBackend) {
      return 0;
    }
    const target = new Date(
      new Date(utcStringFromBackend).getTime() + this.householdTimezoneOffset,
    );

    return target.getTime();
  }

  static convertToUTC(localDate: Date): Date {
    const utcDate = new Date(
      localDate.getTime() + localDate.getTimezoneOffset() * 60000,
    );
    return utcDate;
  }
}
