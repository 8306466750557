import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useDateTransform } from '@hooks/useDateTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import colors from '@styles/colors';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

interface TimelineCardProps {
  text: string | React.ReactElement;
  date: Date;
  image?: ReactElement;
  active: boolean;
}

export const TimelineCard = ({
  text,
  date,
  image = null,
  active,
}: TimelineCardProps) => {
  const { transformToHumanDateAgo } = useDateTransform();
  const userTimeUnits = useUserTimeUnits();

  const {t} = useTranslation();
  const formattedDate = useMemo(() => {
    return transformToHumanDateAgo(date, userTimeUnits);
  }, [date, userTimeUnits, t]);

  return (
    <SpView style={{ paddingHorizontal: 26 }}>
      <SpView
        style={[styles.container, active ? styles.activeContainer : null]}
      >
        <SpView style={styles.imageContainer}>
          {image && <SpView style={styles.imageWrap}>{image}</SpView>}
        </SpView>
        <SpView style={styles.content}>
          <SpView marginBottom={12}>
            {typeof text === 'string' ? (
              <SpText
                color={active ? colors.white.color : colors.lightBlack.color}
              >
                {text}
              </SpText>
            ) : (
              text
            )}
          </SpView>
          <SpText
            size="sm"
            color={active ? colors.white.color : colors.greySmallTitle.color}
          >
            {formattedDate} 
          </SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    borderRadius: 16,
    paddingVertical: 12,
    paddingRight: 44,
    paddingLeft: 0,
  },
  activeContainer: {
    backgroundColor: colors.lightBlack.color,
  },
  content: {
    width: 0,
    flex: 1,
  },
  imageContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: 44,
  },
  imageWrap: {
    position: 'relative',
    left: -12,
  },
});
