import { Platform } from 'react-native';

function camelize(str: string) {
  let shortStr = str;
  const splitedStr = str?.toString().split(' ');
  if (splitedStr?.length > 3) {
    shortStr = `${splitedStr[0]} ${splitedStr[1]} ${splitedStr[2]} ${splitedStr[3]}`;
  }
  return shortStr
    ?.toString()
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return word.toUpperCase();
    })
    ?.replace(/\s+/g, '')
    ?.replace(/[!?,]/g, '');
}

export function testProperties(
  label: string,
  description: string,
  componentName?: string,
  disableAccessible = false,
) {
  // All touchable elements are accessible
  // meaning that it groups its children into a single selectable component
  // sometimes this is not needed for testingr
  const disableAccessibility = disableAccessible ? { accessible: false } : {};
  const testIDLabel = `${camelize(label)}` || '';
  const component = componentName ? `${componentName}_` : '';
  const desc = description ? `_${camelize(description)}` : '';
  const id = `${component}${testIDLabel}${desc}`.trim();
  return Platform.OS === 'android'
    ? {
        accessible: true,
        accessibilityLabel: id,
        ...disableAccessibility,
      }
    : {
        accessible: true,
        testID: id,
        ...disableAccessibility,
      };
}
