import React, { forwardRef, LegacyRef, useMemo } from 'react';
import {
  KeyboardTypeOptions,
  ReturnKeyTypeOptions,
  TextInput,
  TextInputProps,
  ViewStyle,
} from 'react-native';
import { testProperties } from '@utils/testProperties';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import colors from 'src/styles/colors';
import { useTranslation } from 'react-i18next';
import noop from '../../utils/noop';
import {
  inputStyles,
  SpInputWrapper,
  SpInputWrapperContext,
  SpInputWrapperProps,
} from './SpInputWrapper';

export type SpInputProps = SpInputWrapperProps & {
  onChangeText?: (text: string) => void;
  keyboardType?: KeyboardTypeOptions;
  textContentType?: 'oneTimeCode';
  autoFocus?: boolean;
  handleFocus?: () => void;
  handleBlur?: () => void;
  disabled?: boolean;
  placeholder?: string;
  icon?: IconProp;
  password?: boolean;
  isLoading?: boolean;
  multiline?: boolean;
  returnKeyType?: ReturnKeyTypeOptions;
  weightUnit?: string;
  onSubmitEditing?: () => void;
  componentName?: string;
  inputStyle?: ViewStyle | ViewStyle[];
  additionalTestLabel?: string;
  customPlaceholder?: string;
  additionalIconTestLabel?: string;
  testID?: string;
} & TextInputProps;

export const SpInput = forwardRef(function Input(
  {
    onChangeText = noop,
    value,
    keyboardType = 'default',
    textContentType,
    label,
    error = '',
    autoFocus = false,
    handleFocus,
    handleBlur,
    placeholder = '',
    icon,
    password,
    isLoading,
    multiline,
    returnKeyType,
    onSubmitEditing,
    weightUnit = '',
    componentName,
    inputStyle,
    multilineError = false,
    additionalTestLabel = null,
    customPlaceholder,
    additionalTestLabelTail,
    additionalIconTestLabel,
    testID,
    ...props
  }: SpInputProps,
  ref: LegacyRef<TextInput>
): JSX.Element {
  const { t } = useTranslation();
  const textPlacement = multiline ? 'top' : 'center';
  // fix for render issue where placeholder appears unstyled for render cycle
  const handleTextStyleChange = useMemo(() => {
    const style = !value ? inputStyles.placeholder : inputStyles.text;
    const placeholderOrBlank = !value ? customPlaceholder : t('type_here');
    return { style, placeholder: placeholderOrBlank };
  }, [value, placeholder, t]);

  return (
    <SpInputWrapper
      value={value}
      label={label}
      error={t(error)}
      icon={icon}
      weightUnit={weightUnit}
      password={password}
      isLoading={isLoading}
      multilineError={multilineError}
      additionalTestLabel={additionalTestLabel}
      additionalTestLabelTail={additionalTestLabelTail}
      additionalIconTestLabel={additionalIconTestLabel}
      componentName={componentName}
    >
      <SpInputWrapperContext.Consumer>
        {({ onBlur, onFocus, isPasswordSecured }) => {
          return (
            <TextInput
              {...props}
              multiline={multiline}
              ref={ref}
              value={value}
              {...testProperties(
                testID || label || placeholder,
                'InputField',
                componentName
              )}
              style={[
                inputStyles.textInput,
                multiline && inputStyles.multiline,
                value ? inputStyles.text : inputStyles.placeholder,
                handleTextStyleChange.style,
                (icon || error || weightUnit) && inputStyles.offsetWithIcon,
                inputStyle,
                error && inputStyles.errorBg,
              ]}
              onChangeText={text => {
                password
                  ? onChangeText(text.trim())
                  : onChangeText(text);
              }}
              keyboardType={keyboardType}
              secureTextEntry={isPasswordSecured}
              placeholder={customPlaceholder || t('type_here')}
              placeholderTextColor={colors.placeholderTextColor.color}
              onFocus={() => {
                onFocus();
                if (handleFocus) {
                  handleFocus();
                }
              }}
              onBlur={() => {
                onBlur();
                if (handleBlur) {
                  handleBlur();
                }
              }}
              autoFocus={autoFocus}
              textContentType={textContentType}
              returnKeyType={returnKeyType}
              onSubmitEditing={onSubmitEditing}
              textAlignVertical={textPlacement}
              caretHidden={false}
              autoComplete={
                keyboardType === 'email-address' ? 'email' : undefined
              }
            />
          );
        }}
      </SpInputWrapperContext.Consumer>
    </SpInputWrapper>
  );
});
