import React from 'react';
import { SpView } from '@atoms/SpView';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faCheckCircle,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import { StyleSheet } from 'react-native';
import { testProperties } from '@utils/testProperties';

type SpToastIconProps = {
  isError?: boolean;
  size?: number;
  isToast?: boolean;
};

const SpToastIcon = ({
  isError = false,
  size = 24,
  isToast = true,
}: SpToastIconProps) => {
  return (
    <SpView
      {...testProperties(
        isError ? 'faCircleExclamation' : 'faCheckCircle',
        'icon',
      )}
      style={[
        styles.checkBox,
        isError ? styles.checkBoxError : styles.checkBoxSuccess,
        !isToast && { top: '50%' },
      ]}
    >
      <FontAwesomeIcon
        color={colors.white.color}
        icon={isError ? faCircleExclamation : faCheckCircle}
        size={size}
      />
    </SpView>
  );
};

export default SpToastIcon;

const styles = StyleSheet.create({
  checkBox: {
    position: 'absolute',
    left: 0,
    paddingLeft: 19,
    paddingVertical: 4,
    paddingRight: 5,
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
    marginRight: 10,
    shadowOffset: {
      width: 3,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 5.32,

    elevation: 16,
  },
  checkBoxSuccess: {
    backgroundColor: colors.toxicGreen.color,
    shadowColor: colors.toxicGreen.color,
  },
  checkBoxError: {
    shadowColor: colors.errorRed.color,
    backgroundColor: colors.errorRed.color,
  },
});
