import { SpView, SpViewProps } from '@atoms/SpView';
import React, { PropsWithChildren } from 'react';

export const DeviceBottomSheetContainer = ({
  children,
  ...props
}: PropsWithChildren<SpViewProps>) => {
  return (
    <SpView {...props} paddingVertical={18} paddingHorizontal={22}>
      {children}
    </SpView>
  );
};
