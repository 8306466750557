import AuthApi from '@api/AuthApi';
import { applyInterceptors } from '@api/Http';
import MeApi from '@api/MeApi';
import MeClientApi from '@api/MeClientApi';
import { lens } from '@dhmk/zustand-lens';
import { LoginFormModel, RegisterFormModel } from '@models/Auth';
import { DeviceEventEmitter, Platform } from 'react-native';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { DeviceService } from 'src/services/DeviceService';
import { registerForPushNotificationsAsync } from 'src/services/PushNotificationService';
import { StateCreator } from 'zustand';

import { AccountSlice, MergedInterfaces } from '../models';
import { resetAllStores } from '../store';

const createAccountSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  AccountSlice
> = (set, get) => {
  return {
    accountStore: lens((subSet, subGet) => ({
      updatePhotoResult: 'none',
      deletePhotoResult: 'none',
      token: null,
      deviceToken: null,
      expoToken: null,
      user: null,
      userLoading: null,
      loginError: null,
      login: async (data: LoginFormModel): Promise<boolean> => {
        try {
          const { token, user } = await AuthApi.login(data);
          subSet({
            token,
            user,
            loginError: null,
          });
          await get().householdStore.getHouseholds();
          return true;
        } catch (response: any) {
          subSet({
            loginError: response?.status || 500,
          });
          return false;
        }
      },
      register: async (data: RegisterFormModel) => {
        try {
          const { token, user } = await AuthApi.register(data);
          subSet({
            token,
            user,
          });
        } catch (res: any) {}
      },
      getUser: async (forceToken = null) => {
        const user = await MeApi.getUser(forceToken);
        subSet({ user });
        return user;
      },
      updateUser: async (newUser) => {
        const user = await MeApi.updateUser(newUser);
        if (user) {
          if (user.language_id !== subGet().user.language_id) {
            get().breedStore.loadBreeds(true);
            get().conditionStore.loadAll(true);
            get().countryStore.loadCountries(true);
            applyInterceptors({
              getStoreOptions: () => {
                return {
                  token: subGet().token,
                  colour: subGet().user?.use_colour,
                  locale:
                    get().languageStore.getLanguageByID(subGet().user?.language_id || 37)?.code ||
                    'en',
                };
              },
            });
          }

          await AnalyticsService.setUserProperties({
            userLanguage: get().languageStore.getLanguageByID(user.language_id).code,
            userCountry: get().countryStore.getCountryByID(user.country_id).code,
          });

          subSet({ user });
        } else {
          const err = { code: 3 };
          throw err;
        }
      },
      updatePhoto: async (uri) => {
        try {
          const body = new FormData();
          const type = uri.split('.').pop();
          const uploadUri = Platform.OS === 'ios' ? uri.replace('file://', '') : uri;

          body.append('file', {
            uri: uploadUri,
            type: `image/${type}`,
            name: 'media', // Some name is required!!!
          } as unknown as Blob);

          const newPhoto = await MeApi.updatePhoto(body);
          const { user } = subGet();

          user.photo_id = newPhoto.data.id;

          const newUser = await subGet().updateUser(user);

          get().householdStore.getHouseholds();
          subSet({ updatePhotoResult: 'success' });
          return newUser;
        } catch (err) {
          subSet({ updatePhotoResult: 'error' });
          return err;
        }
      },
      deletePhoto: async () => {
        try {
          const { user } = subGet();
          await MeApi.deletePhoto(user.photo_id);
          await subGet().getUser();

          get().householdStore.getHouseholds();
          subSet({ deletePhotoResult: 'success' });
        } catch (err) {
          subSet({ deletePhotoResult: 'error' });
        }
      },
      requestPasswordReset: async (email) => {
        AnalyticsService.logEvent(`account_requestPasswordReset`);

        subSet({ userLoading: true, loginError: null });
        try {
          const data = {
            email_address: email,
          };
          const test = await AuthApi.requestPasswordReset(data);
          subSet({ userLoading: false });
          return test;
        } catch (err) {
          console.log(err);
        }
        subSet({ userLoading: false });
        return 0;
      },
      resetPassword: async (email, password, token, deviceID) => {
        AnalyticsService.logEvent(`account_resetPassword`);

        subSet({ userLoading: true, loginError: false });
        try {
          const data = {
            email_address: email,
            password,
            token,
            client_uid: deviceID,
          };
          const done = await AuthApi.resetPassword(data);
          if (done && done.token) {
            console.log('Success reset password');
            subSet({ token: done.token });
            await subGet().getUser();
            await get().householdStore.getHouseholds();
          } else {
            console.log('fail reset password');

            subSet({ loginError: true });
          }
          subSet({ userLoading: false });
          return done;
        } catch (err) {
          console.log(err);
          subSet({ userLoading: false, loginError: true });
        }
        subSet({ userLoading: false });
        return 0;
      },
      deleteUser: async (households: number[], password: string): Promise<unknown> => {
        AnalyticsService.logEvent(`account_deleteUser`);

        return MeApi.deleteUser(households, password);
      },
      acceptTerms: async () => {
        AnalyticsService.logEvent(`account_acceptTerms`);

        try {
          const user = await MeApi.acceptTerms();
          if (user) {
            await AnalyticsService.setUserProperties({
              userLanguage: get().languageStore.getLanguageByID(user.language_id).code,
              userCountry: get().countryStore.getCountryByID(user.country_id).code,
            });

            subSet({ user });
          }
          return !!user;
        } catch (err) {
          return false;
          // TODO: Set all error handling for user
        }
      },
      isAuthenticated: () => {
        return !!subGet().token;
      },
      registerForPushNotifications: async () => {
        try {
          AnalyticsService.logEvent(`AccountStore - registerForPushNotifications`);

          const tokens = await registerForPushNotificationsAsync();
          AnalyticsService.logEvent(`AccountStore - registerForPushNotifications token `, {
            tokens,
          });

          const client = await DeviceService.getClient();
          AnalyticsService.logEvent(`AccountStore - registerForPushNotifications client `, {
            client,
          });

          if (!tokens || !tokens.deviceToken) {
            return;
          }
          subSet({
            expoToken: tokens.expoToken,
            deviceToken: tokens.deviceToken,
          });

          await MeClientApi.createClient({
            token: subGet().deviceToken,
            platform: client,
          });
        } catch (err) {}
      },
      updateProperty: (data) => {
        subSet(data);
      },
      logout: async (deviceID: string) => {
        await DeviceEventEmitter.emit('isLogoutRunning', true);
        const data = {
          client_uid: deviceID,
        };
        await AuthApi.logout(data);
        subSet({
          token: null,
        });
        setTimeout(() => {
          subSet({
            user: null,
            userLoading: false,
          });
          resetAllStores();
          DeviceEventEmitter.emit('isLogoutRunning', false);
        }, 1000);
      },
      quietLogout: async (deviceID: string, newToken?: string) => {
        try {
          if (subGet().token) {
            const data = {
              client_uid: deviceID,
            };
            await AuthApi.logout(data);
            subSet({
              token: null,
            });
          }
          if (!newToken) {
            return;
          }
          resetAllStores();
          subSet({
            token: newToken || null,
            user: null,
            userLoading: false,
          });

          const newUser = await subGet().getUser(newToken);

          subSet({
            token: newToken || null,
            user: newUser || null,
          });
          await get().householdStore.getHouseholds();
          // await new Promise((resolve) => setTimeout(resolve, 1000));
        } catch (err) {
          console.log(err);
        }
      },
      setUser: ({ token, user }) => {
        subSet({
          token,
          user,
        });
      },
      resetLoginError: () => {
        subSet({
          loginError: null,
        });
      },
    })),
  };
};

export default createAccountSlice;
