import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';

export const ConnectHub = () => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.container}>
      <SpView marginRight={-90} paddingBottom={30}>
        <Image
          style={styles.setupHubImage}
          source={AppImages.hubRouterGraphic}
          {...testProperties('hubRouterGraphic', 'image')}
        />
      </SpView>
      <SpVStack space={14} style={styles.instructions}>
        <SpText size="xxl" color="#263a43" fontFamily="Rubik_SemiBold">
          {t('setup_hub_instruction_1')}
        </SpText>
        <SpText size="md" color="#263a43">
          {t('setup_hub_instruction_2')}
        </SpText>
        <SpText size="md" color="#263a43">
          {t('setup_hub_instruction_3')}
        </SpText>
        <SpText size="sm" color="#263a43" fontFamily="Rubik_SemiBold">
          {t('setup_hub_instruction_4')}
        </SpText>
      </SpVStack>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 80,
    paddingBottom: 60,
    marginTop: 0,
  },
  instructions: {
    position: 'relative',
    marginTop: 0,
  },
  setupHubImage: {
    width: '90%',
    height: 180,
    marginLeft: '12%',
    transform: [{ scale: 0.8 }]
  }
});
