import { MappedPoseidonReportDataModel } from '@models/ReportModel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTransformDrinkingEventToReport } from '../hooks/useTransformDrinkingEventToReport';
import { EventDetailsCard } from './EventDetailsCard';

interface DrinkingPointReportProps {
  data: MappedPoseidonReportDataModel;
}

export const DrinkingEventReport = ({ data }: DrinkingPointReportProps) => {
  const { t } = useTranslation();
  const item = useTransformDrinkingEventToReport(data);
  item.duration = data.duration < 60 ? `00:${item.duration}` : item.duration;

  return (
    <EventDetailsCard
      leftLabel={t('time')}
      leftValue={item.time}
      centerLabel={t('amount')}
      centerValue={item.amount}
      rightLabel={t('duration')}
      rightValue={item.duration}
    />
  );
};
