import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { PetStatisticsModel } from '@models/Device';
import PetInfoSection from './PetInfoSection';
import { AdaptiveSize } from './PetCard';
import useTotalConsumption from '../../hooks/useTotalConsumption';

type PetWalkingProps = {
  data: PetStatisticsModel['movement'];
  disablePadding?: boolean;
  adaptiveSize: AdaptiveSize;
};

const PetWalking = ({
  data,
  disablePadding,
  adaptiveSize,
}: PetWalkingProps) => {
  const { t } = useTranslation();
  const time = useTotalConsumption(data.time_outside);

  return (
    <PetInfoSection
      icon={faClock}
      fontSizeForNumbers={adaptiveSize?.fontSizeForNumbers || 24}
      fontSizeForText={adaptiveSize?.fontSizeForText || 16}
      title={t('time_outside')}
      data={time}
      disablePadding={disablePadding}
      type="time"
    />
  );
};

export default PetWalking;
