import React from 'react';
import colors from '@styles/colors';
import OutdoorsSVG from '@atoms/SvgIcons/OutdoorsSVG';
import { SpRoundIcon } from './SpRoundIcon';

interface SpTreesIconProps {
  action?: any;
}

export const SpTreesIcon = ({ action }: SpTreesIconProps) => (
  <SpRoundIcon
    icon={
      <OutdoorsSVG
        color={colors.greyText.color}
        width={21}
        height={21}
        viewBox="0 0 17 17"
      />
    }
    action={action}
    size={12}
    color={colors.secondary2.color}
    componentName="SpTreesIcon"
  />
);
