import { StyleSheet, TextStyle, View } from 'react-native';
import React from 'react';
import { FormikProps } from 'formik';
import { SpInput } from '@atoms/SpInput';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { t } from 'i18next';
import { emitContinueHandler } from '@molecules/StepFormCreator';
import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import { testProperties } from '@utils/testProperties';

interface FormValues {
  name: string;
}

interface INameFormProps {
  formik: FormikProps<FormValues>;
  label: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
  disableKeyboardAwoiding?: boolean;
  titleStyles?: TextStyle;
  setName?: (name: string) => void;
}

const NameForm = ({
  formik,
  label,
  title,
  subtitle2,
  subtitle,
  disableKeyboardAwoiding = false,
  titleStyles,
  setName,
}: INameFormProps) => {
  return (
    <CustomKeyboardAvoidingView
      {...testProperties('customKeyboardAvoidingView', 'wrapper')}
      androidAdjustType="force"
      disabled={disableKeyboardAwoiding}
      style={{ flex: 1 }}
    >
      {title && (
        <SpText
          style={[styles.title, titleStyles]}
          {...testProperties('name main title', 'text')}
        >
          {title}
        </SpText>
      )}
      {subtitle && (
        <SpText
          style={styles.subtitle}
          {...testProperties('name subtitle', 'text')}
        >
          {subtitle}
        </SpText>
      )}
      {subtitle2 && (
        <SpText
          style={[styles.subtitle, { marginBottom: 40 }]}
          {...testProperties('name subtitle2', 'text')}
        >
          {subtitle2}
        </SpText>
      )}
      <View style={{ paddingBottom: 30 }}>
        <SpInput
          handleBlur={() => {
            formik.setFieldValue('name', formik.values?.name.trim());
            formik.handleBlur('name');
          }}
          value={formik.values?.name || ''}
          onChangeText={text => {
            formik.handleChange('name')(text);
            if (text) formik.setTouched({ ...formik.touched, name: true });
            if (setName) setName(text.trim());
          }}
          label={label}
          placeholder={t('type_here')}
          returnKeyType="done"
          onSubmitEditing={emitContinueHandler}
          error={formik.errors?.name}
        />
      </View>
    </CustomKeyboardAvoidingView>
  );
};

export default NameForm;

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: colors.white.color,
    flex: 1,
  },
  title: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    marginBottom: 24,
    marginTop: 52,
    lineHeight: 26,
    color: colors.greyText.color,
  },
  subtitle: {
    fontFamily: 'Rubik',
    color: colors.black.color,
    marginBottom: 24,
    fontSize: 16,
    lineHeight: 30,
    width: '90%',
  },
});
