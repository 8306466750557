import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

interface HouseholdSelectProps {
  active: boolean;
  text: string;
  onActive: () => void;
}

export const HouseholdSelect = ({
  text,
  active,
  onActive,
}: HouseholdSelectProps) => {
  return (
    <Pressable onPress={onActive} style={styles.container}>
      <SpText color={colors.greyText.color}>{text}</SpText>
      <SpView style={styles.activeWrap}>
        {active && (
          <FontAwesomeIcon
            size={36}
            icon={faCheckCircle}
            color={colors.primary.color}
          />
        )}
      </SpView>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingVertical: 10,
    borderRadius: 14,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
  },
  activeWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
    borderWidth: 2,
    borderRadius: 18,
    borderColor: colors.greyBorder.color,
  },
});
