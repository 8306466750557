import CustomBottomSheetModal from '@atoms/CustomBottomSheetModal';
import { SpDivider } from '@atoms/SpDivider';
import { SpView } from '@atoms/SpView';
import { DeviceHubLedMode } from '@constants/Device';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { DeviceHubControlModel, DeviceModel } from '@models/Device';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HubBrightSVG from '@atoms/SvgIcons/HubBrightSVG';
import HubDimSVG from '@atoms/SvgIcons/HubDimSVG';
import HubNoneSVG from '@atoms/SvgIcons/HubNoneSVG';
import colors from '@styles/colors';
import useBoundStore from 'src/store/store';
import { useCustomToast } from '@hooks/useToast';
import useUpdate from '@hooks/useUpdate';
import { DeviceBottomSheetContainer } from '../DeviceBottomSheetContainer';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import { DeviceStatusHeader } from '../DeviceStatusHeader';
import { HubFooterActions } from './HubFooterActions';
import HubLightSetting from './HubLightSetting';
import { LightOptions } from './LightOptions';

interface HubCardProps {
  data: DeviceModel;
  userHasWriteAccess: boolean;
  onChangeLedMode: (mode: DeviceHubLedMode) => void;
}

export const HubCard = ({
  data,
  userHasWriteAccess,
  onChangeLedMode,
}: HubCardProps) => {
  const [modal, setModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const snapPoints = useMemo(() => ['40%'], []);
  const control = useMemo(() => data?.control as DeviceHubControlModel, [data]);
  const online = data?.status.online;
  const dismissAction = useCallback(() => {
    setModal(null);
  }, [setModal]);
  const { show } = useCustomToast();
  const { updatingHubLedMode, loadingControl } = useBoundStore(
    state => state.deviceStore,
  );
  const changeLedModeAction = useCallback(
    ($event: DeviceHubLedMode) => {
      setModal(false);
      onChangeLedMode($event);
    },
    [setModal, onChangeLedMode],
  );
  const HubLightOptions: Record<
    DeviceHubLedMode,
    { icon: JSX.Element; text: string; action: () => void }
  > = useMemo(() => {
    return {
      [DeviceHubLedMode.Normal]: {
        icon: (
          <HubBrightSVG
            color={colors.greyText.color}
            width={60}
            height={60}
            viewBox="0 0 90 60"
          />
        ),
        text: t('bright'),
        action: () => changeLedModeAction(DeviceHubLedMode.Normal),
      },
      [DeviceHubLedMode.Dim]: {
        icon: (
          <HubDimSVG
            color={colors.greyText.color}
            width={60}
            height={50}
            viewBox="0 0 90 60"
          />
        ),
        text: t('dim'),
        action: () => changeLedModeAction(DeviceHubLedMode.Dim),
      },
      [DeviceHubLedMode.Off]: {
        icon: (
          <HubNoneSVG
            color={colors.greyText.color}
            width={60}
            height={50}
            viewBox="0 0 90 60"
          />
        ),
        text: t('none'),
        action: () => changeLedModeAction(DeviceHubLedMode.Off),
      },
      [DeviceHubLedMode.Success]: null,
    };
  }, [changeLedModeAction]);
  const ledModeValue = control?.led_mode <= 4 ? control?.led_mode : 0;
  const currentLightSetting = useMemo(() => {
    return (
      <HubLightSetting
        // we need to check if the led mode exists in the enum and if not use 0
        text={t(HubLightOptions[ledModeValue]?.text)}
        svgIcon={HubLightOptions[ledModeValue]?.icon}
      />
    );
  }, [control, changeLedModeAction]);

  useUpdate(() => {
    if (loadingControl?.led_mode) {
      show({ description: t('updated_led_mode') });
    }
  }, [loadingControl?.led_mode]);

  return (
    <>
      <SpView flex={1}>
        <DeviceStatusHeader data={data} />
        {userHasWriteAccess && online && (
          <SpView>
            <SpView marginTop={2}>
              <DeviceSettingMenuItem data={data?.id} />
            </SpView>
          </SpView>
        )}
      </SpView>
      {online && (
        <>
          <SpView flex={1}>{currentLightSetting}</SpView>
          {userHasWriteAccess && (
            <SpView style={{ marginBottom: 10 }}>
              <SpDivider style={{ flex: 1, marginBottom: 10 }} />
              <HubFooterActions
                onAction={() => setModal(true)}
                updatingHubLedMode={updatingHubLedMode}
              />
            </SpView>
          )}
        </>
      )}

      <CustomBottomSheetModal
        opened={modal}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        snapPoints={snapPoints}
        onDismiss={dismissAction}
        inScrollView
      >
        <DeviceBottomSheetContainer>
          <LightOptions buttons={HubLightOptions} active={ledModeValue} />
        </DeviceBottomSheetContainer>
      </CustomBottomSheetModal>
    </>
  );
};
