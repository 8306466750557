import { SpCenter } from '@atoms/SpCenter';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { CommonActions } from '@react-navigation/native';
import colors from '@styles/colors';
import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import { RootStackParamList } from './index';
import { navigationRef } from './RootNavigation';

type Props = NativeStackScreenProps<RootStackParamList, 'ErrorScreen'>;

const ErrorScreen = ({ route }: Props) => {
  return (
    <SafeAreaView style={styles.container}>
      <SpCenter flex={1} justifyContent="center" alignItems="center">
        <SpText size="xl">Something went wrong</SpText>
      </SpCenter>
      <SpView padding={19}>
        <SpRoundedHeaderButton
          backgroundColor={colors.primary.color}
          h={56}
          title="Go to dashboard"
          onPress={() => {
            navigationRef.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [
                  { name: 'DashboardNavigation', params: { screen: 'Pets' } },
                ],
              }),
            );
          }}
        />
      </SpView>
    </SafeAreaView>
  );
};

export default ErrorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
});
