import { HouseholdModel } from '@models/Household';
import * as React from 'react';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import { ConfirmRemoveHouseholdDialogModes } from '../constants/ConfirmRemoveHouseholdDialogModes';

export interface ConfirmRemoveHouseholdDialogContextModel {
  households: HouseholdModel[];
  mode: ConfirmRemoveHouseholdDialogModes;
  onConfirm: () => void;
}

export const ConfirmRemoveHouseholdDialogContext = createContext({
  dialogConfig: {} as Partial<ConfirmRemoveHouseholdDialogContextModel>,
  setDialogConfig: {} as Dispatch<
    SetStateAction<Partial<ConfirmRemoveHouseholdDialogContextModel>>
  >,
});

export const ConfirmRemoveHouseholdDialogContextProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const [dialogConfig, setDialogConfig] =
    useState<ConfirmRemoveHouseholdDialogContextModel>({
      households: [],
      mode: null,
      onConfirm: null,
    });

  return (
    <ConfirmRemoveHouseholdDialogContext.Provider
      // @ts-ignore
      value={{ dialogConfig, setDialogConfig }}
    >
      {children}
    </ConfirmRemoveHouseholdDialogContext.Provider>
  );
};
