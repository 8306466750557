import { SpVStack } from '@atoms/SpVStack';
import { SubstanceType } from '@constants/Device';
import React from 'react';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { capitalize } from '@utils/capitalize';
import { AppImages } from '@constants/AppImages';
import { SpView } from '@atoms/SpView';
import { Image, Platform, StyleSheet } from 'react-native';
import TypeButton from './TypeButton';
import { SpText } from '@atoms/SpText';
import text from '@styles/text';

interface SubstanceTypesProps {
  active: SubstanceType;
  onChange: (data: SubstanceType) => void;
}

export const SubstanceTypes = ({ active, onChange }: SubstanceTypesProps) => {
  const substanceOptions = enumToDescriptedArray(SubstanceType, /-/g);
  const bowlTypeImages = [
    AppImages.petBowlSetupWater,
    AppImages.petBowlSetupFood,
  ];

  return (
    <SpVStack
      style={[
        styles.wrapper,
        Platform.OS === 'android' ? styles.androidPaddingBottom : {},
      ]}
      space={2}
      flex={1}
    >
      <SpView style={styles.titleContainer}>
        <SpText style={text.title}>
          What will the bowl be used for?
        </SpText>
        <Image source={AppImages.cdbConfigureInitital}
               style={{ width: '100%', height: 160, resizeMode: 'contain' }}></Image>
      </SpView>
      {substanceOptions.map((option, index) => {
        return (
          <SpView
            key={option.id + index}
            style={[styles.buttonWrap, index === 0]}
          >
            {index === 0 ?
              <TypeButton
                text={capitalize(option.name)}
                isActive={active === option.id}
                onPress={() => {
                  onChange(option.id);
                }}
                isBowl
                {...bowlTypeImages[index]}
                image={bowlTypeImages[index]}

              /> :
              <TypeButton
                text={capitalize(option.name)}
                isActive={active === option.id}
                onPress={() => {
                  onChange(option.id);
                }}
                isBowl
                {...bowlTypeImages[index]}
                image={bowlTypeImages[index]}
                overidePrimaryColor={'#bc68ff'}
                overideBackgroundColor={'#bc68ff1a'}
              />
            }
          </SpView>
        );
      })}
      <SpView style={styles.titleContainer}>
        <SpText style={styles.note}>
          This can be changed at any time in settings
        </SpText>
      </SpView>
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 100,
  },
  buttonWrap: {
    marginTop: 34,
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    marginTop: 10,
    paddingTop: 5,
    height: 30,
    textAlign: 'center',

  },
  titleContainer: {
    width: '100%',

  },
  note: {
    marginTop: 20,
    alignContent: 'center',
    textAlign: 'center',
    color: '#8E8E8E',
    fontSize: 14,
  },
});
