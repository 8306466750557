import Constants from "expo-constants";

function getApiUrl() {
  const APP_API = "https://app-api." + getAppEnv() + ".surehub.io";

  if (!APP_API) {
    throw new Error("API_URL is missing.");
  }

  return APP_API;
}

function getAppName() {
  const APP_NAME =
    Constants.expoConfig?.extra?.APP_NAME || "Internal - Sure Petcare";

  if (!APP_NAME) {
    throw new Error("APP_NAME is missing.");
  }

  return APP_NAME;
}

function getAppEnv() {
  const APP_ENV = Constants.expoConfig?.extra?.APP_ENV || "devstable";

  if (!APP_ENV) {
    throw new Error("APP_ENV is missing.");
  }

  return APP_ENV;
}

function getAppBundleId() {
  const APP_BUNDLE_ID =
    Constants.expoConfig?.extra?.APP_BUNDLE_ID ||
    "com.sureflap.surehub.devstable";

  if (!APP_BUNDLE_ID) {
    throw new Error("APP_BUNDLE_ID is missing.");
  }

  return APP_BUNDLE_ID;
}

export const Env = {
  APP_API: getApiUrl(),
  APP_NAME: getAppName(),
  APP_ENV: getAppEnv(),
  APP_BUNDLE_ID: getAppBundleId(),
};
