import { TimelineEventFullResponseModel } from '@models/Timeline';
import Http from './Http';

class TimelineApi {
  static getEvents(
    householdId: number,
    page: number,
    pageSize: number,
    beforeId: number,
  ): Promise<TimelineEventFullResponseModel> {
    const params = {
      before_id: beforeId || null,
      page_size: pageSize || null,
      page: page || null,
    };

    return Http.get(`/api/timeline/household/${householdId}`, {
      params,
    }).then(response => {
      return {
        meta: response.data.meta,
        data: response.data?.data || [],
      };
    });
  }
}

export default TimelineApi;
