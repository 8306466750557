import { AsyncStorageKeys } from '@constants/AsyncStorageKeys';
import { HouseholdModel } from '@models/Household';
import { UserModel } from '@models/User';
import AsyncStorage from '@react-native-async-storage/async-storage';

export class HouseholdRemovedService {
  static async setOrUpdateInitialData(
    user: UserModel,
    households: HouseholdModel[],
  ): Promise<void> {
    const initialHouseholdsByUserId = await AsyncStorage.getItem(
      AsyncStorageKeys.InitialHouseholdsByUserId,
    );
    const initialHouseholds = initialHouseholdsByUserId
      ? JSON.parse(initialHouseholdsByUserId)
      : null;

    if (
      !initialHouseholds ||
      !initialHouseholds[user.id] ||
      !initialHouseholds[user.id].length ||
      households.length > initialHouseholds[user.id].length
    ) {
      await AsyncStorage.setItem(
        AsyncStorageKeys.InitialHouseholdsByUserId,
        JSON.stringify({
          ...(initialHouseholds || {}),
          [user.id]: households.map(item => item.id),
        }),
      );
    }
  }

  static async checkRemovedHousehold(
    user: UserModel,
    households: HouseholdModel[],
  ): Promise<boolean> {
    const initialHouseholdsByUserId = await AsyncStorage.getItem(
      AsyncStorageKeys.InitialHouseholdsByUserId,
    );
    const initialHouseholdsByUserIdParsed: Record<number, number[]> =
      initialHouseholdsByUserId ? JSON.parse(initialHouseholdsByUserId) : null;
    const householdIds = initialHouseholdsByUserIdParsed[user.id] || [];

    return (
      initialHouseholdsByUserIdParsed && households.length < householdIds.length
    );
  }

  static async setNewInitialData(
    user: UserModel,
    households: HouseholdModel[],
  ): Promise<void> {
    const initialHouseholdsByUserId = await AsyncStorage.getItem(
      AsyncStorageKeys.InitialHouseholdsByUserId,
    );
    const initialHouseholds = JSON.parse(initialHouseholdsByUserId);

    await AsyncStorage.setItem(
      AsyncStorageKeys.InitialHouseholdsByUserId,
      JSON.stringify({
        ...initialHouseholds,
        [user.id]: households.map(item => item.id),
      }),
    );
  }

  static async clear(userId: number): Promise<void> {
    const initialHouseholdsByUserId = await AsyncStorage.getItem(
      AsyncStorageKeys.InitialHouseholdsByUserId,
    );

    const initialHouseholds = JSON.parse(initialHouseholdsByUserId);

    if (initialHouseholds[userId]) {
      delete initialHouseholds[userId];
    }

    await AsyncStorage.setItem(
      AsyncStorageKeys.InitialHouseholdsByUserId,
      JSON.stringify(initialHouseholds),
    );
  }
}
