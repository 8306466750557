import { SpInput } from '@atoms/SpInput';
import { SpView } from '@atoms/SpView';
import { testProperties } from '@utils/testProperties';
import i18n from '@utils/i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import colors from '@styles/colors';
import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import useValidation from 'src/pages/validation';
import { useFormik } from 'formik';

interface NameFormProps {
  name: string;
  error: string;
  onSave: (data: string) => void;
}

export const ChangeNameForm = ({ name, error, onSave }: NameFormProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(name);
  const { commonNameSchema } = useValidation();
  const saveAction = () => {
    onSave(value);
  };

  const formik = useFormik({
    validationSchema: commonNameSchema(t('device_name')),
    initialValues: { name },
    onSubmit: saveAction,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const onChangeText = (data: string) => {
    setValue(data);
    formik.handleChange('name')(data);
  };

  const formikError = formik.errors.name;

  return (
    <CustomKeyboardAvoidingView scrollView={false} modal>
      <SpView style={styles.view}>
        <SpInput
          autoFocus
          label={i18n.t('product_name')}
          placeholder={i18n.t('type_here')}
          onChangeText={onChangeText}
          value={value}
          error={formikError || error}
        />

        <SpRoundedHeaderButton
          {...testProperties(t('save'), t('button'), 'ChangeNameForm')}
          h={56}
          backgroundColor={colors.primary.color}
          disabled={!!formikError}
          onPress={() => formik.handleSubmit()}
          title={i18n.t('save')}
        />
      </SpView>
    </CustomKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  view: {
    padding: 20,
    paddingVertical: 40,
    height: '100%',
    width: '100%',
    flex: 1,
    justifyContent: 'space-between',
  },
  button: {
    marginTop: 20,
  },
});
