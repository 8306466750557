import { lastScreenOptions } from '@constants/Screen';
import CustomHeader from '@molecules/CustomHeader';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpView } from '@atoms/SpView';
import atoms from '@styles/atoms';
import { Account } from './Account';
import { AccountEditSetting } from './AccountEditSetting';
import AccountPasswordWrapper from './molecules/AccountPasswordWrapper';
import NotificationSettings from './Settings/Notifications';
import { PersonalSettings } from './Settings/Personal';
import { PreferenceSettings } from './Settings/Preferences';
import SoundSettings from './Settings/Sounds';
import { navigationRef } from '../RootNavigation';

interface AccountEditSettingProps {
  label: string;
  inputName: string;
  inputValue: any;
}

export type AccountStackParamList = {
  Account: undefined;
  Personal: undefined;
  Preferences: undefined;
  Notifications: undefined;
  AccountEditSetting: AccountEditSettingProps;
  Password: undefined;
  Sounds: undefined;
  EditText: undefined;
  EditSelect: undefined;
  EditButtonList: undefined;
};

const Stack = createStackNavigator<AccountStackParamList>();

export default function AccountNavigation() {
  const { t } = useTranslation();

  return (
    <SpView style={atoms.screenWrapper}>
      <Stack.Navigator initialRouteName='Account'>
        <Stack.Screen
          name='Account'
          options={{
            headerShown: false,
          }}
          component={Account}
        />
        <Stack.Screen
          name='Preferences'
          options={{
            headerShown: true,
            header: () => CustomHeader({ title: t('preferences'), goBackFunc: () => navigationRef.goBack() }),
          }}
          component={PreferenceSettings}
        />
        <Stack.Screen
          name='Personal'
          options={{
            headerShown: true,
            header: () => CustomHeader({ title: t('personal'), goBackFunc: () => navigationRef.goBack() }),
          }}
          component={PersonalSettings}
        />
        <Stack.Screen
          name='AccountEditSetting'

          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}

          component={AccountEditSetting}
        />
        <Stack.Screen
          name='Password'
          options={{
            headerShown: true,
            header: () => CustomHeader({
              withTitle: false, withCross: true, goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={AccountPasswordWrapper}
        />
        <Stack.Screen
          name='Notifications'
          options={{ headerShown: false }}
          component={NotificationSettings}
        />
        <Stack.Screen
          name='Sounds'
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={SoundSettings}
        />
      </Stack.Navigator>
    </SpView>
  );
}
