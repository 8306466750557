import { WeightUnits } from '@constants/WeightUnits';
import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useUserWeightUnits = (): WeightUnits => {
  const user = useBoundStore(s => s.accountStore.user);

  return useMemo(() => {
    return user?.weight_units || WeightUnits.Metric;
  }, [user]);
};
