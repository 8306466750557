import { format } from 'date-fns';
import * as React from 'react';
import { SpView } from '@atoms/SpView';
import { StyleSheet } from 'react-native';
import { SpCenter } from '@atoms/SpCenter';
import { TimeService } from 'src/services/TimeService';
import { SpText } from '@atoms/SpText';
import { testProperties } from '@utils/testProperties';
import { SpHStack } from '@atoms/SpHStack';

export const DateHeader = () => {
  const dateNow = format(
    TimeService.convertOrUnconvertDateByUTC<Date>(new Date(), true),
    'EEE do LLL',
  ).toString();

  return (
    <SpView style={styles.container}>
      <SpCenter style={styles.center}>
        <SpHStack
          space={4}
          justifyContent="center"
          alignItems="center"
          marginVertical={5}
        >
          <SpText {...testProperties('today date', 'text')} style={styles.text}>
            {dateNow}
          </SpText>
        </SpHStack>
      </SpCenter>
    </SpView>
  );
};
const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#263a43',
    height: '100%',
  },
  center: {
    height: '100%',
  },
  text: {
    color: 'white',
  },
});
