import SelectButton from '@atoms/SpSelectButton';
import { SpVStack } from '@atoms/SpVStack';
import { PetBowlFoodType } from '@constants/Device';
import React from 'react';
import {
  Descripted,
  enumToDescriptedArray,
} from '@utils/enumToDescriptedArray';
import { StyleSheet } from 'react-native';

interface FoodTypesProps {
  active: PetBowlFoodType | null;
  onChange: (data: PetBowlFoodType) => void;
  foodTypes?: Descripted<typeof PetBowlFoodType>[];
}

export const FoodTypes = ({
  active,
  onChange,
  foodTypes = enumToDescriptedArray(PetBowlFoodType, /-/g, false),
}: FoodTypesProps) => {
  return (
    <SpVStack space={2} flex={1} justifyContent="center" style={styles.wrapper}>
      {foodTypes.map(option => {
        return (
          <SelectButton
            key={option.id}
            isActive={active === option.id}
            option={option}
            withIcon
            setActive={onChange}
          />
        );
      })}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 100,
  },
  buttonWrap: {
    marginTop: 44,
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
});
