import { SpView } from '@atoms/SpView';
import { AppImages } from '@constants/AppImages';
import { useDoorLockedStatus } from '@hooks/useDoorLockedStatus';
import { useEnabledCurfews } from '@hooks/useEnabledCurfews';
import { DevicePetDoorControlModel } from '@models/Device';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { Image, StyleSheet } from 'react-native';
import useCurfewActive from "../../../Device/hooks/useCurfewActive";

interface ProductCartPetDoorStatusProps {
  data: DevicePetDoorControlModel;
}

export const PetDoorStatus = ({ data }: ProductCartPetDoorStatusProps) => {
  const locked = useDoorLockedStatus(data);
  const enabledCurfews = useEnabledCurfews(data);
  const {isCurfewActive} = useCurfewActive(enabledCurfews);
  return (
    <SpView style={styles.container}>
      {enabledCurfews.length ? (
        <Image
          style={{ width: 28, height: 28 }}
          {...testProperties('curfewEnabled', 'image')}
          source={AppImages.curfewEnabled}
          resizeMode="contain"
        />
      ) : null}
      {locked || isCurfewActive ? (
        <Image
          style={{ width: 26, height: 26 }}
          {...testProperties('doorLocked', 'image')}
          source={AppImages.doorLocked}
          resizeMode="contain"
        />
      ) : null}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});
