import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { AppLinkingPrefixes } from '@constants/AppLinkingPrefixes';
import * as Notifications from 'expo-notifications';
import { RootStackParamList } from './index';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    ...AppLinkingPrefixes,
    Linking.createURL('/'),
    'https://*.surehub.io',
    'https://surehub.io',
    'https://surepetcare.io',


  ],

  config: {
    screens: {
      DashboardNavigation: {
        screens: {
          Pets: 'pets',
          Timeline: 'timeline',
          Products: 'products',
        },
      },
      PetsNavigation: {
        screens: {
          PetDashboardSummary: 'pet/:id/summary',
          PetProfileSettings: 'pet/:id/settings',
          PetDashboardEditSettings: 'pet/:id/settings-edit',
        },
      },
      DeviceNavigation: {
        screens: {
          DeviceDetails: 'device/:id/details',
          DeviceSetting: 'device/:id/settings',
          DeviceAssignPet: 'device/:id/assign-pet',
          DeviceName: 'device/:id/name',
          DeviceBowlSetup: 'device/:id/bowl-setup',
          DevicePortioning: 'device/:id/portioning',
          DeviceLidDelay: 'device/:id/lid-delay',
        },
      },
      ResetPassword: 'auth/password/reset/:token?',

      LoginNavigation: 'login',
      // LoginNavigation: 'auth/password/reset/:token?',
      AuthTokenLogin: 'auth-token-login/:token?',
    },
  },

  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => {
      console.log('url LINKING', url);
      // if url include iam/ then its a deeplink, split and get everything after iam/ then open it
      if (url.includes('iam/')) {

        return;
      }
      return listener(url);
    };

    // Listen to incoming links from deep linking
    const listenerInstance = Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications

    const subscription = Notifications.addNotificationResponseReceivedListener(
      response => {
        const { url } = response.notification?.request?.content?.data || {};

        // Any custom logic to see whether the URL needs to be handled
        // ...

        // Let React Navigation handle the URL
        listener(url);
      },
    );

    return () => {
      // Clean up the event listeners
      listenerInstance.remove();
      subscription.remove();
    };
  },
};

export default linking;
