import { SpLoading } from '@atoms/SpLoading';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import React, { useLayoutEffect } from 'react';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';
import useTour from '@hooks/useTour';
import { DeviceType } from '@constants/Device';
import { PetDoorCatFlapCard } from '../molecules/PetDoorCatFlapDevice/PetDoorCatFlapCard';
import { usePetDoorCatFlapCard } from '../hooks/usePetDoorCatFlapDevice';
import ProductsControlSlide from '../../Tour/components/ProductsControlSlide/ProductsControlSlide';
import CatFlapActionsControlSlide from '../../Tour/components/CatFlapActionsControlSLide/CatFlapActionsControlSlide';
import PetDoorSlide from '../../Tour/components/PetDoorSlide/PetDoorSlide';

interface PetDoorCatFlapDeviceWrapperProps {
  deviceId: number;
  data: DeviceModel;
}

export const PetDoorCatFlapDeviceWrapper = ({
  deviceId,
  data,
}: PetDoorCatFlapDeviceWrapperProps) => {
  const { loadingDevice, error, onChangeCurfew, onChangeLockUnlock } =
    usePetDoorCatFlapCard(data);
  const userHasWriteAccess = useUserHasWriteAccess();
  const deviceStatus = useDeviceStatusHook(deviceId, true);

  useDeviceStatusParse(deviceStatus);

  const initTour = useTour({
    uniqueId:
      data?.product_id === DeviceType.CatFlapConnect
        ? 'v0-products-control-cat-flap'
        : 'v0-products-control-pet-door',
    components:
      data?.product_id === DeviceType.CatFlapConnect
        ? [<ProductsControlSlide />, <CatFlapActionsControlSlide />]
        : [<ProductsControlSlide />, <PetDoorSlide />],
    devices: ['all'],
    showSharedSlideOneTimeIndex: {
      tourGroup: 'feeder-flap-door',
      slideIndexToHide: 0,
    },
  });

  useLayoutEffect(() => {
    initTour();
  }, []);

  if (loadingDevice) {
    return <SpLoading />;
  }
  return (
    <PetDoorCatFlapCard
      data={data}
      error={error}
      userHasWriteAccess={userHasWriteAccess}
      onChangeLockUnlock={onChangeLockUnlock}
      onChangeCurfew={onChangeCurfew}
    />
  );
};
