import { useDetectPushNotification } from '@hooks/useDetectPushNotification';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useRefreshPetData } from '@hooks/useRefreshPetData';
import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { useEffect } from 'react';

import useBoundStore from '../../../store/store';
import { PetCarousel } from '../molecules/PetCarousel/PetCarousel';

export const PetList = () => {
  const { refreshPetData, refreshStats, onceLoaded, dataRefreshing, setOnceLoaded } =
    useRefreshPetData();
  const activeHousehold = useBoundStore((state) => state.householdStore.activeHousehold);

  const isFocused = useIsFocused();
  const pets = usePetsByHousehold();
  const { saveAction } = useDetectPushNotification();

  useEffect(() => {
    setOnceLoaded(false);
  }, [activeHousehold?.id])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFocused) {
      refreshPetData();

      saveAction(refreshPetData, 'pet carousel refresh');

      const id = setInterval(() => {
        refreshPetData();
      }, 1000 * 60);

      return () => {
        clearInterval(id);
      };
    }
  }, [isFocused]);

  return <PetCarousel pets={pets} loading={!onceLoaded} dataRefreshing={dataRefreshing} />;
};
