import ConditionApi from '@api/ConditionApi';
import { lens } from '@dhmk/zustand-lens';
import { StateCreator } from 'zustand';
import { MergedInterfaces } from '../models';
import { ConditionSlice } from '../models/ConditionSlice';

export const noConditionOption = 25;

const createConditionSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  ConditionSlice
> = (rootSet, rootGet) => {
  return {
    conditionStore: lens((set, get, api) => ({
      loading: false,
      data: [],
      loadAll: async (force = false) => {
        if (!force && get().data.length) {
          return;
        }

        set({ loading: true });

        try {
          const data = await ConditionApi.getConditions();
          const ind = data.findIndex(elem => elem.name === 'No Conditions');
          const elem = data.splice(ind, 1);
          data.unshift(...elem);
          set({
            loading: false,
            data,
          });
        } catch (e) {
          set({ loading: false, data: [] });
        }
      },
      reset: () => {
        set({
          loading: false,
          data: [],
        });
      },
    })),
  };
};

export default createConditionSlice;
