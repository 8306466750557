import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import React, { FC } from 'react';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';

interface IProps {
  text: string;
  align?: 'center' | 'left' | 'right';
  style?: StyleProp<TextStyle>;
}

const SpTitle: FC<IProps> = ({ text, align, style }) => {
  return (
    <SpText
      componentName="SpTitle"
      style={[styles.title, { textAlign: align }, [style]]}
    >
      {text}
    </SpText>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontFamily: 'Rubik_SemiBold',
    paddingVertical: 24,
    color: colors.greyText.color,
    lineHeight: 32,
  },
});

export default SpTitle;
