import {
  CloseDelays,
  DeviceControlResponseStatus,
  DeviceDualScanLearnMode,
  DeviceDualScanLockingMode,
  DeviceFeederBowlType,
  DeviceFeederTareType,
  DeviceHubLedMode,
  DeviceHubPairingMode,
  DeviceType,
  FeederFoodType,
  PetBowlFoodType,
  PetDoorLockingMode,
  SubstanceType,
} from '@constants/Device';
import { PetPosition } from '@constants/Pet';
import { TagProfile } from '@constants/Tag';

import { DeviceTagModel } from './DeviceTag';
import { HouseholdModel } from './Household';
import { ProductModel } from './Product';

export interface DeviceVersionModel {
  hardware: number;
  firmware: number;
}

export interface DeviceVersionCollectionModel {
  device: DeviceVersionModel;
  lcd?: DeviceVersionModel;
  rf?: DeviceVersionModel;
}

export interface DeviceStatusModel {
  version: DeviceVersionCollectionModel;
  online: boolean;
  battery: number;
  signal: DeviceSignalModel;
}

export interface DeviceSignalModel {
  device_rssi: number;
  hub_rssi: number;
}

export class DeviceDoorCurfewModel {
  enabled = false;

  lock_time = '';

  unlock_time = '';

  locked = false;

  closePopup?: boolean;
}

export class DevicePetDoorCurfew extends DeviceDoorCurfewModel {
  permission: number;

  delay_time: number;
}

export interface DeviceLockingModel<T, V extends DeviceDoorCurfewModel> {
  mode: T;
  curfew: V;
}

export class DeviceCatFlapCurfew extends DeviceDoorCurfewModel {
  profile?: TagProfile;

  constructor({ lock_time, unlock_time }: Partial<DeviceCatFlapCurfew>) {
    super();

    this.lock_time = lock_time || '';
    this.unlock_time = unlock_time || '';
  }
}

export interface DeviceHubStatusModel extends DeviceStatusModel {
  led_mode: DeviceHubLedMode;
  pairing_mode: DeviceHubPairingMode;
}

export interface DevicePetDoorStatusModel extends DeviceStatusModel {
  battery: number;
  locking: DeviceLockingModel<PetDoorLockingMode, DevicePetDoorCurfew>;
  learn_mode: boolean;
}

export interface DeviceCatFlapStatusModel extends DeviceStatusModel {
  battery: number;
  locking: DeviceLockingModel<DeviceDualScanLockingMode, DeviceCatFlapCurfew>;
  learn_mode: DeviceDualScanLearnMode;
}

export interface DeviceFeederTypeUpdated extends DeviceStatusModel {
  battery: number;
  bowl_status: {
    current_weight: number;
    fill_weight: number;
    food_type: number;
    fill_percent: number;
    index: number;
    substance_type: number;
    last_filled_at: string;
    last_fill_weight: number;
    last_zeroed_at?: string;
  }[];
  learn_mode: boolean;
  online: boolean;
  version: any;
}

export interface DeviceHubControlModel {
  led_mode: DeviceHubLedMode;
  pairing_mode: DeviceHubPairingMode;
}

export interface DevicePetDoorControlModel {
  fast_polling?: boolean;
  curfew?: DevicePetDoorCurfew[];
  locking?: PetDoorLockingMode;
}

export interface DeviceCatFlapControlModel {
  curfew?: DeviceCatFlapCurfew[];
  // locking?: DeviceDualScanLockingMode; // TODO Check this type
  locking?: PetDoorLockingMode;
}

export interface DeviceLearnModel {
  learn_mode?: boolean;
}

export interface DeviceFeederLidModel {
  close_delay?: CloseDelays;
  mode?: number;
}

export interface DeviceFeederBowlSettingModel {
  food_type: FeederFoodType;
  target: number;
}

export class DeviceFeederBowlModel {
  // TODO Add type
  settings?: DeviceFeederBowlSettingModel[];

  type?: DeviceFeederBowlType;

  // TODO Add type
  constructor(type: DeviceFeederBowlType, fbs: DeviceFeederBowlSettingModel[]) {
    this.type = type;
    this.settings = fbs;
  }
}

export interface DeviceFeederControlModel {
  bowls?: DeviceFeederBowlModel;
  lid?: DeviceFeederLidModel;
  tare?: DeviceFeederTareType;
  training_mode?: number;
}

export interface DeviceLoadingControl {
  bowls?: DeviceFeederBowlModel;
  lid?: DeviceFeederLidModel;
  tare?: DeviceFeederTareType;
  training_mode?: boolean;
  close_delay?: boolean;
  led_mode?: number;
  locking?: number;
  tag_profiles?: any[];
}

export interface DeviceFelaquaControlModel {
  tare?: boolean;
}

export interface DeviceCerberusControlModel {
  substance_type?: SubstanceType;
  food_type?: PetBowlFoodType;
}

export type DeviceControlTypeModel =
  | DeviceHubControlModel
  | DevicePetDoorControlModel
  | DeviceCatFlapControlModel
  | DeviceFeederControlModel
  | DeviceFelaquaControlModel
  | DeviceCerberusControlModel;

export interface DeviceModel {
  id?: number;
  parent_device_id?: number;
  product_id?: DeviceType;
  product_firmware_id?: number;
  household_id?: number;
  index?: number;
  name?: string;
  serial_number?: string;
  mac_address?: string;
  version?: string;
  created_at?: Date;
  updated_at?: Date;
  pairing_at?: Date;
  control?: DeviceControlTypeModel;
  status?:
    | DeviceFeederTypeUpdated
    | DevicePetDoorStatusModel
    | DeviceCatFlapStatusModel
    | DeviceHubStatusModel;
  children?: DeviceModel[];
  household?: HouseholdModel;
  parent?: DeviceModel;
  product?: ProductModel;
  tags?: DeviceTagModel[];
}

export interface LockUnlockRequestParamsModel {
  locking: PetDoorLockingMode;
}

export interface UpdateCurfewRequestParamsModel {
  curfew: DevicePetDoorCurfew | DeviceCatFlapCurfew[];
}

export interface ServerControlMetaModel {
  data: DeviceControlTypeModel;
  requestId: string;
  responseId: string;
  status: DeviceControlResponseStatus;
  timeToRespond: number;
  timeToSend: number;
}

export interface ServerControlResponseModel {
  data: DeviceControlTypeModel;
  results: ServerControlMetaModel[];
}

export const DeviceModelMock = (data: Partial<DeviceModel> = {}): DeviceModel => {
  return {
    id: 1,
    parent_device_id: 0,
    name: 'Mock name',
    product_id: DeviceType.FeederConnect,
    product_firmware_id: 1,
    household_id: 1,
    index: 1,
    serial_number: '',
    mac_address: '',
    version: '',
    created_at: new Date(),
    updated_at: new Date(),
    pairing_at: new Date(),
    control: {},
    status: null,
    children: [],
    household: null,
    parent: null,
    product: null,
    tags: [],
    ...data,
  };
};

export type WithFillPercent = {
  status: {
    bowl_status: {
      fill_percent: number;
      last_filled_at: string;
      last_fill_weight: number;
    }[];
  };
};

type DrinkingFeedingPrecalulatedData = {
  date: string;
  total_consumption: number;
  last_consumption: number;
  substance_type: number;
  activity: { total_consumption: number; date: string }[];
  number_of_visits: number;
  consumption_time: number;
};

export type PetStatisticsModel = {
  pet_id: number;
  movement: {
    date: string;
    time_outside: string | number;
    since: string;
    trips_outside: number;
    activity: { date: string; time_outside: string }[];
    where: PetPosition;
    last_entry: string;
    entries: number;
  };
  drinking: DrinkingFeedingPrecalulatedData;
  feeding: DrinkingFeedingPrecalulatedData;
};

export type RequestResult = 'success' | 'error' | 'none';
