import * as React from 'react';
import Svg, { Defs, LinearGradient, Stop, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const HubDimSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={88} height={66} {...props}>
    <Defs>
      <LinearGradient id="a" x1="50%" x2="21.78%" y1="-14.557%" y2="115.438%">
        <Stop offset="0%" stopColor="#C2EA97" stopOpacity={0.507} />
        <Stop offset="100%" stopColor="#5ED07D" stopOpacity={0.524} />
      </LinearGradient>
      <LinearGradient
        id="b"
        x1="49.786%"
        x2="21.78%"
        y1="-13.57%"
        y2="115.438%"
      >
        <Stop offset="0%" stopColor="#91D944" stopOpacity={0.54} />
        <Stop offset="100%" stopColor="#5ED07D" stopOpacity={0.498} />
      </LinearGradient>
    </Defs>
    <G fill="none" fillRule="evenodd" stroke="#263A43">
      <G strokeWidth={3.88}>
        <Path
          fill="url(#a)"
          d="M44.97 25.53 41.007 3.996A1.94 1.94 0 0 1 43.84 1.94l19.922 10.825"
          transform="matrix(-1 0 0 1 114.306 13.22)"
        />
        <Path
          fill="url(#b)"
          d="M8.054 25.53 4.091 3.996A1.94 1.94 0 0 1 6.925 1.94l19.923 10.825"
          transform="translate(10.271 13.22)"
        />
        <Path
          fill="#FFF"
          d="M43.83 23.278c8.732 0 16.637 3.539 22.36 9.261 5.721 5.722 9.26 13.627 9.26 22.359v9.146H12.21v-9.146c0-8.732 3.54-16.637 9.262-22.359 5.722-5.722 13.627-9.261 22.359-9.261Z"
        />
      </G>
      <G strokeLinecap="round" strokeWidth={2.931} opacity={0.4}>
        <Path d="M69.776 6.347 69.14 2M63.36 9.421l-2.947-3.246M77.39 7.798l2.424-3.658M81.927 14.258 86 12.657M81.078 21.593l4.299.811" />
      </G>
      <G strokeLinecap="round" strokeWidth={2.931} opacity={0.4}>
        <Path d="M18.224 6.347 18.86 2M24.64 9.421l2.947-3.246M10.61 7.798 8.185 4.14M6.073 14.258 2 12.657M6.922 21.593l-4.299.811" />
      </G>
    </G>
  </Svg>
);
export default HubDimSVG;
