import * as React from 'react';
import { G, Path, Svg } from 'react-native-svg';

const BothWaysSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <G fill="#263A43" fillRule="nonzero">
      <Path d="M1.488 6.218h7.368V7.95c0 .402.456.627.775.395l5.122-3.732a.488.488 0 0 0 0-.788L9.63.095a.488.488 0 0 0-.775.393V2.22H1.488A.488.488 0 0 0 1 2.708V5.73c0 .27.218.487.488.487Z" />
      <Path d="M14.466 9.782H7.097V8.05a.488.488 0 0 0-.775-.394l-5.121 3.73a.488.488 0 0 0 0 .789l5.121 3.73c.321.235.775.006.775-.393V13.78h7.369c.269 0 .487-.218.487-.488V10.27a.488.488 0 0 0-.487-.487Z" />
    </G>
  </Svg>
);
export default BothWaysSVG;
