import {
  GroupReportPointModel,
  MappedMovementReportDataModel,
} from '@models/ReportModel';
import { useMemo } from 'react';
import {
  addDays,
  differenceInSeconds,
  endOfDay,
  formatISO,
  getDate,
  isBefore,
  startOfDay,
  subDays,
} from 'date-fns';
import useBoundStore from 'src/store/store';

export function useDivideMovementDay(
  data: GroupReportPointModel<MappedMovementReportDataModel>[],
): any {
  // TODO: Add types
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  useMemo(() => {
    const offset = activeHousehold.timezone.utc_offset;
    const dataInitialLength = data?.length;
      for (let i = 0; i < dataInitialLength; i++) {
        const lastPoint = { ...data[i].points[data[i].points?.length - 1] };
        const firstPoint = {...data[i].points[0]}
        const lastFromDate = new Date(lastPoint.from);
        const lastToDate = new Date(lastPoint.to);
        const dayBeforeTo = subDays(lastToDate, 1);

        const firstFromDate = new Date(firstPoint.from)
        const firstToDate = new Date(firstPoint.to)

        if (getDate(firstFromDate) !== getDate(firstToDate)) {
          const copy = firstPoint;
          copy.duration = differenceInSeconds(endOfDay(new Date(copy.from)), new Date(copy.from));
          data[i - 1]?.points.push(copy)
          data[i].points[0].from = formatISO(startOfDay(firstToDate));
          data[i].points[0].duration = differenceInSeconds(firstToDate, startOfDay(firstToDate))
        }

        let fromCopy = lastFromDate;
        while (getDate(fromCopy) < getDate(dayBeforeTo)) {
          fromCopy = addDays(fromCopy, 1);
          const pointCopy = { ...lastPoint };
          pointCopy.duration = 86399;
          data.push({ date: lastFromDate.getTime(), points: [pointCopy] });
        }
        if (isBefore(startOfDay(fromCopy), startOfDay(lastToDate)) && data[i + 1]) {
          if (data[i + 1].points[0].to === lastPoint.to) break;
          const startOfTheDay = startOfDay(lastToDate);
          const differenceInSec = differenceInSeconds(lastToDate, startOfTheDay);
          lastPoint.duration = differenceInSec;
          console.log(differenceInSec)
          lastPoint.from = startOfTheDay.toISOString();
          data[i + 1].points.unshift(lastPoint);
        }
      }
  }, [data]);

  return undefined;
}
