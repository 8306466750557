import { useEffect, useState } from 'react';
import { DeviceService } from '../services/DeviceService';

export const useDeviceId = (): string => {
  const [id, setId] = useState('');

  useEffect(() => {
    DeviceService.getDeviceId().then(data => {
      setId(data);
    });
  }, []);

  return id;
};
