import { lastScreenOptions } from '@constants/Screen';
import CustomHeader from '@molecules/CustomHeader';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { SpView } from '@atoms/SpView';
import atoms from '@styles/atoms';
import { useTranslation } from 'react-i18next';
import ChooseSetup from './ChooseSetup';
import CreateHousehold from './CreateHousehold/CreateHousehold';
import { Household } from './Household';
import HouseholdManageUser from './Users/HouseholdManageUser';
import HouseholdSendInvite from './HouseholdSendInvite/HouseholdSendInvite';
import { HouseHoldUsersProps } from './molecules/HouseholdUserThumbnail';
import JoinHousehold from './molecules/JoinHousehold';
import HouseholdEditSetting from './Settings/HouseholdEditSetting';
import { HouseholdSettings } from './Settings/HouseholdSettings';
import HouseholdUsers from './Users/HouseholdUsers';
import HouseholdUserPermissionSelect from './Users/HouseholdUserPermissionSelect';
import FlowNavigation from '../Flows';
import { navigationRef } from '../RootNavigation';

export interface HouseholdEditSettingProps {
  label: string;
  inputName: string;
  inputValue: any;
  isCreating?: boolean;
  handleTimezone?: (id: unknown) => void;
}

export type HouseholdStackParamList = {
  Household: undefined;
  Personal: undefined;
  Preferences: undefined;
  HouseholdSettings: undefined;
  HouseholdEditSetting: HouseholdEditSettingProps;
  HouseholdUsers: undefined;
  HouseholdManageUser: {
    user: HouseHoldUsersProps;
  };
  HouseholdUserPermissionSelect: {
    selectedPermissionLevelId: number;
    options: {
      title?: string;
      subtitle: string;
      id: number;
    }[];
    id: number;
    role: string;
    userName: string;
  };
  HouseholdSendInvite: undefined;
  JoinHousehold: {
    userHasNoHouseholds: boolean;
  };
  CreateHousehold: undefined;
  ChooseSetup: undefined;
  SelectProduct: undefined;
  AddHub: undefined;
  FlowNavigation: {
    screen: string;
  };
};

const Stack = createStackNavigator<HouseholdStackParamList>();

export default function HouseholdNavigation() {
  const { t } = useTranslation();
  return (
    <SpView style={atoms.screenWrapper}>
      <Stack.Navigator initialRouteName='Household'>
        <Stack.Screen
          name='Household'
          options={{
            header: () =>
              // CustomHeader({ withArrowBack: false, withTitle: false })
              false,
          }}
          component={Household}
        />
        <Stack.Screen
          name='HouseholdSettings'
          options={{
            header: () => CustomHeader({
              title: t('settings'), goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={HouseholdSettings}
        />
        <Stack.Screen
          name='HouseholdEditSetting'
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={HouseholdEditSetting}
        />
        <Stack.Screen
          name='HouseholdUsers'
          options={{
            header: () => CustomHeader({
              title: t('users'), goBackFunc: () => navigationRef.goBack(),

            }),
          }}
          component={HouseholdUsers}
        />
        <Stack.Screen
          name='HouseholdManageUser'
          options={{
            header: () =>
              CustomHeader({
                withArrowBack: true, withTitle: false, goBackFunc: () => navigationRef.goBack(),
              }),
          }}
          component={HouseholdManageUser}
        />
        <Stack.Screen
          name='HouseholdUserPermissionSelect'
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={HouseholdUserPermissionSelect}
        />
        <Stack.Screen
          name='HouseholdSendInvite'
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={HouseholdSendInvite}
        />
        <Stack.Screen
          name='JoinHousehold'
          component={JoinHousehold}
          options={{
            header: () =>
              CustomHeader({
                withCross: false,
                withTitle: false,
                withArrowBack: true,
              }),
          }}
        />
        <Stack.Screen
          name='CreateHousehold'
          options={{ headerShown: false }}
          component={CreateHousehold}
        />
        <Stack.Screen
          name='ChooseSetup'
          component={ChooseSetup}
          options={{
            headerShown: true,
            header: () =>
              CustomHeader({ withArrowBack: true, withTitle: false }),
          }}
        />
        <Stack.Screen
          name='FlowNavigation'
          options={{
            headerShown: false,
          }}
          component={FlowNavigation}
        />
      </Stack.Navigator>
    </SpView>
  );
}
