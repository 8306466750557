import AccessSVG from '@atoms/SvgIcons/AccessSVG';
import { PetStatisticsModel } from '@models/Device';
import { t } from 'i18next';
import React from 'react';

import { AdaptiveSize } from './PetCard';
import PetInfoSection from './PetInfoSection';

type PetTripsToday = {
  data: PetStatisticsModel['movement'];
  disablePadding?: boolean;
  adaptiveSize: AdaptiveSize;
};

const PetTripsToday = ({ data, disablePadding, adaptiveSize }: PetTripsToday) => {
  return (
    <PetInfoSection
      svgIcon={<AccessSVG viewBox="0 0 24 26" width={20} height={20} />}
      fontSizeForNumbers={adaptiveSize?.fontSizeForNumbers || 24}
      fontSizeForText={adaptiveSize.fontSizeForText || 16}
      title={t('entries')}
      data={`${data.entries} ${t('trips_today')}`}
      disablePadding={disablePadding}
      type="count"
    />
  );
};

export default PetTripsToday;
