import {
  GroupReportPointModel,
  MappedDoorStats,
  MappedMovementReportDataModel,
} from '@models/ReportModel';
import { getDoorStatsByRange } from '@utils/report';
import {
  addHours,
  differenceInHours,
  differenceInSeconds,
  endOfHour,
  endOfMonth,
  isSameHour,
  parseISO,
  startOfDay,
  startOfHour,
} from 'date-fns';
import React, { useMemo } from 'react';
import { ReportRangeType } from '../constants/ReportRangeType';
import { useGroupedRangeReportStats } from './useGroupedRangeReportStats';

const groupEventsByHours = (
  start: number,
  end: number,
  sharedData: Record<number, { activity: number; duration: number }>,
): Record<number, { activity: number; duration: number }> => {
  const diff = differenceInSeconds(end, start);
  const isSameHours = isSameHour(end, start);
  const uniqDate = startOfHour(start).getTime();
  const uniqueNextDate = startOfHour(end).getTime();

  let toStartHour: number = null;
  let fromEndHour: number = null;

  if (diff > 3599) {
    return groupEventsByHours(startOfHour(addHours(start, 1)).getTime(), end, {
      ...sharedData,
      [uniqDate]: {
        duration: differenceInSeconds(endOfHour(start).getTime(), start),
        activity: 1,
      },
    });
  }

  if (!isSameHours) {
    toStartHour = Math.abs(differenceInSeconds(start, endOfHour(start)));
    fromEndHour = Math.abs(differenceInSeconds(startOfHour(end), end));
  }

  let newPart = {
    [uniqDate]: {
      duration: (sharedData[uniqDate]?.duration || 0) + (toStartHour || diff),
      activity: 1,
    },
  };

  if (!isSameHours && fromEndHour) {
    newPart = {
      ...newPart,
      [uniqueNextDate]: {
        duration: (sharedData[uniqueNextDate]?.duration || 0) + fromEndHour,
        activity: 1,
      },
    };
  }

  return {
    ...sharedData,
    ...Object.keys(newPart).reduce((acc, item) => {
      // @ts-ignore
      if (sharedData[item]) {
        // @ts-ignore
        acc[item] = {
          // @ts-ignore
          duration: newPart[item].duration,
          // @ts-ignore
          activity: sharedData[item].totalActivity + 1,
        };
      } else {
        // @ts-ignore
        acc[item] = newPart[item];
      }

      return acc;
    }, {}),
  };
};

export function useGroupedMovementReportStats(
  data: GroupReportPointModel<MappedMovementReportDataModel>[],
  date: number,
  range: ReportRangeType,
): MappedDoorStats[] {
  const calculatedRange = useGroupedRangeReportStats(date, range);

  return useMemo(() => {
    if (range === ReportRangeType.OneDay) {
      const startOfDate = startOfDay(calculatedRange.start).getTime();
      const points = data.map(item => item.points).flat();
      const groupedEvents = points.reduce((acc, event) => {
        return groupEventsByHours(
          parseISO(event.from).getTime(),
          parseISO(event.to).getTime(),
          acc,
        );
      }, {});
      const oneDayEvents = Object.keys(groupedEvents).reduce((acc, item) => {
        if (startOfDay(Number(item)).getTime() === startOfDate) {
          // @ts-ignore
          acc[Number(item)] = groupedEvents[item];
        }

        return acc;
      }, {});

      return new Array(24).fill(true).map((_, index) => {
        const currentDate = addHours(startOfDate, index).getTime();
        // @ts-ignore
        const event = oneDayEvents[currentDate];
        return {
          date: currentDate,
          totalDuration: event?.duration || 0,
          totalActivity: event?.activity || 0,
          lastLeft: null,
          lastEnter: null,
        };
      });
    }

    const res = getDoorStatsByRange(
      data,
      calculatedRange.start,
      calculatedRange.finish,
    );

    if (
      range === ReportRangeType.OneYear ||
      range === ReportRangeType.SixMonths
    ) {
      const group = res.reduce((acc: Record<number, MappedDoorStats>, item) => {
        const month = endOfMonth(item.date).getTime();
        if (!acc[month]) {
          acc[month] = {
            ...item,
            date: month,
          };
        }

        acc[month].totalDuration += item.totalDuration;
        acc[month].totalActivity += item.totalActivity;

        return acc;
      }, {});

      return Object.values(group);
    }

    return res;
  }, [data, calculatedRange]);
}
