/* eslint-disable react/no-unstable-nested-components */
import LoadingSpinner from '@atoms/Loader';
import { SpCenter } from '@atoms/SpCenter';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { TimelineEventType } from '@constants/Timeline';
import { TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';
import { getTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { CurfewLockStatus } from './CurfewLockStatus';
import { DoorLockingMode } from './DoorLockingMode';
import { Feeding } from './Feeding';
import { IntruderMovement } from './IntruderMovement';
import { Movement } from './Movement';
import { NewDevice } from './NewDevice';
import { NewTag } from './NewTag';
import { PetName } from './PetName';
import { PetPermissionsChanged } from './PetPermissionsChanged';
import { PoseidonDrinking } from './PoseidonDrinking';
import { PoseidonLowWater } from './PoseidonLowWater';
import { PoseidonWaterFreshness } from './PoseidonWaterFreshness';
import { Tare } from './Tare';
import { TargetWeightSet } from './TargetWeightSet';
import { TimelineCard } from './TimelineCard';
import { TimelineUserPhoto } from './TimelineUserPhoto';
import { TrainingMode } from './TrainingMode';
import { WaterRemoved } from './WaterRemoved';
import { WeightChanged } from './WeightChanged';
import TimelineDevicePhoto from './TimelineDevicePhoto';

interface TimelineProps {
  data: TimelineEventModel[];
  refreshing: boolean;
  lazyLoading: boolean;
  onRefresh: () => void;
  onEndReached: () => void;
}

export const Timeline = ({
  data,
  refreshing,
  lazyLoading,
  onRefresh,
  onEndReached,
}: TimelineProps) => {
  const { t } = useTranslation();
  const renderFooter = useCallback(() => {
    return (
      <SpView style={styles.footer}>
        {lazyLoading ? <LoadingSpinner size='small' /> : null}
      </SpView>
    );
  }, [lazyLoading, t]);

  const renderBlock = (event: TimelineEventModel, active: boolean) => {
    switch (event.type) {
      case TimelineEventType.Movement:
        return <Movement active={active} event={event} />;
      case TimelineEventType.PoseidonWeightChanged:
        return <WeightChanged active={active} event={event} />;
      case TimelineEventType.WeightChanged:
        return <WeightChanged active={active} event={event} />;
      case TimelineEventType.LowBattery:
        return (
          <TimelineCard
            active={active}
            text={t('timeline_low_battery', {
              devicename: getTimelineDeviceName(event.devices),
            })}
            date={event.created_at}
            image={<TimelineDevicePhoto active={active} event={event} />}
          />
        );
      case TimelineEventType.NewTag:
        return <NewTag active={active} event={event} />;
      case TimelineEventType.NewDevice:
        return <NewDevice active={active} event={event} />;
      case TimelineEventType.DoorLockingMode:
        return <DoorLockingMode active={active} event={event} />;
      case TimelineEventType.IntruderMovement:
        return <IntruderMovement active={active} event={event} />;
      case TimelineEventType.UserJoinedHousehold:
        return (
          <TimelineCard
            active={active}
            image={
              <TimelineUserPhoto showPhoto active={active} event={event} />
            }
            text={t('timeline_user_join_household', {
              user: get(event, 'users[0].name', ''),
              household: get(event, 'households[0].name', ''),
            })}
            date={event.created_at}
          />
        );
      case TimelineEventType.NewPet:
        return <PetName active={active} event={event} />;
      case TimelineEventType.AccountCreated:
        return (
          <TimelineCard
            active={active}
            text={t('timeline_account_created')}
            date={event.created_at}
            image={
              <TimelineUserPhoto showPhoto active={active} event={event} />
            }
          />
        );
      case TimelineEventType.CurfewLockStatus:
        return <CurfewLockStatus active={active} event={event} />;
      case TimelineEventType.PoseidonDrinking:
        return <PoseidonDrinking active={active} event={event} />;
      case TimelineEventType.Feeding:
        return <Feeding active={active} event={event} />;
      case TimelineEventType.TargetWeightSet:
        return <TargetWeightSet active={active} event={event} />;
      case TimelineEventType.Tare:
        return <Tare active={active} event={event} />;
      case TimelineEventType.PetPermissionsChanged:
        return <PetPermissionsChanged active={active} event={event} />;
      case TimelineEventType.WeightChangedTargetMet:
        return (
          <TimelineCard
            active={active}
            text={t('notification_weight_changed_target_met', {
              devicename: getTimelineDeviceName(event.devices),
            })}
            date={event.created_at}
            image={<TimelineDevicePhoto active={active} event={event} />}
          />
        );
      case TimelineEventType.TrainingMode:
        return <TrainingMode active={active} event={event} />;
      case TimelineEventType.CurfewTimezoneChange:
        return (
          <TimelineCard
            active={active}
            text={t('timeline_clock_update')}
            date={event.created_at}
            image={<TimelineDevicePhoto active={active} event={event} />}
          />
        );
      case TimelineEventType.PoseidonWaterFreshness:
        return <PoseidonWaterFreshness active={active} event={event} />;
      case TimelineEventType.PoseidonLowWater:
        return <PoseidonLowWater active={active} event={event} />;
      case TimelineEventType.WaterRemoved:
        return <WaterRemoved active={active} event={event} />;
    }
    return null;
  };

  const renderItem = useCallback(
    ({ item, index }: { item: TimelineEventModel; index: number }) => {
      return <SpView>{renderBlock(item, index === 0)}</SpView>;
    },
    [],
  );

  return (
    <FlatList
      style={styles.eventContainer}
      data={data}
      renderItem={renderItem}
      keyExtractor={event => `${event.id}-timeline`}
      ItemSeparatorComponent={() => <SpView style={{ height: 20 }} />}
      ListEmptyComponent={() => (
        <SpCenter height={100}>
          <SpText size='xl' color={colors.greySmallTitle.color}>
            {t('no_entries')}
          </SpText>
        </SpCenter>
      )}
      refreshControl={
        <RefreshControl refreshing={false} onRefresh={onRefresh} />
      }
      ListFooterComponent={renderFooter}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.7}
      showsHorizontalScrollIndicator={false}
    />
  );
};

const styles = StyleSheet.create({
  eventContainer: {
    paddingVertical: 22,
  },
  footer: {
    paddingTop: 12,
    paddingBottom: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
