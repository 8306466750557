import { StackScreenProps } from '@react-navigation/stack';
import React, { useLayoutEffect } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import { RootStackParamList } from '..';

type IProps = StackScreenProps<RootStackParamList, 'WebviewScreen'>;

const WebviewScreen = ({ route, navigation }: IProps) => {
  const { uri, title } = route.params;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: title,
    });
  }, [title]);

  return (
    <SafeAreaView style={styles.container}>
      <WebView
        source={{
          uri,
        }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default WebviewScreen;
