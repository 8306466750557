import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const FullWifiSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={22} height={16} {...props}>
    <G fill="#263A43" fillRule="nonzero">
      <Path d="M21.958 4.674a.245.245 0 0 0-.029-.035C19.01 1.647 15.13 0 11 0 6.872 0 2.99 1.647.071 4.638a.25.25 0 0 0 0 .35l1.598 1.638a.238.238 0 0 0 .341 0C4.415 4.161 7.608 2.804 11 2.804c3.392 0 6.585 1.358 8.99 3.822a.238.238 0 0 0 .341 0L21.93 4.99a.25.25 0 0 0 .03-.315Z" />
      <Path d="M11 4.33c-2.995 0-5.814 1.199-7.937 3.375a.252.252 0 0 0 0 .35l1.593 1.632a.238.238 0 0 0 .341 0C6.603 8.04 8.735 7.135 11 7.135s4.397.906 6.003 2.552a.238.238 0 0 0 .341 0l1.593-1.633a.25.25 0 0 0 0-.35C16.814 5.53 13.995 4.332 11 4.332Z" />
      <Path d="M11 8.661c-1.868 0-3.626.748-4.95 2.105a.25.25 0 0 0 0 .35l1.593 1.632a.238.238 0 0 0 .341 0 4.187 4.187 0 0 1 6.032 0 .238.238 0 0 0 .34 0l1.594-1.633a.252.252 0 0 0 0-.35C14.626 9.41 12.868 8.662 11 8.662ZM11 13.25c-.653 0-1.266.26-1.727.733a.252.252 0 0 0 0 .35l1.556 1.595a.238.238 0 0 0 .342 0l1.556-1.595a.252.252 0 0 0 0-.35A2.397 2.397 0 0 0 11 13.25Z" />
    </G>
  </Svg>
);
export default FullWifiSVG;
