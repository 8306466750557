import { Platform, StyleSheet, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { SpInput } from '@atoms/SpInput';
import { SpView } from '@atoms/SpView';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { BarCodeScanner } from 'expo-barcode-scanner';
import LoadingComponent from '../Molecules/LoadingComponent';

interface WiFiPasswordInputProps {
  SelectedWiFiSSID: string;
  setTargetNetworkPassword: (text: string) => void;
  targetNetworkPassword: string;
  loading: boolean;
  error: string;
}

export const WiFiPasswordInput = ({
  SelectedWiFiSSID,
  setTargetNetworkPassword,
  targetNetworkPassword,
  loading,
  error,
}: WiFiPasswordInputProps) => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [showScan, setShowScan] = useState(false);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };
    try {
      getBarCodeScannerPermissions();
    } catch (e) {
      console.log(e);
    }
  }, []);

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }

  const handleShowScanner = (scannerStatus: boolean) => {
    setShowScan(scannerStatus);
    setScanned(false);
  };
  // @ts-ignore
  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    console.log('coming here');
    setTargetNetworkPassword(data);
    setShowScan(false);
    alert(`Bar code with type ${type} and data ${data} has been scanned!`);
  };

  return (
    <SpView
      style={[
        styles.wrapper,
        Platform.OS === 'android' ? styles.androidPaddingBottom : {},
      ]}
    >
      <SpView style={styles.wrapper}>
        <SpText style={[styles.titlePassword, styles.center]}>
          {t('network_password_required', { ssid: SelectedWiFiSSID })}
        </SpText>
        <SpView>
          <SpInput
            label={t('password')}
            onChangeText={setTargetNetworkPassword}
            value={targetNetworkPassword}
            password
            error={error}
          />
        </SpView>

        <SpView style={styles.marginFive}>
          {loading && (
            <LoadingComponent
              searchMsg=""
              connectedMsg=""
              isConnected={false}
            />
          )}
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    marginBottom: 100,
  },
  titlePassword: {
    marginTop: '2%',
    marginBottom: '10%',
    fontFamily: 'Rubik',
    fontSize: 20,
    color: colors.black.color,
    alignItems: 'center',
    textAlign: 'center',
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  qrCode: {
    marginTop: '15%',
    fontFamily: 'Rubik_Medium',
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.black.color,
    textAlign: 'center',
  },
  marginFive: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  bottomText: {
    position: 'absolute',
    bottom: 40,
    fontSize: 20,
  },
  btnCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBottom: {
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    color: colors.white.color,
  },
  btnTxtFont: {
    maxWidth: 250,
    marginTop: 20,
  },
  bottomTextFont: {
    fontFamily: 'Rubik',
    fontSize: 20,
    color: colors.black.color,
    paddingBottom: 50,
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
});

export default WiFiPasswordInput;
