import { SpProgressBar } from '@atoms/SpProgressBar';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { FeederFoodType } from '@constants/Device';
import { DeviceFeederBowlSettingModel } from '@models/Device';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { BowlTypeSide } from './BowlTypeSide';
import colors from '@styles/colors';

interface BowlTypeProps {
  data: DeviceFeederBowlSettingModel;
  percentage: number;
}

export const SingleBowlType = ({ data, percentage }: BowlTypeProps) => {
  const { t } = useTranslation();
  return (
    <SpView width="100%">
      <SpView style={styles.bowlWrapper} testID="foodRemainingBlock">
        <SpView
          marginTop={4}
          marginBottom={6}
          minHeight={32}
          alignItems="center"
        >
          <SpProgressBar
            value={percentage}
            width="80%"
            borderRadius={16}
            componentName="BowlType"
          />
        </SpView>
      </SpView>
      <SpView style={styles.description}>
        <SpText
          style={styles.targetText}
          fontFamily="Rubik_SemiBold"
          componentName="single"
        >
          {percentage}%
        </SpText>
        <SpView marginLeft={16}>
          <BowlTypeSide
            label={t(FeederFoodType[data.food_type])}
            type="single"
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  description: {
    marginTop: 8,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  targetText: {
    color: colors.greyText.color,
    fontSize: 32,
    lineHeight: 36,
  },
  bowlWrapper: {
    minHeight: 72,
  },
});
