import { Client } from '@models/Client';
import { DeviceModel } from '../models/Device';
import Http from './Http';

class MeClientApi {
  static createClient(object: {
    platform: Client;
    token: string;
  }): Promise<DeviceModel[]> {
    return Http.post(`/api/me/client`, object).then(
      response => response.data.data,
    );
  }
}

export default MeClientApi;
