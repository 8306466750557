import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { AppImages } from '@constants/AppImages';
import { SpCarouselWithSteps } from '@molecules/CarouselWithSteps';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ImageSourcePropType, StyleSheet } from 'react-native';
import text from '@styles/text';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = 280;

interface InsertBatteriesProps {
  enableInsertBatteriesContinue: (data: number) => void;
  setNumberItems: (data: number) => void;
}

export const InsertBatteries = ({
  enableInsertBatteriesContinue,
  setNumberItems,
}: InsertBatteriesProps) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        text: t('setup_cdb_carousel_1'),
        image: AppImages.cdbSetupCarousel1,
      },
      {
        text: t('setup_cdb_carousel_2'),
        image: AppImages.cdbSetupCarousel2,
      },
      {
        text: t('setup_cdb_carousel_3'),
        image: AppImages.cdbSetupCarousel3,
      },
      {
        text: t('setup_cdb_carousel_4'),
        image: AppImages.cdbSetupCarousel4,
      },
      {
        text: t('setup_cdb_carousel_5'),
        image: AppImages.cdbSetupCarousel5,
      },
    ];
  }, []);
  useEffect(() => {
    setNumberItems(items.length);
  }, []);

  const renderCartItem = useCallback(
    (props: {
      item: {
        text: string;
        image: ImageSourcePropType;
      };
    }) => {
      return (
        <SpView accessible style={styles.item}>
          <Image
            accessible
            style={styles.image}
            source={props.item.image}
            resizeMode="contain"
            {...testProperties(`${props.item.text}`, 'image')}
          />
          <SpText
            style={[styles.text, { marginBottom: 24 }]}
            size="sm"
            color={colors.black.color}
          >
            {props.item.text}
          </SpText>
          {/*          <Image
            accessible
            style={styles.image}
            resizeMode="contain"
            source={props.item.badImage}
            {...testProperties(`${props.item.badText}`, 'image')}
          />
          <SpText style={styles.text} size="sm" color={colors.errorRed.color}>
            {props.item.text}
          </SpText> */}
        </SpView>
      );
    },
    [],
  );

  return (
    <SpView style={styles.container}>
      <SpView>
        <SpText style={[text.title, styles.title]}>
          {t('insert_the_batteries')}
        </SpText>
      </SpView>
      <SpCarouselWithSteps
        renderItem={renderCartItem}
        data={items}
        sliderWidth={SLIDER_WIDTH}
        slideWidth={ITEM_WIDTH}
        onLastTemplate={enableInsertBatteriesContinue}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 100,
    alignItems: 'center',
    maxHeight: '85%',
  },
  text: {
    marginTop: 8,
    textAlign: 'center',
    fontFamily: 'Rubik_SemiBold',
  },
  image: {
    width: 240,
    height: 140,
  },
  item: {
    flex: 1,
    padding: 20,
    justifyContent: 'space-around',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    marginBottom: 80,
  },
  title: {
    marginBottom: 10,
  }
});
