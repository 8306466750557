import AuthApi from '@api/AuthApi';
import { SpInput } from '@atoms/SpInput';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpText } from '@atoms/SpText';
import SpTextButton from '@atoms/SpTextButton';
import { SpView } from '@atoms/SpView';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDeviceId } from '@hooks/useDeviceId';
import atomStyles from '@styles/atoms';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import { get } from 'lodash-es';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet } from 'react-native';
import useBoundStore from 'src/store/store';
import useValidation from '../../validation';

type Props = {
  loading: boolean;
  onSetLoading: (data: boolean) => void;
  navigateToLogin: () => void;
  navigateToForgotPassword: () => void;
  onPasswordChecked: (data: string) => void;
};

const ConfirmPassword: React.FC<Props> = ({
  loading,
  navigateToLogin,
  navigateToForgotPassword,
  onPasswordChecked,
  onSetLoading,
}) => {
  const { simplePasswordSchema } = useValidation();
  const { t } = useTranslation();
  const deviceId = useDeviceId();
  const { user } = useBoundStore(state => state.accountStore);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    setValue,
  } = useForm<{ password: string }>({
    mode: 'onSubmit',
    resolver: yupResolver(simplePasswordSchema),
  });

  const submitAction = useCallback(
    async (data: { password: string }) => {
      onSetLoading(true);

      try {
        await AuthApi.login(
          {
            email_address: user?.email_address,
            password: data.password,
            device_id: deviceId,
          },
          true,
        );
        onPasswordChecked(data.password);
      } catch (e) {
        setError('password', { type: 'custom', message: t('sign_error') });
        setValue('password', '');
        onSetLoading(false);
      }
    },
    [handleSubmit, isValid, onSetLoading, onPasswordChecked],
  );

  return (
    <>
      <SpText style={[textStyles.title, styles.customTitle]}>
        {t('confirm_password')}
      </SpText>
      <Pressable onPress={dismissKeyboard} style={styles.flex}>
        <SpText style={[textStyles.loadingText, styles.smallerText]}>
          {user?.email_address}
        </SpText>
        <SpTextButton
          text={t('use_another_account')}
          textStyle={[atomStyles.forgotText, styles.mt8]}
          onPress={navigateToLogin}
        />
        <SpView style={styles.mt20}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <SpInput
                autoFocus
                label={t('password')}
                placeholder={t('type_here')}
                onChangeText={onChange}
                value={value}
                error={t(get(errors, 'password.message'))}
                password
                returnKeyType="done"
                inputStyle={styles.greyInput}
              />
            )}
            name="password"
          />
        </SpView>
        <SpTextButton
          text={t('terms_forgotten_password')}
          textStyle={[
            atomStyles.forgotText,
            {
              marginTop: Object.keys(errors).length ? 40 : 20,
            },
          ]}
          onPress={navigateToForgotPassword}
        />
      </Pressable>

      <SpView paddingBottom={10}>
        <SpRoundedHeaderButton
          h={56}
          isLoading={loading}
          title={t('continue')}
          backgroundColor={colors.primary.color}
          onPress={handleSubmit(submitAction)}
        />
      </SpView>
    </>
  );
};

const styles = StyleSheet.create({
  mt24: {
    marginTop: 24,
    padding: 0,
    marginBottom: 0,
    textAlign: 'left',
  },
  smallerText: {
    fontSize: 16,
    textAlign: 'left',
    marginTop: 36,
  },
  mt8: {
    marginTop: 8,
  },
  flex: {
    flex: 1,
  },
  inputStyles: {
    marginTop: undefined,
  },
  greyInput: {
    backgroundColor: colors.greyInput.color,
  },
  customTitle: {
    marginTop: 28,
  },
  mt20: {
    marginTop: 20,
  },
});

export default ConfirmPassword;
