import {
  launchCameraAsync,
  MediaTypeOptions,
  requestCameraPermissionsAsync,
  UIImagePickerPresentationStyle,
} from 'expo-image-picker';
import { CameraPermissionResponse, ImagePickerResult } from 'expo-image-picker/src/ImagePicker.types';

export const useCamera = (): {
  takePhoto: () => Promise<ImagePickerResult>;
  requestPermissions: () => Promise<CameraPermissionResponse>;
} => {
  const takePhoto = async () => {
    return launchCameraAsync({
      mediaTypes: MediaTypeOptions.Images,
      presentationStyle: UIImagePickerPresentationStyle.FULL_SCREEN,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
  };

  const requestPermissions = () => {
    return requestCameraPermissionsAsync();
  };

  return {
    takePhoto,
    requestPermissions,
  };
};
