import {
  DeviceCatFlapControlModel,
  DeviceCatFlapCurfew,
  DevicePetDoorControlModel,
  DevicePetDoorCurfew,
} from '@models/Device';
import { useMemo } from 'react';

export const useEnabledCurfews = (
  data: DeviceCatFlapControlModel | DevicePetDoorControlModel,
): DevicePetDoorCurfew[] | DeviceCatFlapCurfew[] => {
  // @ts-ignore
  return useMemo(() => {
    if (data.curfew && Array.isArray(data.curfew)) {
      return data.curfew.filter(
        (item: DevicePetDoorCurfew | DeviceCatFlapCurfew) => item.enabled,
      );
    }

    if (data.curfew && (data.curfew as DevicePetDoorCurfew).enabled) {
      return [data.curfew];
    }

    return [];
  }, [data.curfew]);
};
