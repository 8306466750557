/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/destructuring-assignment */
import { IToastProps } from '@hooks/useToast';
import { CommonActions } from '@react-navigation/native';
import i18n from '@utils/i18n';
import React from 'react';
import { navigationRef } from 'src/pages/RootNavigation';

type Props = {
  children: React.ReactNode;
  showErrorToast: (props: IToastProps) => void;
  logout: () => void;
  isAuthenticated: boolean;
};
type State = {
  crashedComponentName: string;
  routes: { name: string }[];
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { crashedComponentName: '', routes: [] };
  }

  static getDerivedStateFromError() {
    return {
      crashedComponentName: navigationRef?.getCurrentRoute()?.name || '',
      routes: navigationRef.getRootState()?.routes || [],
    };
  }

  async navigateToSavePlace() {
    const { crashedComponentName, routes } = this.state;

    if (!__DEV__ && (routes.length === 1 || routes.length === 0)) {
      navigationRef.dispatch(
        CommonActions.reset({ index: 0, routes: [{ name: 'ErrorScreen' }] }),
      );
      return;
    }

    const currentStack = routes[routes.length - 1];

    this.props.showErrorToast({
      isError: true,
      description: i18n.t('error_occured'),
    });

    if (__DEV__) {
      return;
    }

    switch (currentStack.name) {
      case 'LoginNavigation':
      case 'RegisterNavigation':
        navigationRef.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: 'OnboardingLetsStart',
              },
            ],
          }),
        );
        break;
      case 'DeviceNavigation':
        navigationRef.dispatch(
          CommonActions.reset({ index: 0, routes: [{ name: 'Products' }] }),
        );
        break;
      case 'HouseholdNavigation':
        navigationRef.dispatch(
          crashedComponentName === 'Household'
            ? CommonActions.reset({ index: 0, routes: [{ name: 'Pets' }] })
            : CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: 'HouseholdNavigation',
                  params: { screen: 'Household' },
                },
              ],
            }),
        );
        break;
      case 'RemoveAccountNavigation':
        const prevStack = routes[routes.length - 2] || null;
        navigationRef.dispatch(
          CommonActions.reset(
            prevStack?.name === 'AccountNavigation'
              ? {
                index: 0,
                routes: [
                  {
                    name: 'AccountNavigation',
                    params: { screen: 'Account' },
                  },
                ],
              }
              : { index: 0, routes: [{ name: 'ErrorScreen' }] },
          ),
        );
        break;
      default:
        navigationRef.dispatch(
          CommonActions.reset({ index: 0, routes: [{ name: 'Pets' }] }),
        );
    }
  }

  componentDidCatch(): void {
    setTimeout(this.navigateToSavePlace.bind(this), 0);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
