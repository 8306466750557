import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

const PawSVG = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' width={38} height={32} {...props}>
    <G fill='none' fillRule='evenodd'>
      <Path fill={props.color || '#000'} d='M0 0h38v32H0z' />
      <G fill='#FFF'>
        <Path d='m19.285 12.228 5.14-5.359c-.03-1.318-.389-2.564-1.032-3.523-.742-1.104-1.808-1.737-2.928-1.737-1.116 0-2.182.633-2.925 1.737-.674 1.001-1.045 2.313-1.045 3.695 0 1.381.371 2.692 1.045 3.694.474.704 1.08 1.214 1.745 1.493M10.809 12.472c1.117 0 2.183-.633 2.925-1.737.674-1.002 1.045-2.313 1.045-3.694 0-1.382-.371-2.694-1.045-3.695-.743-1.104-1.808-1.738-2.925-1.738-1.12 0-2.185.633-2.928 1.738C7.21 4.347 6.839 5.66 6.839 7.041c0 1.381.37 2.692 1.042 3.694.743 1.104 1.809 1.737 2.928 1.737M19.726 27.806l3.326-6.827-4.915-1.04a2.946 2.946 0 0 1-2.215-2.09c-.295-.969-.026-2.087.716-2.86l1.317-1.374a7.248 7.248 0 0 0-2.318-.386c-2.132 0-4.202.96-5.823 2.701-1.55 1.661-2.63 3.966-3.047 6.492-.264 1.611.217 3.161 1.325 4.253 1.152 1.137 2.657 1.482 4.13.95a9.946 9.946 0 0 1 3.415-.598c1.176 0 2.325.2 3.415.597.224.081.45.141.674.182M7.329 13.275c-.306-1.09-.912-2.033-1.704-2.657-.873-.686-1.913-.923-2.854-.65C1.83 10.242 1.069 11 .683 12.05c-.35.958-.377 2.085-.071 3.174.303 1.09.909 2.033 1.705 2.657.635.499 1.357.76 2.066.76.265 0 .529-.036.784-.11.94-.274 1.702-1.033 2.088-2.084.353-.957.377-2.084.074-3.173' />
        <Path d='m37.311 15.593-14.94 15.58c-.33.35-.85.39-1.23.12a.962.962 0 0 1-.3-1.21l5.13-10.53-7.42-1.57a.938.938 0 0 1-.7-.66c-.1-.33-.01-.7.23-.95L33.021.793c.33-.34.85-.39 1.23-.12s.5.78.3 1.21l-5.14 10.53 7.42 1.57c.34.08.61.33.71.66.1.34.01.7-.23.95' />
      </G>
    </G>
  </Svg>
);

export default PawSVG;
