import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import SpTitle from '@molecules/SpTitle';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from './Layout';

export const RemoveHouseholdInformationDialog = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <Layout nextButtonText={t('continue')} onNext={() => navigation.goBack()}>
      <SpView alignItems="center">
        <SpTitle
          align="center"
          text={t('ad_explanation_delete_household_information_title')}
        />
      </SpView>
      <SpText>{t('ad_explanation_dialog_delete_household_body')}</SpText>
    </Layout>
  );
};
