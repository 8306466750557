import React from 'react';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { PetModel } from '@models/Pet';
import { LinearGradient } from 'expo-linear-gradient';
import colors from '@styles/colors';
import { SpView } from '@atoms/SpView';
import { SpText } from '@atoms/SpText';
import HouseholdSVG from '@atoms/SvgIcons/HouseholdSVG';
import { CommonActions, NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from 'src/pages';

interface HouseholdHeaderProps {
  activePet?: PetModel;
  name: string;
  children: React.ReactNode;
  openPetsBottomSheet?: () => void;
}

export const HouseholdHeader = ({
  activePet,
  children,
  openPetsBottomSheet,
}: HouseholdHeaderProps) => {
  // const navigation = useNavigation();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();

  return (
    <SpView style={styles.headerContainer} testID="HouseholdHeader">
      <LinearGradient
        style={styles.gradientBlur}
        colors={['rgba(236, 237, 247, 0.01)', colors.secondary4.color]}
        start={{ x: 0.5, y: 0.01 }}
        end={{ x: 0.5, y: 1.13 }}
      />

      <View style={styles.headerIconsWrapper}>
        <TouchableOpacity
          onPress={() => {
            rootNavigation.navigate('DashboardNavigation' ) 
          }}
        >
          <FontAwesomeIcon
            color={colors.greyText.color}
            size={30}
            icon={faAngleLeft}
          />
        </TouchableOpacity>
      </View>
      <SpView style={styles.petInfo}>
        <HouseholdSVG />
      </SpView>

      <View style={styles.headerIconsWrapper} />
      <TouchableOpacity onPress={openPetsBottomSheet} style={styles.petInfo}>
        <SpText size="xl" style={{ marginTop: 2 }} fontFamily="Rubik_Medium">
          {activePet && activePet.name}
        </SpText>
      </TouchableOpacity>
      <SpView style={styles.content}>{children}</SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    height: Platform.OS === 'ios' ? 330 : 280,
    alignItems: 'center',
  },
  headerLabel: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: colors.placeholderGrey.color,
    fontSize: 18,
    textAlign: 'center',
    width: '100%',
  },
  headerIconsWrapper: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 25,
    marginTop: Platform.OS === 'ios' ? 70 : 20,
  },
  backButton: {
    flex: 1,
    height: '100%',
  },
  rightIcons: {
    flexDirection: 'row',
    gap: 19,
  },
  center: {
    alignItems: 'center',
  },
  gradientBlur: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  petInfo: {
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  content: {
    width: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: -33,
    zIndex: 9000,
  },
});
