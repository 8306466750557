import { PetGroupedStatistic } from '@models/ReportModel';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

const formatWeek = (start: number, end: number): string => {
  return `${format(start, 'dd')}-${format(end, 'dd')} ${format(end, 'LLL')}`;
};

const formatMonth = (date: number): string => {
  return format(date, 'LLLL');
};

export const useLineGraphTrendLabels = (data: PetGroupedStatistic<unknown>) => {
  const weekLastLabels = useMemo(() => {
    return [
      'Last Week Avg',
      formatWeek(data.previousWeekRange.start, data.previousWeekRange.end),
    ];
  }, [data.previousWeekRange]);

  const weekThisLabels = useMemo(() => {
    return [
      'This Week Avg',
      formatWeek(data.currentWeekRange.start, data.currentWeekRange.end),
    ];
  }, [data.currentWeekRange]);

  const monthLastLabels = useMemo(() => {
    return ['Last Month Avg', formatMonth(data.previousMonthRange.start)];
  }, [data.previousMonthRange]);

  const monthThisLabels = useMemo(() => {
    return ['This Month Avg', formatMonth(data.currentWeekRange.start)];
  }, [data.currentWeekRange]);

  return {
    weekLastLabels,
    weekThisLabels,
    monthLastLabels,
    monthThisLabels,
  };
};
