import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useUserHasAdminAccess = (): boolean => {
  const { userHasAdminAccess, activeHousehold } = useBoundStore(
    s => s.householdStore,
  );

  return useMemo(() => {
    return userHasAdminAccess();
  }, [userHasAdminAccess, activeHousehold]);
};
