import React from 'react';
import NotificationSVG from '@atoms/SvgIcons/NotificationSVG';
import { SpRoundIcon } from './SpRoundIcon';

interface SpNotificationsIconProps {
  action: any;
}
export const SpNotificationsIcon = ({ action }: SpNotificationsIconProps) => (
  <SpRoundIcon
    color="transparent"
    icon={<NotificationSVG width={30} height={30} viewBox="0 0 23 25" />}
    action={action}
    componentName="SpNotificationsIcon"
  />
);
