import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import {
  NoMedicalConditionId,
  NoMedicalConditionOption,
} from '@constants/MedicalConditions';
import { ConditionModel } from '@models/Condition';
import { PetConditionModel } from '@models/PetCondition';
import SpSearchList from '@molecules/SpSearchList';
import colors from '@styles/colors';
import * as React from 'react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';

interface PetProfileMedicalSelectConditionsProps {
  conditions: ConditionModel[];
  petConditions: PetConditionModel[];
  loading: boolean;
  onChange: (data: number[]) => void;
  extraButtonMargin?: number;
  setSelected?: Dispatch<SetStateAction<any[]>>;
  selected?: any[];
}

export const PetProfileMedicalSelectConditions = ({
  conditions,
  petConditions,
  loading,
  onChange,
  extraButtonMargin,
  setSelected,
  selected,
}: PetProfileMedicalSelectConditionsProps) => {
  const { t } = useTranslation();

  const selectConditionAction = (items: number[]) => {
    if (
      (items.includes(NoMedicalConditionId) &&
        !selected.includes(NoMedicalConditionId)) ||
      items.length === 0
    ) {
      setSelected([NoMedicalConditionId]);
      return;
    }

    if (selected.includes(NoMedicalConditionId) && items.length > 1) {
      setSelected(items.filter(item => item !== NoMedicalConditionId));
      return;
    }

    setSelected(items);
  };

  useEffect(() => {
    setSelected(petConditions.map(({ id }) => id));
  }, [petConditions]);

  const noConditionsOption = conditions.shift();
  conditions.sort((a,b) => a.name.localeCompare(b.name))
  conditions.unshift(noConditionsOption);

  return (
    <CustomKeyboardAvoidingView disabled={Platform.OS === 'ios'}>
      <SafeAreaView style={styles.container}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <SpSearchList
            arr={conditions}
            selectedItems={selected}
            setItemId={selectConditionAction}
            hasInput
            withMulti
            multiEmptyPlaceholder={t('no_conditions')}
            placeholder={t('type_here')}
            headerText={t('no_conditions')}
            firstPlugText={t('no_conditions')}
            isMedical
          />
        </TouchableWithoutFeedback>
      </SafeAreaView>
    </CustomKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  button: {
    padding: 20,
  },
});
