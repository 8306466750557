import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useCurrentLanguageCode = (): string => {
  const user = useBoundStore(s => s.accountStore.user);
  const getLanguageByID = useBoundStore(s => s.languageStore.getLanguageByID);

  return useMemo(() => {
    return getLanguageByID(user.language_id)?.code || 'en';
  }, [user.language_id]);
};
