import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { PetModel } from '@models/Pet';
import { ReportType } from '../constants/ReportType';
import AveragePetStatusService, { PetData } from '../../../services/AveragePetStatusService';
import { ReportRangeType } from '../constants/ReportRangeType';

export function useReportDashboardCalculations(
  pet: PetModel,
  activeStatsArray: PetData,
  viewedDays: ReportRangeType,
  isMovement: boolean,
  currentDate: string | number | Date,
  type: ReportType,
) {
  const averagePetStatusService = new AveragePetStatusService();
  const { t } = useTranslation();

  const useCachedAmounts = () => {
    const amounts = useMemo(() => {
      const data = averagePetStatusService.countAvarageAmount(
        activeStatsArray,
        viewedDays,
        isMovement,
        new Date(currentDate),
      );

      const convertToString = (amount: number): string => {
        if (type !== ReportType.Movement) {
          return `${amount}`;
        }

        const days = Math.floor(
          (amount * 1000) / averagePetStatusService.daysInMiliseconds,
        );
        const hours = Math.floor((amount % (3600 * 24)) / 3600);
        const minutes = Math.floor((amount % 3600) / 60);

        return `${days ? days + t('day') : ''} ${
          hours ? hours + t('hours_unit_1') : ''
        } ${minutes ? minutes + t('minutes_unit_2') : ''} ${
          !days && !hours && !minutes ? amount + t('seconds_unit_1') : ''
        }`.trim();
      };

      return [convertToString(data[0]), convertToString(data[1])];
    }, [activeStatsArray, viewedDays, isMovement, currentDate, type]);
    return amounts;
    // return useCacheController(
    //   `dashboard_amounts_${pet.id}_${type}_${viewedDays}_${isMovement}_`,
    //   amounts,
    //   format(new Date(currentDate), 'dd/MM/yyyy'),
    //   activeStatsArray,
    // );
  };

  const useCachedDrinkingAndFeedingStats = (amountsCache: [string, string]) => {
    const drinkingAndFeedingStats = useMemo(() => {
      const curr = +amountsCache[0];
      const prev = +amountsCache[1];
      const isIncreasing = curr > prev;

      let percentage =
        Math.floor(100 - (isIncreasing ? prev / curr : curr / prev) * 100) || 0;

      if (percentage < 0) {
        percentage = Math.abs(percentage);
      }
      if (percentage !== 0 && !percentage) {
        percentage = 0;
      }
      if (percentage > 100) {
        percentage = 100;
      }

      return {
        isIncreasing,
        percentage,
        isNormal: percentage < 10 || isIncreasing,
      };
    }, [amountsCache]);

    const cacheKey = `dashboard_drinkingAndFeedingStats_${pet.id}_${type}_${viewedDays}_${isMovement}_`;
    const cacheDate = format(new Date(currentDate), 'dd/MM/yyyy');
    return drinkingAndFeedingStats;
    // return useCacheController(
    //   cacheKey,
    //   drinkingAndFeedingStats,
    //   cacheDate,
    //   amountsCache,
    // );
  };
  const useCachedLength = () => {
    const length = useMemo(() => {
      console.log('ran length calcs');
      return averagePetStatusService.countAvgTime(
        activeStatsArray,
        viewedDays,
        new Date(currentDate),
        isMovement,
      );
    }, [activeStatsArray, viewedDays, currentDate, isMovement]);

    const cacheKey = `dashboard_length_${pet.id}_${type}_${viewedDays}_${isMovement}_`;
    const cacheDate = format(new Date(currentDate), 'dd/MM/yyyy');
    return length;
    // return useCacheController(cacheKey, length, cacheDate, activeStatsArray);
  };

  const useCachedVisits = () => {
    console.log('ran visits calcs');
    const visits = useMemo(() => averagePetStatusService.countAvgVisits(
        activeStatsArray,
        viewedDays,
        new Date(currentDate),
        isMovement,
      )
      , [activeStatsArray, viewedDays, currentDate, isMovement]);

    const cacheKey = `dashboard_visits_${pet.id}_${type}_${viewedDays}_${isMovement}_`;
    const cacheDate = format(new Date(currentDate), 'dd/MM/yyyy');
    return visits;
    // return useCacheController(cacheKey, visits, cacheDate, activeStatsArray);
  };

  return {
    useCachedAmounts,
    useCachedDrinkingAndFeedingStats,
    useCachedLength,
    useCachedVisits,
  };
}
