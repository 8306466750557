import React, { useMemo } from 'react';

export const getLineGraphTrendPoints = (
  current: { totalActivity: number }[],
  last: { totalActivity: number }[],
) => {
  return {
    last: last.map((item, i) => ({
      x: i + 1,
      y: item.totalActivity,
    })),
    current: current.map((item, i) => ({
      x: i + 1,
      y: item.totalActivity,
    })),
  };
};

export function useLineGraphTrendPoints(
  current: { totalActivity: number }[],
  last: { totalActivity: number }[],
): { current: { x: number; y: number }[]; last: { x: number; y: number }[] } {
  return useMemo(() => {
    return getLineGraphTrendPoints(current, last);
  }, [current, last]);
}
