import { useEffect, useState } from 'react';
import DeviceApi from '@api/DeviceApi';
import { DeviceModel } from '@models/Device';

const useDeviceStatusHook = (deviceId: DeviceModel['id'], active = false) => {
  const [data, setData] = useState<DeviceModel[]>([]);

  const fetchDeviceStatus = async () => {
    try {
      const response = await DeviceApi.getDeviceStatus(deviceId);
      if (!response) {
        setData([]);
      } else setData(response);
    } catch (e) {
      console.log('Device status ERROR =>', e);
    }
  };

  useEffect(() => {
    if (!active) return () => console.log('No active');
    fetchDeviceStatus();
    const interval = setInterval(() => {
      fetchDeviceStatus();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return data;
};

export default useDeviceStatusHook;
