import { Platform, StyleSheet, View } from 'react-native';
import React, { useMemo, useState } from 'react';
import { PetBioMenu } from '@constants/Menus';
import PetBioCommentWrapper from 'src/pages/PetProfile/molecules/PetBioCommentWrapper';
import PetBioBreedWrapper from 'src/pages/PetProfile/molecules/PetBioBreedWrapper';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import useBoundStore from 'src/store/store';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import { PetProfileMedicalSelectConditions } from '../../../PetProfile/molecules/PetProfileMedicalSelectConditions';
import { FlowStackParamList } from '../../index';

type Props = NativeStackScreenProps<
  FlowStackParamList,
  'CreatePetProfileDetails'
>;

const CreatePetProfileDetails = ({ route }: Props) => {
  const [value, setValue] = useState(route.params.inputValue);
  const conditions = useBoundStore(state => state.conditionStore.data);
  const [selected, setSelected] = useState([]);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const input = useMemo(() => {
    return (
      <View style={{ flex: 1 }}>
        {route.params.inputName === PetBioMenu.Comments && (
          <PetBioCommentWrapper
            value={value}
            label={route.params.label}
            handleChange={setValue}
          />
        )}
        {route.params.inputName === PetBioMenu.BreedID && (
          <PetBioBreedWrapper
            handleChange={setValue}
            selectedBreed={route.params.inputValue}
            type={PetBioMenu.BreedID}
            anotherTypeBreedValue={route.params.anotherTypeBreed}
            anotherSpecie={route.params.anotherSpecie}
          />
        )}
        {route.params.inputName === PetBioMenu.BreedID2 && (
          <PetBioBreedWrapper
            handleChange={setValue}
            selectedBreed={route.params.inputValue}
            type={PetBioMenu.BreedID2}
            anotherTypeBreedValue={route.params.anotherTypeBreed}
            anotherSpecie={route.params.anotherSpecie}
          />
        )}
        {route.params.inputName === 'Conditions' && (
          <PetProfileMedicalSelectConditions
            conditions={conditions}
            petConditions={value}
            extraButtonMargin={Platform.OS === 'android' ? 20 : undefined}
            loading={false}
            setSelected={setSelected}
            selected={selected}
            onChange={cond => {
              route.params.handleChange(cond);
              navigation.goBack();
            }}
          />
        )}
      </View>
    );
  }, [route, setValue, value, conditions, selected]);

  return (
    <View style={{ flex: 1 }}>
      <CustomKeyboardAvoidingView
        androidAdjustType="force"
        disabled={
          Platform.OS === 'ios' ||
          route.params.inputName !== PetBioMenu.Comments
        }
      >
        {input}
      </CustomKeyboardAvoidingView>

      <View style={[styles.buttonWrapper]}>
        <SpRoundedHeaderButton
          disabled={

            (value === undefined &&
              route.params.inputName !== PetBioMenu.Comments) ||
            // Breed 2 is allowed to be null so it can be cleared
            (value === null &&
              route.params.inputName !== PetBioMenu.BreedID2 &&
              route.params.inputName !== PetBioMenu.Comments) ||

            (route.params.inputName === 'Conditions' && selected.length === 0)
          }
          backgroundColor={colors.primary.color}
          h={56}
          title={t('save')}
          onPress={() => {
            route.params.handleChange(
              route.params.inputName === 'Conditions' ? selected : value,
            );
            navigation.goBack();
          }}
        />
      </View>
    </View>
  );
};

export default CreatePetProfileDetails;

const styles = StyleSheet.create({
  buttonWrapper: {
    position: 'absolute',
    bottom: 68,
    left: 19,
    right: 18,
  },
  shadowWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
  },
});
