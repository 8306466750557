import { PetDoorLockingMode } from '@constants/Device';
import { DeviceCatFlapCurfew, DeviceModel, DevicePetDoorCurfew } from '@models/Device';
import { get } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToastEffect from '@hooks/useToastEffect';
import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';

export const usePetDoorCatFlapCard = (data: DeviceModel) => {
  const { t } = useTranslation();
  const loadingControl = useBoundStore(
    state => state.deviceStore.loadingControl,
  );
  const {
    loadingDevice,
    updateCurfewError,
    updateCurfewRequest,
    updateCurfewSuccess,
    updateLockUnlockError,
    updateLockUnlockRequest,
    updateLockUnlockSuccess,
    updateProperty,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateCurfewRequest: deviceStore.updateCurfewRequest,
      updateLockUnlockRequest: deviceStore.updateLockUnlockRequest,
      updateCurfewError: deviceStore.updateCurfewError,
      updateCurfewSuccess: deviceStore.updateCurfewSuccess,
      updateLockUnlockError: deviceStore.updateLockUnlockError,
      updateLockUnlockSuccess: deviceStore.updateLockUnlockSuccess,
      updateProperty: deviceStore.updateProperty,
    };
  });
  const [error, setError] = useState('');

  const onChangeLockUnlock = (locking: PetDoorLockingMode) => {
    updateLockUnlockRequest(data.id, { locking });
  };

  const onChangeCurfew = useCallback(
    (curfew: DevicePetDoorCurfew | DeviceCatFlapCurfew[]) => {
      const slicedArray = curfew.length > 4 ? curfew.slice(0, 4) : curfew;
      updateCurfewRequest(data.id, { curfew: slicedArray });
    },
    [data, updateCurfewRequest],
  );

  useToastEffect({
    resetter: () => {
      updateProperty({ updateCurfewSuccess: null, updateCurfewError: null });
    },
    successCondition: !loadingControl.curfew && !!updateCurfewSuccess,
    errorCondition: !!updateCurfewError,
    successToastMessage: t('success_update_curfew'),
    errorToastMessage: t('error_update_curfew'),
  });

  useToastEffect({
    resetter: () => {
      updateProperty({
        updateLockUnlockError: null,
        updateLockUnlockSuccess: null,
      });
    },
    successCondition: updateLockUnlockSuccess,
    errorCondition: updateLockUnlockError,
    successToastMessage: t('success_lock_unlock_pets'),
    errorToastMessage: t('error_lock_unlock_pets'),
  });

  useEffect(() => {
    // @ts-ignore
    if (updateCurfewError && updateCurfewError.status !== 400) {
      setError(
        get(data, 'error.error.currentCurfew.lock_time[0]') ||
          get(data, 'error.error.currentCurfew.unlock_time[0]') ||
          t('sorry_try_again'),
      );
    }
  }, [updateCurfewError, updateCurfewSuccess]);

  return {
    loadingDevice,
    onChangeLockUnlock,
    onChangeCurfew,
    error,
  };
};
