import { PetBowlFoodType } from '@constants/Device';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useFoodTypeTranslation = () => {
  const { t } = useTranslation();
  const language = useCurrentLanguageCode();

  const titleCaseWord = (word: string): string => {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  };

  return useCallback(
    (type: PetBowlFoodType) => {
      const wet = t('wet');
      const dry = t('dry');
      switch (type) {
        case PetBowlFoodType.wet:
          return language === 'de' ? titleCaseWord(wet) : wet.toLowerCase();
        case PetBowlFoodType.dry:
          return language === 'de' ? titleCaseWord(dry) : dry.toLowerCase();
      }

      return '';
    },
    [language],
  );
};
