import { StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import SpSentIcon from '@atoms/RoundIcons/SpSentIcon';
import { SpText } from '@atoms/SpText';
import LoadingSpinner from '@atoms/Loader';
import LoaderCircleLine from '@atoms/LoaderCircleLine';
import { SpCenter } from '@atoms/SpCenter';
import SpTimesIcon from '@atoms/RoundIcons/SpTimesIcon';
import { testProperties } from '@utils/testProperties';

interface ILoadingComponentProps {
  searchMsg?: string;
  isConnected?: boolean;
  connectedMsg?: string;
  direction?: 'row' | 'column';
  type?: 'default' | 'circleLine' | 'insideCircle' | 'insideCircleLine';
  error?: boolean;
  centralizedText?: boolean;
}

const LoadingComponent = ({
  searchMsg,
  isConnected,
  connectedMsg,
  direction = 'column',
  type = 'default',
  error,
  centralizedText = false,
}: ILoadingComponentProps) => {
  const text = useMemo(() => {
    return (
      <SpText
        style={[
          styles.loaderMsg,
          direction === 'column' ? styles.columnOffset : styles.rowOffset,
          centralizedText ? styles.center : null,
        ]}
        {...testProperties('SuccessProduct', 'Text')}
      >
        {isConnected ? connectedMsg : searchMsg}
      </SpText>
    );
  }, [isConnected, searchMsg, connectedMsg, direction]);
  return (
    <View style={{ flexDirection: direction, alignItems: 'center' }}>
      {isConnected ? (
        <>
          <View {...testProperties('SuccessProduct', 'Image')}>
            {error ? <SpTimesIcon /> : <SpSentIcon />}
          </View>
          {text}
        </>
      ) : (
        <>
          {type === 'default' && <LoadingSpinner />}
          {type === 'circleLine' && (
            <LoaderCircleLine backgroundColor="#dddfe0" />
          )}
          {type === 'insideCircleLine' && (
            <SpCenter style={styles.circle}>
              <LoaderCircleLine backgroundColor="#dddfe0" />
            </SpCenter>
          )}
          {type === 'insideCircle' && (
            <SpCenter style={styles.circle}>
              <LoadingSpinner />
            </SpCenter>
          )}
          {text}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loaderMsg: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
  },
  columnOffset: {
    marginTop: 16,
  },
  rowOffset: {
    marginLeft: 12,
  },
  circle: {
    width: 56,
    height: 56,
    borderRadius: 28,
    backgroundColor: '#dddfe0',
  },
  center: {
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  }
});

export default LoadingComponent;
