import { ProductModel } from '@models/Product';
import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useDeviceProductById = (id: number): ProductModel => {
  const products = useBoundStore(s => s.productStore.data);

  return useMemo(() => {
    return products.find(item => item.id === id) || null;
  }, [products, id]);
};
