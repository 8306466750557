import { SpView } from '@atoms/SpView';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { useCustomToast } from '@hooks/useToast';
import ResetPasswordForm from '@molecules/ResetPasswordForm';
import colors from '@styles/colors';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { navigationRef } from '../../RootNavigation';
import { ReactSetState } from './WhatIsChanging';

interface ResetPasswordProps {
  setStep: ReactSetState<number>;
}

const ResetPassword = ({ setStep }: ResetPasswordProps) => {
  const toast = useCustomToast();
  const { t } = useTranslation();

  const submitSuccessAction = () => {
    const navObj = {
      parentNavigatorName: 'LoginNavigation',
      screenName: 'Login',
      makeLogout: true,
    };
    navigationRef.navigate('SuccessScreen', {
      title: t('email_sent'),
      msg: t('email_sent_msg'),
      withButton: true,
      withHeader: true,
      buttonText: t('continue'),
      buttonNavObject: navObj,
      headerNavObject: navObj,
    });
  };

  const submitErrorAction = useCallback(() => {
    toast.show({
      description: t('email_address_error'),
      isError: true,
    });
  }, [t]);

  return (
    <SpView style={styles.gap}>
      <TouchableOpacity style={styles.cross} onPress={() => setStep(3)}>
        <FontAwesomeIcon
          icon={faXmark}
          size={24}
          color={colors.greyText.color}
        />
      </TouchableOpacity>
      <SpView paddingBottom={10} flex={1}>
        <ResetPasswordForm
          onSubmitSuccess={submitSuccessAction}
          onSubmitError={submitErrorAction}
        />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  gap: {
    paddingTop: 10,
    flex: 1,
  },
  cross: {
    marginBottom: 18,
  },
});

export default ResetPassword;
