import { PetBowlFoodType, SubstanceType } from '@constants/Device';
import { DeviceCerberusControlModel } from '@models/Device';
import { EditMenuItem } from '@models/Menu';
import i18n from '@utils/i18n';

export const getActions = (
  data: DeviceCerberusControlModel,
  changeFood: ($event: DeviceCerberusControlModel, isLeft: boolean) => void,
  changeSubstance: ($event: SubstanceType, isFood: boolean) => void,
): EditMenuItem[] => {
  let action = [
    {
      label: i18n.t('substance_type'),
      value: i18n.t(SubstanceType[data?.substance_type]),
      action: () => changeSubstance(data?.substance_type, true),
    },
  ];
  if (data.substance_type === SubstanceType.food) {
    action = [
      ...action,
      {
        label: i18n.t('food_type'),
        value: i18n.t(PetBowlFoodType[data?.food_type]),
        action: () => changeFood(data, true),
      },
    ];
  }

  return action;
};
