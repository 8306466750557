import qs from 'qs';
import { HouseholdModel } from '../models/Household';
import Http from './Http';

class HouseholdApi {
  static readonly httpParams = {
    with: [
      'invites',
      'invites.creating',
      'invites.creating.profilePhoto',
      'invites.accepting',
      'invites.accepting.profilePhoto',
      'pets',
      'users',
      'users.user',
      'users.user.profilePhoto',
      'users.households',
      'timezone',
    ],
  };

  static getHouseholds(): Promise<HouseholdModel[]> {
    return Http.get(`/api/household`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static createHousehold(household: HouseholdModel): Promise<HouseholdModel[]> {
    return Http.post(`/api/household`, household, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static updateHousehold(household: HouseholdModel): Promise<HouseholdModel[]> {
    return Http.put(`/api/household/${household.id}`, household, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default HouseholdApi;
