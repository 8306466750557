import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { MappedPoseidonReportDataModel } from '@models/ReportModel';
import { differenceInSeconds, parseISO } from 'date-fns';
import React, { useMemo } from 'react';

export function useTransformDrinkingEventToReport(
  data: MappedPoseidonReportDataModel
) {
  const userTimeFormat = useUserTimeUnits();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertSecondToFormat, timeToFormat, convertDateToTime } =
    useTimeTransform();
  const { convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode
  );
  return useMemo(() => {
    const lengthInSeconds = differenceInSeconds(
      parseISO(data.to.toString()).getTime(),
      parseISO(data.from.toString()).getTime()
    );
    const duration = convertSecondToFormat(lengthInSeconds);
    const time = timeToFormat(convertDateToTime(data.from), userTimeFormat);
    const weight = convertWithUnits(
      Math.round(Math.abs(data.weights[0].change)) || 0
    );

    return {
      time,
      duration: duration.unit === 'TIME_S_UNIT' ? `00:${duration.time}` : `${duration.time}`,
      amount: weight,
    };
  }, [data, convertSecondToFormat, userTimeFormat]);
}
