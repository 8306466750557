export enum PetGender {
  male = 1,
  female = 0,
}

export enum PetPosition {
  none,
  inside,
  outside,
}

export enum PetSpecie {
  cat = 1,
  dog = 2,
}

export enum PetSpayedOptions {
  yes = 1,
  no = 2,
  not_sure = 3,
}

export enum PetDietOptions {
  wet_food_only,
  dry_food_only,
  wet_and_dry_food,
}

export enum PetContext {
  FeedingDrinking = 1,
  FillBowl = 5,
}
