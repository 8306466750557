import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpView } from '@atoms/SpView';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { PropsWithChildren } from 'react';

import { Pressable, StyleSheet, ViewStyle } from 'react-native';

interface LayoutFooterProps {
  withBackButton?: boolean;
  nextButtonText: string;
  isLoading?: boolean;
  disabled?: boolean;
  onNext: () => void;
  extraContainerStyle?: ViewStyle;
}

export const LayoutFooter = ({
  withBackButton = false,
  isLoading = false,
  disabled = false,
  nextButtonText,
  onNext,
  extraContainerStyle = {},
}: PropsWithChildren<LayoutFooterProps>) => {
  const navigation = useNavigation();

  return (
    <SpView style={[styles.buttons, extraContainerStyle]}>
      {withBackButton && (
        <Pressable
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <FontAwesomeIcon
            color={colors.greyText.color}
            size={28}
            icon={faChevronLeft}
          />
        </Pressable>
      )}
      <SpView flex={1} paddingBottom={34}>
        <SpRoundedHeaderButton
          h={56}
          disabled={disabled}
          isLoading={isLoading}
          title={nextButtonText}
          backgroundColor={colors.primary.color}
          onPress={onNext}
        />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    paddingHorizontal: 19,
  },
  backButton: {
    width: 56,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 14,
    borderColor: '#919ba0',
    marginRight: 24,
  },
});
