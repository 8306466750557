import { DeviceModel } from '@models/Device';
import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useDeviceById = (id: number): DeviceModel => {
  const devices = useBoundStore(s => s.deviceStore.deviceData);

  return useMemo(() => {
    return devices.find(item => item.id === id) || null;
  }, [devices, id]);
};
