export const FullDateFormat = 'yyyy-MM-dd HH:mm:ss';
export const WithoutTimeDateFormat = 'yyyy-MM-dd';
export const DayMonthFormat = 'd MMM';
export const DayFullMonthFormat = 'd MMMM';
export const DayWeekMonthFormat = 'EEE d MMMM';
export const DayWeekMonthShortFormat = 'EEE d MMM';

export enum TimeUnits {
  ISO,
  AMPM,
}
