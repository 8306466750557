import { useEffect, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';

export const useIsLogoutRunning = () => {
  const [running, setRunning] = useState(false);

  useEffect(() => {
    DeviceEventEmitter.addListener('isLogoutRunning', data => setRunning(data));
  }, []);

  return running;
};
