import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';
import colors from '@styles/colors';
import { SpDivider } from '@atoms/SpDivider';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from 'src/pages';

type CDBHelpFailedPairing = {};

const CDBHelpFailedPairing: React.FC<CDBHelpFailedPairing> = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const handleNavigation = (uri: string) => {
    return () => {
      navigation.navigate('WebviewScreen', {
        uri: `https://${uri}`,
        title: 'Support',
      });
    };
  };
  return (
    <SpView style={styles.container}>
    <SpText color="#fff" style={styles.question}>
    Tips for pairing your device:
    </SpText>
    <SpView>
      <SpText color="#fff" bold={true}  style={styles.title}>
      Bowl placement
      </SpText>
      <SpText color="#fff"  style={styles.paragraphDesc}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pulvinar venenatis dignissim.
      </SpText>
    </SpView>
    <SpView>
      <SpText color="#fff" bold style={styles.title}>
      Wifi connection
      </SpText>
      <SpText color="#fff" style={styles.paragraphDesc}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pulvinar venenatis dignissim.
      </SpText>
    </SpView>
    <SpView>
      <SpText color="#fff" bold style={styles.title}>
      Router type
      </SpText>
      <SpText color="#fff" size="sm" style={styles.paragraphDesc}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pulvinar venenatis dignissim.
      </SpText>
    </SpView>
    <SpDivider />
      <SpText color="#fff" size="sm" style={styles.paragraph2}>
        {t('setup_cdb_pairing_paragraph_2')}
      </SpText>
      <TouchableOpacity onPress={handleNavigation(t('setup_cdb_battery_link'))}>
        <SpText color="#fff" bold style={styles.link}>
          {t('setup_cdb_battery_link')}
        </SpText>
      </TouchableOpacity>

  </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.helpScreenColor.color,
    color: 'white',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  paragraph: {
    color: 'white',
    fontSize: 16,
    marginTop: 15,
  },
  paragraph2: {
    color: 'white',
    fontSize: 16,
    marginTop: 55,
  },
  title: {
    color: 'white',
    fontStyle: 'bold',
    fontSize: 18,
    marginTop: 20,
    marginBottom: 5,
  },
  paragraphDesc: {
    color: 'white',
    fontSize: 14,
  },
  link: {
    color: 'white',
    marginTop: 25,
    fontWeight: 'bold',
    fontSize: 16,
  },
  question: {
    fontSize: 20,
  },
});
export default CDBHelpFailedPairing;
