import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useCDBInHousehold } from '@hooks/useCDBInHousehold';
import { useClickOutside } from '@hooks/useClickOutside';
import useTour from '@hooks/useTour';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel } from '@models/ReportModel';
import { useIsFocused } from '@react-navigation/native';
import colors from '@styles/colors';
import * as d3 from 'd3';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import { G, Rect, Svg, Text } from 'react-native-svg';

import { GraphTooltip } from './GraphTooltip';
import AmountEatenGraphsSlide from '../../../Tour/components/AmountEatenGraphsSlide/AmountEatenGraphsSlide';
import { ReportRangeType } from '../../constants/ReportRangeType';
import { ReportType } from '../../constants/ReportType';
import { useGroupedConsumptionReportStats } from '../../hooks/useGroupedConsumptionReportStats';
import { useSummaryReportGraphData } from '../../hooks/useSummaryReportGraphData';
import useTickFormatting from '../../hooks/useTickFormatting';

const { width } = Dimensions.get('window');

interface FeedingGraphProps {
  rangeType: ReportRangeType;
  currentDate: number;
  stats: MappedReportModel;
  pet: PetModel;
  devices: DeviceModel[];
}

export const FeedingGraph = ({
  rangeType,
  currentDate,
  stats,
  pet,
  devices,
}: FeedingGraphProps) => {
  const { t } = useTranslation();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const [activeBar, setActiveBar] = useState<number>(null);
  const clickOutsideRef = useClickOutside(() => {
    setActiveBar(null);
  });
  const [isThereCDBBothFood, setIsThereCDBBothFood] = useState<boolean>(false);
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const { calculateBarOpacity } = useTickFormatting(rangeType);

  const cdbDevices = useCDBInHousehold();
  const isFocused = useIsFocused();

  useEffect(() => {
    setIsThereCDBBothFood(!!cdbDevices?.length);
  }, [cdbDevices]);

  const initTour = useTour({
    uniqueId: 'v0-graph-eaten',
    components: [<AmountEatenGraphsSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const data = useGroupedConsumptionReportStats(
    stats?.feeding || [],
    pet,
    devices,
    currentDate,
    rangeType,
    ReportType.Feeding,
  );
  const yValues = useMemo(() => data.map((item) => item.totalWeight), [data]);
  const xValues = useMemo(() => data.map((item) => item.date), [data]);
  const {
    xAxisLabels,
    yAxisLabels,
    barWidth,
    barGap,
    chartHeight,
    margin,
    height,
    barRadius,
    xAxisLabelStyle,
    yAxisLabelStyle,
    chartWidth,
  } = useSummaryReportGraphData(width, rangeType, xValues, yValues, 0.44, {
    top: 140,
    right: 12,
    bottom: 28,
    left: 12,
  });

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(yValues)])
    .range([chartHeight, 0]);

  const tooltipInfo = useMemo(() => {
    if (activeBar !== null) {
      const eventByIndex = data[activeBar];
      const { totalDryWeight, totalWetWeight, totalBothWeight } = eventByIndex;
      const foods = {
        dry: totalDryWeight || 0,
        wet: totalWetWeight || 0,
        both: totalBothWeight || 0,
      };
      const y = foods.dry + foods.wet + foods.both;
      const isMultiple = Object.values(foods).filter(Boolean).length > 1;

      let text = null;

      if (isMultiple) {
        text = (
          <SpView>
            <SpView flexDirection="row" marginBottom={4} justifyContent="space-between">
              <SpText style={styles.tooltipText}>{t('dry')}</SpText>
              <SpText style={styles.tooltipText}>
                {' '}
                {convertWithUnits(eventByIndex.totalDryWeight, true)}
              </SpText>
            </SpView>
            <SpView flexDirection="row" justifyContent="space-between">
              <SpText style={styles.tooltipText}>{t('wet')}</SpText>
              <SpText style={styles.tooltipText}>
                {' '}
                {convertWithUnits(eventByIndex.totalWetWeight, true)}
              </SpText>
            </SpView>
            {isThereCDBBothFood && (
              <SpView flexDirection="row" justifyContent="space-between">
                <SpText style={styles.tooltipText}>{t('both')}</SpText>
                <SpText style={styles.tooltipText}>
                  {' '}
                  {convertWithUnits(eventByIndex.totalBothWeight, true)}
                </SpText>
              </SpView>
            )}
          </SpView>
        );
      } else {
        let foodTypeTranslationKey = null;

        if (eventByIndex.totalDryWeight) {
          foodTypeTranslationKey = 'dry';
        } else if (eventByIndex.totalWetWeight) {
          foodTypeTranslationKey = 'wet';
        } else {
          foodTypeTranslationKey = 'both';
        }

        text = (
          <SpView flexDirection="row" justifyContent="space-between">
            <SpText style={styles.tooltipText}>{t(foodTypeTranslationKey)}</SpText>
            <SpText style={styles.tooltipText}>
              {' '}
              {convertWithUnits(
                eventByIndex.totalWetWeight ||
                  eventByIndex.totalDryWeight ||
                  eventByIndex.totalBothWeight,
                true,
              )}
            </SpText>
          </SpView>
        );
      }

      return {
        y: isMultiple ? yScale(y) + 86 : yScale(y) + 104,
        text,
      };
    }

    return null;
  }, [activeBar, rangeType]);

  return (
    <SpView>
      <TouchableOpacity
        style={styles.graphContainer}
        activeOpacity={1}
        ref={clickOutsideRef}
        onPress={() => setActiveBar(null)}>
        {tooltipInfo ? (
          <GraphTooltip
            top={tooltipInfo.y}
            calcLeftProps={{
              index: activeBar,
              barWidth,
              barGap,
              marginLeft: margin.left,
              marginRight: margin.right,
              chartWidth,
              rangeType,
            }}>
            {tooltipInfo.text}
          </GraphTooltip>
        ) : null}
        <Svg width={width} height={height}>
          <G transform={`translate(${margin.left},${margin.top})`}>
            {/* Draw bars dry */}
            {data.map((item, index) => {
              let dryHeight = item.totalDryWeight
                ? item.totalDryWeight + item.totalWetWeight + item.totalBothWeight
                : 0;
              const wetHeight = item.totalWetWeight
                  ? item.totalWetWeight + item.totalBothWeight
                  : 0;
              if(item.totalDryWeight && item.totalWetWeight && dryHeight > wetHeight && Math.abs(dryHeight - wetHeight) <= 15){
                dryHeight += 20
              }
              return (
                <Rect
                  rx={item.totalDryWeight ? barRadius : 0}
                  key={`bars_dry_${item.date}`}
                  x={index * (barWidth + barGap)}
                  y={yScale(dryHeight)}
                  width={barWidth}
                  height={dryHeight ? chartHeight - yScale(dryHeight) + barRadius : 0}
                  fill={activeBar === index ? colors.lightBlack.color : colors.green.color}
                  opacity={calculateBarOpacity(index)}
                  onPress={() => setActiveBar(index)}
                />
              );
            })}

            {/* Draw bars wet */}
            {data.map((item, index) => {
              const wetHeight = item.totalWetWeight
                ? item.totalWetWeight + item.totalBothWeight
                : 0;
              return (
                <Rect
                  rx={item.totalWetWeight && !item.totalDryWeight ? barRadius : 0}
                  key={`bars_wet_${item.date}${index}`}
                  x={index * (barWidth + barGap)}
                  y={yScale(wetHeight)}
                  width={barWidth}
                  height={wetHeight ? chartHeight - yScale(wetHeight) + barRadius : 0}
                  fill={activeBar === index ? colors.lightBlack.color : colors.smallGreen.color}
                  opacity={calculateBarOpacity(index)}
                  onPress={() => setActiveBar(index)}
                />
              );
            })}

            {/* Draw bars both */}
            {isThereCDBBothFood &&
              data.map((item, index) => (
                <Rect
                  rx={
                    item.totalBothWeight && !item.totalWetWeight && !item.totalDryWeight
                      ? barRadius
                      : 0
                  }
                  key={`bars_both_${item.date}${index}`}
                  x={index * (barWidth + barGap)}
                  y={yScale(item.totalBothWeight)}
                  width={barWidth}
                  height={
                    item.totalBothWeight
                      ? chartHeight - yScale(item.totalBothWeight) + barRadius
                      : 0
                  }
                  fill={activeBar === index ? colors.lightBlack.color : colors.pastelGreen.color}
                  opacity={calculateBarOpacity(index)}
                  onPress={() => setActiveBar(index)}
                />
              ))}

            <Rect
              x={-margin.left}
              y={chartHeight}
              width={width}
              height={margin.bottom}
              fill="white"
            />

            {/* Draw x-axis labels */}
            {(xAxisLabels || []).map((item, index) => {
              return (
                <Text
                  key={`x-axis_labels_${item}_${index}`}
                  x={index * (barWidth + barGap) + barWidth / 2}
                  y={chartHeight + xAxisLabelStyle.offsetTop}
                  fontSize={xAxisLabelStyle.fontSize}
                  textAnchor="middle"
                  fill={xAxisLabelStyle.color}>
                  {item}
                </Text>
              );
            })}

            {/* Draw y-axis labels */}
            {yAxisLabels.map((tick, index) => (
              <React.Fragment key={tick + index}>
                <Rect
                  x={yAxisLabelStyle.offsetLeft - 5}
                  y={yScale(tick) - yAxisLabelStyle.fontSize / 2}
                  width={50}
                  height={yAxisLabelStyle.fontSize + 6}
                  fill="white"
                  opacity={0.5}
                  rx={6}
                  ry={6}
                />
                <Text
                  x={yAxisLabelStyle.offsetLeft}
                  y={yScale(tick)}
                  fontSize={yAxisLabelStyle.fontSize}
                  fill={yAxisLabelStyle.color}
                  textAnchor="start"
                  alignmentBaseline="center">
                  {convertWithUnits(tick, true)}
                </Text>
              </React.Fragment>
            ))}
          </G>
        </Svg>
      </TouchableOpacity>
      <SpView style={styles.legendWrap}>
        <SpView style={styles.legend}>
          <SpView style={styles.legendItem}>
            <SpView style={[styles.indicator, { backgroundColor: colors.green.color }]} />
            <SpText size="sm" fontFamily="Rubik_Medium">
              {t('dry')}
            </SpText>
          </SpView>
          <SpView style={styles.legendItem}>
            <SpView style={[styles.indicator, { backgroundColor: colors.smallGreen.color }]} />
            <SpText size="sm" fontFamily="Rubik_Medium">
              {t('wet')}
            </SpText>
          </SpView>
          {isThereCDBBothFood && (
            <SpView style={styles.legendItem}>
              <SpView style={[styles.indicator, { backgroundColor: colors.pastelGreen.color }]} />
              <SpText size="sm" fontFamily="Rubik_Medium">
                {t('both')}
              </SpText>
            </SpView>
          )}
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  graphContainer: {
    backgroundColor: 'rgba(45, 181, 142, 0.1)',
  },
  legendWrap: {
    alignItems: 'center',
    marginTop: 8,
  },
  legend: {
    backgroundColor: 'rgba(45, 181, 142, 0.1)',
    borderRadius: 16,
    paddingHorizontal: 26,
    paddingVertical: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicator: {
    width: 24,
    height: 8,
    borderRadius: 4,
    marginRight: 6,
  },
  tooltipText: {
    color: colors.white.color,
    fontSize: 14,
    lineHeight: 14,
  },
});
