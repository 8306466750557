import {
  DayWeekMonthFormat,
  DayWeekMonthShortFormat,
} from '@constants/DateFormat';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
} from '@models/ReportModel';
import { endOfDay, format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import { SpText } from '@atoms/SpText';
import { VisitsLineChart } from '@molecules/VisitsLineChart';
import { fillEmptyPoints } from '@utils/fillEmptyPoints';
import { useIsFocused } from '@react-navigation/native';
import useTour from '@hooks/useTour';
import { SpView } from '@atoms/SpView';
import { useGroupedConsumptionStats } from '../../hooks/useGroupedConsumptionStats';
import { useCurrentAndPreviousDays } from '../../hooks/useCurrentAndPreviousDays';
import { FeedingEventReport } from '../FeedingEventReport';
import { LineGraphModalWrapper } from './LineGraphModalWrapper';
import {
  ReportRangeLastName,
  ReportRangeType,
} from '../../constants/ReportRangeType';
import DateNavigation from '../DateNavigation';
import FeedingVisitsSlide from '../../../Tour/components/FeedingVisitsSlide/FeedingVisitsSlide';
import { PetAverageStatsFeeds } from './PetAverageStatsFeeds';
import useViewedDays from '../../hooks/useViewedDays';
import useBoundStore from "../../../../store/store";
import LoadingSpinner from "@atoms/Loader";
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */

/* eslint-disable react/no-unused-prop-types */
interface PetDashboardFeedingProps {
  data: GroupReportPointModel<MappedConsumptionReportDataModel>[];
  pet: PetModel;
  devices: DeviceModel[];
  setViewedReportDays: (value: number) => void;
  setViewedActiveDate: (value: number) => void;
  rangeType: ReportRangeType;
}

export const PetDashboardFeeding = ({
  data,
  pet,
  devices,
  setViewedReportDays,
  setViewedActiveDate,
  rangeType,
}: PetDashboardFeedingProps) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(1);
  const [activeDate, setActiveDate] = useState(endOfDay(new Date()).getTime());
  const [viewedDays, setViewedDays] = useViewedDays(
    rangeType,
    setViewedReportDays,
  );
  const isFocused = useIsFocused();
  const { loading } = useBoundStore(s => s.reportStore);

  const initTour = useTour({
    uniqueId: 'v0-graph-feeding-dashboard',
    components: [<FeedingVisitsSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const activityDetails =
    useCurrentAndPreviousDays<MappedConsumptionReportDataModel>(
      activeDate,
      data,
      viewedDays,
    );

  const convertedActivityDetails = useMemo(
    () => fillEmptyPoints(activityDetails.rangeData, viewedDays, activeDate),
    [activityDetails, viewedDays, activeDate],
  );

  const stats = useGroupedConsumptionStats(
    data,
    pet,
    devices,
    activityDetails?.currentDay?.date,
    false,
  );

  const pointDetailsRenderItem = useCallback(
    ($event: { item: MappedConsumptionReportDataModel }) => {
      return <FeedingEventReport data={$event.item} />;
    },
    [],
  );

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: GroupReportPointModel<MappedConsumptionReportDataModel>;
      index: number;
    }) => {
      if (!item) {
        return;
      }
      const isActive = index + 1 === activeItem;
      const isVisibleLeftTitle = () => {
        if (viewedDays === 7) {
          return format(item?.date, DayWeekMonthShortFormat);
        }
        if (viewedDays === 28) {
          if (index === 0 || isActive || index % 7 === 0) {
            return format(item?.date, DayWeekMonthShortFormat);
          }
          return null;
        }
      };
      return (
        <VisitsLineChart
          isActive={item.points.length && isActive ? true : false}
          setActive={() => {
            item.points.length && setActiveItem(index + 1);
          }}
          lowState={viewedDays === 28}
          leftTitle={isVisibleLeftTitle()}
          rightTitle={`${item?.points.length} ${t('visits').toLowerCase()}`}
          points={item.points}
          pointDetailsRenderItem={pointDetailsRenderItem}
        />
      );
    },
    [activeItem, viewedDays, pointDetailsRenderItem],
  );

  const ListHeader = useCallback(
    () => (
      <View>
        {convertedActivityDetails.length > 0 &&
          convertedActivityDetails?.[0]?.date ===
            endOfDay(new Date()).getTime() && (
            <SpText style={{ marginBottom: -20 }} fontFamily='Rubik_Medium'>
              {t('today')}
            </SpText>
          )}
      </View>
    ),
    [convertedActivityDetails],
  );
  const dateNavigationData = {
    name: t(ReportRangeLastName[viewedDays === 28 ? 28 : 7], {
      value: viewedDays === 28 ? 28 : 7,
    }),
    viewedDays,
  };
  return (
    <>
      {stats ? <LineGraphModalWrapper data={stats} modal='LineGraph' /> : null}
      {/* <Header onGeneratePDFReport={onGeneratePDFReport} /> */}
      <View style={styles.container}>
        <DateNavigation
          title={dateNavigationData.name}
          currentDate={new Date(activeDate)}
          setDate={date => {
            setActiveDate(date);
            setViewedActiveDate(date);
            setActiveItem(viewedDays === 28 ? null : 1);
          }}
          viewedDays={viewedDays - 1}
          setViewedDays={(days: number) => {
            setViewedDays(days);
            setViewedReportDays(days);
            setActiveItem(days === 28 ? null : 1);
          }}
          withButtons
        />
        {loading ?
            <SpView style={styles.spinner}>
              <LoadingSpinner/>
            </SpView> :
            <>
              <SpView style={styles.averageContainer}>
                <SpView style={styles.averageWrap}>
                  <PetAverageStatsFeeds data={convertedActivityDetails}/>
                </SpView>
              </SpView>
              <SpView style={styles.flatlistContainer}>
                <FlatList
                    style={styles.flatlist}
                    data={convertedActivityDetails}
                    renderItem={renderItem}
                    ListHeaderComponent={ListHeader}
                    keyExtractor={item => item?.date.toString()}
                />
              </SpView>
            </>}
      </View>

    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    flex: 1,
  },
  flatlist: { marginHorizontal: -24, paddingHorizontal: 24 },
  flatlistContainer: {
    flex:1,
    paddingTop: 56,
    marginHorizontal: -24,
    paddingHorizontal: 24,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
  },
  averageContainer: {
    marginTop: 8,
    position: 'relative',
    height: 48,
    zIndex: 9999,
  },
  averageWrap: {
    paddingHorizontal: 8,
    width: '100%',
    position: 'absolute',
    justifyContent: 'space-between',
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%'
  }
});
