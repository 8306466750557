import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useUserHasWriteAccess = (): boolean => {
  const { userHasWriteAccess, activeHousehold } = useBoundStore(
    s => s.householdStore,
  );

  return useMemo(() => {
    return userHasWriteAccess();
  }, [userHasWriteAccess, activeHousehold]);
};
