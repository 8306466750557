import { Image, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';

interface PairingToHouseholdStepProps {
  isLoading: boolean;
  connected: boolean;
}

export const PairingToHouseholdStep = ({
  isLoading,
}: PairingToHouseholdStepProps) => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={[styles.center]}>
        <Image
          style={{ width: 200 }}
          source={AppImages.cdbPairingHousehold}
          resizeMode="stretch"
          {...testProperties('cdbPairingHousehold', 'image')}
        />
        <SpText style={[text.loadingText, styles.text]}>
          {t('connecting_everything_up')}
        </SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '40%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginTop: '5%',
  },
});

export default PairingToHouseholdStep;
