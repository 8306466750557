import i18n from '@utils/i18n';
import { useMemo } from 'react';
import { ReportType } from '../constants/ReportType';
import { capitalize } from '@utils/capitalize';

export enum ReportTabs {
  Summary = 1,
  Detail,
}

export const useReportTabs = (type: ReportType) => {
  return useMemo(() => {
    let summaryName = '';
    let detailName = '';

    if (type === ReportType.Feeding) {
      summaryName = i18n.t('amount');
      detailName = capitalize(i18n.t('feeds'));
    }

    if (type === ReportType.Drinking) {
      summaryName = i18n.t('amount');
      detailName = i18n.t('drinks');
    }

    if (type === ReportType.Movement) {
      summaryName = i18n.t('time_outside');
      detailName = i18n.t('entries');
    }

    return {
      [ReportTabs.Summary]: summaryName,
      [ReportTabs.Detail]: detailName,
    };
  }, [type]);
};
