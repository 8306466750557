import { DeviceType } from '@constants/Device';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CompleteSetupProduct } from './CompleteSetupProduct';

interface CompleteSetupProductWithLoadingProps {
  productId: DeviceType;
  onLoadedCallback?: (arg: (arg: boolean) => void) => void;
  deviceId: number;
  onPress?: () => void;
}

export const CompleteSetupProductWithLoading = ({
  productId,
  onLoadedCallback,
  deviceId,
  onPress,
}: CompleteSetupProductWithLoadingProps) => {
  const essenceCreatedCbRef = useRef(null);
  const [created, setCreated] = useState(false);
  const childStateCallback = useCallback((cb: (data: boolean) => void) => {
    essenceCreatedCbRef.current = cb;
  }, []);

  useEffect(() => {
    onLoadedCallback(setCreated);
  }, [setCreated]);

  useEffect(() => {
    if (created) {
      essenceCreatedCbRef.current(true);
    }
  }, [created]);

  return (
    <CompleteSetupProduct
      loaded={created}
      productId={productId}
      onLoadedCallback={childStateCallback}
      deviceId={deviceId}
      onPress={onPress}
    />
  );
};
