import { FeederFoodType } from '@constants/Device';
import { MappedConsumptionReportDataModel } from '@models/ReportModel';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransformFeedingEventToReport } from '../hooks/useTransformFeedingEventToReport';
import { EventDetailsCard } from './EventDetailsCard';

interface FeedingPointReportProps {
  data: MappedConsumptionReportDataModel;
}

export const FeedingEventReport = ({ data }: FeedingPointReportProps) => {
  const { t } = useTranslation();
  const item = useTransformFeedingEventToReport(data);
  item.duration = data.duration < 60 ? `00:${item.duration}` : item.duration;

  const weightColumn = useMemo(() => {
    if (item.leftBowl.type && item.rightBowl.type) {
      return {
        label: `${t(FeederFoodType[item.leftBowl.type])} / ${t(FeederFoodType[item.rightBowl.type])}`,
        value: `${item.leftBowl.value || 0} / ${item.rightBowl.value || 0}`,
      };
    } else if (item.leftBowl.type) {
      return {
        label: t(FeederFoodType[item.leftBowl.type]),
        value: item.leftBowl.value,
      };
    } else {
      return {
        label: `${t(FeederFoodType[1])} / ${t(FeederFoodType[2])}`,
        value: '0 / 0',
      }
    }
  }, [item]);

  return (
    <EventDetailsCard
      leftLabel={t('time')}
      leftValue={item.time}
      centerLabel={weightColumn.label}
      centerValue={weightColumn.value}
      rightLabel={t('duration')}
      rightValue={item.duration}
    />
  );
};
