const mockLinechartsData = [
  {
    left: 29,
    isActive: true,
  },
  {
    left: 60,
  },
  {
    left: 72,
  },
  {
    left: 122,
  },
  {
    left: 228,
  },
  {
    left: 286,
  },
];

export default mockLinechartsData;
