import { Image, Platform, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import { useTranslation } from 'react-i18next';
import images from '@styles/images';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import text from '@styles/text';

export const InstructionStep1 = () => {
  const { t } = useTranslation();
  return (
    <SpView style={[styles.center, Platform.OS === 'android' ? styles.androidPaddingBottom : {}]}>
      <Image
        style={images.imageAddCerberusStep1}
        source={AppImages.addCerberusSetup1a}
      />
      <SpText style={[text.body, styles.biffetText]}>
        {t('cdb_setup_instruction1_paragraph')}
      </SpText>
      <SpView />
      <SpView style={styles.roundedGreyInfo}>
        <Image
          style={images.imageAddCerberusStep1Icon1}
          source={AppImages.imageAddCerberusStep1Icon1}
        />
        <SpText style={styles.info}>{t('setup_cdb_batteries')}</SpText>
      </SpView>
      <SpView style={styles.roundedGreyInfo}>
        <Image
          style={images.imageAddCerberusStep1Icon2}
          source={AppImages.imageAddCerberusStep1Icon2}
        />
        <SpText style={styles.info}>{t('setup_cdb_screwdriver')}</SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  roundedGreyInfo: {
    backgroundColor: '#EEE',
    borderRadius: 10,
    width: '90%',
    height: 50,
    flexDirection: 'row',
    paddingLeft: 10,
    marginTop: 10,
  },
  biffetText: {
    fontSize: 20,
    marginBottom: 20,
  },
  info: {
    fontSize: 14,
    alignItems: 'flex-end',
    marginTop: 15,
    marginLeft: 10,
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
});

export default InstructionStep1;
