import { DeviceType } from '@constants/Device';
import { useMemo } from 'react';
import { useDevicesByHousehold } from './useDevicesByHousehold';

const useAreProductsPresent = () => {
  const devices = useDevicesByHousehold();
  return useMemo(
    () => devices.filter(elem => elem.product_id !== DeviceType.Hub).length > 0,
    [devices],
  );
};

export default useAreProductsPresent;
