import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';
import {
  GestureResponderEvent,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import colors from 'src/styles/colors';
import { testProperties } from '@utils/testProperties';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';
import PasswordEyeOpened from './SvgIcons/PasswordEyeOpenedSVG';
import PasswordEyeClosed from './SvgIcons/PasswordEyeClosedSVG';

export interface SpInputWrapperProps {
  value: string;
  label?: string;
  error?: string;
  hint?: string;
  icon?: IconProp;
  password?: boolean;
  isLoading?: boolean;
  weightUnit?: string;
  multilineError?: boolean;
  accessible?: boolean;
  additionalTestLabel?: string;
  componentName?: string;
  additionalTestLabelTail?: string;
  additionalIconTestLabel?: string;
}

interface SpInputWrapperContext {
  onFocus: () => void;
  onBlur: () => void;
  isPasswordSecured: boolean;
}

export const SpInputWrapperContext = createContext<SpInputWrapperContext>(null);

export const SpInputWrapper = ({
  value,
  label,
  error = '',
  hint = '',
  children,
  icon,
  weightUnit = '',
  password = false,
  isLoading = false,
  multilineError,
  additionalTestLabel,
  accessible = false,
  componentName,
  additionalTestLabelTail,
  additionalIconTestLabel,
}: PropsWithChildren<SpInputWrapperProps>): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const [isPasswordSecured, setIsPasswordSecured] = useState(password);

  const { t } = useTranslation();

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const provideProps = useMemo(() => {
    return { onBlur, onFocus, isPasswordSecured };
  }, [onBlur, onFocus, isPasswordSecured]);

  const shadowStyles = useMemo(() => {
    const shadow = {
      shadowOffset: {
        width: 0,
        height: -2,
      },
      shadowOpacity: 0.3,
      shadowRadius: 3,

      elevation: 8,
    };
    if (error) {
      return {
        shadowColor: colors.errorRed.color,
        ...shadow,
      };
    }
    if (focused) {
      return {
        shadowColor: colors.primary.color,
        ...shadow,
      };
    }
    return null;
  }, [focused, error]);

  const inputBorderStyles = useMemo(() => {
    if (error) {
      return {
        borderColor: colors.errorRed.color,
      };
    }
    if (focused) {
      return {
        borderColor: colors.primary.color,
      };
    }
    return { borderColor: '#919ba0' };
  }, [error, focused]);

  const onPasswordPress = (event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsPasswordSecured(!isPasswordSecured);
  };

  const rightIcon = useMemo(() => {
    if (password) {
      return (
        <View
          style={styles.rightIcon}
          {...testProperties(
            isPasswordSecured ? 'faEye' : 'faEyeSlash',
            `eye icon`,
            `${additionalIconTestLabel ? `${additionalIconTestLabel}` : ''}`,
          )}
        >
          <Pressable onPress={onPasswordPress}>
            {isPasswordSecured ? <PasswordEyeClosed /> : <PasswordEyeOpened />}
          </Pressable>
        </View>
      );
    }
    if (error) {
      return (
        <View
          style={styles.rightIcon}
          {...testProperties('faCircleExclamation', 'errorIcon')}
        >
          <FontAwesomeIcon
            color={colors.errorRed.color}
            size={24}
            icon={faCircleExclamation}
          />
        </View>
      );
    }
    if (icon) {
      return (
        <View
          style={styles.rightIcon}
          {...testProperties(icon.iconName || 'faSearch', 'Icon')}
        >
          <FontAwesomeIcon
            color={colors.greyText.color}
            size={24}
            icon={icon}
          />
        </View>
      );
    }
    return null;
  }, [password, error, icon, isPasswordSecured]);

  const testErrorObj = useMemo(() => {

    if(!error || typeof error !== 'string') return null
    if (error?.includes('Value must be greater than')) {
      return testProperties('PleaseEnterAWeight', 'ErrorText');
    }
    if (error?.includes('Weight must not contain more than')) {
      return testProperties('WeightMustNotContain', 'ErrorText');
    }
    return testProperties(
      error,
      'ErrorText',
      `${additionalTestLabel || ''}ErrorText${
        additionalTestLabelTail ? `_${additionalTestLabelTail}` : ''
      }`,
    );
  }, [error]);

  return (
    <SpView
      {...testProperties(
        `SpInputWrapper`,
        `Component${label ? `_${label}` : ''}`,
      )}
      accessible={accessible}
      style={[styles.container, isLoading && { opacity: 0.5 }]}
    >
      {label && (
        <Text {...testProperties(`${label}`, `label`)} style={styles.label}>
          {label}
        </Text>
      )}
      <SpView
        {...testProperties(
          'SpInput',
          `Component`,
          `${componentName || ''}`,
          Platform.OS === 'ios',
        )}
        style={[styles.inputWrapper, { ...inputBorderStyles }]}
      >
        <View style={[styles.inputContainer]}>
          <View style={[styles.shadowContainer, { ...shadowStyles }]}>
            <SpInputWrapperContext.Provider value={provideProps}>
              {children}
            </SpInputWrapperContext.Provider>
            {!error && weightUnit && (
              <Text
                {...testProperties(
                  'weightUnit',
                  `${additionalTestLabel || ''}Text`,
                )}
                style={styles.weightUnit}
              >
                {t(weightUnit)}
              </Text>
            )}
            {rightIcon}
          </View>
        </View>
      </SpView>
      <View
        {...testProperties(`ErrorHint`, `Wrapper${label ? `_${label}` : ''}`)}
        style={[
          styles.errorHintWrapper,
          multilineError && { height: undefined },
        ]}
      >
        {!!hint && !error && (
          <Text
            {...testProperties(hint, `${additionalTestLabel || ''}HintText`)}
            style={styles.hint}
          >
            {hint}
          </Text>
        )}
        {!!error && (
          <Text {...testErrorObj} style={styles.error}>
            {error}
          </Text>
        )}
      </View>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    marginBottom: -28,
  },
  inputWrapper: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 16,
  },
  offsetWithIcon: {
    paddingRight: 72,
  },
  textInput: {
    paddingLeft: 23,
    paddingRight: 53,
    height: 56,
    flex: 1,
    width: '100%',
    backgroundColor: 'rgba(38, 58, 67, 0.05)',
    borderRadius: 16,
  },
  multiline: {
    height: 'auto',
    minHeight: 184,
    paddingTop: 14,
    paddingBottom: 16,
    maxHeight: 254,
  },
  text: {
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik',
  },
  label: {
    marginBottom: 8,
    height: 20,
    fontSize: 14,
    color: colors.labelTextColor.color,
    lineHeight: 20,
    fontFamily: 'Rubik',
  },
  error: {
    color: colors.errorRed.color,
    fontSize: 14,
    fontFamily: 'Rubik',
    lineHeight: 20,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  placeholder: {
    fontSize: 14,
    fontFamily: 'Rubik',
    color: colors.placeholderGrey.color,
  },
  hint: {
    color: 'darkgrey',
    lineHeight: 20,
  },
  weightUnit: {
    position: 'absolute',
    right: 23,
    fontFamily: 'Rubik_Medium',
    color: colors.greyText.color,
    fontSize: 20,
    top: 15,
  },
  rightIcon: { position: 'absolute', top: 15, right: 25 },
  errorBg: { backgroundColor: '#ffffff' },
  shadowContainer: {
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    borderRadius: 16,
    backgroundColor: '#fff',
  },
  errorHintWrapper: { marginTop: 8, minHeight: 20 },
});

export const inputStyles = styles;
