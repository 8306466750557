import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import { SpInput } from '@atoms/SpInput';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import colors from 'src/styles/colors';
import { useFormik } from 'formik';
import useValidation from 'src/pages/validation';
import noop from '@utils/noop';
import { useTranslation } from 'react-i18next';
import { SpView } from '@atoms/SpView';
import { useReturnHandler } from '@hooks/useReturnHandler';
import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import { AnalyticsService } from 'src/services/AnalyticsService';
import useBoundStore from '../../../store/store';
import { HouseholdStackParamList } from '../index';
import HouseholdTimezonesWrapper from '../molecules/HouseholdTimezonesWrapper';

type Props = NativeStackScreenProps<
  HouseholdStackParamList,
  'HouseholdEditSetting'
>;

const HouseholdEditSetting = ({ navigation, route }: Props) => {
  const [value, setValue] = useState(route.params.inputValue);
  const [inputName] = useState(route.params.inputName);
  const { loadTimezones, activeHousehold, updateHousehold, loadingHouseholds } =
    useBoundStore(state => {
      const { householdStore, timezoneStore } = state;
      return {
        loadTimezones: timezoneStore.loadTimezones,
        activeHousehold: householdStore.activeHousehold,
        updateHousehold: householdStore.updateHousehold,
        loadingHouseholds: householdStore.loadingHouseholds,
      };
    });
  const handler = useReturnHandler(inputName);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { t } = useTranslation();
  const { commonNameSchema } = useValidation();
  const formik = useFormik({
    validationSchema: commonNameSchema(t('household_name')),
    initialValues: { name: value },
    onSubmit: noop,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const onUpdateHousehold = useCallback(async () => {
    if (inputName === 'name') {
      const validName = await formik.validateForm();
      if (Object.keys(validName).length !== 0) return;
    }
    try {
      if (route.params.isCreating) {
        route.params.handleTimezone(value);
      } else {
        AnalyticsService.logEvent(`household_update_${inputName}`);

        await updateHousehold({ ...activeHousehold, [inputName]: value });
      }
      handler();
    } catch (err: any) {
      handler(err.code);
    } finally {
      navigation.pop();
    }
  }, [value, formik.values, activeHousehold]);

  const onChangeName = (text: string) => {
    setValue(text);
    formik.handleChange('name')(text);
  };

  useEffect(() => {
    if (value) {
      setIsButtonDisabled(value === route.params.inputValue ? true : false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [value]);

  useEffect(() => {
    loadTimezones(true);
  }, []);

  return (
    <CustomKeyboardAvoidingView scrollView={false} modal>
      <SpView style={styles.content}>
        <View style={styles.inner}>
          {route.params.label === 'Name' && (
            <SpView style={styles.inputWrapper}>
              <SpInput
                value={value}
                placeholder={t('type_here')}
                label={t('household_name')}
                onChangeText={onChangeName}
                isLoading={loadingHouseholds}
                error={(t(formik.errors?.name as string)) || ''}
              />
            </SpView>
          )}
          {route.params.label === 'Timezone' && (
            <HouseholdTimezonesWrapper
              currentTimezoneId={value}
              handleChange={setValue}
            />
          )}
        </View>
        <View style={styles.buttonWrapper}>
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            h={56}
            title={t('save')}
            onPress={() => onUpdateHousehold()}
            isLoading={loadingHouseholds}
            disabled={loadingHouseholds || isButtonDisabled}
            stylesForContainer={
              Platform.OS === 'android' ? { marginBottom: 30 } : undefined
            }
          />
        </View>
      </SpView>
    </CustomKeyboardAvoidingView>
  );
};

export default HouseholdEditSetting;

const styles = StyleSheet.create({
  content: {
    flex: Platform.select({ web: null, default: 1 }),
    backgroundColor: colors.white.color,
    height: Platform.select({ web: '100%', default: null }),
  },
  parentInner: {
    height: '100%',
    backgroundColor: colors.white.color,
  },
  inner: {
    backgroundColor: colors.white.color,
    flex: Platform.select({ web: null, default: 1 }),
    height: Platform.select({ web: '80%', default: null }),
  },
  buttonWrapper: {
    paddingHorizontal: 19,
    marginBottom: 34,
    bottom: Platform.select({ web: 60, default: null }),
    width: Platform.select({ web: '100%', default: null }),
  },
  inputWrapper: {
    paddingTop: 48,
    paddingHorizontal: 19,
    height: '100%',
  },
  buttonWrap: {
    bottom: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
});
