import { RemoteConfigKeys } from '@constants/RemoteConfig';
import { useCallback, useEffect, useState } from 'react';

export const useRemoteConfigStringValue = (name: RemoteConfigKeys): string => {
  const [value, setValue] = useState<string>('');

  const getData = useCallback(async () => {
    try {
      setValue(null);
    } catch (error) {
      console.log(error);
    }
  }, [setValue]);

  useEffect(() => {
    getData().then();
  }, [getData]);

  return value;
};
