import MeApi from '@api/MeApi';
import { AsyncStorageKeys } from '@constants/AsyncStorageKeys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { differenceInDays, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';
import { SendAnalyticsDataService } from '../services/SendAnalyticsDataService';
import { MergedInterfaces } from '../store/models';
import useBoundStore from '../store/store';
import { DdLogs } from '../services/SPDataDogService';

export const useRefreshAnalyticsData = () => {
  const [allow, setAllow] = useState(false);
  const { activeHousehold } = useBoundStore(
    (state: MergedInterfaces) => state.householdStore,
  );
  const run = async () => {
    const [meData, UPLog] = await Promise.all([
      MeApi.getStartData(),
      AsyncStorage.getItem(AsyncStorageKeys.UPLog),
    ]);

    const timeSinceLastOpen = UPLog
      ? differenceInDays(Date.now(), parseISO(UPLog as string).getTime())
      : null;

    if (timeSinceLastOpen !== null && timeSinceLastOpen < 7) {
      await SendAnalyticsDataService.trackEvents({ pets: meData.pets });
      return;
    }

    await AsyncStorage.setItem(
      AsyncStorageKeys.UPLog,
      new Date().toISOString(),
    );
    await SendAnalyticsDataService.trackProperties({
      activeHousehold,
      devices: meData.devices,
      households: meData.households,
      user: meData.user,
    });
    await SendAnalyticsDataService.trackEvents({ pets: meData.pets });
  };

  useEffect(() => {
    DeviceEventEmitter.addListener('refreshAnalyticsData', () =>
      setAllow(true),
    );
  }, []);

  useEffect(() => {
    if (!allow) {
      return;
    }

    if (!activeHousehold) {
      return;
    }

    setAllow(false);
    DdLogs.info('useRefreshAnalyticsData - useEffect');

    run().then();
  }, [activeHousehold, allow]);
};
