const mockLinechartsData = [
  {
    width: 15,
    left: 34,
    isActive: true,
  },
  {
    width: 33,
    left: 52,
  },
  {
    width: 15,
    left: 95,
  },
  {
    width: 24,
    left: 159,
  },
  {
    width: 104,
    left: 212,
  },
];

export default mockLinechartsData;
