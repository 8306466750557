import axios from 'axios';
import { DeviceNetworks, DeviceProductType, DeviceStatus, NetworkCredentials } from '@models/WiFiDevice';
import { AnalyticsService } from '../services/AnalyticsService';

// const root = "http://localhost:8088";
const root = 'http://192.168.4.1';
const DeviceHttp = axios.create({
  headers: {
    'Content-Type': 'text/plain',
    Origin: 'http://localhost:19006',
  },
  withCredentials: false,
});

class WiFiDeviceAPI {
static async getProduct(timeout: number = 6000): Promise<DeviceProductType> {
  AnalyticsService.logEvent('WiFiDeviceAPI - getProduct');

    return await DeviceHttp.get(`${root}/product`, {
      timeout,
    }).then(data => data.data);
  }

  static async scanForWiFi(): Promise<DeviceNetworks[]> {
    AnalyticsService.logEvent('WiFiDeviceAPI - scanForWiFi');

    return await DeviceHttp.get(`${root}/scan_for_wifi`, {
      timeout: 10000,
    }).then(data => data.data);
  }

  static async connectToWiFi(passwordObj: NetworkCredentials): Promise<void> {
    AnalyticsService.logEvent('WiFiDeviceAPI - connectToWiFi');

    return await DeviceHttp.post(`${root}/connect_to_wifi`, passwordObj).then(
      data => data.data,
    );
  }

  static async checkStatus(): Promise<DeviceStatus> {
    AnalyticsService.logEvent('WiFiDeviceAPI - checkStatus');

    return await DeviceHttp.get(`${root}/check`, {
      timeout: 3000,
    }).then(data => data.data);
  }

  static async closeConnection(): Promise<void> {
    AnalyticsService.logEvent('WiFiDeviceAPI - closeConnection');

    return await DeviceHttp.get(`${root}/closeap`);
  }
}

export default WiFiDeviceAPI;
