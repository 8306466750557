import { Linking, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import useBoundStore from 'src/store/store';
import EssenceCreated from '@atoms/EssenceCreated';
import WiFiDeviceAPI from '@api/WifiDeviceConnector';
import { useTranslation } from 'react-i18next';
import StepFormCreator from '@molecules/StepFormCreator';
import {
  NavigationProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import WiFiSSIDSelect from '../Flows/DogBowl/WiFiSSIDSelect';
import WiFiPasswordInput from '../Flows/DogBowl/WiFiPasswordInput';
import { DeviceStackParamList } from './index';
import WiFiConnectToDevice from '../Flows/DogBowl/WiFiConnectToDevice';
import InstructionStep3 from '../Flows/DogBowl/InstructionStep3';
import { openModal as openModalBox } from '../../services/ModalBox';
import Paired from '../Flows/DogBowl/Paired';
import ThereSeemsToBeAProblem from '../Flows/DogBowl/ThereSeemsToBeAProblem';
import ThereSeemsToBeAProblemContactCS from '../Flows/DogBowl/ThereSeemsToBeAProblemContactCS';
import PairingToHouseholdStep from '../Flows/DogBowl/PairingToHouseholdStep';
import PairingFailed from '../Flows/DogBowl/PairingFailed';
import { navigationRef } from '../RootNavigation';

const DeviceWiFiSetup = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceWiFiSetup'>) => {
  const { t } = useTranslation();
  const {
    setSettingUpWiFiDevice,
    loading,
    error,
    success,
    stopSearch,
    getNetworks,
    startScanDevice,
    deviceSSIDS,
    targetNetworkID,
    targetNetworkPassword,
    setTargetNetworkPassword,
    startCheckDeviceStatus,
    connectDeviceToWifi,
    WiFiDeviceConnectedToServer,
    connectedToWiFiDevice,
    startSearchForProduct,
    startPairDeviceToHousehold,
    setTimeoutForWiFiProduct,
    pairedDeviceDeviceID,
    WiFiDeviceConnectedToNetwork,
    reset
  } = useBoundStore(s => s.WiFiDeviceStore);
  const { retryCount } = useBoundStore(s => s.RetryStore);
  const [searchForDeviceAttempts, setSearchForDeviceAttempts] = useState(0);

  const { id } = route.params;
  const [submitValues, setSubmitValues] = useState<any>();
  const [step, setStep] = useState(1);
  const [paired, setPaired] = useState(false);
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const [returnToStep, setReturnToStep] = useState(0);
  const [bowlFlashingOrange, setBowlFalshingOrange] = useState(null);
  const [retryConnectToDevice, setRetryConnectToDevice] = useState<boolean>(false);

  useEffect(() => {
    console.log('disable network check');
    setSettingUpWiFiDevice(true);
    return () => setSettingUpWiFiDevice(false);
  }, [setSettingUpWiFiDevice]);
  useEffect(() => {
    return () => {
      stopSearch();
      reset();
    };
  }, []);
  useEffect(() => {
    if (step === 2) {
      if (connectedToWiFiDevice) {
        console.log('connected to wifi device, will skip searching for device');
        return;
      }
      // increment search attempts and start searching
      if ((!loading && searchForDeviceAttempts < 3) || retryConnectToDevice) {
        if (error) {
          console.log(
            'error connecting to device, will ask user if they want to try again',
            searchForDeviceAttempts,
          );
          setReturnToStep(5);
          setStep(21);
          stopSearch();
          return;
        }
        console.log(
          'no error so will search for devices',
          searchForDeviceAttempts,
        );
        setSearchForDeviceAttempts(searchForDeviceAttempts + 1);
        setTimeoutForWiFiProduct(14000);
        startSearchForProduct('CDB');
      }
      if (!loading && searchForDeviceAttempts >= 3 && error) {
        console.log('error connecting to device, wont try again');
        setStep(22);
        stopSearch();
      }
    }
  }, [step, loading, searchForDeviceAttempts, loading, connectedToWiFiDevice, retryConnectToDevice]);


  useEffect(() => {
    return stopSearch;
  }, []);
  useEffect(() => {
    if (step === 3) {
      startScanDevice();
    }
  }, [step]);
  // useEffect(() => {
  //   if (step === 4 && WiFiDeviceConnectedToServer) {
  //     WiFiDeviceAPI.closeConnection().then(done =>
  //       setTimeout(() => {
  //         setStep(5);
  //       }, 1000),
  //     );
  //   }
  // }, [step, setStep, WiFiDeviceConnectedToServer]);

  useEffect(() => {
    // if(step === 7){
    //   console.log(`step 7, will check if loading: ${loading}, error: ${error}, success: ${success}"`)
    //  }
    if (step === 4 && !loading && !error && success) {
      console.log('step 7, no error, success, will go to step 8');
      setTimeout(() => {
        setReturnToStep(4);
        startCheckDeviceStatus();
        setStep(5);
      }, 300);
    }
  }, [step, setStep, success, error, loading]);

  useEffect(() => {
    if (step === 5) {
      // console.log(`Step 8, pairedDeviceDeviceID: ${pairedDeviceDeviceID}, loading: ${loading}, error: ${error},  WiFiDeviceConnectedToServer: ${WiFiDeviceConnectedToServer}` )
    }
    if (
      step === 5 &&
      !pairedDeviceDeviceID &&
      !loading &&
      !error &&
      WiFiDeviceConnectedToServer
    ) {
      console.log('wifi change success')
      // console.log(
      //   "on step 8, but no paired device id, will pair to household in 6 seconds",
      //   step,
      //   pairedDeviceDeviceID,
      //   loading,
      //   error
      // );
      WiFiDeviceAPI.closeConnection().then(() => {
        setTimeout(() => {
          setStep(6);

        }, 6000);
      });
    }
    // We stopped loading, there was an error before we could connect to wifi so its probably a problem with the SSID or Password
    if (step === 5 && !loading && error && !WiFiDeviceConnectedToNetwork) {
      // console.log("error connecting to wifi, will go to step 7")
      // error was a timeout, probably issue connecting to dog bowl
      if (error === 'ECONNABORTED') {
        setStep(20);
      } else {
        setStep(4);
      }
    }
    // We stopped loading, there was an error after we connected to wifi so its probably a problem with the server
    if (step === 5 && !loading && error && WiFiDeviceConnectedToNetwork) {
      // console.log("error connecting to server, will go to step 20")

      setStep(20);
    }
    // We stopped loading, there was an error before we connected to wifi so its probably a problem with the creds or network
    if (step === 5 && !loading && !error && !WiFiDeviceConnectedToNetwork) {
      console.log('error connecting to wifi, will go to step 20');

      setStep(20);
    }

  }, [
    step,
    setStep,
    pairedDeviceDeviceID,
    error,
    loading,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToServer,
  ]);

  const onOpenGetHelpModal = useCallback(() => {
    switch (step) {
  
      default: {
        Linking.openURL('https://www.surepetcare.com/support');
      }
    }
    return false;
  }, [openModalBox, step]);

  const onSubmit = useCallback(() => {
    setPaired(true);
    WiFiDeviceAPI.closeConnection();
  }, []);

  const headerProps = {
    withLeaveButton: true,
    withRightButton: true,
    rightButtonText: t('get_help'),
    rightButtonAction: () => {
      onOpenGetHelpModal();
    },
  };

  const stopSearchLeaveAction = useCallback(() => {
    stopSearch()
    navigation.goBack()
  }, [navigation, stopSearch])

    return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle=""
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      safeAreaView={false}
      steps={{
        1: {
          ui: InstructionStep3,
          hideCustomHeader: true,
          backBottomButton: true,
        },
        2: {
          ui: WiFiConnectToDevice({
            connectedToWiFiDevice,
            setRetryConnectToDevice,
          }),
          customHeaderProps: {
            withLeaveButton: false,
            withRightButton: false,
            withArrowBack: false,
            withTitle: false,
            withCross: false,
          },
          hideCustomHeader: true,
          hideProgressHeader: true,

          backBottomButton: false,
          hideButton: !connectedToWiFiDevice,
          interceptor: () => {
            if (connectedToWiFiDevice) {
              return true;
            }
            // startSearch();
            return false;
          },
        },
        3: {
          ui: WiFiSSIDSelect({
            deviceSSIDS,
            targetNetworkID,
            loading,
            currentStep: step === 3,
          }),
          customHeaderProps: {
            withLeaveButton: false,
            withRightButton: false,
            withArrowBack: false,
            withTitle: false,
            withCross: false,
          },
          backBottomButton: false,
          hideProgressHeader: true,
          hideCustomHeader: true,
          interceptor: () => {
            if (deviceSSIDS.length > 0) {
              stopSearch();
              return true;
            }
            return false;
          },
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            console.log(routeName)
            if(routeName === 'SelectSSID'){
              navigation.goBack()
              return true
            } 
            if(routeName === 'DeviceWiFiSetup'){
              stopSearch();
              setStep(2)
              return true
            }
            return 'goBack'
          }
        },
        4: {
          ui: WiFiPasswordInput({
            SelectedWiFiSSID: deviceSSIDS[targetNetworkID]?.ssid || '',
            setTargetNetworkPassword,
            targetNetworkPassword,
            loading,
            error: error ? t(String(error)) : null,
          }),
          customHeaderProps: {
            ...headerProps,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideProgressHeader: true,
          hideCustomHeader: true,
          hideButton: loading,
          buttonDisabled: !targetNetworkPassword,

          interceptor: () => {
            if (!targetNetworkPassword) {
              return false;
            }
            console.log(
              'Telling CDB to connectDeviceToWifi from the interceptor',
            );
            connectDeviceToWifi();
            return false;
          },
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            console.log(routeName)
            if(routeName === 'SelectSSID'){
              navigation.goBack()
              return true
            } 
            if(routeName === 'DeviceWiFiSetup'){
              stopSearch();
              setStep(3)
              return true
            }
            return 'goBack'
          }
        },
        5: {
          // @ts-ignore
          ui: PairingToHouseholdStep({
            isLoading: loading,
          }),
          hideButton: true,
          customHeaderProps: {
            withLeaveButton: false,
            goBackFunc: () => {
              setStep(7);
              stopSearch();
            },
          },
        },
        6: {
          // @ts-ignore
          ui: Paired,
          props: { getHelpAction: onOpenGetHelpModal },
          customHeaderProps: {
            withLeaveButton: true,
          },

          interceptor: () => {
         
            navigation.navigate('DeviceSetting', { id });
            return true;
          },
        },
        7: {
          // @ts-ignore
          ui: <></>,
          customHeaderProps: {
            withLeaveButton: true,
          },

        },
        20: {
          // @ts-ignore
          ui: PairingFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();

            return false;
          },
        },

        21: {
          // @ts-ignore
          ui: ThereSeemsToBeAProblem,
          props: {
            bowlFlashingOrange,
            setIsFlashingOrange: setBowlFalshingOrange,
          },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: bowlFlashingOrange === null,
          interceptor: () => {
            setStep(bowlFlashingOrange ? returnToStep : 3);
            setBowlFalshingOrange(null);
            return false;
          },
        },

        22: {
          // @ts-ignore
          ui: ThereSeemsToBeAProblemContactCS,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
          },
          hideButton: true,
        },
      
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  stepWrapper: {
    marginTop: '40%',
  },
  formWrapper: { paddingHorizontal: 24 },
  center: {
    alignItems: 'center',
  },
});

export default DeviceWiFiSetup;
