import { DeviceCatFlapCurfew, DevicePetDoorCurfew } from '@models/Device';
import { getLocalTime } from '@utils/curfew';
import { format, isBefore, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useMemo, useState } from 'react';

import useBoundStore from '../../../store/store';

const useCurfewActive = (enabledCurfews: DevicePetDoorCurfew[] | DeviceCatFlapCurfew[]) => {
  const [lockUnlockTime, setLockUnlockTime] = useState(null);
  const activeHousehold = useBoundStore((s) => s.householdStore.activeHousehold);
  const isCurfewActive = useMemo(() => {
    let smallestTime =
      enabledCurfews.length > 0 ? parse(enabledCurfews[0].lock_time, 'HH:mm', new Date()) : null;
    const currentTime = format(
      utcToZonedTime(new Date(), activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta')),
      'HH:mm',
    );

    for (const range of enabledCurfews) {
      const lockTime = getLocalTime(
        range.lock_time,
        activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
      );
      const unLockTime = getLocalTime(
        range.unlock_time,
        activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
      );
      const lockTimeObj = new Date(`2000-01-01 ${lockTime}`);
      const unLockTimeObj = new Date(`2000-01-01 ${unLockTime}`);

      if (unLockTimeObj < lockTimeObj) {
        unLockTimeObj.setDate(unLockTimeObj.getDate() + 1);
      }
      const currentTimeObj = new Date(`2000-01-01 ${currentTime}`);
      if (lockTimeObj <= currentTimeObj && currentTimeObj <= unLockTimeObj) {
        setLockUnlockTime(`${lockTime} ${unLockTime}`);
        return true;
      } else {
        if (range.lock_time === enabledCurfews[0].lock_time) {
          setLockUnlockTime(`${lockTime} ${unLockTime}`);
        } else if (isBefore(parse(range.lock_time, 'HH:mm', new Date()), smallestTime)) {
          setLockUnlockTime(`${lockTime} ${unLockTime}`);
          smallestTime = parse(range.lock_time, 'HH:mm', new Date());
        }
      }
    }
    return false;
  }, [enabledCurfews, activeHousehold]);

  return { isCurfewActive, lockUnlockTime };
};

export default useCurfewActive;
