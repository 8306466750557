import React from 'react';
import PetChangeModal from '../PetDashboardHeaderMolecules/PetChangeModal';

interface HeaderProps {
  onGeneratePDFReport: () => void;
}

export const Header = ({ onGeneratePDFReport }: HeaderProps) => {
  return (
    <>
      <PetChangeModal modal="PetDashboardVisitsChangePet" />
      {/* <SpButton onPress={onGeneratePDFReport} title="Get PDF" /> */}
    </>
  );
};
