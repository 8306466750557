import { createNavigationContainerRef, } from '@react-navigation/native';
import { RootStackParamList } from './index';
import { useRef } from 'react';

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export function navigate(name: keyof RootStackParamList, params?: never) {
  if (navigationRef.isReady()) {
    // @ts-ignore
    navigationRef.navigate(name, params);
  } else {
    console.log('navigationRef is not ready');
  }
}
