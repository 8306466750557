import { SpProfileImage } from '@atoms/SpProfileImage';
import SpSettingBadge from '@atoms/SpSettingBadge';
import SpTextButton from '@atoms/SpTextButton';
import { PetSpecie } from '@constants/Pet';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { SpMenuStack } from '@molecules/SpMenuStack';
import {
  CommonActions,
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import colors from '@styles/colors';
import SpAlert from '@utils/alert';
import { dateDiffInString } from '@utils/dateDiffInString';
import i18n from '@utils/i18n';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native';
import useBoundStore from 'src/store/store';
import DietSVG from 'src/components/atoms/SvgIcons/DietSVG';
import MedicalSVG from 'src/components/atoms/SvgIcons/MedicalSVG';
import { TimeService } from 'src/services/TimeService';
import PetBioSvg from '@atoms/SvgIcons/PetBioSVG';
import { useCustomToast } from '@hooks/useToast';
import MicrochipNumberModal from './molecules/MicrochipNumberModal';
import { PetStackParamList } from '../Dashboard/Pets';
import { RootStackParamList } from '../index';

const PetProfile = () => {
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<PetStackParamList, 'PetProfile'>>();
  const { getPetById, deletePet } = useBoundStore(state => state.petStore);
  const userHasAdminAccess = useUserHasAdminAccess();
  const activePet = getPetById(route.params.id);

  const { show } = useCustomToast();
  const { t } = useTranslation();

  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode,
  );

  const petWeight = useMemo(() => {
    if (activePet?.weight === undefined) {
      return '-';
    }
    return convertWithUnits(Number(activePet?.weight || 0), true, true);
  }, [userWeightUnits, userCountryCode, activePet?.weight]);

  const buttons = [
    {
      label: i18n.t('bio'),
      iconSVG: (
        <PetBioSvg
          color={colors.greyText.color}
          width={21}
          height={21}
          viewBox='0 0 120 120'
        />
      ),
      action: () =>
        navigation.navigate('PetProfileSettings', { id: route.params.id }),
    },
    {
      label: i18n.t('medical'),
      iconSVG: <MedicalSVG />,
      action: () => {
        navigation.navigate('PetProfileMedical', { id: route.params.id });
      },
    },
    {
      label: i18n.t('diet'),
      iconSVG: <DietSVG />,
      action: () => navigation.navigate('PetDiet', { id: route.params.id }),
    },
  ];

  const deleteHandler = useCallback(() => {
    SpAlert(i18n.t('delete_pet'), i18n.t('delete_pet_message'), [
      {
        text: t('delete'),
        onPress: () => {
          deletePet(route.params.id)
            .then(() => {
              show({ description: t('delete_pet_success') });
              rootNavigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [
                    { name: 'DashboardNavigation', params: { screen: 'Pets' } },
                  ],
                }),
              );
            })
            .catch(() => {
              show({ description: t('delete_pet_error'), isError: true });
            });
        },
        style: 'destructive',
      },
      {
        text: t('cancel'),
        style: 'cancel',
      },
    ]);
  }, [route.params?.id]);

  const genderIcon = useMemo(() => {
    if (activePet?.gender === undefined) {
      return null;
    }
    return (
      <FontAwesomeIcon
        color={colors.toxicGreen.color}
        icon={activePet?.gender === 0 ? faVenus : faMars}
        size={20}
        style={styles.petNameIcon}
      />
    );
  }, [activePet?.gender]);

  const timeZonedDateInstring = useMemo(() => {
         if(activePet?.date_of_birth === undefined) {
      return null;
    }
    return dateDiffInString(activePet?.date_of_birth, t);

  }, [t, activePet?.date_of_birth]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollWrap}>
        <View style={styles.inner}>
          <MicrochipNumberModal value={activePet?.tag?.tag} />
          <SpProfileImage
            width={120}
            height={120}
            imageUrl={activePet?.photo?.location}
          />
          <View style={styles.petInfoWrapper}>
            <Text
              {...testProperties(activePet?.name, 'pet name text')}
              style={styles.petName}
            >
              {activePet?.name}
            </Text>
            <View
              {...testProperties(
                activePet?.gender === 0 ? 'faVenus' : 'faMars',
                'icon',
              )}
            >
              {genderIcon}
            </View>
          </View>
          <Text {...testProperties('DOB age', 'text')} style={styles.ageText}>
            {timeZonedDateInstring}
          </Text>
          <View style={styles.badgesWrap}>
            <SpSettingBadge
              title={t('species')}
              value={t(PetSpecie[activePet?.species_id])}
            />
            <View style={styles.badgesOffset}>
              <SpSettingBadge title={t('weight')} value={`${petWeight}`} />
            </View>
          </View>
          <View style={styles.menuStackOffset}>
            <SpMenuStack buttons={buttons} />
          </View>
        </View>
        {userHasAdminAccess && (
          <SpTextButton
            text={t('delete_pet')}
            textStyle={styles.deleteButtonText}
            onPress={deleteHandler}
            containerStyle={styles.deleteButton}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

export default PetProfile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  scrollWrap: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  inner: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 24,
    paddingHorizontal: 19,
    backgroundColor: colors.white.color,
  },
  petName: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    marginTop: 4,
    lineHeight: 32,
    color: colors.greyText.color,
  },
  petNameIcon: { marginLeft: 10, marginTop: 7 },
  petInfoWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ageText: {
    color: colors.greyText.color,
    fontFamily: 'Rubik',
    lineHeight: 24,
    fontSize: 16,
  },
  badgesWrap: {
    marginTop: 48,
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgesOffset: { marginLeft: 32 },
  menuStackOffset: {
    width: '100%',
    marginTop: 48,
  },
  deleteButton: {
    alignSelf: 'center',
    marginTop: 48,
    marginBottom: 68,
  },
  deleteButtonText: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
    color: colors.greyText.color,
  },
});
