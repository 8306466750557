export enum PetDoorQuickActions {
  curfew = 1,
  lock,
}

export enum CatFlapQuickActions {
  curfew = 1,
  lock,
  indoor_mode,
}

export enum HubQuickActions {
  lights,
}
