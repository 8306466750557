import { Pressable, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { MarkedDates } from 'react-native-calendars/src/types';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';
import colors from '../../styles/colors';
import SpModal from './SpModal';


interface CalendarModalProps {
  selectedDate: string;
  setSelectedDate: (date: number) => void;
  modal?: string;
  warningDates?: string[];
}

const allowInsights = false;

const CalendarModal = ({
  selectedDate,
  setSelectedDate,
  modal,
  warningDates,
}: CalendarModalProps) => {
  const [currCode, setCurrCode] = useState({});
  const markWarningDates = useMemo(() => {
    const commonStyles = {
      marked: true,
      dotColor: colors.orangeWarning.color,
      selectedColor: colors.orangeWarning.color,
    };
    const obj: MarkedDates = {};
    warningDates.forEach((item: string) => {
      obj[item] = {
        ...commonStyles,
        selected: selectedDate === item,
      };
    });
    return allowInsights ? obj : {};
  }, [warningDates, selectedDate]);

  const code = useCurrentLanguageCode();
  const { t } = useTranslation();

  useEffect(() => {
    if (code) {
      LocaleConfig.locales.en = LocaleConfig.locales[''];
      LocaleConfig.locales[code] = {
        monthNames: [
          t('january'),
          t('february'),
          t('march'),
          t('april'),
          t('may'),
          t('june'),
          t('july'),
          t('august'),
          t('september'),
          t('october'),
          t('november'),
          t('december'),
        ],
        dayNames: [
          t('sunday'),
          t('monday'),
          t('tuesday'),
          t('wednesday'),
          t('thursday'),
          t('friday'),
          t('saturday'),
        ],
        dayNamesShort: [
          t('sunday_short'),
          t('monday_short'),
          t('tuesday_short'),
          t('wednesday_short'),
          t('thursday_short'),
          t('friday_short'),
          t('saturday_short'),
        ],
      };
      LocaleConfig.defaultLocale = code;
      setCurrCode(code);
    }
  }, [code, t]);

  const arrowIcons = useCallback((direction: string) => {
    if (direction === 'left') {
      return (
        <FontAwesomeIcon
          icon={faCaretLeft}
          color={colors.white.color}
          size={18}
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faCaretRight}
        color={colors.white.color}
        size={18}
      />
    );
  }, []);

  return (
    <SpModal backdropColor="transparent" modalName={modal}>
      <View style={styles.wrapper}>
        <Pressable accessible={false} style={[styles.content]}>
          <Calendar
            testID="CalendarModal"
            onDayPress={day => {
              setSelectedDate(day.timestamp);
            }}
            headerStyle={{ marginTop: 5, marginBottom: 10 }}
            maxDate={new Date().toDateString()}
            initialDate={selectedDate}
            renderArrow={arrowIcons}
            markedDates={{
              [selectedDate]: {
                selected: true,
                selectedColor: colors.secondary.color,
              },
              ...markWarningDates,
            }}
            theme={{
              todayBackgroundColor: 'rgba(255,255,255, 0.3);',
              todayTextColor: colors.white.color,
              calendarBackground: colors.greyText.color,
              arrowColor: colors.white.color,
              textDisabledColor: 'rgba(255,255,255, 0.2);',
              dayTextColor: colors.white.color,
              dotStyle: { width: 7, height: 7, borderRadius: 50 },
              monthTextColor: colors.white.color,
              textDayFontSize: 14,
              textDayFontFamily: 'Rubik',
              textSectionTitleColor: colors.secondary.color,
              textMonthFontSize: 16,
              textMonthFontFamily: 'Rubik_Medium',
              textDayHeaderFontFamily: 'Rubik_Medium',
              textDayHeaderFontSize: 14,
            }}
          />
        </Pressable>
      </View>
    </SpModal>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '90%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    marginTop: 56,
  },
});

export default CalendarModal;
