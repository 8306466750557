import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { HouseholdRemovedService } from '../services/HouseholdRemovedService';
import useBoundStore from '../store/store';

export const HouseholdRemoved = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const households = useBoundStore(s => s.householdStore.households);
  const { user } = useBoundStore(s => s.accountStore);
  const allHouseholdsRemoved = useMemo(
    () => households.length === 0,
    [households],
  );

  useEffect(() => {
    HouseholdRemovedService.setNewInitialData(user, households).then();
  }, [households, user]);

  return (
    <SafeAreaView style={styles.view}>
      <ScrollView contentContainerStyle={styles.container}>
        <SpView flex={1}>
          <SpView marginVertical={28} alignItems="center">
            <FontAwesomeIcon
              color={colors.errorRed.color}
              size={56}
              icon={faCircleExclamation}
            />
          </SpView>
          <SpView>
            <SpVStack space={8}>
              <SpText align="center">
                {t(
                  allHouseholdsRemoved
                    ? 'ad_hh_removed_single_title'
                    : 'ad_hh_removed_multiple_title',
                )}
              </SpText>
              <SpText align="center">
                {t(
                  allHouseholdsRemoved
                    ? 'ad_hh_removed_single_description'
                    : 'ad_hh_removed_multiple_description',
                )}
              </SpText>
            </SpVStack>
          </SpView>
        </SpView>

        <SpView marginTop={46} paddingBottom={34}>
          <SpRoundedHeaderButton
            h={56}
            title={t('continue')}
            backgroundColor={colors.primary.color}
            onPress={() => navigation.goBack()}
          />
        </SpView>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 19,
  },
});
