import IconButton from '@atoms/IconButton';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import noop from '@utils/noop';
import { getCurfewStatusText } from '@utils/curfew';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useDoorLockedStatus } from '@hooks/useDoorLockedStatus';
import { useEnabledCurfews } from '@hooks/useEnabledCurfews';
import { DeviceCatFlapControlModel, DevicePetDoorControlModel } from '@models/Device';
import colors from '@styles/colors';
import LockSVG from '@atoms/SvgIcons/LockSVG';
import UnlockSVG from '@atoms/SvgIcons/UnlockSVG';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { LockOptionInfo } from '../../constants/LockOption';
import useBoundStore from 'src/store/store';
import useCurfewActive from "../../hooks/useCurfewActive";
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';
import LoadingSpinner from '@atoms/Loader';

type Props = {
  data: DeviceCatFlapControlModel | DevicePetDoorControlModel;
};

const LockInfo: React.FC<Props> = ({ data }) => {
  const locked = useDoorLockedStatus(data);
  const loadingControl = useBoundStore((state) => state.deviceStore.loadingControl);
  const enabledCurfews = useEnabledCurfews(data);
  const { t } = useTranslation();
  const { isCurfewActive, lockUnlockTime } = useCurfewActive(enabledCurfews);

  const lockTitle = useMemo(() => {
    return locked ? t('locked') : t('unlocked');
  }, [locked, t]);


  const lockDescription = useMemo(() => {
    return LockOptionInfo[data.locking]?.label;
  }, [data?.locking, locked]);
  const userTimeUnits = useUserTimeUnits();
  const { timeToFormat } = useTimeTransform();

  const convertTimeToUnits = (time: string) => {
    return timeToFormat(time, userTimeUnits);
  };

  const { updateCurfewLoading } = useBoundStore((s) => s.deviceStore);
  let isLongLang = false;

  if (useCurrentLanguageCode() === 'pt-br' || useCurrentLanguageCode() === 'fr') isLongLang = true;

  const lockUnlockTimeView = useMemo(() => {
    if (!lockUnlockTime) return null;
    const splitedData = lockUnlockTime?.split(' ');
    const [lockTime, unlockTime] = [
      convertTimeToUnits(splitedData[0]),
      convertTimeToUnits(splitedData[1]),
    ];
    return (
      <SpView
        style={[
          styles.timeContainer,
          styles.mainContainer,
          { flexDirection: isLongLang ? 'column' : 'row' },
        ]}>
        <SpView style={styles.timeContainer}>
          <LockSVG color={colors.primary.color} width={16} height={16} viewBox="0 0 28 28" />
          <SpText style={styles.textLockUnlock}>{lockTime}</SpText>
        </SpView>
        <SpView style={styles.timeContainer}>
          <UnlockSVG color={colors.primary.color} width={16} height={16} viewBox="0 0 28 28" />
          <SpText style={styles.textLockUnlock}>{unlockTime}</SpText>
        </SpView>
      </SpView>
    )
  }, [loadingControl.curfew, lockUnlockTime]);
  const icon = useMemo(() => {
    const props = {
      color: colors.primary.color,
      width: 28,
      height: 28,
      viewBox: '0 0 28 28',
    };
    return locked ? <LockSVG {...props} /> : <UnlockSVG {...props} />;
  }, [locked]);
  return (
    <SpView style={styles.curfews}>
      <SpView style={styles.curfewItem}>
        <IconButton
          width={64}
          height={64}
          action={noop}
          rounded
          svgIcon={icon}
          componentName="faLock"
          iconColor={colors.primary.color}
          backgroundColor={colors.lightGrey.color}
        />
        <SpText size="md" style={styles.title} align="center">
          {lockTitle}
        </SpText>
        {lockDescription && (
          <SpText size="sm" style={styles.description} align="center">
            {lockDescription}
          </SpText>
        )}
      </SpView>
      {!!enabledCurfews.length && (
        <SpView style={styles.curfewItem}>
          {loadingControl.curfew ? (
            <SpView style={styles.loader}>
              <LoadingSpinner />
            </SpView>
          ) : (
            <SpView style={styles.curfewItem}>
              <IconButton
                width={64}
                height={64}
                action={noop}
                rounded
                componentName="faHouse"
                iconColor={colors.greyText.color}
                icon={faHouse}
                backgroundColor={colors.lightGrey.color}
              />
              <SpText size="md" style={styles.title} numberOfLines={1}>
                {isCurfewActive
                  ? t(getCurfewStatusText(!!enabledCurfews.length))
                  : t('curfew_next')}
              </SpText>
              {lockUnlockTimeView}
            </SpView>
          )}
        </SpView>
      )}
    </SpView>
  );
};

export default LockInfo;

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 8,
    textAlign: 'center',
    minWidth: 100,
  },
  description: {
    marginTop: 4,
    color: 'rgba(0, 0, 0, 0.59)',
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
    justifyContent: 'center',
  },
  mainContainer: {
    gap: 10,
    maxWidth: 150,
    alignItems: 'center',
  },
  curfews: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 10,
  },
  textLockUnlock: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.greyText.color,
  },
  curfewItem: {
    alignItems: 'center',
    width: '50%',
  },
});
