import * as React from 'react';
import { G, Path, Svg } from 'react-native-svg';

const CurfewEnabledSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={30} height={28} {...props}>
    <G fill="#263A43" fillRule="evenodd">
      <Path
        fillRule="nonzero"
        d="m16.036 1.123.798.694A7.931 7.931 0 0 0 28.75 12.168l.745.648c.473.412.63 1.045.399 1.614-.231.57-.795.937-1.435.937h-2.15v11.717c0 .464-.395.841-.882.841H18.05c-.487 0-.882-.377-.882-.841V19.97h-4.336v7.114c0 .464-.395.841-.882.841H4.572c-.487 0-.882-.377-.882-.841V15.367H1.54C.9 15.367.337 15 .105 14.43a1.411 1.411 0 0 1 .399-1.613l13.46-11.694a1.587 1.587 0 0 1 2.072 0Z"
      />
      <Path d="M23.307.173a6.23 6.23 0 1 1 0 12.46 6.23 6.23 0 0 1 0-12.46Zm0 1.465c-.625 0-1.133.463-1.133 1.034v3.83c0 .062.007.124.02.183.045.272.19.527.426.708l2.663 2.046a1.133 1.133 0 1 0 1.38-1.797L24.44 5.934l.001-3.262c0-.571-.507-1.034-1.133-1.034Z" />
    </G>
  </Svg>
);
export default CurfewEnabledSVG;
