import { FieldsObj } from '@models/FormikTypes';

export const stepFormikValid = (
  key: string,
  submitErrors: FieldsObj,
  formikErrors: FieldsObj,
) => {
  if (submitErrors?.[key] && formikErrors?.[key]) {
    return formikErrors[key];
  }
  return undefined;
};
