/* eslint-disable react/jsx-no-useless-fragment */
import { SpHouseIcon } from '@atoms/RoundIcons/SpHouseIcon';
import { SpHStack } from '@atoms/SpHStack';
import { SpText } from '@atoms/SpText';
import { SpVStack } from '@atoms/SpVStack';
import React from 'react';
import { PetPosition } from '@constants/Pet';
import { Dimensions, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { getSingleDuration } from '@utils/date';
import { SpTreesIcon } from '@atoms/RoundIcons/SpTreesIcon';
import { useTranslation } from 'react-i18next';
import { SpCenter } from '@atoms/SpCenter';
import text from '@styles/text';

const CONTAINER_VERTICAL_PADDING = Math.min(
  (Dimensions.get('screen').height / 100) * 1.6,
  16
);
interface PetMovementNotificationProps {
  locationSince: string;
  location: PetPosition;
}

export const PetMovementNotification = ({
  locationSince,
  location,
}: PetMovementNotificationProps) => {
  const { t } = useTranslation();
  const PetIcons = {
    0: <></>,
    1: <SpHouseIcon />,
    2: <SpTreesIcon />,
  };
  return locationSince ? (
    <SpHStack
      backgroundColor={colors.secondary4.color}
      borderRadius={16}
      paddingHorizontal={20}
      paddingVertical={16}
      alignItems="center"
      justifyContent="space-between"
      style={styles.generalContainer}
      space={8}
    >
      <SpVStack space={2}>
        <SpText style={styles.movementTitle}>{t(PetPosition[location])}</SpText>
        <SpText style={styles.movementDuration}>
          {getSingleDuration(locationSince)}
        </SpText>
      </SpVStack>
      {PetIcons[location]}
    </SpHStack>
  ) : (
    <SpCenter style={[styles.generalContainer, styles.unknownContainer]}>
      <SpText style={[text.smallTitle, styles.unknownText]}>
        {t('location_unknown')}
      </SpText>
    </SpCenter>
  );
};

const styles = StyleSheet.create({
  movementTitle: {
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
  },
  movementDuration: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    maxWidth: 190,
  },
  generalContainer: {
    borderRadius: 16,
    paddingHorizontal: 20,
    paddingVertical: CONTAINER_VERTICAL_PADDING,
  },
  unknownText: {
    color: colors.lightBlack.color,
  },
  unknownContainer: {
    backgroundColor: colors.greyInput.color,
    paddingVertical: 28,
  },
});
