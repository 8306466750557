import { SpView } from '@atoms/SpView';
import React, { PropsWithChildren, ReactNode } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';

type DeviceSettingContainerProps = {
  children?: ReactNode,
  showMarginTop?: boolean
}

export const DeviceSettingContainer = ({ children, showMarginTop = false }: DeviceSettingContainerProps) => {
  return (
    <SafeAreaView style={styles.view}>
      <SpView flex={1} paddingHorizontal={18} marginTop={showMarginTop ? 0 : 23}>
        {children}
      </SpView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: '#fff',
    height: '100%',
    justifyContent: 'space-between',
  },
});
