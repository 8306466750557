import EssenceCreated from '@atoms/EssenceCreated';
import { DeviceType } from '@constants/Device';
import { useDevicesSearchService } from '@hooks/useDeviceService';
import StepFormCreator from '@molecules/StepFormCreator';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeviceEventEmitter, Linking, StyleSheet, View } from 'react-native';
import useValidation from 'src/pages/validation';
import useBoundStore from 'src/store/store';
import AddProduct from 'src/pages/Flows/Generic/AddProduct/AddProduct';
import { useTranslation } from 'react-i18next';
import { AppImages } from '@constants/AppImages';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import SpTitle from '@molecules/SpTitle';
import ProgressHeader from '@molecules/ProgressHeader';
import { DeviceFeederBowlModel } from '@models/Device';
import { navigateToProducts } from '@utils/navigationShortcuts';
import { StepFormStepsObj } from '@models/FormikTypes';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import NameForm from '../../Generic/NameForm';
import { BeforeYouContinue } from '../../Generic/BeforeYouContinue';
import PromptPage from '../../Generic/PromptPage/PromptPage';
import InstructionsSteps from '../../Molecules/InstructionsSteps';
import ChooseBowlType from '../../Feeder/ChooseBowlType';
import FitBowl from '../../Feeder/FitBowl';
import ZeroFeeder from '../../Feeder/ZeroFeeder';
import SelectMeals from '../../Feeder/SelectMeals';
import PortionAmountForm from '../../Feeder/PortionAmountForm';
import { CompleteSetupProduct } from '../../Generic/CompleteSetupProduct';
import { FlowStackParamList } from '../../index';

export const resolveBowlType = (
  singleOption: any,
  leftBowlOption: any,
  rightBowlOption: any,
  isLeft?: boolean,
  isBowlSingle?: boolean,
) => {
  if (isBowlSingle) {
    return singleOption;
  }
  if (isLeft) {
    return leftBowlOption;
  }
  return rightBowlOption;
};

enum Steps {
  FIT_BATTERY = 1,
  PAIR,
  ENTER_NAME,
  ADDED,
  BOWL_SETUP,
  FIT_BOWL,
  ZERO_FEEDER,
  MEALS_SETUP,
  ENTER_AMOUNT,
  SETUP_COMPLETED,
}

export interface BowlOptions {
  single: number;
  left: number;
  right: number;
}

type Props = NativeStackScreenProps<FlowStackParamList, 'AddFeeder'>;

const AddFeeder = ({ route }: Props) => {
  const [step, setStep] = useState(route?.params?.step || 1);
  const [deviceIdentifier, setDeviceIdentifier] = useState(
    route?.params?.deviceId,
  );
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [submitValues, setSubmitValues] = useState<any>();
  const [tryTimes, setTryTimes] = useState(0);
  const { t } = useTranslation();
  const { updateTarget, updateFoodTypeAsync } = useBoundStore(
    s => s.deviceStore,
  );
  const { commonNameSchema } = useValidation();
  const [bowlType, setBowlType] = useState(null);
  const isBowlSingle = bowlType === 1;
  const optionsObj: BowlOptions = {
    single: null,
    left: null,
    right: null,
  };
  const [bowlFoodType, setBowlFoodType] = useState(optionsObj);
  const [weightError, setWeightError] = useState(optionsObj);
  const [weight, setWeight] = useState<BowlOptions>(optionsObj);
  const [name, setName] = useState<string>('');
  const { deviceSearchService, connected, otherDevices, maxRetriesError } =
    useDevicesSearchService(DeviceType.FeederConnect, setSelectedDevice);

  const navigation = useNavigation<NavigationProp<any>>();
  const { loadDevice, updateNameRequest, getDeviceById } = useBoundStore(
    s => s.deviceStore,
  );

  useEffect(() => {
    (async () => {
      await loadDevice(true);
      if (!selectedDevice && deviceIdentifier) {
        setSelectedDevice(getDeviceById(deviceIdentifier));
      }
    })();
  }, [selectedDevice, deviceIdentifier]);

  useEffect(() => {
    DeviceEventEmitter.addListener('AddFeederPlusStep', () =>
      setStep(step + 1),
    );
    return () => {
      DeviceEventEmitter.removeAllListeners('AddFeederPlusStep');
    };
  }, [step]);

  useEffect(() => {
    if (step === Steps.PAIR) {
      deviceSearchService?.start();
    }

    return () => {
      return deviceSearchService ? deviceSearchService.stop() : null;
    };
  }, [step, deviceSearchService]);

  const tryAgain = () => {
    if (tryTimes > 1) {
      setStep(1);
      Linking.openURL(t('get_help_url'));
      return;
    }
    deviceSearchService.maxRetriesHandler(false);
    setTryTimes(old => ++old);
    setStep(2);
    deviceSearchService.start();
  };

  const foundDeviceStep = useMemo((): StepFormStepsObj => {
    return maxRetriesError
      ? {
          ui: (
            <AddProduct
              subtitle={t('feeder_subtitle')}
              connected={connected}
              imageUrl={AppImages.pairFeeder}
              searchMsg={t('feeder_looking_msg')}
              connectedMsg={
                maxRetriesError
                  ? t('feeder_not_found')
                  : t('feeder_connected_msg')
              }
              maxRetriesError={maxRetriesError}
            />
          ),
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          buttonText: tryTimes > 1 ? t('get_help') : t('sorry_try_again'),
          forcePressHandler: tryAgain,
        }
      : {
          ui: (
            <View style={{ marginLeft: -10, flex: 1 }}>
              <AddProduct
                subtitle={t('feeder_subtitle')}
                connected={connected}
                imageUrl={AppImages.pairFeeder}
                connectedMsg={t('feeder_connected_msg')}
                searchMsg={t('feeder_looking_msg')}
                maxRetriesError={maxRetriesError}
              />
            </View>
          ),
          hideButton: !connected,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          effect: () => {
            if (!connected && otherDevices.length === 0) {
              deviceSearchService.stop();
            }
          },
          interceptor: () => {
            if (connected && otherDevices.length === 0) {
              return true;
            }
            deviceSearchService.start();
            return false;
          },
        };
  }, [maxRetriesError, connected, deviceSearchService, tryTimes, setStep]);

  const portionAmount = () => {
    const portionFormCreator = (isLeft?: boolean) => {
      return (
        <PortionAmountForm
          bowlFoodType={bowlFoodType}
          weight={weight}
          setWeight={setWeight}
          weightError={weightError}
          setWeightError={setWeightError}
          isBowlSingle={isBowlSingle}
          isLeft={isLeft}
        />
      );
    };

    return (
      <>
        <SpTitle
          style={{ marginTop: -30 }}
          align='center'
          text={t('portion_amount')}
        />
        <SpText style={{ marginBottom: 20 }} align='center'>
          {t('portion_amount_subtitle')}
        </SpText>
        {isBowlSingle ? (
          portionFormCreator()
        ) : (
          <View>
            {portionFormCreator(true)}
            <View style={{ marginTop: 20 }}>{portionFormCreator()}</View>
          </View>
        )}
      </>
    );
  };

  const bowlSetupFinish = useMemo(() => {
    const weightSetted = isBowlSingle
      ? weight.single
      : weight.left || weight.right;
    return weightSetted ? (
      <>
        <ProgressHeader
          step={12}
          stepsCount={12}
          title={t('configure_product')}
        />
        <View style={{ marginTop: 24 }}>
          <SpTitle align='center' text={t('bowl_portion_title')} />
        </View>
        <InstructionsSteps
          arr={[t('bowl_portion_subtitle'), t('bowl_portion_subtitle2')]}
          space={12}
        />
      </>
    ) : (
      <PromptPage
        title={t('no_portion_title')}
        subtitle={t('no_portion_subtitle')}
        subtitle2={t('no_portion_subtitle2')}
        buttonTitle={t('setup_portion')}
        buttonAction={() => setStep(step - 1)}
        additionalPadding={80}
      />
    );
  }, [isBowlSingle, weight, step]);

  const updateFeeder = useCallback(async () => {
    const arr = [];
    arr.push(
      isBowlSingle
        ? {
            food_type: bowlFoodType.single,
            target: Number(weight?.single) || 0,
          }
        : {
            food_type: bowlFoodType.left,
            target: Number(weight?.left) || 0,
          },
    );
    if (!isBowlSingle) {
      arr.push({
        food_type: bowlFoodType.right,
        target: Number(weight?.right) || 0,
      });
    }
    const bowls: DeviceFeederBowlModel = {};
    bowls.settings = arr;
    bowls.type = bowlType;

    updateFoodTypeAsync(selectedDevice?.id, bowls);
    return true;
  }, [weight, bowlFoodType, isBowlSingle, selectedDevice?.id]);

  const onFeederSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        await updateNameRequest(selectedDevice?.id, {
          name: submitValues.name,
        });
        await loadDevice(true);
        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, selectedDevice?.id],
  );

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={step < 5 ? t('add_product') : t('configure_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      leaveButtonAction={() => {
        navigation.navigate('FlowNavigation', { screen: 'SelectProduct' });
      }}
      steps={{
        1: {
          ui: <BeforeYouContinue productId={DeviceType.FeederConnect} />,
          customHeaderProps: {
            withRightButton: true,
            rightButtonText: t('get_help'),
            withLeaveButton: true,
          },
          backBottomButton: true,
        },
        2: foundDeviceStep,
        3: {
          ui: NameForm,
          props: {
            setName,
            label: t('product_name'),
            title: t('name_feeder_title'),
            subtitle: t('subtitle {{productName}} {{example}}', {
              example: t('name_feeder_example'),
              productName: t('pet_feeder'),
            }),
            subtitle2: t('subtitle2'),
            disableKeyboardAwoiding: true,
          },
          formik: {
            schema: commonNameSchema('Feeder name'),
            names: ['name'],
          },
          keyboardAwoidingProps: {
            androidAdjustType: 'force',
            extraScrollHeight: 100,
          },
          customHeaderProps: {
            withLeaveButton: true,
          },
          buttonDisabled: name === '',
        },
        4: {
          ui: (
            <CompleteSetupProduct
              productId={DeviceType.FeederConnect}
              onLoadedCallback={onFeederSubmit}
              deviceId={selectedDevice?.id}
              onPress={() => {
                navigation.navigate('AddPets', {
                  deviceId: selectedDevice?.id,
                  deviceType: DeviceType.FeederConnect,
                  step: 3,
                });
              }}
              disableAddBtn
            />
          ),
          invisibleHeader: true,
          hideButton: true,
          handleAndroidBackPress: () => {
            navigateToProducts()
            return true
          }
        },
        5: {
          ui: (
            <View style={{ marginTop: 32 }}>
              <ChooseBowlType
                bowlType={bowlType}
                setBowlType={setBowlType}
                withTitle
              />
            </View>
          ),
          customStyleButton: { marginBottom: 30 },
          buttonDisabled: bowlType === null,
          interceptor: () => {
            if (bowlType) {
              return true;
            }
            return false;
          },
          customHeaderProps: {
            withLeaveButton: true,
          },
        },
        6: {
          ui: FitBowl,
          props: { isBowlSingle },
        },
        7: {
          ui: ZeroFeeder,
          backBottomButton: true,
        },
        8: {
          ui: SelectMeals,
          props: {
            bowlFoodType,
            setBowlFoodType,
            isBowlSingle,
            isLeft: true,
          },
          buttonDisabled: isBowlSingle
            ? !bowlFoodType.single
            : !bowlFoodType.left,
          backBottomButton: true,
          interceptor: () => {
            if (bowlFoodType) {
              return true;
            }
            return false;
          },
        },
        9: {
          ui: (
            <View>
              <SelectMeals
                bowlFoodType={bowlFoodType}
                setBowlFoodType={setBowlFoodType}
                isBowlSingle={isBowlSingle}
              />
            </View>
          ),
          backBottomButton: true,
          skipStep: isBowlSingle,
          buttonDisabled: !bowlFoodType.right,
        },
        10: {
          ui: portionAmount(),
          backBottomButton: true,
          interceptor: updateFeeder,
          extraScrollHeightAndroid: isBowlSingle ? 0 : 100,
          buttonDisabled: isBowlSingle
            ? !!weightError.single
            : !!weightError.right || !!weightError.left,
        },
        11: {
          ui: bowlSetupFinish,
          hideProgressHeader: true,
          hideCustomHeader: true,
        },
        12: {
          ui: (
            <View style={[styles.center, { marginTop: 112 }]}>
              <EssenceCreated msg={t('setup_complete')} initialValue />
            </View>
          ),
          interceptor: () => {
            navigateToProducts();
            return false;
          },
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  formWrapper: { paddingHorizontal: 24 },
  error: {
    fontSize: 12,
    color: 'red',
    textAlign: 'center',
  },
  errorWrapper: {
    height: 25,
  },
  center: {
    alignItems: 'center',
  },
  link: {
    color: colors.greyText.color,
    textAlign: 'center',
    marginVertical: 24,
  },
  feederAddedContainer: { flex: 1, paddingTop: 80 },
});

export default AddFeeder;
