import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import { SpInput } from '@atoms/SpInput';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import SpTitle from '@molecules/SpTitle';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import text from '@styles/text';
import { get } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, SafeAreaView, StyleSheet } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import useBoundStore from '../../store/store';
import useValidation from '../validation';
import { RemoveAccountParamList } from './index';
import { LayoutFooter } from './LayoutFooter';
import { PlaceholderText } from './molecules/PlaceholderText';

type ConfirmProps = NativeStackScreenProps<
  RemoveAccountParamList,
  'ConfirmStep'
>;

export const ConfirmStep = ({ route }: ConfirmProps) => {
  const { simplePasswordSchema } = useValidation();
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RemoveAccountParamList>>();
  const selectedHouseholds = route.params?.selectedHouseholds || [];
  const { user, deleteUser } = useBoundStore(s => s.accountStore);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<{ password: string }>({
    mode: 'onSubmit',
    resolver: yupResolver(simplePasswordSchema),
  });

  const submitAction = useCallback(
    async (data: { password: string }) => {
      setLoading(true);
      try {
        await deleteUser(
          selectedHouseholds.map(item => item.id),
          data.password,
        );
        navigation.reset({
          index: 0,
          routes: [{ name: 'RemoveSuccess' }],
        });
      } catch (e) {
        setError('password', {
          type: 'custom',
          message: t('password_is_incorrect'),
        });
        setValue('password', '');
      } finally {
        setLoading(false);
      }
    },
    [handleSubmit, deleteUser, selectedHouseholds, setError],
  );

  const forgotPasswordAction = useCallback(() => {
    navigation.navigate('ResetPassword', { selectedHouseholds });
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <CustomKeyboardAvoidingView
        scrollView
        extraHeight={20}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollContainer}
      >
        <SpView flex={1}>
          <SpView marginTop={28}>
            {selectedHouseholds.length ? (
              <>
                <SpView marginBottom={24}>
                  <SpText align="center">
                    {t('ad_confirm_delete_household_title')}
                  </SpText>
                </SpView>

                <SpVStack space={12}>
                  <PlaceholderText text={user?.email_address} />
                  {selectedHouseholds.map(item => (
                    <PlaceholderText key={item.id} text={item.name} />
                  ))}
                </SpVStack>
              </>
            ) : (
              <>
                <SpView marginBottom={24}>
                  <SpText align="center">
                    {t('ad_confirm_delete_account_title')}
                  </SpText>
                </SpView>
                <PlaceholderText text={user?.email_address} />
              </>
            )}
          </SpView>

          <SpView marginTop={46}>
            <SpView alignItems="center">
              <SpView width="80%">
                <SpText
                  align="center"
                  fontFamily="Rubik_SemiBold"
                  size="sm"
                  color={colors.greyText.color}
                >
                  {t('ad_confirm_re_enter_password')}
                </SpText>
              </SpView>
            </SpView>

            <SpView marginTop={28}>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SpInput
                    label={t('retype_password')}
                    onChangeText={onChange}
                    value={value}
                    error={t(get(errors, 'password.message'))}
                    password
                  />
                )}
                name="password"
              />
              <Pressable
                style={styles.forgotWrap}
                onPress={forgotPasswordAction}
              >
                <SpText style={[text.linkText, styles.forgotText]}>
                  {t('forgotten_your_password')}
                </SpText>
              </Pressable>
            </SpView>
          </SpView>
        </SpView>

        <LayoutFooter
          withBackButton
          nextButtonText={t('continue')}
          onNext={handleSubmit(submitAction)}
          isLoading={loading}
          extraContainerStyle={styles.zeroPadding}
        />
      </CustomKeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  forgotWrap: {
    marginTop: 30,
  },
  forgotText: {
    textAlign: 'left',
  },
  view: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 19,
  },
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  zeroPadding: {
    paddingHorizontal: 0,
  },
});
