import { SpProfileImage } from '@atoms/SpProfileImage';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';

export const TimelinePetPhoto = ({
  active,
  event,
}: {
  active: boolean;
  event: TimelineEventModel;
}) => {
  const pets = usePetsByHousehold();
  const petId = useMemo(() => {
    return get(event, 'pets[0].id', null);
  }, [event]);
  const currentPet = useMemo(() => {
    return pets.find(item => item.id === petId);
  }, [pets, petId]);

  return (
    <SpProfileImage
      backgroundColor='#dde0e2'
      imageUrl={currentPet?.photo?.location}
      width={active ? 56 : 48}
      height={active ? 56 : 48}
      withBorder={active}
      borderWidth={2}
      borderColor={colors.green.color}
    />
  );
};
