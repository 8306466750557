import { StyleSheet } from 'react-native';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React from 'react';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { addDays, format, startOfWeek } from 'date-fns';
import mockDateData from './mockDateData';
import DateItemMock from './DateItemMock';

const HistoricalDataDashboardSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.calendar}>
        {mockDateData.map((item, ind) => {
          const dayOfTheWeek = format(
            addDays(startOfWeek(new Date()), ind + 1),
            'EE',
          )[0];
          return (
            <DateItemMock
              key={`${item.date}`}
              isActive={item.isActive}
              dateNumber={item.date}
              dayOfTheWeek={dayOfTheWeek}
              circleBorderColor={item.borderColor}
            />
          );
        })}
      </SpView>
      <SpTitle align="center" text={t('historical_data')} />
      <SpText style={styles.contentText}>
        {t('historical_data_tour_text')}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    lineHeight: 24,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  calendar: {
    marginTop: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
});

export default HistoricalDataDashboardSlide;
