import { Image, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import SelectButtonsWrapper from '@molecules/SelectButtonsWrapper';

interface ThereSeemsToBeAProblemProps {
  bowlFlashingOrange: number;
  setIsFlashingOrange: (bowlFlashingOrange: number) => void;
}

export const ThereSeemsToBeAProblem = ({
  bowlFlashingOrange,
  setIsFlashingOrange,
}: ThereSeemsToBeAProblemProps) => {
  const { t } = useTranslation();
  enum dogBowlStillFlashingOptions {
    no_its_not = 0,
    yes_it_is = 1,
  }
  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <Image
            style={{ width: 200 }}
            source={AppImages.cdbFailedtoPair}
            resizeMode="contain"
            {...testProperties('cdbPairingHousehold', 'image')}
          />
          <SpText style={[text.title]}>
            There seems to have been a problem.
          </SpText>
          <SpText style={[text.body, styles.text]}>
            Is your Dog Bowl Connect still flashing orange?
          </SpText>
          <SelectButtonsWrapper
            optionsEnum={dogBowlStillFlashingOptions}
            handleChange={setIsFlashingOrange}
            selectedOption={dogBowlStillFlashingOptions[bowlFlashingOrange]}
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '10%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
  },
});

export default ThereSeemsToBeAProblem;
