import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { TagModel } from '@models/Tag';
import { TimelineEventModel } from '@models/Timeline';
import { get } from 'lodash-es';
import React, { useMemo } from 'react';
import useBoundStore from '../../../store/store';

export const useTimelinePetName = (event: TimelineEventModel) => {
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const pets = usePetsByHousehold();

  const getPetFromTagID = (tagID: number) => {
    if (!tagID) {
      return null;
    }

    return pets.find(item => item.tag_id === tagID);
  };

  const getPetFromTag = (tag: TagModel) => {
    return tag ? getPetFromTagID(tag.id) : null;
  };

  const getPetFromTags = (tags: TagModel[]) => {
    const tag = get(tags, '[0]');

    if (!tag) {
      return null;
    }

    return getPetFromTag(tag);
  };

  return useMemo(() => {
    const pet = event.pets ? event.pets[0] : getPetFromTags(event.tags);
    
    if (!pet || !pet.name) {
      if (!event.tags || !event.tags[0]) {
        return null;
      }

      return event.tags[0].tag;
    }

    return pet.name;
  }, [event.tags, event.pets, activeHousehold]);
};
