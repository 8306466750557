import { subDays, subMonths } from 'date-fns';
import React, { useMemo } from 'react';
import { ReportRangeType } from '../constants/ReportRangeType';

export function useGroupedRangeReportStats(
  date: number,
  range: ReportRangeType,
): { start: number; finish: number } {
  return useMemo(() => {
    switch (range) {
      case ReportRangeType.OneDay:
        return {
          start: date,
          finish: date,
        };
      case ReportRangeType.SevenDays:
        return {
          start: subDays(date, 6).getTime(),
          finish: date,
        };
      case ReportRangeType.TwentyEightDays:
        return {
          start: subDays(date, 27).getTime(),
          finish: date,
        };
      case ReportRangeType.SixMonths:
        return {
          start: subMonths(date, 5).getTime(),
          finish: date,
        };
      case ReportRangeType.OneYear:
        return {
          start: subMonths(date, 11).getTime(),
          finish: date,
        };
      default:
        return {
          start: 0,
          finish: date,
        };
    }
  }, [date, range]);
}
