import { DeviceType } from '@constants/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useEffect, useRef, useState } from 'react';
import { RootStackParamList } from 'src/pages';
import SearchDeviceService from 'src/services/SearchDevice';
import DeviceApi from '@api/DeviceApi';
import { DeviceModel } from '@models/Device';
import useBoundStore from 'src/store/store';
import { MAX_DEVICE_SEARCH_RETRIES } from '@constants/FindDevice';
import { useDevicesByHousehold } from './useDevicesByHousehold';
import { FlowStackParamList } from '../pages/Flows';

// Rename "useDevicesSearchService" to "useDeviceSearch" for better readability
export const useDevicesSearchService = (
  productId: number,
  setDeviceSelected?: any,
  maxRetries = MAX_DEVICE_SEARCH_RETRIES,
) => {
  const deviceServiceRef = useRef<SearchDeviceService>();
  const [connected, setConnected] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [foundDevices, setFoundDevices] = useState([]);

  const [otherDevices, setOtherDevices] = useState([]);
  const [deviceName, setDeviceName] = useState('');
  const devices = useDevicesByHousehold();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();

  const [isLoading, setIsLoading] = useState(false);
  const [maxRetriesError, setMaxRetriesError] = useState(false);
  const { loadDevice, updateNameRequest } = useBoundStore(s => s.deviceStore);

  // Initialize device search service
  const initDeviceSearchService = () => {
    deviceServiceRef.current = new SearchDeviceService({
      productId,
      devices,
      onSuccess: handleSuccess,
      onError: handleError,
      onFound: handleFoundOtherDevice,
      loadingEnd: () => setIsLoading(false),
      loadingStart: () => setIsLoading(true),
      maxRetriesHandler: (value: boolean) => setMaxRetriesError(value),
      activeHouseholdId: activeHousehold.id,
      maxRetries,
    });
  };

  useEffect(() => {
    if (activeHousehold?.id) {
      initDeviceSearchService();
    }
  }, [devices, setConnected, setDeviceId, setOtherDevices]);

  useEffect(() => {
    if (otherDevices?.length) {
      flowNavigation.navigate('IncorrectProduct', {
        foundDeviceType: otherDevices[0].product_id,
        foundDevice: otherDevices[0].id,
      });
    }
  }, [otherDevices]);

  // Handle successful device search
  const handleSuccess = (data: DeviceModel[]) => {
    // Pairing logic based on device type
    if (
      data[0].product_id === DeviceType.Hub ||
      data[0].product_id === DeviceType.Cerberus
    ) {
      console.log('Is a hub, so will pair', data);
      // if its a hub or only one device found then pair the first one found
      if (data[0].product_id === DeviceType.Hub) {
        DeviceApi.pairAsync(data[0].id, activeHousehold.id).then(success => {
          setConnected(true);
          setDeviceId(data[0].id);
        });
        return;
      }
      // if there is more than one device in date set other devices else pair the only one found
      setFoundDevices(data);
    } else {
      setConnected(true);
      if (setDeviceSelected) {
        setDeviceSelected(data[0]);
      }
      setDeviceName(data[0].name);
      setDeviceId(data[0].id);
    }
  };

  const pairChosenDevice = (chosenDeviceID: number) => {
    DeviceApi.pairAsync(chosenDeviceID, activeHousehold.id)
      .then(success => {
        setConnected(true);
        setDeviceId(chosenDeviceID);
      })
      .catch(err => {
        console.log(err);
      });
  };
  // Handle error during device search
  const handleError = (err: any) => {
    console.log(err);
  };

  // Handle found devices
  const handleFoundOtherDevice = (devicesArr: any[]) => {
    setOtherDevices(devicesArr);
  };

  const onSubmit = (name: string, screen = 'Products') => {
    try {
      updateNameRequest(deviceId, { name });
      loadDevice(true);
      navigation.navigate('DashboardNavigation', {
        screen,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    return () => {
      deviceServiceRef.current?.stop();
    };
  }, []);

  // Display toast when other connections are found

  return {
    deviceSearchService: deviceServiceRef.current,
    connected,
    isLoading,
    deviceId,
    onSubmit,
    deviceName,
    otherDevices,
    pairChosenDevice,
    foundDevices,
    maxRetriesError,
  };
};
