import { DeviceType } from '@constants/Device';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { useMemo } from 'react';

import { DeviceModel } from '../models/Device';

export const useCDBInHousehold = (): DeviceModel[] => {
  const devices = useDevicesByHousehold();

  return useMemo(() => {
    return (devices || []).filter(
      (device: DeviceModel) => device.product_id === DeviceType.Cerberus,
    );
  }, [devices]);
};
