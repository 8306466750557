import { SpText } from '@atoms/SpText';
import { SpVStack } from '@atoms/SpVStack';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { SpHStack } from '@atoms/SpHStack';
import colors from '@styles/colors';
import { QuickActionButton } from '@constants/Navigation';
import { SpRoundIcon } from '@atoms/RoundIcons/SpRoundIcon';
import { testProperties } from '@utils/testProperties';

interface LockActionsProps {
  buttons: QuickActionButton[];
  textStyle?: React.CSSProperties;
  onSelect: (data: any) => void;
  disabled?: boolean;
}

export const SpLockUnlockActions = ({
  buttons,
  textStyle = null,
  onSelect,
  disabled = false,
}: LockActionsProps) => {
  return (
    <SpVStack space={10} width="100%">
      {buttons.map((item, index) => {
        return (
          <Pressable
            onPress={() => onSelect(item?.id)}
            key={`${item.text}${index}`}
            {...testProperties('button', `lockButton${index}`)}

            style={styles.stack}
            disabled={disabled}

          >
            <SpText style={[styles.movementTitle, textStyle]}>
              {item.text}
            </SpText>
            <SpRoundIcon
              icon={item.icon}
              size={12}
              color={colors.secondary2.color}
              action={() => onSelect(item?.id)}
            />
          </Pressable>
        );
      })}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    padding: 20,
    flexDirection: 'row',
  },
  image: {
    height: 36,
    width: 36,
  },
  movementTitle: {
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
    width: '85%',
  },
  movementDuration: {
    fontFamily: 'Rubik',
    fontSize: 16,
    colors: colors.greyText.color,
    maxWidth: 190,
  },
  stack: {
    backgroundColor: colors.secondary3.color,
    borderRadius: 16,
    paddingHorizontal: 20,
    paddingVertical: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});
