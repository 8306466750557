import { RemoveAccountCases } from '../constants/RemoveAccountCases';
import { useGroupedHouseholds } from './useGroupedHouseholds';

export const useRemoveCase = () => {
  const data = useGroupedHouseholds();

  if (data.householdsAsOwner.length > 1) {
    return RemoveAccountCases.OwnerMultiple;
  }

  if (data.householdsAsOwner.length) {
    return RemoveAccountCases.Owner;
  }

  return RemoveAccountCases.Viewer;
};
