import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const PetActiveSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <G fill="#03A2B1" fillRule="nonzero" stroke="#03A2B1" strokeWidth={0.5}>
      <Path d="M14.052 11.727a5.192 5.192 0 0 0-4.994 3.738 2.47 2.47 0 0 1-.884 1.271 5.142 5.142 0 0 0-2.036 4.241c.073 2.734 2.314 4.954 5.064 5.021a5.212 5.212 0 0 0 1.977-.335 2.468 2.468 0 0 1 1.746 0 5.213 5.213 0 0 0 1.978.335c2.75-.067 4.99-2.287 5.064-5.02a5.142 5.142 0 0 0-2.037-4.242 2.47 2.47 0 0 1-.884-1.27 5.192 5.192 0 0 0-4.994-3.74Zm0 1.697c1.505 0 2.82.952 3.302 2.328l.058.182a4.15 4.15 0 0 0 1.296 1.998l.19.154a3.447 3.447 0 0 1 1.368 2.846c-.049 1.83-1.559 3.325-3.405 3.37a3.52 3.52 0 0 1-1.12-.153l-.212-.072a4.173 4.173 0 0 0-2.714-.083l-.24.083c-.42.159-.87.236-1.332.225-1.846-.045-3.356-1.54-3.405-3.37a3.443 3.443 0 0 1 1.207-2.716l.16-.13a4.182 4.182 0 0 0 1.414-1.922l.074-.23a3.491 3.491 0 0 1 3.36-2.51ZM4.337 8.798C2.443 8.798 1 10.63 1 12.806s1.443 4.009 3.337 4.009c1.894 0 3.337-1.834 3.337-4.009 0-2.175-1.443-4.008-3.337-4.008Zm0 1.697c.852 0 1.636.996 1.636 2.311 0 1.316-.784 2.312-1.636 2.312-.852 0-1.636-.996-1.636-2.312 0-1.315.784-2.311 1.636-2.311ZM23.663 8.798c-1.894 0-3.337 1.833-3.337 4.008s1.443 4.009 3.337 4.009c1.894 0 3.337-1.834 3.337-4.009 0-2.175-1.443-4.008-3.337-4.008Zm0 1.697c.852 0 1.636.996 1.636 2.311 0 1.316-.784 2.312-1.636 2.312-.852 0-1.636-.996-1.636-2.312 0-1.315.784-2.311 1.636-2.311ZM9.972 2C8.078 2 6.635 3.833 6.635 6.008s1.443 4.009 3.337 4.009c1.894 0 3.337-1.834 3.337-4.009C13.31 3.833 11.866 2 9.972 2Zm0 1.697c.852 0 1.636.996 1.636 2.311 0 1.316-.784 2.312-1.636 2.312-.852 0-1.636-.996-1.636-2.312 0-1.315.784-2.311 1.636-2.311ZM18.204 2c-1.895 0-3.337 1.833-3.337 4.008s1.442 4.009 3.337 4.009c1.894 0 3.337-1.834 3.337-4.009C21.54 3.833 20.098 2 18.204 2Zm0 1.697c.852 0 1.636.996 1.636 2.311 0 1.316-.784 2.312-1.636 2.312-.853 0-1.636-.996-1.636-2.312 0-1.315.783-2.311 1.636-2.311Z" />
    </G>
  </Svg>
);
export default PetActiveSVG;
