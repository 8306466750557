import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { SpView } from './SpView';
import { SpText } from './SpText';
import SpToastIcon from './RoundIcons/SpToastIcon';

type SpErrorBadgeProps = {
  text: string;
  marginBottom?: string | number;
  marginTop?: string | number;
  title?: string;
};

const SpErrorBadge = ({
  text,
  marginBottom = 32,
  marginTop = 10,
  title,
}: SpErrorBadgeProps) => {
  return (
    <SpView style={[styles.container, { marginBottom, marginTop }]}>
      <SpToastIcon isError isToast={false} />
      {title && (
        <SpText style={[styles.title, styles.generalText]}>{title}</SpText>
      )}
      <SpText style={[styles.text, styles.generalText]}>{text}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.errorRed.color,
    borderRadius: 10,
    paddingRight: 28,
    paddingLeft: 68,
    paddingVertical: 14,
    position: 'relative',
    backgroundColor: 'rgba(237, 0, 85, 0.05)',
  },
  text: {
    fontFamily: 'Rubik',
  },
  generalText: {
    fontSize: 14,
    color: colors.greyText.color,
  },
  title: {
    fontFamily: 'Rubik_Medium',
  },
});

export default SpErrorBadge;
