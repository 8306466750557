import { StyleSheet } from 'react-native';

const utils = StyleSheet.create({
  textWrap: {
    marginLeft: 25,
    paddingBottom: 32,
    paddingRight: 40,
  },
  rowStart: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  flexCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default utils;
