import CustomBottomSheetModal from '@atoms/CustomBottomSheetModal';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { useTranslation } from 'react-i18next';
import PreferencesSVG from '@atoms/SvgIcons/PreferencesSVG';
import colors from '@styles/colors';
import { DeviceEventEmitter, StyleSheet } from 'react-native';
import { SpText } from '@atoms/SpText';
import text from '@styles/text';
import { ScrollView } from 'react-native-gesture-handler';
import { SpAddButton } from '@atoms/SpAddButton';
import { format } from 'date-fns';
import { TimeService } from 'src/services/TimeService';
import { useDevicesWithoutHubByHousehold } from '@hooks/useDevicesWithoutHubByHousehold';
import useTour from '@hooks/useTour';
import { EditMenuItem } from '@models/Menu';
import CustomHeader from '@molecules/CustomHeader';
import PersonalSVG from '@atoms/SvgIcons/PersonalSVG';
import useAreProductsPresent from '@hooks/useAreProductsPresent';
import { DeviceType } from '@constants/Device';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import useBoundStore from '../../store/store';
import { HouseholdList } from './molecules/HouseholdList';
import { HouseholdMenu } from './molecules/HouseholdMenu';
import { HouseholdSelector } from './molecules/HouseholdSelector';
import { HouseholdStackParamList } from './index';
import ChooseSetup from './ChooseSetup';
import { RootStackParamList } from '../index';
import { HouseholdHeader } from './molecules/HouseholdHeader';
import HouseholdSlide from '../Tour/components/HouseholdSlide/HouseholdSlide';
import MyHouseholdSlide from '../Tour/components/MyHouseholdSlide/MyHouseholdSlide';

export const Household = () => {
  const { t } = useTranslation();
  const [open, setOpenModal] = useState<boolean>(false);
  const snapPoints = useMemo(() => ['40%'], []);
  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const {
    isHouseholdActive,
    setActiveHousehold,
    activeHousehold,
    isHouseholdOwned,
  } = useBoundStore(state => state.householdStore);
  const { loadDevice } = useBoundStore(state => state.deviceStore);
  const userHasAdminAccess = useUserHasAdminAccess();
  const { ownedHouseholds, joinedHouseholds } = useBoundStore(
    useCallback(state => {
      return {
        ownedHouseholds: state.householdStore.ownedHouseholdsData,
        joinedHouseholds: state.householdStore.joinedHouseholdsData,
        userHasWriteAccess: state.householdStore.userHasWriteAccess(),
      };
    }, []),
  );

  const isFocused = useIsFocused();
  const devices = useDevicesWithoutHubByHousehold();

  const uniqueDevice = devices.length === 1 ? devices[0] : null;

  const initTour = useTour({
    uniqueId: 'v0-household',
    components: [<HouseholdSlide />, <MyHouseholdSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
    loadDevice(true);
  }, [isFocused]);

  const inviteDate = useMemo(() => {
    const myInviteDate = activeHousehold?.created_at;
    if (!myInviteDate) {
      return null;
    }
    const timezonedDate = TimeService.convertOrUnconvertDateByUTC(
      new Date(myInviteDate),
      true,
    );
    return format(timezonedDate, 'dd MMM yyyy') || null;
  }, [activeHousehold]);

  const onDismiss = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onOpenSideBar = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const navigateTo = (target: keyof HouseholdStackParamList) => {
    navigation.navigate(target);
  };

  const menuStack = useMemo(() => {
    const result: EditMenuItem[] = [
      {
        label: t('settings'),
        iconSVG: (
          <PreferencesSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
        action: () => navigateTo('HouseholdSettings'),
      },
    ];

    if (userHasAdminAccess) {
      result.push({
        label: t('users'),
        iconSVG: (
          <PersonalSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
        action: () => navigateTo('HouseholdUsers'),
      });
      // No integrations yet - Sam 11/07/23
      // result.push({
      //   label: t('integrations'),
      //   iconSVG: <NotificationFilledSVG color={colors.greyText.color} />,
      //   action: () => alert('Integrations TBD'),
      // });
    }

    return result;
  }, [userHasAdminAccess]);
  const areProductsPresent = useAreProductsPresent();
  const isPlaceholder = !userHasAdminAccess && inviteDate;
  const stepForPets = useMemo(() => {
    if (!areProductsPresent) return 0;
    if (uniqueDevice) return 2;
    return 1;
  }, [uniqueDevice, areProductsPresent]);

  return activeHousehold ? (
    <ScrollView style={styles.background}>
      <SpView style={{ backgroundColor: '#fff' }}>
        <HouseholdHeader name="HouseholdHeader">
          <HouseholdSelector
            householdName={activeHousehold?.name}
            action={onOpenSideBar}
          />
        </HouseholdHeader>
        <SpVStack
          space={20}
          paddingTop={70}
          paddingHorizontal={20}
          style={{ zIndex: -1 }}
        >
          {!isPlaceholder && <HouseholdMenu menuStack={menuStack} />}
          {userHasAdminAccess && (
            <>
              <SpAddButton
                title={t('add_pet')}
                onPress={() =>
                  rootNavigation.navigate('FlowNavigation', {
                    screen: 'AddPets',
                    params: {
                      deviceType: uniqueDevice ? uniqueDevice.product_id : null,
                      step: stepForPets,
                      deviceId: uniqueDevice ? uniqueDevice.id : null,
                      existingDevice: true,
                    },
                  })
                }
                style={{ marginBottom: 30  }}
              />
              <SpAddButton
                title={t('add_product')}
                onPress={() => rootNavigation.navigate('FlowNavigation')}
                backgroundColor={{backgroundColor: 'rgba(3,162,177, 0.25)'}}
                plusColor={{backgroundColor: colors.primary.color}}
              />
            </>
          )}

          {isPlaceholder && (
            <SpView style={styles.textWrapper}>
              <SpText
                style={[
                  text.label,
                  { color: colors.greySmallTitle.color, fontSize: 16 },
                ]}
              >
                {t('invited_to_this_household_on{{date}}', {
                  date: inviteDate,
                })}
              </SpText>
            </SpView>
          )}
        </SpVStack>

        {open && (
          <CustomBottomSheetModal
            opened={open}
            backdropComponent={BottomSheetBackdrop}
            index={0}
            snapPoints={snapPoints}
            onDismiss={onDismiss}
          >
            <HouseholdList
              navigationRef={navigateTo}
              isHouseholdActive={isHouseholdActive}
              setActiveHousehold={newActiveHousehold => {
                setActiveHousehold(newActiveHousehold);
                DeviceEventEmitter.emit('refreshAnalyticsData');
              }}
              joinedHouseholds={joinedHouseholds}
              ownedHouseholds={ownedHouseholds}
              isHouseholdOwned={isHouseholdOwned}
              closeBottomSheetModal={onDismiss}
            />
          </CustomBottomSheetModal>
        )}
      </SpView>
    </ScrollView>
  ) : (
    <>
      <CustomHeader withTitle={false} />
      <ChooseSetup />
    </>
  );
};
const styles = StyleSheet.create({
  background: {
    backgroundColor: colors.white.color,
  },
  container: {
    width: '100%',
    paddingTop: 20,
  },
  textWrapper: {
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  block: {
    height: 40,
    backgroundColor: colors.white.color,
  },
});
