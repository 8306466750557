import CustomHeader, { ICustomHeaderProps } from '@molecules/CustomHeader';
import { TransitionPresets } from '@react-navigation/stack';
import { Dimensions, Platform } from 'react-native';

export const lastScreenOptions = (props: Partial<ICustomHeaderProps> = {}) => {
  return {
    gestureEnabled: Platform.OS === 'ios',
    header: () =>
      CustomHeader({ withTitle: false, withArrowBack: false, ...props }),
    ...TransitionPresets.ModalPresentationIOS,
  };
};

export const WINDOW_HEIGHT = Dimensions.get('window').height;
export const WINDOW_WIDTH = Dimensions.get('window').width;
