import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import TimezoneApi from '@api/TimezoneApi';
import { MergedInterfaces, TimezoneSlice } from '../models';

function compareTimezones(a: any, b: any) {
  const timezoneA = getTimezoneValue(a?.name);
  const timezoneB = getTimezoneValue(b?.name);
  if (timezoneA === timezoneB) {
    return a?.name?.localeCompare(b?.name);
  }

  return parseFloat(timezoneA) - parseFloat(timezoneB);
}

function getTimezoneValue(timezoneString: string) {
  return timezoneString
    .substring(timezoneString.indexOf('(') + 4, timezoneString.indexOf(')'))
    .replace(':', '');
}

const createTimezoneSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  TimezoneSlice
> = () => {
  return {
    timezoneStore: lens((subSet, subGet) => ({
      timezones: [],
      error: null,
      loadingTimezones: false,
      getTimezoneByID: id => {
        const { timezones } = subGet();

        return timezones.find(item => item.id === id);
      },
      loadTimezones: async (force = false) => {
        if (!force && subGet().timezones.length) {
          return;
        }

        subSet({ loadingTimezones: true, error: null });

        try {
          const timezones = await TimezoneApi.getTimezones();

          const sortedTimezones = timezones
            .map(
              ({ id, timezone, name, utc_offset, created_at, updated_at }) => {
                let timezoneName: string = timezone
                  ?.split('/')
                  ?.reverse()
                  ?.join(', ');
                const timezoneTime = name?.split(' ')[0];

                if (timezoneName.includes('_')) {
                  timezoneName = timezoneName.split('_').join(' ');
                }
                return {
                  id,
                  name: `${timezoneName} ${timezoneTime}` || name,
                  timezone: timezoneName,
                  utc_offset,
                  created_at,
                  updated_at,
                };
              },
            )
            .sort(compareTimezones);

          subSet({ timezones: sortedTimezones });
        } catch ({ response }) {
          subSet({ error: response?.status || 500 });
        }

        subSet({ loadingTimezones: false });
      },
    })),
  };
};
export default createTimezoneSlice;
