import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import SpModal from '@molecules/SpModal';
import colors from '@styles/colors';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { closeModal } from 'src/services/ModalBox';
import { ReportName, ReportType } from '../../constants/ReportType';
import SelectOptionWhite from './SelectOptionWhite';
import { useTranslation } from 'react-i18next';

interface SelectActivityTypeModalProps {
  type: ReportType;
  types: ReportType[];
  onChange: (data: ReportType) => void;
}

const SelectActivityTypeModal = ({
  type,
  types,
  onChange,
}: SelectActivityTypeModalProps) => {
  const { t } = useTranslation()
  return (
    <SpModal modalName="SelectActivityTypeModal" backdropColor="transparent">
      <Pressable accessible={false} style={styles.modalWrap}>
        <SpView style={styles.modalContent}>
          <SpVStack space={4}>
            {types.map(item => {
              return (
                <SelectOptionWhite
                  key={item}
                  item={{ id: item, name: t(ReportName[item]) }}
                  active={item === type}
                  onChange={() => {
                    closeModal('SelectActivityTypeModal');
                    onChange(item);
                  }}
                />
              );
            })}
          </SpVStack>
        </SpView>
      </Pressable>
    </SpModal>
  );
};

export default SelectActivityTypeModal;

const styles = StyleSheet.create({
  modalWrap: {
    paddingHorizontal: 20,
    width: '100%',
    marginTop: 134,
    backgroundColor: colors.white.color,
  },
  modalContent: {
    backgroundColor: colors.greyText.color,
    borderRadius: 10,
    height: 150,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  modalTitle: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    lineHeight: 24,
    color: colors.white.color,
  },
});
