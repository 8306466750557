import * as Application from 'expo-application';
import { Platform } from 'react-native';
import { Client } from '@models/Client';
import * as Device from 'expo-device';
import * as Localization from 'expo-localization';

export class DeviceService {
  static async getClient(): Promise<Client> {
    const uuid = await DeviceService.getDeviceId();

    const formatOSString = () => {
      switch (Platform.OS) {
        case 'ios':
          return 'iOS';
          break;

        case 'android':
          return 'Android';
          break;
        case 'web':
          return 'Web';
          break;
        default:
          return '';
          break;
      }
    };

    return {
      app: {
        bundle_identifier: Application.applicationId,
        version: Application.nativeApplicationVersion,
      },
      device: {
        name: Device.modelName,
        model: {
          manufacturer: Device.manufacturer,
          name: Device.modelId || '',
        },
        uuid,
        os: {
          platform: formatOSString(),

          version: Device.osVersion,
        },
      },
      locale: {
        language: Localization.locale,
        country: 'en',
      },
    };
  }

  static async getDeviceId(): Promise<string> {
    if (Platform.OS === 'android') {
      return Application.androidId;
    }

    if (Platform.OS === 'ios') {
      return Application.getIosIdForVendorAsync();
    }

    return 'web';
  }
}
