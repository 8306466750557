import { DeviceModel } from '@models/Device';
import React, { useCallback, useEffect } from 'react';
import LoadingSpinner from '@atoms/Loader';
import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { CerberusCard } from '../molecules/CerberusDevice/CerberusCard';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';

interface CerberusWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const CerberusWrapper = ({ data, deviceId }: CerberusWrapperProps) => {
  const { loadingDevice, updateProperty } = useBoundStore(
    (state: MergedInterfaces) => {
      const { deviceStore } = state;
      return {
        loadingDevice: deviceStore.loadingDevice,
        updateProperty: deviceStore.updateProperty,
      };
    },
  );
  const deviceStatus = useDeviceStatusHook(deviceId, true);

  useDeviceStatusParse(deviceStatus);
  const clearStoreProperties = useCallback(() => {
    updateProperty({
      loadingDevice: false,
    });
  }, [updateProperty]);

  useEffect(() => {
    clearStoreProperties();
  }, []);

  useEffect(() => {
    return () => clearStoreProperties();
  }, []);

  if (loadingDevice) {
    return <LoadingSpinner />;
  }

  return <CerberusCard data={data} />;
};
