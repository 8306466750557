import { DeviceType } from '@constants/Device';
import {
  DeviceFeederControlModel,
  DeviceModel,
  DevicePetDoorControlModel,
} from '@models/Device';
import { SpDeviceOffline } from '@molecules/SpDeviceOffline';
import React from 'react';
import { getFillPercentage } from 'src/pages/Device/utils/getFillingPercentage';
import { FeederStatus } from './FeederStatus';
import { FelaquaStatus } from './FelaquaStatus';
import { PetDoorStatus } from './PetDoorStatus';

interface DeviceIndicatorsProps {
  data: DeviceModel;
}

export const DeviceIndicators = ({ data }: DeviceIndicatorsProps) => {
  const online = data?.status.online;

  if (!online) {
    return <SpDeviceOffline deviceName={data.name} useImage />;
  }

  if (
    data.product_id === DeviceType.PetDoorConnect ||
    data.product_id === DeviceType.CatFlapConnect
  ) {
    return <PetDoorStatus data={data.control as DevicePetDoorControlModel} />;
  }

  if (data.product_id === DeviceType.FelaquaConnect) {
    const percentage = getFillPercentage(data as any, true);
    return <FelaquaStatus fillLevel={percentage} />;
  }

  if (data.product_id === DeviceType.FeederConnect) {
    const percentage = getFillPercentage(data as any, true);
    return <FeederStatus fillPercentages={percentage} data={data.control as DeviceFeederControlModel} />;
  }

  return null;
};
