/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react';

import { StyleSheet, SafeAreaView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import CustomHeader from '@molecules/CustomHeader';
import { SpText } from '@atoms/SpText';
import { SpSwitch } from '@atoms/SpSwitch';
import { ScrollView } from 'react-native-gesture-handler';
import text from '@styles/text';
import { SpView } from '@atoms/SpView';
import atoms from '@styles/atoms';
import ActivityChanger from '@molecules/ActivityChanger';
import { ReportType } from 'src/pages/Dashboard/constants/ReportType';
import { RequestResult } from '@models/Device';
import useToastEffect from '@hooks/useToastEffect';
import useBoundStore from '../../../store/store';

type SwitchViewProps = {
  active: boolean;
  description: string;
  action: () => void;
  disabled: boolean;
  title: string;
};

type Option = {
  active: boolean;
  description: string;
  title: string;
  action: () => void;
};

const NotificationSettings = () => {
  const { user, updateUser } = useBoundStore(state => state.accountStore);
  const { t } = useTranslation();
  const [requestResult, setRequestResult] = useState<RequestResult>('none');
  const notifications = user?.notifications || {};
  const [activities, setActivities] = useState({
    animal_movement: notifications.animal_movement,
    drinking_activity: notifications.drinking_activity,
    feeding_activity: notifications.feeding_activity,
    curfew: notifications.curfew,
    drinking_topup: notifications.drinking_topup,
    feeding_topup: notifications.feeding_topup,
  });

  const types = useMemo(() => {
    const results: ReportType[] = [];

    if (notifications.animal_movement !== undefined) {
      results.push(ReportType.Movement);
    }
    if (notifications.feeding_activity !== undefined) {
      results.push(ReportType.Feeding);
    }
    if (notifications.drinking_activity !== undefined) {
      results.push(ReportType.Drinking);
    }

    return results;
  }, [notifications]);

  const [selectedType, setSelectedType] = useState(types[0]);
  const oneSelected = Object.values(activities).includes(true);
  const setAllSelected = (bool: boolean) => {
    setActivities(curr => {
      Object.keys(curr).forEach(key => {
        curr[key as keyof typeof curr] = bool;
      });
      onUpdatePreferences(curr);
      return { ...curr };
    });
  };

  async function onUpdatePreferences(newActivities: object) {
    try {
      await updateUser({ ...user, notifications: newActivities });
      setRequestResult('success');
    } catch (err) {
      setRequestResult('error');
    }
  }
  type Activity = keyof typeof activities;
  const getAction = (key: string) => () => {
    setActivities(curr => {
      curr[key as Activity] = !curr[key as Activity];
      onUpdatePreferences(curr);
      return { ...curr };
    });
  };

  const options = useMemo(() => {
    const getOptions = (): { main: Option; sub: Option } => {
      switch (selectedType) {
        case ReportType.Movement:
          return {
            main: {
              active: activities.animal_movement,
              description: t('access_notification_setttings_title'),
              action: getAction('animal_movement'),
              title: t('notification_toggle_cat_flap_animal_movement_title'),
            },
            sub: {
              active: activities.curfew,
              description: t('access_notification_setttings_description'),
              title: t('notification_toggle_curfew_title'),
              action: getAction('curfew'),
            },
          };
        case ReportType.Feeding:
          return {
            main: {
              active: activities.feeding_activity,
              description: t('feeding_notification_setttings_title'),
              title: t('notification_toggle_feeding_activity_title'),
              action: getAction('feeding_activity'),
            },
            sub: {
              active: activities.feeding_topup,
              description: t('feeding_notification_setttings_description'),
              action: getAction('feeding_topup'),
              title: t('notification_toggle_feeding_topup_title'),
            },
          };
        case ReportType.Drinking:
          return {
            main: {
              active: activities.drinking_activity,
              description: t('drinking_notification_setttings_title'),
              title: t('notification_toggle_drinking_activity_title'),
              action: getAction('drinking_activity'),
            },
            sub: {
              active: activities.drinking_topup,
              description: t('drinking_notification_setttings_description'),
              title: t('notification_toggle_drinking_topup_title'),
              action: getAction('drinking_topup'),
            },
          };
      }
    };
    return getOptions();
  }, [activities, selectedType]);

  useToastEffect({
    resetter: () => setRequestResult('none'),
    successCondition: requestResult === 'success',
    errorCondition: requestResult === 'error',
    successToastMessage: t('notification_update_success_message'),
    errorToastMessage: t('error_notofications'),
  });

  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader title={t('notifications')} />
      <ScrollView style={styles.inner}>
        <View style={styles.mainContainer}>
          <View style={styles.row}>
            <SpText style={[text.loadingText, styles.allNotifications]}>
              {t('all_notifications')}
            </SpText>
            <SpSwitch checked={oneSelected} onChange={setAllSelected} />
          </View>
          <SpText style={[text.defaultText, styles.mainDescription]}>
            {t('notification_settings_preview')}
          </SpText>
          <SpView style={styles.line} marginTop={27} />
          <ActivityChanger
            selectedType={selectedType}
            availiableTypes={types}
            onChange={setSelectedType}
            style={styles.activityChanger}
            disabled={!oneSelected}
          />
          <SpView height={20} />
          <SwitchView {...options.main} disabled={!oneSelected} />
          <SwitchView {...options.sub} disabled={!oneSelected} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const SwitchView: React.FC<SwitchViewProps> = ({
  disabled,
  description,
  action,
  active,
  title,
}) => {
  return (
    <SpView style={disabled && atoms.disabledButton}>
      <SpView style={[styles.row, styles.selectButton]}>
        <SpText style={[text.loadingText, styles.selectButtonTitle]}>
          {title}
        </SpText>
        <SpView flex={1} />
        <SpSwitch checked={active} onChange={action} />
      </SpView>
      <SpText style={[text.defaultText, styles.mainDescription]}>
        {description}
      </SpText>
      <SpView style={styles.line} marginTop={15} marginBottom={23} />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 19,
  },
  buttonWrapper: {
    paddingHorizontal: 24,
    paddingTop: 10,
  },
  spacing: {
    marginTop: 10,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainDescription: {
    marginTop: 20,
    fontSize: 15,
  },
  mainContainer: {
    marginTop: 44,
  },
  allNotifications: {
    marginTop: undefined,
  },
  line: {
    backgroundColor: colors.greyAccent.color,
    height: 1,
    width: '100%',
  },
  selectButton: {
    backgroundColor: colors.greyInput.color,
    borderRadius: 16,
    padding: 16,
  },
  selectButtonTitle: {
    marginTop: undefined,
    fontSize: 16,
    textAlign: undefined,
    flex: 7,
  },
  activityChanger: {
    paddingHorizontal: 10,
  },
});

export default NotificationSettings;
