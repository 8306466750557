import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import CustomHeader from '@molecules/CustomHeader';
import { navigationRef } from '../RootNavigation';
import { Login } from './Login';
import RequestResetPassword from './RequestResetPassword';
import { AcceptTerms } from './AcceptTerms';

export type LoginStackParamList = {
  Login: undefined;
  RequestResetPassword: undefined;
  AcceptTerms: undefined;
};

const Stack = createStackNavigator<LoginStackParamList>();

export default function LoginNavigation() {
  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        name="Login"
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withArrowBack: true,
              goBackFunc: () => navigationRef.navigate('OnboardingLetsStart'),
            }),
        }}
        component={Login}
      />
      <Stack.Screen
        name="RequestResetPassword"
        options={{
          headerShown: true,
          header: () => CustomHeader({ withTitle: false }),
        }}
        component={RequestResetPassword}
      />
      <Stack.Screen
        name="AcceptTerms"
        options={{ headerShown: true }}
        component={AcceptTerms}
      />
    </Stack.Navigator>
  );
}
