import React from 'react';
import colors from '@styles/colors';
import IndoorsSVG from '@atoms/SvgIcons/IndoorsSVG';
import { SpRoundIcon } from './SpRoundIcon';

interface SpHouseIconProps {
  action?: any;
}

export const SpHouseIcon = ({ action }: SpHouseIconProps) => (
  <SpRoundIcon
    icon={
      <IndoorsSVG
        color={colors.greyText.color}
        width={23}
        height={23}
        viewBox="0 0 17 17"
      />
    }
    action={action}
    size={12}
    color={colors.secondary2.color}
    componentName="SpHouseIcon"
  />
);
