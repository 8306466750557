import { ProductModel } from '@models/Product';
import Http from './Http';

class ProductApi {
  static getAll(): Promise<ProductModel[]> {
    return Http.get(`/api/product/`, {}).then(response => {
      return response.data.data;
    });
  }
}

export default ProductApi;
