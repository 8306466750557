import { WeightUnits } from '@constants/WeightUnits';
import Qty from 'js-quantities';
import { useTranslation } from 'react-i18next';

export const useWeightConversion = (
  userWeightUnits: WeightUnits,
  userCountryCode = 'en',
): {
  convertWithUnits: (
    value: number,
    isWeight?: boolean,
    showOnlyValue?: boolean,
  ) => string;
  getShortUnit: (isWeight?: boolean) => string;
  convertingLargeUnitToSmaller: (values: number) => number;
  getWeightUnitForBowls: () => string;
} => {
  const { t } = useTranslation();

  const convertInternalMetricsWeight = (
    value: number,
    unit: Qty,
    showOnlyKg = false,
  ): Qty[] => {
    if (value >= 1000 || showOnlyKg) {
      return [unit.to('kg').toPrec(0.01)];
    }
    return [unit.toPrec('g')];
  };

  const convertInternalMetricsVolumnen = (value: number, unit: Qty): Qty[] => {
    if (value >= 1000) {
      return [unit.to('l').toPrec(0.01)];
    }
    return [unit.toPrec('ml')];
  };

  const convertInternalImperialsWeight = (
    value: number,
    unit: Qty,
    showOnlyLbs: boolean,
  ): Qty[] => {
    if (showOnlyLbs) {
      return [unit.to('lb').toPrec(0.01)];
    }

    const wholeLbs = unit.to('lb');
    const lbs = Qty(Math.floor(wholeLbs.scalar), 'lb').toPrec(0.01);
    const diffForOz = wholeLbs.scalar - lbs.scalar;
    const oz = Qty(diffForOz, 'lb').to('oz').toPrec(0.01);

    if (lbs.scalar === 0) {
      return [oz];
    }

    return [lbs, oz];
  };

  const convertInternalImperialsVolumnen = (
    value: number,
    unit: Qty,
  ): Qty[] => {
    const isUSA = userCountryCode === 'us';
    return isUSA
      ? [unit.to('floz').toPrec(0.01)]
      : [unit.to('flozimp').toPrec(0.01)];
  };

  const getTranslationUnit = (units: WeightUnits | string): string => {
    switch (units) {
      case 'oz':
        units = t('UNIT_WEIGHT_OZ');
        break;
      case 'lbs':
        units = t('UNIT_WEIGHT_LBS');
        break;
      case 'floz':
        units = t('UNIT_FLUID_FLOZ');
        break;
      case 'flozimp':
        units = t('UNIT_FLUID_FLOZ');
        break;
      case 'ml':
        units = t('UNIT_FLUID_ML');
        break;
      case 'kg':
        units = t('UNIT_WEIGHT_KG');
        break;
      case 'g':
        units = t('UNIT_WEIGHT_GR');
        break;
    }
    return units as string;
  };

  const configurableRoundingFormatter = (): ((
    scalar: unknown,
    units: string,
  ) => string) => {
    return (scalar, units) => {
      units = getTranslationUnit(units);
      return `${scalar} ${units}`;
    };
  };

  const convertInternal = (
    value: number,
    isWeight = true,
    showOnlyValue = false,
  ): Qty[] => {
    const unit = Qty(value, isWeight ? 'g' : 'ml');
    let total;

    switch (userWeightUnits) {
      case WeightUnits.Metric: {
        total = isWeight
          ? convertInternalMetricsWeight(value, unit, showOnlyValue)
          : convertInternalMetricsVolumnen(value, unit);
        break;
      }
      case WeightUnits.Imperial: {
        total = isWeight
          ? convertInternalImperialsWeight(value, unit, showOnlyValue)
          : convertInternalImperialsVolumnen(value, unit);
        break;
      }
    }
    return total;
  };

  const convertWithUnits = (
    value: number,
    isWeight = false,
    showOnlyValue = false,
  ): string => {
    return convertInternal(value, isWeight, showOnlyValue)
      .map(q => q.format(configurableRoundingFormatter()))
      .join(' ');
  };

  const getShortUnit = (isWeight = true): string => {
    switch (userWeightUnits) {
      case WeightUnits.Metric:
        return isWeight ? t('UNIT_WEIGHT_GR') : t('UNIT_FLUID_ML');
      case WeightUnits.Imperial:
        return isWeight ? t('UNIT_WEIGHT_OZ') : t('UNIT_FLUID_FLOZ');
    }
  };

  const getWeightUnitForBowls = () => {
    return t(
      userWeightUnits === WeightUnits.Metric
        ? 'UNIT_WEIGHT_GR'
        : 'UNIT_WEIGHT_OZ',
    );
  };

  const convertingLargeUnitToSmaller = (value: number) => {
    switch (userWeightUnits) {
      case WeightUnits.Imperial:
        return value * 16;
        break;
      case WeightUnits.Metric:
        return value * 1000;
        break;
      default:
        return null;
        break;
    }
  };

  return {
    convertWithUnits,
    getShortUnit,
    convertingLargeUnitToSmaller,
    getWeightUnitForBowls,
  };
};
