import { SpProgressBar } from '@atoms/SpProgressBar';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import {
  DeviceFeederTypeUpdated,
  DeviceModel,
  PetStatisticsModel,
  WithFillPercent,
} from '@models/Device';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StyleSheet } from 'react-native';
import colors from '@styles/colors';
import PetLastActionTime from 'src/pages/Dashboard/molecules/PetCarousel/PetLastActionTime';
import { faClock, faDroplet } from '@fortawesome/free-solid-svg-icons';
import { SpDivider } from '@atoms/SpDivider';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { DeviceStatusHeader } from '../DeviceStatusHeader';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import { getFillPercentage } from '../../utils/getFillingPercentage';
import useLastDate from '../../hooks/useLastDate';

interface DrinkingCardProps {
  data: DeviceModel & WithFillPercent;
  userHasWriteAccess: boolean;
  onChangeZero: () => void;
}

export const DrinkingCard = ({
  data,
  userHasWriteAccess,
  onChangeZero,
}: DrinkingCardProps) => {
  const online = data?.status.online;
  const { t } = useTranslation();
  const percent = getFillPercentage(data, false);
  const lastFilled = useLastDate(
    data.status as unknown as DeviceFeederTypeUpdated,
    'last_filled_at',
  );
    const lastActionData = useMemo(() => {
      if (typeof lastFilled === 'string') return undefined;
      return {
        last_consumption: lastFilled,
      } as unknown as PetStatisticsModel['drinking'];
    }, [lastFilled]);

  return (
    <SpView paddingHorizontal={0} flex={1}>
      <DeviceStatusHeader data={data} />

      {
        <>
          {userHasWriteAccess && online && (
            <SpView marginTop={2}>
              <DeviceSettingMenuItem data={data?.id} />
            </SpView>
          )}
          {online && (
            <SpView
              marginTop={18}
              backgroundColor={colors.white.color}
              alignItems="center"
              style={styles.container}
            >
              <SpView
                minHeight={32}
                minWidth={172}
                style={styles.progressBarContainer}
              >
                <SpProgressBar
                  indicatorColors={['#45acd9', '#83daf0']}
                  value={percent[0]}
                  width={200}
                  borderRadius={16}
                />
              </SpView>
              <SpView flexDirection="row" alignItems="center">
                <FontAwesomeIcon
                  icon={faDroplet}
                  size={16}
                  color={colors.greyText.color}
                />
                <SpText style={styles.title}>{t('water_remaining')}</SpText>
              </SpView>
              <SpText style={styles.percent}>{percent}%</SpText>

              <SpView marginTop={32} width="100%">
                <SpDivider
                  style={styles.divider}
                  color={colors.greyBorder.color}
                />
                <PetLastActionTime
                  icon={faClock}
                  data={lastActionData}
                  title={t('last_filled')}
                  withTimeMarker
                  rightSideFlexEnd
                />
                <SpDivider
                  style={styles.divider}
                  color={colors.greyBorder.color}
                />
              </SpView>
              {/* Felaqua Taring to be disabled for now */}
              {/* <SpView */}
              {/*   backgroundColor={colors.white.color} */}
              {/*   flexDirection="row" */}
              {/*   marginTop={2} */}
              {/* > */}
              {/*   {userHasWriteAccess && ( */}
              {/*     <SpView marginTop={28} width={160} height={160}> */}
              {/*       <GradientButton */}
              {/*         disabled={!!loadingControl?.tare} */}
              {/*         isLoading={!!loadingControl?.tare} */}
              {/*         colorsGradient={[ */}
              {/*           'rgba(0, 189, 136, 0.4)', */}
              {/*           'rgba(45, 181, 142, 0.4)', */}
              {/*         ]} */}
              {/*         icon={ */}
              {/*           <PortionSVG */}
              {/*             color={colors.greyText.color} */}
              {/*             width={45} */}
              {/*             height={45} */}
              {/*             viewBox="0 0 16 16" */}
              {/*           /> */}
              {/*         } */}
              {/*         borderRadius={18} */}
              {/*         text={t('zero')} */}
              {/*         action={onChangeZero} */}
              {/*       /> */}
              {/*     </SpView> */}
              {/*   )} */}
              {/* </SpView> */}
            </SpView>
          )}
        </>
      }
    </SpView>
  );
};

const styles = StyleSheet.create({
  message: {
    color: '#57534e',
  },
  button: {
    padding: 20,
  },
  title: {
    marginLeft: 4,
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    paddingBottom: 1,
  },
  container: {
    position: 'absolute',
    flex: 1,
    height: '55%',
    paddingHorizontal: 19,
    bottom: 0,
    left: -18,
    right: -18,
    paddingTop: 28,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: -9,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    border: 'none',

    elevation: 8,
  },
  progressBarContainer: { position: 'absolute', top: -16 },
  percent: {
    fontFamily: 'Rubik_Medium',
    fontSize: 32,
    lineHeight: 32,
    color: colors.greyText.color,
    marginTop: 8,
  },
  divider: {
    width: '100%',
  },
});
