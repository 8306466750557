import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import BreedApi from '@api/BreedApi';
import { MergedInterfaces } from '../models';
import { BreedSlice } from '../models/BreedSlice';

const createBreedSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  BreedSlice
> = (set, get) => {
  return {
    breedStore: lens((subSet, subGet, api) => ({
      breeds: [],
      error: null,
      loadingBreeds: false,
      getBreedByID: id => {
        const { breeds } = subGet();
        return breeds.filter(item => item.id === id)[0];
      },
      loadBreeds: async (force = false) => {
        if (!force && subGet().breeds.length) {
          return;
        }

        subSet({ loadingBreeds: true, error: null });

        try {
          const breeds = await BreedApi.getBreeds();
          breeds.sort((curr, next) => curr.name.localeCompare(next.name));
          subSet({ breeds });
        } catch (response: any) {
          subSet({ error: response?.status || 500 });
        }

        subSet({ loadingBreeds: false });
      },
      reset: () => {
        subSet({
          error: null,
          loadingBreeds: false,
          breeds: [],
        });
      },
    })),
  };
};
export default createBreedSlice;
