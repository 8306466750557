import { ReportModel } from '@models/ReportModel';
import qs from 'qs';
import Http from './Http';

class ReportApi {
  static getReports(
    householdId: number,
    petId: number,
    from: string,
    to: string,
  ): Promise<ReportModel> {
    return Http.get(
      `/api/v2/report/household/${householdId}/pet/${petId}/aggregate`,
      {
        params: {
          from,
          to,
        },
        paramsSerializer: params => qs.stringify(params),
      },
    ).then(response => {
      return response.data.data;
    });
  }
}

export default ReportApi;
