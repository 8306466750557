import { SpDivider } from '@atoms/SpDivider';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SpFlatList } from '@atoms/SpFlatList';
import { getNestedProperty } from '@utils/getNestedProperty';
import { ListRenderItemInfo } from 'react-native';
import { EditMenuItem } from '@models/Menu';
import { SpEditMenuItem } from './SpEditMenuItem';
import useBoundStore from '../../store/store';

export interface EditMenuStackProps {
  buttons: EditMenuItem[];
  stackType?: string;
  showTitleDivider?: boolean;
}

export const SpEditMenuStack = ({
  buttons,
  stackType = '',
  showTitleDivider = true,
}: EditMenuStackProps) => {
  const { bottom } = useSafeAreaInsets();

  const loadingControl = useBoundStore(
    state => state.deviceStore.loadingControl,
  );

  return (
    <SpFlatList
      data={buttons}
      showsVerticalScrollIndicator={false}
      ListHeaderComponent={showTitleDivider ? <SpDivider /> : null}
      contentContainerStyle={{ paddingBottom: bottom }}
      keyExtractor={(item, index) => `${item.label}-${index}`}
      renderItem={({ item }: ListRenderItemInfo<EditMenuItem>) => (
        <SpEditMenuItem
          stackType={stackType}
          label={item.label}
          value={item.value}
          action={item.action}
          icon={item?.icon ?? null}
          iconSVG={item?.iconSVG ?? null}
          actionIcon={item?.actionIcon}
          viewOnly={item?.viewOnly}
          disabled={item?.disabled}
          isLoading={
            item?.loadingKey
              ? getNestedProperty(loadingControl, item.loadingKey)
              : false
          }
        />
      )}
    />
  );
};
