import ReportApi from '@api/ReportApi';
import { lens } from '@dhmk/zustand-lens';
import { MappedReportModel } from '@models/ReportModel';
import { mapReportData } from '@utils/report';
import { endOfDay, formatISO, subDays } from 'date-fns';
import { StateCreator } from 'zustand';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { MergedInterfaces } from '../models';

export interface ReportSliceModel {
  reportStore: {
    loading: boolean;
    data: MappedReportModel;
    detailsReports: Record<
      string,
      { expires: number; data: MappedReportModel }
    >;
    loadDetailsReport: (
      householdId: number,
      petId: number,
      fromNumberDays?: number,
      fromDate?: Date,
    ) => Promise<MappedReportModel>;
    loadSummaryReports: (
      householdId: number,
      petId: number,
      from: number,
      to: number,
    ) => void;
    resetReports: () => void;
  };
}

const createReportSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  ReportSliceModel
> = (rootSet, rootGet) => {
  return {
    reportStore: lens((set, get, api) => ({
      data: null,
      detailsReports: {},
      loading: false,
      loadDetailsReport: async (
        householdId: number,
        petId: number,
        fromNumberDays = 7,
        fromDate = new Date(),
      ) => {
        const cacheKey = `${householdId}-${petId}`;
        const { detailsReports } = get();

        // if (
        //   detailsReports[cacheKey] &&
        //   detailsReports[cacheKey].expires > Date.now()
        // ) {
        //   console.log('cache hit', detailsReports[cacheKey].data);
        //   return Promise.resolve(detailsReports[cacheKey].data);
        // }
        const calculateZonedMidnightAsUTCDatetime = (date: Date, timezone: string) => {
// Convert the current date to the target timezone's equivalent date-time
          const nowInTargetTimezone = utcToZonedTime(date, timezone);
// Use endOfDay from date-fns to get the end of the day (23:59:59.999) in the target timezone
          const endOfDayInTargetTimezone = endOfDay(nowInTargetTimezone);
// Convert this back to a UTC Date object representing the exact moment of end of day in the target timezone
          const endOfDayUTC = zonedTimeToUtc(endOfDayInTargetTimezone, timezone);
// Format this UTC Date object to an ISO string
          return formatISO(endOfDayUTC);
        };
        set({ loading: true });

        return ReportApi.getReports(
          householdId,
          petId,
          // String(zonedTimeToUtc(subDays(fromDate, fromNumberDays), 'UTC')),
          calculateZonedMidnightAsUTCDatetime(subDays(fromDate, fromNumberDays), rootGet().householdStore.activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta')),
          calculateZonedMidnightAsUTCDatetime(fromDate, rootGet().householdStore.activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta')),
        )
          .then(data => {
            const groupedData = mapReportData(
              data,
              rootGet().deviceStore.getDevicesCollection(),
            );

            set({
              loading: false,
              detailsReports: {
                ...detailsReports,
                [cacheKey]: {
                  data: groupedData,
                  expires: Date.now() + 60 * 1000,
                },
              },
            });
            return groupedData;
          })
          .catch(() => {
            set({ loading: false });

            return null;
          });
      },

      loadSummaryReports: async (
        householdId: number,
        petId: number,
        from: number,
        to: number,
      ) => {
        set({ loading: true });
        try {
          const fromDate = new Date(from);
          const toDate = new Date(to);

          const data = await ReportApi.getReports(
            householdId,
            petId,
            formatInTimeZone(toDate, 'UTC', 'yyyy-MM-dd'),
            formatInTimeZone(toDate, 'UTC', 'yyyy-MM-dd'),
          );
          const groupedData = mapReportData(
            data,
            rootGet().deviceStore.getDevicesCollection(),
          );
          set({ loading: false, data: groupedData });
        } catch (e) {
          set({ loading: false, data: null });
        }
      },
      resetReports: () => {
        set({
          loading: false,
          data: null,
          detailsReports: {},
        });
      },
    })),
  };
};

export default createReportSlice;
