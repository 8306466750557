/* eslint-disable no-fallthrough */
import {
  DeviceFeederBowlType,
  DeviceFeederTareType,
  DeviceStatusIDs,
  DeviceType,
  FeederFoodType,
  PetDoorLockingMode,
} from '@constants/Device';
import { lens } from '@dhmk/zustand-lens';
import {
  DeviceCatFlapControlModel,
  DeviceCatFlapCurfew,
  DeviceCerberusControlModel,
  DeviceFeederControlModel,
  DeviceFeederTypeUpdated,
  DeviceFelaquaControlModel,
  DeviceHubControlModel,
  DeviceModel,
  DevicePetDoorControlModel,
  DevicePetDoorCurfew,
  ServerControlResponseModel,
} from '@models/Device';
import { cloneDeep } from 'lodash-es';
import { StateCreator } from 'zustand';
import { DeviceTagModel } from '@models/DeviceTag';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { TagProfile } from '@constants/Tag';
import { de } from 'date-fns/locale';
import { PetModel } from '@models/Pet';
import DeviceApi from '../../api/DeviceApi';
import { MergedInterfaces } from '../models';
import { DeviceSliceModel } from '../models/DeviceSlice';
import { DdLogs } from '../../services/SPDataDogService';

type ZeroScalingErrorType = { [key: string]: any };

const createDeviceSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  DeviceSliceModel
> = (set, get) => {
  return {
    deviceStore: lens((subSet, subGet) => ({
      updateTagProfileLoading: false,
      updateCurfewLoading: false,
      updateCloseDeviceResult: 'none',
      changeFoodTypeResult: 'none',
      updatePortioningResult: 'none',
      updatingHubLedMode: false,
      updateTagProfileResult: 'none',
      deviceData: [],
      loadingDevice: false,
      firstRequestDataFetched: true,
      error: null,
      success: null,
      productSetupSelection: null,
      updateZeroScalesLoading: false,
      loadingControl: {},
      updateZeroScalesLoadingStatus: (payload: boolean) => {
        AnalyticsService.logEvent(`DeviceStore - updateZeroScalesLoadingStatus`);

        subSet({ updateZeroScalesLoading: payload });
      },
      setProductSetupSelection: product => {
        subSet({ productSetupSelection: product });
      },
      updateProperty: data => subSet(data),
      getDeviceById: (id: number) => {
        AnalyticsService.logEvent('DeviceStore - getDeviceById');
        return subGet().deviceData.find(item => item.id === id) || null;
      },
      updateDevice: data => {
        try {
          AnalyticsService.logEvent('DeviceStore - updateDevice');
          const index = subGet().deviceData.findIndex(
            item => item.id === data.id,
          );
          const { deviceData } = subGet();
          const newData = [...deviceData];

          newData.splice(index, 1, { ...data });
          subSet({ deviceData: newData });
        } catch (e) {
          DdLogs.error('DeviceStore - updateDevice', 'updateDeviceError', JSON.stringify(e));
          subSet({ updateTargetError: e as ServerControlResponseModel });
        }
      },
      deleteDevice: async id => {
        try {
          await AnalyticsService.logEvent('DeviceStore - deleteDevice');

          await DeviceApi.deleteDevice(id);
          subGet().loadDevice(true);
          return true;
        } catch ({ response }: any) {
          subSet({ error: response?.status || 500 });
          await DdLogs.error('DeviceStore - deleteDevice', 'deleteDeviceError', JSON.stringify(response));
          return false;
        }
      },
      loadDevice: async (force = false) => {
        if(!get().householdStore.activeHousehold?.id) {
          DdLogs.error('DeviceStore - loadDevice', 'no active household',);
          return
        }
       await AnalyticsService.logEvent('DeviceStore - loadDevice', {skip: (!force && subGet().deviceData.length)});
        if (!force && subGet().deviceData.length) {
          return;
        }

        subSet({ loadingDevice: true, error: null });

        try {
          const deviceData = await DeviceApi.getDevices(
            get().householdStore.activeHousehold?.id,
          );
          subSet({
            deviceData: deviceData.map(item => {
              return {
                ...item,
                tags: item?.tags || [], // Map empty tags by default
              };
            }),
          });
        } catch ({ response }) {
          // @ts-ignore
          subSet({ error: response?.status || 500 });
          DdLogs.error('DeviceStore - loadDevice', 'promise error', JSON.stringify(response));
        } finally {
          subSet({ loadingDevice: false, firstRequestDataFetched: false });

        }

      },
      updateLockUnlockLoading: false,
      updateLockUnlockSuccess: false,
      updateLockUnlockError: null,
      updateLockUnlockRequest: async (id, data) => {
        AnalyticsService.logEvent(`device_updateLockUnlockRequest`, {
          mode: data.locking,
        });
        await AnalyticsService.logEvent('DeviceStore - updateLockUnlockRequest')
        subSet({
          updateLockUnlockError: null,
          loadingControl: {
            ...subGet().loadingControl,
            ...{ locking: data.locking },
          },
          updateLockUnlockLoading: true,
        });
        try {
          await DeviceApi.lockUnlock(id, data);
          const device = cloneDeep(subGet().getDeviceById(id));
          device.control.locking = data.locking;
          if (
            device.product_id === DeviceType.PetDoorConnect &&
            (device.control.curfew as DevicePetDoorCurfew).enabled
          ) {
            (device.control.curfew as DevicePetDoorCurfew).enabled = false;
          }

          subGet().updateDevice(device);
          subSet({
            updateLockUnlockError: null,
            updateLockUnlockSuccess: true,
          });
        } catch (error) {
          subSet({
            updateLockUnlockError: true,
            updateLockUnlockSuccess: null,
          });
        } finally {
          subSet({ updateLockUnlockLoading: false });
        }
      },
      updateCurfewSuccess: null,
      updateCurfewError: null,
      updateCurfewRequest: async (id, data) => {
      await AnalyticsService.logEvent('DeviceStore - updateCurfewRequest')
        subSet({
          loadingControl: {
            ...subGet().loadingControl,
            ...{ curfew: data.curfew },
          },
          updateCurfewLoading: true,
        });

        try {
          const updatedData = await DeviceApi.updateCurfewAsync(id, data);
          const device = subGet().getDeviceById(id);

          let curfewEnabled = false;

          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            device.control.curfew = data.curfew;

            if (device.product_id === DeviceType.PetDoorConnect) {
              curfewEnabled = (data.curfew as DevicePetDoorCurfew).enabled;
            } else {
              curfewEnabled = (data.curfew as DeviceCatFlapCurfew[]).some(
                ({ enabled }) => enabled,
              );
            }
          }

          if (
            curfewEnabled &&
            device.product_id === DeviceType.PetDoorConnect
          ) {
            (
              device.control as
                | DeviceCatFlapControlModel
                | DevicePetDoorControlModel
            ).locking = PetDoorLockingMode.curfew;
          }

          subGet().updateDevice(device);
          subSet({ updateCurfewSuccess: device });
        } catch (e) {
          subSet({ updateCurfewError: e });
          await DdLogs.error('DeviceStore - updateCurfewRequest','updateCurfewError', JSON.stringify(e));
        } finally {
          subSet({ updateCurfewLoading: false });
        }

        // subSet({ loadingDevice: false });
      },
      updateNameSuccess: null,
      updateNameError: null,
      updateNameRequest: async (id, data) => {
        subSet({ loadingDevice: true });

        try {
          await AnalyticsService.logEvent('DeviceStore - updateNameRequest')
          const updatedData = await DeviceApi.updateName(id, data);
          const device = subGet().getDeviceById(id);

          device.name = updatedData.name;

          subGet().updateDevice(device);
          subSet({ updateNameSuccess: device });
        } catch (e) {
          subSet({ updateNameError: 'Something went wrong' });
          await DdLogs.error('DeviceStore - updateNameRequest', 'updateNameError', JSON.stringify(e));
        }

        subSet({ loadingDevice: false });
      },
      assignUnassignPetError: null,
      unassignPetSuccess: null,
      unassignAssignMultiplePetsRequest: async (id, petsData) => {
      await AnalyticsService.logEvent('DeviceStore - unassignAssignMultiplePetsRequest')
        // TODO: add types
        async function applyPetsAsync(): Promise<any> {
          const results = [];
          const finalRes = [];
          for (const petData of petsData) {
            subSet({
              loadingControl: {
                ...subGet().loadingControl,
                ...{
                  tag_profiles: [
                    ...(subGet().loadingControl?.tag_profiles || []),
                    ...[{ tag_id: petData.tagId }],
                  ],
                },
              },
            });
        
            if (petData.assign) {
              results.push({
                tag_id: petData.tagId,
                request_action: 1,
              });
              finalRes.push([true])
            } else {
              results.push({
                tag_id: petData.tagId,
                request_action: 2,
              });
              finalRes.push([false])
            }
          }
          const res = await DeviceApi.assignPetAsync(id, results);

          res.forEach((item, index) => {
            finalRes[index].push(item)
         })
          return finalRes;
        }

        try {
          const result = await applyPetsAsync();
          const device = subGet().getDeviceById(id);
          result.forEach(item => {
            if (
              item[1]?.results &&
              item[1]?.results[0].status_id === DeviceStatusIDs.Pending
            ) {
              const petObject = {
                created_at: item[1].data?.created_at,
                device_id: device.id,
                id: item[1].data?.id,
                index: item[1].data?.index,
                profile: item[1].data?.profile,
                updated_at: item[1].data?.updated_at,
                version: item[1].data?.version,
              };
              device.tags = item[0]
                ? [...device.tags, petObject]
                : device.tags.filter(el => el.id !== item[1].data?.id);
            }
          });
          subGet().updateDevice(cloneDeep(device));
          subSet({
            assignPetSuccess: device,
          });
          const { tag_profiles, ...loadingControl } = subGet().loadingControl;
          subSet({
            assignPetSuccess: device,
            loadingControl,
          });
          return true;
        } catch (e) {
          subSet({
            assignUnassignPetError:
              get(e, 'data.error.message[0]') || 'Sorry try again',
          });
          await DdLogs.error('DeviceStore - unassignAssignMultiplePetsRequest', 'assignUnassignPetError', JSON.stringify(e));
          return false;
        }
      },
      updateTagProfileError: null,
      updateTagProfile: async (deviceId, pet) => {
        await AnalyticsService.logEvent('DeviceStore - updateTagProfile')
        const flap = subGet().getDeviceById(deviceId);
        const index = flap.tags.findIndex(item => item.id === pet.tag.id);
        if (index === -1) return TagProfile.None;
        const currentProfile = flap.tags[index].profile;
        subSet({
          updateTagProfileResult: 'none',
          updateTagProfileLoading: true,
        });
        try {
          const tagProfile =
          currentProfile === TagProfile.Permitted
            ? TagProfile.Safe
            : TagProfile.Permitted;
         const updateTagRes =  await DeviceApi.putDeviceTagAsync(flap.id, [{tag_id: pet.tag_id, request_action: 0, profile: tagProfile,
          }]);
       console.log('updateTagProfile', updateTagRes.data)
          flap.tags[index].profile = tagProfile;
          if (updateTagRes) {
            subGet().updateDevice(cloneDeep(flap));
            subSet({ updateTagProfileResult: 'success' });    
          }
          return tagProfile;
        } catch (e){
          subSet({ updateTagProfileResult: 'error' });
          await DdLogs.error('DeviceStore - updateTagProfile', 'updateTagProfileError', JSON.stringify(e));
          return TagProfile.None;
        } finally {
          subSet({ updateTagProfileLoading: false });
        }
      },
      mutateTagProfiles: (item, deviceId, action) => {
        AnalyticsService.logEvent('DeviceStore - mutateTagProfiles');
        const device = subGet().getDeviceById(deviceId);
        const index = device.tags.findIndex(el => el.id === item.tag_id);

        if (action === 0) {
          if (index >= 0) {
            device.tags[index].index = item.index;
            device.tags[index].profile = item.profile;
          } else {
            device.tags = [...device.tags, item];
          }
        } else if (action === 1) {
          device.tags = device.tags.filter(el => el.id !== item.tag_id);
        }
        subGet().updateDevice(cloneDeep(device));
      },
      unassignPetRequest: async (id, tagId) => {
        subSet({ loadingDevice: true });
        try {
          await AnalyticsService.logEvent('DeviceStore - unassignPetRequest')

          await DeviceApi.unassignPet(id, tagId);
          const device = subGet().getDeviceById(id);

          device.tags = device.tags.filter(item => item.id !== tagId);

          subGet().updateDevice(device);
          subSet({ unassignPetSuccess: device });
        } catch (e) {
          // TODO Write parser
          subSet({
            assignUnassignPetError:
              get(e, 'data.error.message[0]') || 'Sorry try again',
          });
          await DdLogs.error('DeviceStore - unassignPetRequest', 'assignUnassignPetError', JSON.stringify(e));
        }

        subSet({ loadingDevice: false });
      },
      assignPetSuccess: null,
      assignPetError: null,
      assignPetRequest: async (id, tagId) => {
        subSet({ loadingDevice: true });

        try {
          AnalyticsService.logEvent(`device_assignPetRequest`, null);

          const updatedData = await DeviceApi.assignPetAsync(id, tagId);
          const device = subGet().getDeviceById(id);

          device.tags = [...device.tags, updatedData];

          subGet().updateDevice(device);
          subSet({ assignPetSuccess: device });
        } catch (e) {
          // TODO Write parser
          subSet({
            assignUnassignPetError:
              get(e, 'data.error.message[0]') || 'Sorry try again',
          });
        }

        subGet().loadDevice(true);
        subSet({ loadingDevice: false });
      },
      updateZeroScalesSuccess: null,
      updateZeroScalesError: null,
      updateZeroScales: async (id, type, lidSetup) => {
        AnalyticsService.logEvent(`device_updateZeroScales`);

        subSet({
          updateZeroScalesLoading: true,
          updateZeroScalesError: null,
          updateZeroScalesSuccess: null,
        });

        try {
          const updatedData = await DeviceApi.updateZeroScales(id, type);
          const device = subGet().getDeviceById(id);

          device.control = updatedData;

          subGet().updateDevice(device);
          subSet({
            updateZeroScalesSuccess: { lidSetup },
            updateZeroScalesError: null,
          });
        } catch (e) {
          subSet({
            updateZeroScalesError: {
              lidSetup,
              error: e as ServerControlResponseModel,
            },
            updateZeroScalesSuccess: null,
          });
        } finally {
          subSet({ updateZeroScalesLoading: false });
        }
      },
      updateZeroScalesAsync: async (id, type, lidSetup) => {
        AnalyticsService.logEvent(`device_updateZeroScales`);

        subSet({
          updateZeroScalesLoading: true,
          updateZeroScalesError: null,
          updateZeroScalesSuccess: null,
        });

        subSet({
          loadingControl: {
            ...subGet().loadingControl,
            tare: type,
          },
        });
        const longPooling = async (requestId: string) => {
          let status = null;
          while (true) {
            try {
              status = (await DeviceApi.getDeviceStatus(id)) as unknown as
                | {
                    request_id: string;
                    status: DeviceStatusIDs;
                  }[]
                | ZeroScalingErrorType;

              if (Array.isArray(status)) {
                const data = status.find(
                  elem => elem?.request_id === requestId,
                );

                if (data && data.status !== DeviceStatusIDs.Pending) {
                  return data.status;
                }
                await new Promise(res => {
                  setTimeout(res, 100);
                });
              }
            } catch {
              if (!Array.isArray(status)) {
                if (status?.data === 'API calls quota exceeded!') {
                  return DeviceStatusIDs.DeviceError;
                }
              }
              break;
            }
          }
        };
        let status: DeviceStatusIDs = DeviceStatusIDs.NoChange;
        try {
          const updatedData = await DeviceApi.updateZeroScalesAsync(id, type);
          const device = cloneDeep(subGet().getDeviceById(id));

          const requestId = updatedData.results[0]?.request_id;
          status = await longPooling(requestId);

          if (status !== DeviceStatusIDs.Success) {
            throw new Error('unsuccesfull');
          }
          const currDate = new Date().toISOString();

          device.status = device.status as unknown as DeviceFeederTypeUpdated;
          if (device.status?.bowl_status) {
            switch (type) {
              case DeviceFeederTareType.Both:
                device.status.bowl_status = device.status?.bowl_status?.map(
                  elem => ({
                    ...elem,
                    last_zeroed_at: currDate,
                  }),
                );
              case DeviceFeederTareType.Left:
                device.status.bowl_status[0].last_zeroed_at = currDate;
              case DeviceFeederTareType.Right:
                device.status.bowl_status[1].last_zeroed_at = currDate;
            }
          }

          subGet().updateDevice(device);
          subSet({
            updateZeroScalesSuccess: { lidSetup },
            updateZeroScalesError: null,
          });
        } catch (e: any) {
          const error =
            status === DeviceStatusIDs.DeviceError
              ? {
                  results: [{ status: 3 }],
                }
              : e;
          subSet({
            updateZeroScalesError: {
              lidSetup,
              error: error as ServerControlResponseModel,
            },
            updateZeroScalesSuccess: null,
          });
        } finally {
          subSet({ updateZeroScalesLoading: false });
        }
      },
      updateFoodType: async (id, data) => {
        subSet({ loadingDevice: true });

        try {
          AnalyticsService.logEvent(`device_updateFoodType`);

          const updatedData = await DeviceApi.updateFoodType(id, data);
          const device = subGet().getDeviceById(id);

          (device.control as DeviceFeederControlModel) = updatedData;

          subGet().updateDevice(device);
          // subSet({ success: true });
        } catch (e) {
          subSet({ error: e as ServerControlResponseModel });
        }

        subSet({ loadingDevice: false });
      },
      updateFoodTypeAsync: async (id, data) => {
        AnalyticsService.logEvent(`device_updateFoodType`);
        subSet({
          loadingControl: {
            ...subGet().loadingControl,
            ...{
              bowls: {
                ...subGet().loadingControl.bowls,
                ...data,
              },
            },
          },
        });
        try {
          const updatedData = await DeviceApi.updateFoodTypeAsync(id, {
            settings: data.settings,
            type: data.type || null,
          });
          const device = subGet().getDeviceById(id) as DeviceModel;
          const deviceControl = device.control as DeviceFeederControlModel;
          deviceControl.bowls = deviceControl.bowls || {};

          if (updatedData?.pending.length) {
            deviceControl.bowls.settings = data.settings;
            (deviceControl.bowls.type as DeviceFeederBowlType) = data.type;
          }
          subGet().updateDevice({ ...device, control: deviceControl });
          subSet({ changeFoodTypeResult: 'success', loadingDevice: false });
          return true;
        } catch (e) {
          subSet({
            error: e as ServerControlResponseModel,
            changeFoodTypeResult: 'error',
            loadingDevice: false,
          });
          return false;
        }
      },
      updateCerberusControl: async (id, data) => {
        subSet({ loadingDevice: true });

        try {
          const newCerberusControl = {
            substance_type: data.substance_type,
            food_type: data.food_type,
          };
          const updatedData = await DeviceApi.updateCerberusControl(
            id,
            newCerberusControl,
          );
          const device = subGet().getDeviceById(id);

          (device.control as DeviceCerberusControlModel) = updatedData;

          subGet().updateDevice(device);
          subSet({ success: true, loadingDevice: false });
          return true;
        } catch (e) {
          subSet({
            error: e as ServerControlResponseModel,
            loadingDevice: false,
          });
          return false;
        }
      },
      updateCloseDelaySuccess: null,
      updateCloseDelayError: null,
      updateCloseDelay: async (id, data) => {
        AnalyticsService.logEvent(`device_updateCloseDelay`);

        subSet({ loadingDevice: true });

        const device = subGet().getDeviceById(id);
        const { lid } = device.control as DeviceFeederControlModel;

        try {
          (device.control as DeviceFeederControlModel) =
            await DeviceApi.updateCloseDelay(id, {
              ...lid,
              close_delay: data,
            });

          subGet().updateDevice(device);
          subSet({ success: true });
        } catch (e) {
          subSet({ error: e as ServerControlResponseModel });
        }

        subSet({ loadingDevice: false });
      },
      updateCloseDelayAsync: async (id, data) => {
        AnalyticsService.logEvent(`device_updateCloseDelay`);

        const device = subGet().getDeviceById(id);
        const { lid } = device.control as DeviceFeederControlModel;
        subSet({
          loadingControl: {
            ...subGet().loadingControl,
            ...{
              lid: {
                close_delay: data,
              },
            },
          },
        });
        try {
          const response = await DeviceApi.updateCloseDelayAsync(id, {
            ...lid,
            close_delay: data,
          });
          if (response.results[0].status_id === DeviceStatusIDs.Pending) {
            device.control.lid.close_delay = data;
          }
          subGet().updateDevice(device);
          subSet({ updateCloseDeviceResult: 'success' });
        } catch (e) {
          subSet({
            error: e as ServerControlResponseModel,
            updateCloseDeviceResult: 'error',
          });
        }
      },
      updateTargetSuccess: null,
      updateTargetError: null,
      updateTarget: async (id, data) => {
        AnalyticsService.logEvent(`device_updateTargetWeight`);

        subSet({ loadingDevice: true });

        try {
          const updatedData = await DeviceApi.updateTarget(id, data);
          const device = subGet().getDeviceById(id);

          (device.control as DeviceFeederControlModel) = updatedData;

          subGet().updateDevice(device);
          subSet({ updateTargetSuccess: true });
        } catch (e) {
          subSet({ updateTargetError: e as ServerControlResponseModel });
        }

        subSet({ loadingDevice: false });
      },
      updateTargetAsync: async (id, data) => {
        AnalyticsService.logEvent(`device_updateTargetWeight`);

        try {
          subSet({
            loadingControl: {
              ...subGet().loadingControl,
              ...{
                bowls: {
                  settings: [{ target: data }],
                },
              },
            },
          });
          const updatedData = await DeviceApi.updateTargetAsync(id, data);
          const device = subGet().getDeviceById(id);

          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            device.control.bowls.settings = data.settings;
          }
          subGet().updateDevice(device);
          subSet({ updateTargetSuccess: true });
          return true;
        } catch (e) {
          subSet({ updateTargetError: e as ServerControlResponseModel });
          return false;
        }
      },
      updateBowlType: async (id, type) => {
        AnalyticsService.logEvent(`device_updateBowlType`);

        subSet({ loadingDevice: true });

        try {
          const updatedData = await DeviceApi.updateBowlType(id, {
            type,
            settings: [
              {
                food_type: FeederFoodType.wet,
                target: 0,
              },
              {
                food_type: FeederFoodType.dry,
                target: 0,
              },
            ],
          });
          const device = subGet().getDeviceById(id);

          (device.control as DeviceFeederControlModel) = updatedData;

          subGet().updateDevice(device);
          subSet({ success: true });
        } catch (e) {
          subSet({ error: e as ServerControlResponseModel });
        }

        subSet({ loadingDevice: false });
      },
      updateBowlTypeAsync: async (id, type) => {
        AnalyticsService.logEvent(`device_updateBowlType`);
        try {
          subSet({
            loadingControl: {
              ...subGet().loadingControl,
              ...{
                bowls: {
                  type,
                },
              },
            },
          });
          const updatedData = await DeviceApi.updateBowlTypeAsync(id, {
            type,
            settings: [
              {
                food_type: FeederFoodType.wet,
                target: 0,
              },
              {
                food_type: FeederFoodType.dry,
                target: 0,
              },
            ],
          });
          const device = subGet().getDeviceById(id);

          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            device.control.bowls.type = type;
          }

          subGet().updateDevice(device);

          subSet({ loadingDevice: false });
          return true;
        } catch (e) {
          subSet({
            error: e as ServerControlResponseModel,
            loadingDevice: false,
          });
          return false;
        }
      },
      updateDrinkingTare: async id => {
        subSet({
          loadingControl: {
            ...subGet().loadingControl,
            tare: true,
          },
        });

        try {
          const updatedData = await DeviceApi.updateTareAsync(id, true);
          const device = subGet().getDeviceById(id);

          (device.control as DeviceFelaquaControlModel) = updatedData;
          subGet().updateDevice(device);
        } catch (e) {
          subSet({ error: e as ServerControlResponseModel });
        }

        // subSet({ loadingDevice: false });
      },
      updateLedMode: async (id, data) => {
        AnalyticsService.logEvent(`device_updateLedMode`);
        subSet({ updatingHubLedMode: true });
        try {
          const updatedData = await DeviceApi.updateLedModeAsync(id, data);
          const device = subGet().getDeviceById(id);

          if (updatedData.results[0].status_id === DeviceStatusIDs.Pending) {
            device.control.led_mode = data;
          }
          subSet({
            loadingControl: {
              ...subGet().loadingControl,
              led_mode: data,
            },
          });
          subGet().updateDevice(device);
          // subSet({ success: true });
        } catch (e) {
          subSet({ error: e as ServerControlResponseModel });
        } finally {
          subSet({ updatingHubLedMode: false });
        }
      },
      getDevicesCollection: () => {
        return subGet().deviceData.reduce<Record<number, DeviceModel>>(
          (acc, item) => {
            acc[item.id] = item;
            return acc;
          },
          {},
        );
      },
      resetStatusFields: () => {
        subSet({
          error: null,
          success: null,
          loadingDevice: false,
        });
      },
      deviceReset: () => {
        subSet({
          error: null,
          deviceData: [],
          loadingDevice: false,
          success: null,
        });
      },
      setLoadingControl: value => {
        ///////////////

        subSet({
          loadingControl: value,
        });
      },
      resetUpdateZeroScales: () => {
        subSet({
          updateZeroScalesSuccess: null,
          updateZeroScalesError: null,
          updateZeroScalesLoading: false,
        });
      },
      resetZeroScaleError: () => {
        subSet({
          updateZeroScalesError: null,
        });
      },
    })),
  };
};
export default createDeviceSlice;
