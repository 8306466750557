import { WeightUnits } from '@constants/WeightUnits';

export const convertToGramsOrOz = (weight: number, weightUnit: WeightUnits) => {
  switch (weightUnit) {
    case WeightUnits.Metric: {
      return weight * 1000;
    }
    case WeightUnits.Imperial: {
      return weight * 453.6;
    }
    default:
      return null;
      break;
  }
};
