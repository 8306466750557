/* eslint-disable react/jsx-no-useless-fragment */
import SpSentIcon from '@atoms/RoundIcons/SpSentIcon';
import SpErrorBadge from '@atoms/SpErrorBadge';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { DeviceControlResponseStatus } from '@constants/Device';
import { ServerControlResponseModel } from '@models/Device';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

interface ZeroScalesProps {
  isQuickAction?: boolean;
  loading: boolean;
  success: boolean;
  error: ServerControlResponseModel;
  lastZeroed: string;
}

export const SpZeroScalesUpdateStatusMessage = ({
  isQuickAction = false,
  loading,
  success,
  error,
  lastZeroed,
}: ZeroScalesProps) => {
  const { t } = useTranslation();
  const zeroedError =
    error &&
    get(error, 'results[0].status') === DeviceControlResponseStatus.DeviceError;
  const errorExist = error || zeroedError;

  return (
    <SpVStack space={8} style={styles.wrapper}>
      {!errorExist && !loading && !success && !isQuickAction && (
        <>
          <SpText size="xl" align="center">
            {t('zero_bowl_title')}
          </SpText>
          <SpText size="sm" style={styles.description} align="center">
            {t('last_zeroed', { value: lastZeroed })}
          </SpText>
        </>
      )}

      {errorExist && (
        <>
          {zeroedError ? (
            <SpErrorBadge
              marginBottom={isQuickAction ? 0 : 32}
              marginTop={isQuickAction ? 0 : 10}
              text={t('zero_error_description')}
              title={t('zero_error_title')}
            />
          ) : (
            <SpErrorBadge
              marginBottom={isQuickAction ? 0 : 32}
              marginTop={isQuickAction ? 0 : 10}
              text={t('zero_error_general')}
            />
          )}
        </>
      )}

      {success && (
        <SpView style={styles.rowSuccessContainer}>
          <SpSentIcon />
          <SpView flex={1}>
            <SpText style={styles.successMsg}>{t('zero_command_sent')}</SpText>
            <SpText style={styles.successDesc}>
              {t('zero_command_sent_description')}
            </SpText>
          </SpView>
        </SpView>
      )}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
  description: {
    marginTop: 16,
    marginBottom: 30,
  },
  wrapper: {
    position: 'relative',
  },
  rowSuccessContainer: {
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },
  successMsg: {
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  successDesc: {
    marginTop: 4,
  },
});
