/* eslint-disable react/react-in-jsx-scope */
import { StyleSheet, View, Animated, Easing } from 'react-native';
import { useEffect, useRef } from 'react';

const startRotationAnimation = (
  durationMs: number,
  rotationDegree: Animated.Value,
): void => {
  Animated.loop(
    Animated.timing(rotationDegree, {
      toValue: 360,
      duration: durationMs,
      easing: Easing.linear,
      useNativeDriver: true,
    }),
  ).start();
};
const LoaderCircleLine = ({
  color = '#57656c',
  durationMs = 1000,
  backgroundColor = 'transparent',
}) => {
  const rotationDegree = useRef(new Animated.Value(0)).current;
  const style = {
    borderColor: color,
    borderTopColor: backgroundColor,
    transform: [
      {
        rotateZ: rotationDegree.interpolate({
          inputRange: [0, 360],
          outputRange: ['0deg', '360deg'],
        }),
      },
    ],
  };
  useEffect(() => {
    startRotationAnimation(durationMs, rotationDegree);
  }, [durationMs, rotationDegree]);
  return (
    <View
      style={[styles.container, { backgroundColor }]}
      accessibilityRole="progressbar"
    >
      <Animated.View style={[styles.progress, style]} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 28,
    width: '100%',
  },
  progress: {
    width: 36,
    height: 36,
    borderRadius: 18,
    borderWidth: 3,
  },
});

export default LoaderCircleLine;
