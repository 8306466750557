import colors from '@styles/colors';

const mockDateData = [
  {
    date: '8',
    isActive: false,
    borderColor: 'transparent',
  },
  {
    date: '9',
    isActive: false,
    borderColor: 'transparent',
  },
  {
    date: '10',
    isActive: false,
    borderColor: colors.orangeWarning.color,
  },
  {
    date: '11',
    isActive: false,
    borderColor: '#ffffff',
  },
  {
    date: '12',
    isActive: false,
    borderColor: '#ffffff',
  },
  {
    date: '13',
    isActive: true,
    borderColor: '#ffffff',
  },
]

export default mockDateData;