import { TimelineEventModel } from '@models/Timeline';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import TimelineDevicePhoto from './TimelineDevicePhoto';

export const CurfewLockStatus = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);

  const parsedData = useMemo(() => {
    return JSON.parse(event.data);
  }, [event.data]);

  const text = useMemo(() => {
    return t(
      parsedData.locked
        ? 'timeline_device_curfew_locked'
        : 'timeline_device_curfew_unlocked',
      {
        devicename: deviceName,
      },
    );
  }, [parsedData, deviceName, t]);

  return (
    <TimelineCard
      active={active}
      image={<TimelineDevicePhoto active={active} event={event} />}
      text={text}
      date={event.created_at}
    />
  );
};
