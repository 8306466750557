import { SpView } from '@atoms/SpView';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React from 'react';
import { Image, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { AppImages } from '@constants/AppImages';

const TimelineSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView>
        <SpView style={styles.firstBlock}>
          <SpView
            style={[
              styles.line,
              {
                width: 91,
                height: 14,
                backgroundColor: '#c0e8dd',
                marginLeft: 51,
              },
            ]}
          />
          <SpView
            style={[
              styles.line,
              {
                width: 46,
                height: 8,
                backgroundColor: '#c0e8dd',
                marginLeft: 51,
              },
            ]}
          />
          <Image style={styles.catAvatar} source={AppImages.catAvatar} />
        </SpView>
        <SpView style={styles.secondBlockWrapper}>
          <SpView
            style={[
              styles.line,
              {
                width: 140,
                height: 14,
                backgroundColor: '#263a43',
                marginLeft: 51,
                marginBottom: 7,
                opacity: 0.2,
              },
            ]}
          />
          <SpView
            style={[
              styles.line,
              {
                width: 124,
                height: 14,
                backgroundColor: '#263a43',
                marginLeft: 51,
                marginBottom: 11,
                opacity: 0.15,
              },
            ]}
          />
          <SpView
            style={[
              styles.line,
              {
                width: 47,
                height: 8,
                backgroundColor: '#263a43',
                marginLeft: 51,
                opacity: 0.1,
              },
            ]}
          />
          <SpView style={styles.catFlapWrapper}>
            <Image style={styles.catFlap} source={AppImages.catFlap} />
          </SpView>
        </SpView>
        <SpView style={styles.thirdBlockWrapper}>
          <SpView
            style={[
              styles.line,
              {
                width: 92,
                height: 14,
                backgroundColor: '#263a43',
                marginLeft: 51,
                marginBottom: 7,
                marginTop: 10,
                opacity: 0.08,
              },
            ]}
          />
          <SpView
            style={[
              styles.line,
              {
                width: 46,
                height: 8,
                backgroundColor: '#263a43',
                marginLeft: 51,
                marginBottom: 11,
                opacity: 0.06,
              },
            ]}
          />
          <Image style={styles.userAvatar} source={AppImages.tourUserAvatar} />
        </SpView>
      </SpView>
      <SpTitle align="center" text={t('timeline')} />
      <SpText style={styles.contentText}>{t('timeline_tour_text')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  firstBlock: {
    height: 64,
    width: 236,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#263a43',
    borderRadius: 11,
    rowGap: 9.7,
    justifyContent: 'center',
    marginBottom: 27.8,
  },
  secondBlockWrapper: {
    width: 236,
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 16.7,
  },
  secondBlock: {
    height: 83,
    width: 236,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 11,
    justifyContent: 'center',
  },
  thirdBlockWrapper: {
    width: 236,
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  thirdBlock: {
    height: 50,
    width: 236,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 11,
    justifyContent: 'center',
  },
  line: {
    borderRadius: 10.4,
  },
  catAvatar: {
    width: 44,
    height: 44,
    position: 'absolute',
    left: -10,
    borderWidth: 1.4,
    borderColor: colors.green.color,
    borderRadius: 22,
  },
  catFlapWrapper: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: '#dde0e2',
    left: -7,
    opacity: 0.7,
  },
  catFlap: {
    width: 20,
    height: 20,
  },
  userAvatar: {
    width: 34,
    height: 34,
    position: 'absolute',
    left: -6,
  },
});

export default TimelineSlide;
