import NotificationApi from '@api/NotificationApi';
import { lens } from '@dhmk/zustand-lens';
import { NotificationEventModel } from '@models/Notification';
import { StateCreator } from 'zustand';
import { MergedInterfaces } from '../models';

export interface NotificationSliceModel {
  notificationStore: {
    loading: boolean;
    data: NotificationEventModel[];
    messages: {title: string, link: string}[]
    loadAll: (force: boolean) => void;
    reset: () => void;
    addMessage: (title: string, link: string) => void;
  };
}

const createNotificationSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  NotificationSliceModel
> = (rootSet, rootGet) => {
  return {
    notificationStore: lens((set, get, api) => ({
      data: [],
      messages: [],
      loading: false,
      loadAll: async (force = false) => {
        if (!force && get().data.length) {
          return;
        }

        set({ loading: true });
        try {
          const data = await NotificationApi.getEvents();
          set({ loading: false, data });
        } catch (e) {
          set({ loading: false, data: [] });
        }
      },
      reset: () => {
        set({
          loading: false,
          data: [],
          messages: []
        });
      },
      addMessage: (title: string, link: string) => {
        const messages = get().messages;
        messages.push({title, link});
        set({messages});
      }
    })),
  };
};

export default createNotificationSlice;
