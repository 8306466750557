import React from 'react';
import {
  ButtonProps,
  Dimensions,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
} from 'react-native';
import { SpText } from '@atoms/SpText';
import { testProperties } from '@utils/testProperties';
import colors from '@styles/colors';
import { SpView } from './SpView';
import { SpPlusIcon } from './SpPlusIcon';

interface SpButtonProps extends ButtonProps {
  textStyle?: StyleProp<TextStyle>;
  backgroundColor?: StyleProp<TextStyle>;
  plusColor?: StyleProp<TextStyle>;
  style?: StyleProp<TextStyle>;
}

export const SpAddButton = ({
  title,
  backgroundColor = { backgroundColor: colors.secondary3.color},
  plusColor = { backgroundColor: colors.secondary.color },
  style = {},
  ...props
}: SpButtonProps) => {
  return (
    <Pressable
      style={[styles.createHouseholdButton, style]}
      accessible={false}
      {...testProperties(title, 'SpAddButton')}
      {...props}
    >
      <SpView style={[styles.createHouseholdButtonContainer, backgroundColor]}>
        <SpText
          size="xl"
          fontFamily="Rubik_Medium"
          color={colors.greyText.color}
          style={{ opacity: 10 }}
        >
          {title}
        </SpText>
      </SpView>

      <SpView style={[styles.plus, plusColor]}>
        <SpPlusIcon color={colors.white.color} />
      </SpView>
      <SpView style={styles.block} />
    </Pressable>
  );
};

const WIDTH = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: 20,
  },
  textWrapper: {
    paddingHorizontal: 20,
    paddingTop: 20,
    alignItems: 'center',
  },
  createHouseholdButtonContainer: {
    borderRadius: 16,
    flex: 1,
    height: Platform.OS === 'ios' ? 72 : 70,
    paddingLeft: WIDTH > 390 ? 17 : 7,
    flexDirection: 'row',
    alignItems: 'center',
  },
  createHouseholdButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  plus: {
    width: WIDTH > 390 ? 44 : 39,
    height: WIDTH > 390 ? 44 : 39,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 22,
    position: 'absolute',
    right: WIDTH > 390 ? -3 : -6,
  },
  block: {
    width: 22,
    height: '100%',
  },
});
