import { StyleSheet } from 'react-native';
import Toast from 'react-native-toast-message';
import NetInfo from '@react-native-community/netinfo';

export type IToastProps = {
  description: string;
  isError?: boolean;
};

export const useCustomToast = () => {
  const show = (props: IToastProps) => {
    const isError = props.isError || false;
    NetInfo.fetch().then(state => {
      if (state.isConnected) {
        Toast.show({
          type: 'generalToast',
          props: {
            description: props.description,
            fontFamily: styles.textFont,
            isError,
          },
        });
      }
    });
  };

  return { show };
};

const styles = StyleSheet.create({
  textFont: {
    fontFamily: 'Rubik_Medium',
  },
});
