import CustomBottomSheetModal from '@atoms/CustomBottomSheetModal';
import LoadingSpinner from '@atoms/Loader';
import { SpProfileImage } from '@atoms/SpProfileImage';
import { SpSwitch } from '@atoms/SpSwitch';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import IndoorsSVG from '@atoms/SvgIcons/IndoorsSVG';
import OutdoorsSVG from '@atoms/SvgIcons/OutdoorsSVG';
import {
  DeviceFeederBowlType,
  DeviceFeederTareType,
  DeviceType,
  PetDoorLockingMode,
} from '@constants/Device';
import { PetPosition } from '@constants/Pet';
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { BottomSheetBackdrop, TouchableOpacity } from '@gorhom/bottom-sheet';
import {
  DeviceFeederControlModel,
  DeviceModel,
  RequestResult,
} from '@models/Device';
import { PetModel } from '@models/Pet';
import { SpLockUnlockActions } from '@molecules/SpLockUnlockActions';
import {
  SpZeroScalesActions,
  ZeroScalesActionModel,
} from '@molecules/SpZeroScalesActions';
import { SpZeroScalesUpdateStatusMessage } from '@molecules/SpZeroScalesUpdateStatusMessage';
import colors from '@styles/colors';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { usePetDevices } from '@hooks/usePetDevices';
import { TagProfile } from '@constants/Tag';
import { MergedInterfaces } from '../../../../store/models';
import useBoundStore from '../../../../store/store';
import { useDeviceById } from '@hooks/useDeviceById';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';

interface PetCardQuickActionsProps {
  pet: PetModel;
  indoorOnlyModeDevices: DeviceModel[];
  changeLocationDevices: DeviceModel[];
  zeroBowlDevices: DeviceModel[];
  isOpen: boolean;
  onUpdateLocation: (data: PetPosition) => void;
  onDismiss: () => void;
  isLocationUpdating: boolean;
}

export const PetCardQuickActions = ({
  isOpen,
  pet,
  onDismiss,
  onUpdateLocation,
  indoorOnlyModeDevices,
  changeLocationDevices,
  zeroBowlDevices,
  isLocationUpdating,
}: PetCardQuickActionsProps) => {
  const { t } = useTranslation();
  const snapPoints = useMemo(() => ['40%'], []);
  const {
    updateZeroScalesError,
    updateZeroScalesLoading,
    updateZeroScalesSuccess,
    updateZeroScalesAsync,
    updateProperty,
    loadingDevice,
    updateTagProfile,
    updateTagProfileLoading,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;

    return {
      updateZeroScalesAsync: deviceStore.updateZeroScalesAsync,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesLoading: deviceStore.updateZeroScalesLoading,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      loadingDevice: deviceStore.loadingDevice,
      updateProperty: deviceStore.updateProperty,
      updateTagProfile: deviceStore.updateTagProfile,
      updateTagProfileLoading: deviceStore.updateTagProfileLoading
    };
  });
  const deviceStoreData = useBoundStore(state => ({
    load: state.deviceStore.loadDevice,
  }));
  const loadingControl = useBoundStore(s => s.deviceStore.loadingControl)
  const petDevices = usePetDevices(pet);
  const flap = petDevices.find(
    elem => elem.product_id === DeviceType.CatFlapConnect,
  );
  const device = useDeviceById(flap?.id);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);
  const deviceStatus = useDeviceStatusHook(flap?.id, true)
  useDeviceStatusParse(deviceStatus);
  const [checked, setChecked] = useState<RequestResult>('none');
  useEffect(() => {
    deviceStoreData.load(true);
  }, [checked, loadingControl])
  const currPet = petsWithTag.find(elem => elem.pet.tag_id === pet.tag_id)
  const locationOption = useMemo(() => {
    if (changeLocationDevices.length) {
      const petWhere = pet?.status?.activity?.where;
      const outdoor = (
        <OutdoorsSVG
          color={colors.greyText.color}
          width={21}
          height={21}
          viewBox='0 0 17 17'
        />
      );
      const indoor = (
        <IndoorsSVG
          color={colors.greyText.color}
          width={23}
          height={23}
          viewBox='0 0 17 17'
        />
      );
      const getPosition = () => {
        switch (petWhere) {
          case PetPosition.inside:
            return t('outside');
          case PetPosition.outside:
            return t('inside');
          default:
            return '';
        }
      };

      if (petWhere === PetPosition.none || !petWhere) {
        return [
          {
            icon: indoor,
            text: t('change_pet_location {{position}}', {
              position: t('inside'),
            }),
            id: PetDoorLockingMode.inside_only,
          },
          {
            icon: outdoor,
            text: t('change_pet_location {{position}}', {
              position: t('outside'),
            }),
            id: PetDoorLockingMode.outside_only,
          },
        ];
      }
      return [
        {
          icon: petWhere === PetPosition.inside ? outdoor : indoor,
          text: t('change_pet_location {{position}}', {
            position: getPosition(),
          }),
          id:
            pet.status.activity?.where === PetPosition.inside
              ? PetPosition.outside
              : PetPosition.inside,
        },
      ];
    }

    return [];
  }, [changeLocationDevices, pet]);

  const zeroBowlOption: ZeroScalesActionModel[] = useMemo(() => {
    if (zeroBowlDevices.length) {
      const type = (zeroBowlDevices[0].control as DeviceFeederControlModel)
        ?.bowls?.type;

      return [
        {
          id:
            type === DeviceFeederBowlType.Single
              ? DeviceFeederTareType.Left
              : DeviceFeederTareType.Both,
          text:
            type === DeviceFeederBowlType.Single
              ? i18n.t('zero_bowl')
              : i18n.t('zero_both_bowls'),
          image: [],
        },
      ];
    }

    return [];
  }, [zeroBowlDevices]);

  const zeroBowlAction = useCallback(
    (event: DeviceFeederTareType) => {
      if (updateZeroScalesLoading || loadingDevice) {
        return;
      }

      updateZeroScalesAsync(zeroBowlDevices[0].id, event, true);
    },
    [zeroBowlDevices, updateZeroScalesLoading],
  );

  const indoorOnlyModeAction = async () => {
    if (flap) {
      const result = await updateTagProfile(flap.id, pet);
      if (result !== TagProfile.None) {
        setChecked(result === TagProfile.Safe ? 'success' : 'error');
      }
    }
  };

  useEffect(() => {
    if (flap && checked === 'none') {
      const index = flap.tags.findIndex(item => item.id === pet.tag_id);
      if (index === -1) return;
      if (loadingControl.tag_profiles || updateTagProfileLoading) return
      setChecked(
        flap?.tags[index]?.profile === TagProfile.Safe ? 'success' : 'error',
      );
    }
  }, [checked, flap, loadingControl]);

  useEffect(() => {
    updateProperty({
      updateZeroScalesLoading: false,
    });
    return () => updateProperty({ updateTagProfileResult: 'none' });
  }, []);

  const component = useMemo(() => {
    return () => (
      <Animated.View style={styles.avatarContainer}>
        <SpView style={styles.avatar}>
          <SpProfileImage
            width={46}
            height={46}
            imageUrl={pet?.photo?.location}
          />
        </SpView>
      </Animated.View>
    );
  }, [pet]);

  return (
    <SpView>
      <CustomBottomSheetModal
        opened={isOpen}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        handleComponent={component}
        snapPoints={snapPoints}
        onDismiss={() => {
          updateProperty({
            updateZeroScalesError: null,
            updateZeroScalesSuccess: null,
            updateZeroScalesLoading: false,
          });
          onDismiss();
        }}
        inScrollView
      >
        <ScrollView>
          <SpView style={styles.header}>
            <SpView flexDirection='row' alignItems='center'>
              <SpView marginRight={4}>
                <FontAwesomeIcon
                  color={colors.greyText.color}
                  size={22}
                  icon={faBoltLightning}
                />
              </SpView>

              <SpText
                size='xxl'
                color={colors.greyText.color}
                fontFamily='Rubik_Medium'
                align='center'
              >
                {t('quick_actions')}
              </SpText>
            </SpView>
          </SpView>

          <SpView paddingHorizontal={19} paddingBottom={36}>
            {updateZeroScalesLoading || updateTagProfileLoading ||  isLocationUpdating ? (
              <SpView paddingBottom={19} alignItems='center'>
                <LoadingSpinner />
              </SpView>
            ) : null}

            <SpVStack space={10}>
              {!!zeroBowlDevices.length && (
                <SpZeroScalesUpdateStatusMessage
                  isQuickAction
                  loading={updateZeroScalesLoading}
                  success={!!updateZeroScalesSuccess}
                  error={updateZeroScalesError?.error}
                  lastZeroed=''
                />
              )}

              {!!changeLocationDevices.length && (
                <SpLockUnlockActions
                  textStyle={{ fontSize: 16 }}
                  buttons={locationOption}
                  onSelect={onUpdateLocation}
                />
              )}

              {!!indoorOnlyModeDevices.length && (
                <SpView style={styles.indoorOnlyContainer}>
                  <SpView>
                    <SpText fontFamily='Rubik_Medium'>
                      {t('indoor_mode_title')}
                    </SpText>
                    <SpView marginTop={4}>
                      {!loadingControl.tag_profiles && !updateTagProfileLoading && <SpText
                        size="sm"
                        color={currPet.pet.profile === TagProfile.Safe ? colors.toxicGreen.color : colors.errorRed.color}
                        fontFamily="Rubik_Medium"
                      >
                        {currPet.pet.profile === TagProfile.Safe ? t('enabled') : t('disabled')}
                      </SpText>}
                    </SpView>
                  </SpView>
                  <SpView>
                    {checked !== 'none' && (
                      <TouchableOpacity
                        onPress={indoorOnlyModeAction}
                        activeOpacity={1}
                      >
                        {updateTagProfileLoading ||  isLocationUpdating || loadingControl.tag_profiles ? <LoadingSpinner/> : <SpSwitch
                          disabled
                          checked={currPet.pet.profile === TagProfile.Safe ? true : false}
                          onChange={indoorOnlyModeAction}
                          customStyle={{ opacity: 1 }}
                        />}
                      </TouchableOpacity>
                    )}
                  </SpView>
                </SpView>
              )}

              {!!zeroBowlDevices.length && (
                <SpZeroScalesActions
                  textStyle={{ fontSize: 16 }}
                  buttons={zeroBowlOption}
                  onSelect={zeroBowlAction}
                />
              )}
            </SpVStack>
          </SpView>
        </ScrollView>
      </CustomBottomSheetModal>
    </SpView>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 26,
    marginTop: 10,
    position: 'relative',
  },
  avatarContainer: {
    alignItems: 'center',
    marginTop: -20,
  },
  avatar: {
    backgroundColor: colors.white.color,
    borderRadius: 50,
  },
  indoorOnlyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    borderRadius: 16,
  },
});
