import IconButton from '@atoms/IconButton';
import { SpCenter } from '@atoms/SpCenter';
import { SpDivider } from '@atoms/SpDivider';
import { SpHStack } from '@atoms/SpHStack';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import DashSVG from '@atoms/SvgIcons/DashSVG';
import PawSVG from '@atoms/SvgIcons/PawSVG';
import { PetPosition } from '@constants/Pet';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { PetModel } from '@models/Pet';
import SpPetSkeletonLoader from '@molecules/SpPetSkeletonLoader';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { RootStackParamList } from 'src/pages';

import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';
import { format } from 'date-fns';
import textStyles from '@styles/text';

import { PetCardHeader } from './PetCardHeader';
import { PetCardQuickActions } from './PetCardQuickActions';
import PetDrinkingFeeding from './PetDrinkingFeeding';
import PetLastActionTime from './PetLastActionTime';
import PetTimeAtBowl from './PetTimeAtBowl';
import PetTripsToday from './PetTripsToday';
import PetWalking from './PetWalking';
import { usePetCardQuickActions } from '../../hooks/usePetCardQuickActions';
import { PetMovementNotification } from '../PetMovementNotification';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = Math.min(Math.round(SLIDER_WIDTH * 0.65), 350);
const HEIGHT = Dimensions.get('screen').height;

interface PetCardProps {
  item: PetModel;
  loading: boolean;
  dataRefreshing: boolean;

}

export type AdaptiveSize =
  | {
      fontSizeForNumbers: number;
      fontSizeForText: number;
    }
  | undefined;

const { width } = Dimensions.get('window');
const adaptiveSize: AdaptiveSize = {
    fontSizeForNumbers: Math.min(width / 15, 24),
    fontSizeForText: Math.min(width / 18, 24),
};

export const PetCard = ({ item, loading, dataRefreshing }: PetCardProps) => {

  const pet = item;
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocationUpdating, setIsLocationUpdating] = useState(false)
  const { updatePetPosition, setActivePet, getPetStatistics } = useBoundStore(s => s.petStore);

  const petActions = getPetStatistics(item?.id)
  const quickActions = usePetCardQuickActions(pet);
    
  const writeAccess = useUserHasWriteAccess();
  const handleUpdateLocation = async (data: PetPosition) => {
    const formattedDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    try {
      setIsLocationUpdating(true);
      await updatePetPosition(pet.id, {
        where: data,
        since: formattedDate,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLocationUpdating(false);
    }
  };
  const handleNavigationToStats = () => {
    setActivePet(pet.id);

    navigate('PetsNavigation', {
      screen: 'PetDashboardSummary',
      params: { id: pet.id },
    });
  };

  const handleModal = (modalOpen: boolean) => () => {
    setIsModalOpen(modalOpen);
  };

  const allowQuickActions = useMemo(() => {
    return (
      writeAccess &&
      Boolean(
        quickActions.zeroBowl.length ||
          quickActions.indoorOnlyMode.length ||
          quickActions.changeLocation.length,
      )
    );
  }, [quickActions, writeAccess]);

  const displayContentLogic = useMemo(() => {
    const drinking = petActions?.drinking;
    const feeding = petActions?.feeding;
    const movement = petActions?.movement;
    const notEmpty = {
      drinking: Object.keys(drinking || {}).length !== 0,
      movement: Object.keys(movement || {}).length !== 0,
      feeding: Object.keys(feeding || {}).length !== 0,
    };
      switch (true) {
      // Drinking
      case notEmpty.drinking && !notEmpty.movement && !notEmpty.feeding:
        return (
          <>
            <PetDrinkingFeeding
              data={drinking}
              isDrinking
              disablePadding
              adaptiveSize={adaptiveSize}
            />
            <SpDivider />
            <PetLastActionTime
              icon={faClock}
              data={drinking}
              title={t('last_drank')}
              disablePadding
            />
            <SpDivider />
            {/* <PetDrinkingNotification
              isWarning
              description={t('drinking_reduced')}
              disabledInsightStyle={atoms.invisibleInsight}
            /> */}
            <PetTimeAtBowl data={drinking as any} isFeeds={false} />
          </>
        );
      // Movement
      case notEmpty.movement && !notEmpty.feeding && !notEmpty.drinking:
        return (
          <>
            <PetMovementNotification locationSince={movement.since} location={movement.where} />
            <PetWalking data={movement} disablePadding adaptiveSize={adaptiveSize} />
            <SpDivider />
            <PetTripsToday data={movement} disablePadding adaptiveSize={adaptiveSize} />
          </>
        );
      // Feeding
      case notEmpty.feeding && !notEmpty.movement && !notEmpty.drinking:
        return (
          <>
            <PetDrinkingFeeding
              data={feeding}
              isDrinking={false}
              disablePadding
              adaptiveSize={adaptiveSize}
            />
            <SpDivider />
            <PetLastActionTime icon={faClock} data={feeding} title={t('last_ate')} disablePadding />
            <SpDivider />
            {/* <PetMealsGivenNotification mealsGiven={1} /> */}
            <PetTimeAtBowl data={feeding as any} isFeeds />
          </>
        );
      // Drinking and Movement
      case notEmpty.drinking && notEmpty.movement && !notEmpty.feeding:
        return (
          <>
            <PetMovementNotification locationSince={movement.since} location={movement.where} />
            <PetWalking data={movement} disablePadding adaptiveSize={adaptiveSize} />
            <SpDivider />
            <PetDrinkingFeeding
              data={drinking}
              isDrinking
              disablePadding
              adaptiveSize={adaptiveSize}
            />
          </>
        );
      // Feeding and Movement
      case notEmpty.feeding && notEmpty.movement && !notEmpty.drinking:
        return (
          <>
            <PetMovementNotification locationSince={movement.since} location={movement.where} />
            <PetWalking data={movement} disablePadding adaptiveSize={adaptiveSize} />
            <SpDivider />
            <PetDrinkingFeeding
              data={feeding}
              isDrinking={false}
              disablePadding
              adaptiveSize={adaptiveSize}
            />
          </>
        );
      // Drinking and Feeding
      case notEmpty.feeding && !notEmpty.movement && notEmpty.drinking:
        return (
          <>
            <PetDrinkingFeeding
              data={drinking}
              isDrinking
              disablePadding
              adaptiveSize={adaptiveSize}
            />
            <SpDivider />
            <PetLastActionTime
              icon={faClock}
              data={drinking}
              title={t('last_drank')}
              disablePadding
            />
            <SpDivider />
            <PetDrinkingFeeding
              data={feeding}
              isDrinking={false}
              disablePadding
              adaptiveSize={adaptiveSize}
            />
          </>
        );
      // Drinking and Feeding and Movement
      case notEmpty.drinking && notEmpty.movement && notEmpty.drinking:
        return (
          <>
            <PetMovementNotification locationSince={movement.since} location={movement.where} />

            <PetDrinkingFeeding
              data={drinking}
              isDrinking
              disablePadding
              adaptiveSize={adaptiveSize}
            />
            <SpDivider />
            <PetDrinkingFeeding
              data={feeding}
              isDrinking={false}
              disablePadding
              adaptiveSize={adaptiveSize}
            />
          </>
        );
      default:
        return (
          <SpCenter flex={1}>
            <SpText style={[textStyles.smallTitle, styles.noDataText]}>
              {t('no_assigned_pets')}
            </SpText>
          </SpCenter>
        );
    }
  }, [pet, petActions]);
    const petHeader = useMemo(() => {
    return <PetCardHeader petActions={petActions} pet={pet} />;
  }, [petActions, pet]);
  
  return (
    <>
      {(loading && dataRefreshing) || !pet.name ? (
        <SpPetSkeletonLoader />
      ) : (
        <SpView
          borderRadius={24}
          maxWidth={ITEM_WIDTH}
          padding={HEIGHT < 810 ? 10 : 20}
          backgroundColor="#ffffff"
          height="100%"
          justifyContent="space-between">
          <SpView>{petHeader}</SpView>
          <SpView style={styles.boxContainer}>{displayContentLogic}</SpView>
          <SpHStack justifyContent="space-between" flexDirection="row" space={3} width="100%">
            {allowQuickActions && (
              <IconButton
                svgIcon={
                  <PawSVG color={colors.primary.color} width={50} height={50} viewBox="0 0 50 30" />
                }
                action={handleModal(true)}
                iconColor={colors.white.color}
                backgroundColor={colors.primary.color}
                componentName={`QuickAction${pet.id}`}
              />
            )}
            <IconButton
              action={handleNavigationToStats}
              iconColor={colors.white.color}
              backgroundColor={colors.greyText.color}
              componentName={`Dashboard${pet.id}`}
              width={!allowQuickActions ? ITEM_WIDTH - 40 : undefined}
              svgIcon={
                <DashSVG color={colors.greyText.color} width={50} height={50} viewBox="0 0 50 30" />
              }
            />
          </SpHStack>
        </SpView>
      )}

      {isModalOpen && !loading && (
        <PetCardQuickActions
          pet={pet}
          zeroBowlDevices={quickActions.zeroBowl}
          changeLocationDevices={quickActions.changeLocation}
          indoorOnlyModeDevices={quickActions.indoorOnlyMode}
          isOpen={isModalOpen}
          onUpdateLocation={handleUpdateLocation}
          isLocationUpdating={isLocationUpdating}
          onDismiss={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  blurer: {
    opacity: 0,
  },
  boxContainer: {
    flex: 1,
    marginVertical: HEIGHT > 720 ? '7%' : '3%',
    justifyContent: 'space-evenly',
    marginTop: 0,
  },
  noDataText: {
    fontSize: 16,
    fontFamily: 'Rubik',
  },
});
