import { DeviceControlResponseStatus, DeviceFeederTareType, DeviceHubLedMode } from '@constants/Device';

import {
  DeviceCatFlapControlModel,
  DeviceCerberusControlModel,
  DeviceFeederBowlModel,
  DeviceFeederControlModel,
  DeviceFeederLidModel,
  DeviceFelaquaControlModel,
  DeviceHubControlModel,
  DeviceModel,
  DevicePetDoorControlModel,
  LockUnlockRequestParamsModel,
  ServerControlResponseModel,
  UpdateCurfewRequestParamsModel,
} from '@models/Device';
import { DeviceTagModel } from '@models/DeviceTag';
import { TagModel } from '@models/Tag';
import qs from 'qs';
import { TagProfile } from '@constants/Tag';
import Http from './Http';

class DeviceApi {
  static readonly httpParams = {
    with: ['children', 'tags', 'control', 'status'],
  };

  static putDeviceControlAsync<T>(id: string | number, data: T) {
    return Http.put(`/api/device/${id}/control/async`, data);
  }

  static putDeviceControl<T>(id: string | number, data: T) {
    return Http.put(`/api/device/${id}/control`, data).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }

  static putDeviceTagAsync<T>(
    id: number,
    data: { tag_id: number, request_action?: number, profile?: number } | Array<{ tag_id: number, request_action?: number, profile?: number }>,
  ) {
    return Http.put(`/api/v2/device/${id}/tag/async`, data);
  }

  static deleteDeviceTagAsync<T>(id: number, tagId: number) {
    return Http.delete(`/api/device/${id}/tag/${tagId}/async`, {});
  }

  static getDeviceTags(id: number): Promise<TagModel[]> {
    return Http.get(`/api/device/${id}/tag`, {
      params: this.httpParams,
    }).then(response => {
      return response.data.data;
    });
  }

  // TODO filter by household and reload whenever user changes household
  static getDevices(householdId?: number): Promise<DeviceModel[]> {
    return Http.get(`/api/device`, {
      params: { ...this.httpParams, householdid: householdId },
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static deleteDevice(deviceId: DeviceModel['id']): Promise<DeviceModel[]> {
    return Http.delete(`/api/device/${deviceId}`).then(
      response =>
        DeviceApi.parseResponse(response.data) as Promise<DeviceModel[]>,
    );
  }

  static allPairingAsync(): Promise<DeviceModel[] | any> {
    return Http.get(`/api/device/pairing`, {
      timeout: 2000,
    })
      .then(
        response =>
          DeviceApi.parseResponse(response.data) as Promise<DeviceModel[]>,
      )
      .catch(err => {
        return err;
      });
  }

  static pairWithCode(
    pairingCode: string,
    householdId: DeviceModel['household_id'],
  ): Promise<DeviceModel> {
    return Http.post(`/api/device/pair/${householdId}`, {
      pairing_code: pairingCode,
    }, {
      signal: DeviceApi.newAbortSignal(4000)
    }).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }

  static pairAsync(
    deviceId: DeviceModel['id'],
    householdId: DeviceModel['household_id'],
  ): Promise<DeviceModel> {
    return Http.post(`/api/device/${deviceId}/pair/${householdId}`);
  }

  static getDeviceStatus(deviceId: DeviceModel['id']): Promise<DeviceModel[]> {
    return Http.get(`/api/device/${deviceId}/control/status`, {
      signal: DeviceApi.newAbortSignal(5000),
    })
      .then(response => response.data.data)
      .catch(error => {
        // console.log(error);
        return error;
      });
  }

  // TODO: we need to prevent the user from controlling the product if its offline

  static lockUnlock(
    deviceId: number,
    data: LockUnlockRequestParamsModel,
  ): Promise<DeviceCatFlapControlModel | DevicePetDoorControlModel> {
    return DeviceApi.putDeviceControl(deviceId, data);
  }

  static lockUnlockAsync(id: number, data: LockUnlockRequestParamsModel) {
    return Http.put(`/api/device/${id}/control/async`, data).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateCurfew(
    deviceId: number,
    curfew: UpdateCurfewRequestParamsModel,
  ): Promise<DevicePetDoorControlModel | DeviceCatFlapControlModel> {
    return DeviceApi.putDeviceControl(deviceId, curfew);
  }

  static updateCurfewAsync(id: number, data: UpdateCurfewRequestParamsModel) {
    return Http.put(`/api/device/${id}/control/async`, data).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateName(id: number, data: DeviceModel): Promise<DeviceModel> {
    return Http.put(`/api/device/${id}`, data).then(
      response => response.data.data,
    );
  }

  static updateNameAsync(
    deviceId: number,
    data: DeviceModel,
  ): Promise<DeviceModel> {
    return DeviceApi.putDeviceControlAsync(deviceId, data).then(
      response => response.data.data,
    );
  }

  static unassignPet(id: number, tagId: number): Promise<unknown> {
    return Http.delete(`/api/device/${id}/tag/${tagId}`).then(
      response => response.data.data,
    );
  }

  static unassignPetAsync(id: number, data?: any): Promise<unknown> {
    return DeviceApi.deleteDeviceTagAsync(id, data).then((response) => response.data);
  }

  // TODO: can use pending for device tags too
  static assignPet(
    id: number,
    tagId: number,
    data?: { profile: TagProfile },
  ): Promise<DeviceTagModel> {
    return Http.put(`/api/device/${id}/tag/${tagId}`, data || {}).then(
      response => {
        return response.data.data;
      },
    );
  }

  static assignPetAsync(id: number, data?: any): Promise<any> {
    return DeviceApi.putDeviceTagAsync(id, data).then((response) => {
      return response.data;
    });
  }

  static updateZeroScales(
    deviceId: number,
    tare: DeviceFeederTareType | boolean,
  ): Promise<DeviceFeederControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { tare });
  }

  static updateZeroScalesAsync(
    deviceId: number,
    tare: DeviceFeederTareType | boolean,
  ) {
    return DeviceApi.putDeviceControlAsync(deviceId, { tare }).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateFoodType(
    deviceId: number,
    bowls: DeviceFeederBowlModel,
  ): Promise<DeviceFeederControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { bowls });
  }

  static updateFoodTypeAsync(deviceId: number, bowls: DeviceFeederBowlModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateCerberusControl(
    deviceId: number,
    data: DeviceCerberusControlModel,
  ): Promise<DeviceCerberusControlModel> {
    return DeviceApi.putDeviceControl(deviceId, data);
  }

  static updateCerberusControlAsync(
    deviceId: number,
    bowls: DeviceCerberusControlModel,
  ): Promise<DeviceCerberusControlModel> {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateCloseDelay(
    deviceId: number,
    lid: DeviceFeederLidModel,
  ): Promise<DeviceFeederControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { lid });
  }

  static updateCloseDelayAsync(deviceId: number, lid: DeviceFeederLidModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { lid }).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateTarget(
    deviceId: number,
    bowls: DeviceFeederBowlModel,
  ): Promise<DeviceFeederControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { bowls });
  }

  static updateTargetAsync(deviceId: number, bowls: DeviceFeederBowlModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateBowlType(
    deviceId: number,
    bowls: DeviceFeederBowlModel,
  ): Promise<DeviceFeederControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { bowls });
  }

  static updateBowlTypeAsync(deviceId: number, bowls: DeviceFeederBowlModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      DeviceApi.parseResponse(response),
    );
  }

  static updateTare(
    deviceId: number,
    tare: boolean,
  ): Promise<DeviceFelaquaControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { tare });
  }

  static updateTareAsync(id: number, tare: boolean) {
    return Http.put(`/api/device/${id}/control/async`, {
      tare,
    }).then(response => DeviceApi.parseResponse(response));
  }

  static updateLedMode(
    deviceId: number,
    led_mode: DeviceHubLedMode,
  ): Promise<DeviceHubControlModel> {
    return DeviceApi.putDeviceControl(deviceId, { led_mode });
  }

  static learnMode(
    id: number,
    learn_mode: boolean,
  ): Promise<DeviceHubControlModel> {
    return Http.put(`/api/device/${id}/control`, {
      learn_mode,
    }).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }

  static updateLedModeAsync(deviceId: number, led_mode: DeviceHubLedMode) {
    return DeviceApi.putDeviceControlAsync(deviceId, { led_mode }).then(
      response => DeviceApi.parseResponse(response),
    );
  }

  static updateHubPairingMode(deviceId: number, pairing_mode = 2) {
    return DeviceApi.putDeviceControlAsync(deviceId, { pairing_mode }).then(
      response => {
        return DeviceApi.parseResponse(response.data);
      },
    );
  }

  private static newAbortSignal(timeoutMs: number) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
    return abortController.signal;
  }

  private static parseResponse(
    response: ServerControlResponseModel,
  ): Promise<any> {
    const someError = (response?.results || []).some(
      item =>
        item.status === DeviceControlResponseStatus.DeviceError ||
        item.status === DeviceControlResponseStatus.ServerError ||
        item.status === DeviceControlResponseStatus.DeviceTimeout,
    );

    return new Promise((resolve, reject) => {
      if (someError) {
        reject(response);
      } else {
        resolve(response.data);
      }
    });
  }
}

export default DeviceApi;
