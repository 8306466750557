import { PetPosition } from '@constants/Pet';
import { TimelineEventModel } from '@models/Timeline';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import { TimelinePetPhoto } from './TimelinePetPhoto';

export const IntruderMovement = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);

  const text = useMemo(() => {
    let translationKey = '';
    const direction = event?.movements[0]?.direction;

    if (
      event.movements &&
      (event.movements[0].tag_id || !event.movements[0].tag_id)
    ) {
      switch (direction) {
        case PetPosition.none:
          translationKey = 'timeline_action_lookthrough';
          break;
        case PetPosition.outside:
          translationKey = 'timeline_action_lefthouse';
          break;
      }
    }

    return t(translationKey, {
      devicename: deviceName,
    });
  }, [deviceName, t]);

  return (
    <TimelineCard
      active={active}
      image={<TimelinePetPhoto event={event} active={active} />}
      text={text}
      date={event.created_at}
    />
  );
};
