import { PetBioMenu } from '@constants/Menus';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { DeviceEventEmitter } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/lib/typescript/native-stack';
import useBoundStore from 'src/store/store';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { convertToGramsOrOz } from '@utils/convertToGramsOrOz';
import { useReturnHandler } from './useReturnHandler';
import { useUserWeightUnits } from './useUserWeightUnits';

export const useUpdatePet = (
  updateButtonLoading: Dispatch<SetStateAction<boolean>>,
  updateButtonDisabled: Dispatch<React.SetStateAction<boolean>>,
  inputName: string,
  navigation: NativeStackNavigationProp<any>,
): ((value: any) => void) => {
  const COMMA_REGEX = /,/g;
  const { activePet, updatePet } = useBoundStore(state => state.petStore);
  const userWeightUnits = useUserWeightUnits();
  const handler = useReturnHandler(
    inputName === PetBioMenu.Name ? 'pet_name' : inputName,
  );
  return useCallback(
    async val => {
      try {
        updateButtonLoading(true);
        updateButtonDisabled(true);
        AnalyticsService.logEvent(`pet_update_${inputName}`);
        await updatePet(activePet.id, {
          ...activePet,
          [inputName]:
            inputName === PetBioMenu.Weight
              ? convertToGramsOrOz(
                  Number(val.replace(COMMA_REGEX, '.')),
                  userWeightUnits,
                ).toFixed(0)
              : val,
        });

        if (inputName === 'species_id') {
          DeviceEventEmitter.emit('PetBioSpeciesChanged', 'breed_id', false);
          DeviceEventEmitter.emit('PetBioSpeciesChanged', 'breed_id2', false);
        }
        if (inputName === 'breed_id') {
          DeviceEventEmitter.emit('PetBioSpeciesChanged', 'breed_id', true);
        }
        if (inputName === 'breed_id2') {
          DeviceEventEmitter.emit('PetBioSpeciesChanged', 'breed_id2', true);
        }

        navigation.pop();
        handler();
      } catch (err: any) {
        navigation.pop();
        handler(err.code);
      }
    },
    [inputName, handler],
  );
};
