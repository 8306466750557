// regexp values
const upperCase = '[A-Z]';
const numbersCheck = '[0-9]';
const specialSymbol = '[^A-Za-z0-9]';
const lowerCase = '[a-z]';
//! don`t change the oredr of the regex array
export const regex = [numbersCheck, lowerCase, upperCase, specialSymbol];
export enum PasswordTypes {
  invalid,
  valid,
  invalidLength,
}

const checkPassword = (password: string): PasswordTypes => {
  if (password.length >= 8 && password.length <= 40) {
    return PasswordTypes.invalidLength;
  }

  for (let i = 1; i < regex.length + 1; i += 1) {
    const isValidCheck = new RegExp(regex[i - 1]).test(password);
    if (!isValidCheck) {
      return PasswordTypes.invalid;
    }
  }

  return PasswordTypes.valid;
};

export default checkPassword;
