import React, { ReactNode } from 'react';
import { Dimensions, Pressable, StyleSheet } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';
import colors from '@styles/colors';
import LoadingSpinner from '@atoms/Loader';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';
import { SpText } from './SpText';
import { SpView } from './SpView';

type Coordinates = {
  x: number;
  y: number;
};

type GradientButtonProps = {
  colorsGradient: string[];
  start?: Coordinates;
  end?: Coordinates;
  locations?: number[];
  borderRadius?: number;
  width?: number;
  height?: number;
  icon?: ReactNode | ReactNode[];
  text: string;
  action: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  styleContainer?: any;
};
const BUTTON_SIZE = Math.max(
  Math.min(Dimensions.get('screen').height * 0.18, 160),
  125,
);
const GradientButton = ({
  colorsGradient,
  start,
  end,
  borderRadius = 0,
  width = BUTTON_SIZE,
  height = BUTTON_SIZE,
  locations,
  icon,
  text,
  action,
  isLoading,
  disabled,
  styleContainer,
  ...restProps
}: GradientButtonProps) => {
  const [sizes, layoutHandler] = useAdaptiveFontSize({ text: 20 }, [text], {
    en: 10,
  });
  const windowWidth = Dimensions.get('window').width;

  const isLongText = () => {
    if (windowWidth < 380 && text.length > 10 && !text.includes(' ')) {
      return true;
    }
    return false;
  };
  
  return (
    <LinearGradient
      colors={colorsGradient}
      start={start}
      end={end}
      locations={locations}
      style={[
        styles.container,
        { borderRadius, width, height },
        isLoading ? { opacity: 0.5 } : {},
        styleContainer || {},
      ]}
    >
      <Pressable
        accessible={false}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        {...restProps}
        onPress={action}
        disabled={!!disabled}
      >
        <SpView flexDirection="row" justifyContent="center">
          {icon instanceof Array ? (
            icon.length > 1 &&
            icon.map((item: React.ReactNode, index: number) => (
              <SpView key={`${index}${index + 1}`}>{item}</SpView>
            ))
          ) : (
            <SpView>{icon}</SpView>
          )}
        </SpView>
        <SpText
          onTextLayout={layoutHandler}
          fontFamily="Rubik_Medium"
          style={[
            styles.textStyles,
            { fontSize: isLongText() ? 13 : sizes.text },
          ]}
          color={colors.greyText.color}
          numberOfLines={text.includes(' ') ? 2 : 1}
        >
          {text}
        </SpText>
      </Pressable>
      {isLoading && (
        <SpView style={styles.loadingSpinner}>
          <LoadingSpinner size="large" />
        </SpView>
      )}
    </LinearGradient>
  );
};

export default GradientButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 6,
    paddingVertical: 20,
  },
  textStyles: {
    marginTop: 24,
    textAlign: 'center',
  },
  loadingSpinner: {
    left: 20,
    top: 20,
    bottom: 20,
    right: 20,
    width: '100%',
    height: '100%',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
