import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReportRangeType } from '../constants/ReportRangeType';

const useViewedDays = (
  rangeType: ReportRangeType,
  setViewedReportDays: (data: number) => void,
): [ReportRangeType, Dispatch<SetStateAction<number>>] => {
  const [viewedDays, setViewedDays] = useState(
    [ReportRangeType.TwentyEightDays, ReportRangeType.SevenDays].includes(
      rangeType,
    )
      ? rangeType
      : 7,
  );

  useEffect(() => setViewedReportDays(viewedDays), [viewedDays]);

  return [viewedDays, setViewedDays];
};

export default useViewedDays;
