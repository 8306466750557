import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import React, { useMemo, useState } from 'react';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';

import { NavigationProp, useNavigation } from '@react-navigation/native';
import useBoundStore from 'src/store/store';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';

import { useTranslation } from 'react-i18next';
import SpTitle from '@molecules/SpTitle';
import colors from '@styles/colors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SpInput } from '@atoms/SpInput';
import SpErrorBadge from '@atoms/SpErrorBadge';
import CustomKeyboardAvoidingView from '@atoms/CustomKeyboardAvoidingView';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import { RootStackParamList } from 'src/pages';
import { HouseholdStackParamList } from '..';

type Props = NativeStackScreenProps<HouseholdStackParamList, 'JoinHousehold'>;

const JoinHousehold: React.FC<Props> = ({ route }) => {
  const noHouseholds = route.params?.userHasNoHouseholds;
  const [inviteCode, setInviteCode] = useState('');
  const { loadingInvites, acceptInvite } = useBoundStore(s => s.inviteStore);
  const { bottom } = useSafeAreaInsets();
  const [inputError, setInputError] = useState('');
  const [requestError, setRequestError] = useState(false);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { t } = useTranslation();

  const handleInvite = async () => {
    if (!inviteCode) {
      setInputError(t('input_error_empty'));
    } else {
      setRequestError(false);
      try {
        await acceptInvite(inviteCode);
        navigation.navigate('SuccessScreen', {
          title: t('hosehold_joined'),
          withButton: true,
          buttonText: t('continue'),
          buttonNavObject: {
            screenName: 'DashboardNavigation',
            reset: true,
          },
        });
      } catch {
        setInviteCode('');
        setRequestError(true);
      }
    }
  };

  const handleChangeText = (text: string) => {
    setRequestError(false);
    setInputError('');
    setInviteCode(text);
  };

  const placeholder = useMemo(() => {
    if (inputError) return '';
    return t(noHouseholds ? 'type_here' : 'type_code');
  }, [inputError]);

  return (
    <TouchableWithoutFeedback accessible={false} onPress={Keyboard.dismiss}>
      <CustomKeyboardAvoidingView
        scrollEnabled={false}
        enableAutomaticScroll
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps='handled'
      >
        <SpTitle text={t('join_a_household')} />
        {noHouseholds ? (
          <SpText style={styles.text}>
            {t('join_household_with_no_household')}
          </SpText>
        ) : (
          <>
            <SpText style={styles.text}>
              {t('join_household_subtitle_1')}
            </SpText>
            <SpText style={styles.text}>
              {t('join_household_subtitle_2')}
            </SpText>
          </>
        )}

        {requestError && (
          <SpErrorBadge marginBottom={0} marginTop={0} text={t('error_code')} />
        )}
        <SpView style={styles.inputWrapper}>
          <SpInput
            label={t('invite_code')}
            placeholder={placeholder}
            value={inviteCode}
            onChangeText={handleChangeText}
            error={inputError}
          />
        </SpView>

        <SpView
          style={[
            styles.buttonWrapper,
            {
              paddingBottom: bottom + 34,
            },
          ]}
        >
          <SpRoundedHeaderButton
            title={t('join')}
            backgroundColor={colors.primary.color}
            h={56}
            onPress={handleInvite}
            disabled={loadingInvites}
            isLoading={loadingInvites}
            stylesForContainer={
              Platform.OS === 'android' ? { marginBottom: 30 } : undefined
            }
          />
        </SpView>
      </CustomKeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};

export default JoinHousehold;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingTop: 6,
    flex: 1,
    backgroundColor: colors.white.color,
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    flex: 1,
  },
  inputWrapper: { marginTop: 24, paddingBottom: 30 },
  text: {
    fontFamily: 'Rubik',
    lineHeight: 24,
    marginBottom: 24,
    color: colors.greyText.color,
  },
});
