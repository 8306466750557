import { getSuccessTranslation } from '@utils/getSuccessTranslation';
import { useTranslation } from 'react-i18next';
import { CallTypes } from '@constants/Calls';
import { getErrorTranslation } from '../utils/getErrorTranslation';
import { useCustomToast } from './useToast';

export const useReturnHandler = (label: string) => {
  const { t } = useTranslation();
  const toast = useCustomToast();
  return (type?: CallTypes, message?: string) => {
    const conf =
      type || message
        ? {
            description: message || t(getErrorTranslation(type, label)),
            isError: true,
          }
        : {
            description: t(getSuccessTranslation(label)),
          };
    toast.show(conf);
  };
};
