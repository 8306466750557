import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
} from '@models/ReportModel';
import { endOfDay, subDays } from 'date-fns';

export const fillEmptyPoints = (
  data: GroupReportPointModel<
    | MappedConsumptionReportDataModel
    | MappedMovementReportDataModel
    | MappedPoseidonReportDataModel
  >[],
  viewedDays: number,
  activeDate: number
) => {
  const result = [];
  let initialDate = activeDate

  for (let i = 0; i < viewedDays; i++) {
    let point = data.find(item => item?.date === initialDate);

    if (point) {
      result.push(point);
    } else {
      const obj = {
        date: initialDate,
        points: [] as any,
      };
      result.push(obj);
    }
    initialDate = new Date(subDays(initialDate, 1)).valueOf();
  }
  return result;
};
