import { LanguageModel } from '@models/Language';
import SpSearchList from '@molecules/SpSearchList';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Keyboard,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import useBoundStore from 'src/store/store';

interface AccountLanguageProps {
  selectedLanguageId: number;
  handleChange: (language: string | number) => void;
}

type LanguageNewModel = { id: number; itemName: string; name: string };

export const AccountLanguageWrapper = ({
  selectedLanguageId,
  handleChange,
}: AccountLanguageProps) => {
  const [selectedItemId, setSelectedItemId] = useState<number[]>(
    selectedLanguageId ? [selectedLanguageId] : [],
  );

  const { languages } = useBoundStore(state => state.languageStore);

  const LanguageArr = useMemo(() => {
    return languages
      .map(({ native_name, name, id }: LanguageModel) => {
        const itemName = native_name;
        return { id, itemName, name };
      })
      .sort((a: LanguageNewModel, b: LanguageNewModel) => {
        if (a?.itemName && b?.itemName) {
          return a.itemName.localeCompare(b.itemName);
        }
        if (!b?.itemName) {
          return a.itemName.localeCompare(b.name);
        }
        return a.name.localeCompare(b.name);
      });
  }, [languages]);

  const onChangeLanguage = useCallback((ids: number[]) => {
    setSelectedItemId(ids);
    handleChange(ids[0]);
  }, []);
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View style={styles.container}>
        <SpSearchList
          arr={LanguageArr}
          selectedItems={selectedItemId}
          setItemId={onChangeLanguage}
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
