import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SupportSVG = (props: SvgProps) => (
  <Svg width={16} height={16} {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M12.364 0H3.636A3.64 3.64 0 0 0 0 3.636v5.818a3.643 3.643 0 0 0 2.91 3.564v2.255a.727.727 0 0 0 1.13.605l4.178-2.787h4.146A3.64 3.64 0 0 0 16 9.454V3.636A3.64 3.64 0 0 0 12.364 0Zm-1.455 8.727H5.091a.727.727 0 1 1 0-1.454h5.818a.727.727 0 1 1 0 1.454Zm1.455-2.909H3.636a.727.727 0 1 1 0-1.454h8.728a.727.727 0 1 1 0 1.454Z"
    />
  </Svg>
);
export default SupportSVG;
