/* eslint-disable react/no-unused-prop-types */
import { SpView } from '@atoms/SpView';
import React, { useCallback } from 'react';
import colors from '@styles/colors';
import SpTextButton from '@atoms/SpTextButton';
import { StyleSheet } from 'react-native';
import { SpSwitch } from '@atoms/SpSwitch';
import useBoundStore from 'src/store/store';

export type TimeValue = {
  lock_time: string;
  unlock_time: string;
  id: string;
  time?: string;
  enabled: boolean;
};

type Props = {
  isEditable: boolean;
  setSelectedValue: (data: unknown) => void;
  selectedValue: TimeValue;
  index: number;
  item: TimeValue;
  changeCurfewAction: (id: number) => void;
};

type WrapperProps = { isFirst: boolean };

const CurfewTimeItem: React.FC<Props> = ({
  isEditable,
  setSelectedValue,
  item,
  selectedValue,
  index,
  changeCurfewAction,
}) => {
  const TimeWrapper = useCallback(
    ({ isFirst }: WrapperProps) => {
      const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);

      const data = item[isFirst ? 'lock_time' : 'unlock_time'];
      const condition =
        item.id === selectedValue?.id && data === selectedValue.time;

      const pressHandler = () => {
        setSelectedValue({
          time: data,
          id: item.id,
          isLockedTime: isFirst,
          index,
          item,
        });
      };

      return (
        <SpView flexDirection="row" alignItems="center">
          <SpTextButton
            disabled={!isEditable}
            textStyle={[
              styles.textTime,
              condition ? styles.textTimeSelected : styles.textTimeDefault,
            ]}
            text={data}
            onPress={pressHandler}
          />
          {isEditable && <SpView style={styles.triangle} />}
        </SpView>
      );
    },
    [isEditable, selectedValue, item],
  );

  return (
    <SpView flex={1} marginTop={18} width="100%">
      <SpView
        flexDirection="row"
        backgroundColor={isEditable ? colors.secondary3.color : colors.lightGrey.color}
        flex={1}
        width="100%"
        justifyContent="space-between"
        paddingHorizontal={31}
        paddingVertical={12}
        borderRadius={16}
      >
        <TimeWrapper isFirst />
        <TimeWrapper isFirst={false} />
        {isEditable && (
          <SpSwitch
            checked={item.enabled}
            onChange={() => {
              changeCurfewAction(index);
            }}
          />
        )}
      </SpView>
    </SpView>
  );
};

export default CurfewTimeItem;

const styles = StyleSheet.create({
  textTime: {
    fontFamily: 'Rubik',
    fontSize: 20,
  },
  textTimeDefault: {
    color: colors.black.color,
  },
  textTimeSelected: {
    color: colors.primary.color,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderRightWidth: 7,
    borderTopWidth: 7,
    borderLeftWidth: 7,
    borderRadius: 2,
    borderTopColor: colors.greyText.color,
    marginLeft: 5,
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
});
