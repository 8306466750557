import { SpLoading } from '@atoms/SpLoading';
import { AllowedTimelineEvents } from '@constants/Timeline';
import { TimelineEventMetaModel, TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { last } from 'lodash-es';

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import useTour from '@hooks/useTour';
import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { Timeline } from '../molecules/Timeline/Timeline';
import TimelineSlide from '../../Tour/components/TimelineSlide/TimelineSlide';
import { useDetectPushNotification } from '@hooks/useDetectPushNotification';

export const TimelineWrapper = () => {
  const [refreshControlLoading, setRefreshControlLoading] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [allEvents, setAllEvents] = useState<TimelineEventModel[]>([]);
  const [pagination, setPagination] = useState<TimelineEventMetaModel>(null);
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const timeLineStoreData = useBoundStore((state: MergedInterfaces) => ({
    loading: state.timelineStore.loading,
    load: state.timelineStore.getByHousehold,
    reset: state.timelineStore.reset,
  }));
  const { saveAction } = useDetectPushNotification();
  const isFocused = useIsFocused();

  const initTour = useTour({
    uniqueId: 'v0-timeline',
    components: [<TimelineSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const filteredEvents = useMemo(() => {
    return allEvents.filter(item => AllowedTimelineEvents.includes(item.type));
  }, [allEvents]);

  const loadEvents = useCallback(
    async (beforeId: number = null) => {
      try {
        if (!activeHousehold) return;
        const data = await timeLineStoreData.load(activeHousehold.id, beforeId);

        if (beforeId) {
          setAllEvents(prevState => {
            return [...prevState, ...data.data];
          });
        } else {
          setAllEvents(data.data);
        }

        setPagination(data.meta);
      } catch (e) {}
    },
    [activeHousehold?.id],
  );

  const refreshHandler = useCallback(() => {
    setRefreshControlLoading(true);
    loadEvents();
  }, [activeHousehold?.id]);

  const endReachedHandler = useCallback(
    ($event: { distanceFromEnd: number }) => {
      if (
        timeLineStoreData.loading ||
        lazyLoading ||
        $event.distanceFromEnd <= 0 ||
        Platform.OS === 'web'
      ) {
        return;
      }

      if (allEvents.length && allEvents.length < pagination.count) {
        setLazyLoading(true);
        loadEvents(last(allEvents).id).finally(() => {
          setLazyLoading(false);
        });
      }
    },
    [pagination, allEvents, lazyLoading, Platform.OS],
  );


  useEffect(()=>{
 
    saveAction(loadEvents);

}, [isFocused]);

  useEffect(() => {
    if (activeHousehold?.id && isFocused) {
      loadEvents();
    }
  }, [activeHousehold, isFocused]);

  useEffect(() => {
    if (!timeLineStoreData.loading) {
      setRefreshControlLoading(false);
    }
  }, [timeLineStoreData.loading]);

  if (timeLineStoreData.loading && !refreshControlLoading && !lazyLoading && filteredEvents.length === 0) {
    return <SpLoading />;
  }

  return (
    <SafeAreaView style={styles.container}>
      <Timeline
        data={filteredEvents}
        lazyLoading={lazyLoading}
        onRefresh={refreshHandler}
        onEndReached={endReachedHandler}
        refreshing={refreshControlLoading}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.white.color,
  },
});
