import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import React, { useCallback, useMemo } from 'react';
import { ReportType } from '../../constants/ReportType';
import { ReportTabs, useReportTabs } from '../../hooks/useReportTabs';
import { Tabs } from '../Tabs';

interface TabsWrapProps {
  activeTab: ReportTabs;
  type: ReportType;
  onChange: (data: ReportTabs) => void;
}

export const TabsWrap = ({ activeTab, type, onChange }: TabsWrapProps) => {
  const tabs = useReportTabs(type);
  const tabIds = useMemo(() => Object.keys(tabs).map(Number), []);

  const renderTab = useCallback(
    (item: ReportTabs) => {
      return (
        <SpText
          fontFamily="Rubik_Medium"
          size="md"
          color={
            activeTab === item ? colors.primary.color : colors.greyText.color
          }
          adjustsFontSizeToFit
          numberOfLines={1}
        >
          {tabs[item]}
        </SpText>
      );
    },
    [activeTab, tabs],
  );

  return (
    <Tabs
      data={tabIds}
      active={activeTab}
      renderItem={renderTab}
      onChange={onChange}
    />
  );
};
