import { PetPosition } from '@constants/Pet';
import { TimelineEventModel } from '@models/Timeline';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { useTimelinePetName } from '../../hooks/useTimelinePetName';
import { TimelineCard } from './TimelineCard';
import { TimelinePetPhoto } from './TimelinePetPhoto';

export const Movement = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);
  const petName = useTimelinePetName(event);
  const userName = get(event, 'users[0].name', '');

  const text = useMemo(() => {
    if (!event.tags && !event.movements) {
      return '';
    }

    if (event.movements[0].direction === PetPosition.none) {
      return t('timeline_action_lookthrough_door', {
        petname: petName,
        devicename: deviceName,
      });
    }

    if (event.movements[0].direction === PetPosition.inside) {
      return event.users
        ? t('timeline_action_inside_house_by_user', {
            user: userName,
            petname: petName,
          })
        : t('timeline_action_pet_entered_house', {
            petname: petName,
            devicename: deviceName,
          });
    }

    if (event.movements[0].direction === PetPosition.outside) {
      return event.users
        ? t('timeline_action_outside_house_by_user', {
            user: userName,
            petname: petName,
          })
        : t('timeline_action_pet_left_house', {
            petname: petName,
            devicename: deviceName,
          });
    }

    return '';
  }, [event, deviceName, petName, userName, t]);

  return (
    <TimelineCard
      active={active}
      image={<TimelinePetPhoto active={active} event={event} />}
      text={text}
      date={event.created_at}
    />
  );
};
