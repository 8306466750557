/* eslint-disable react/jsx-no-useless-fragment */
import LoadingSpinner from '@atoms/Loader';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import { DeviceFeederBowlType, DeviceFeederTareType } from '@constants/Device';
import { getZeroScalesActions } from '@constants/ZeroScalesActions';
import {
  SpZeroScalesActions,
  ZeroScalesActionModel,
} from '@molecules/SpZeroScalesActions';
import { SpZeroScalesUpdateStatusMessage } from '@molecules/SpZeroScalesUpdateStatusMessage';
import colors from '@styles/colors';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DeviceFeederTypeUpdated } from '@models/Device';
import { getSingleDuration } from '@utils/date';
import { MergedInterfaces } from '../../../../store/models';
import useBoundStore from '../../../../store/store';
import useLastDate from '../../hooks/useLastDate';

interface ZeroScalesProps {
  type: DeviceFeederBowlType;
  onChange: (data: DeviceFeederTareType) => void;
  events: DeviceFeederTypeUpdated;
}

export const ZeroScales = ({ type, onChange, events }: ZeroScalesProps) => {
  const {
    updateZeroScalesError,
    updateZeroScalesLoading,
    updateZeroScalesLoadingStatus,
    updateZeroScalesSuccess,
    resetUpdateZeroScales,
    resetZeroScaleError,
  } = useBoundStore(({ deviceStore }: MergedInterfaces) => {
    return {
      updateZeroScales: deviceStore.updateZeroScales,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesLoading: deviceStore.updateZeroScalesLoading,
      updateZeroScalesLoadingStatus: deviceStore.updateZeroScalesLoadingStatus,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      resetUpdateZeroScales: deviceStore.resetUpdateZeroScales,
      resetZeroScaleError: deviceStore.resetZeroScaleError,
    };
  });

  const lastZeroed = useLastDate(events, 'last_zeroed_at');

  useEffect(() => {
    updateZeroScalesLoadingStatus(false);
    resetZeroScaleError();

    return () => {
      resetUpdateZeroScales();
    };
  }, []);

  const actions: ZeroScalesActionModel[] = useMemo(() => {
    return getZeroScalesActions(type);
  }, [type]);

  const onSelect = (bowlType: DeviceFeederTareType) => {
    onChange(bowlType);
  };

  return (
    <SpVStack space={8} style={styles.wrapper}>
      {updateZeroScalesLoading && (
        <SpView alignItems="center">
          <LoadingSpinner />
        </SpView>
      )}

      <SpZeroScalesUpdateStatusMessage
        loading={updateZeroScalesLoading}
        success={!!updateZeroScalesSuccess}
        error={updateZeroScalesError?.error}
        lastZeroed={
          typeof lastZeroed === 'string'
            ? lastZeroed
            : getSingleDuration(new Date(lastZeroed).toISOString())
        }
      />

      <SpZeroScalesActions buttons={actions} onSelect={onSelect} />
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.secondary4.color,
    borderRadius: 16,
  },
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
  description: {
    marginTop: 16,
    marginBottom: 30,
  },
  wrapper: {
    position: 'relative',
  },
  rowSuccessContainer: {
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },
  successMsg: {
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  successDesc: {
    marginTop: 4,
  },
});
