import React, { FC } from 'react';
import { SpView } from '@atoms/SpView';
import { StyleSheet } from 'react-native';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';

interface IDateItemMock {
  isActive?: boolean;
  circleBorderColor?: string;
  dateNumber?: string;
  dayOfTheWeek?: string;
}

const DateItemMock: FC<IDateItemMock> = ({
  isActive = false,
  circleBorderColor = '#ffffff',
  dateNumber,
  dayOfTheWeek,
}) => {
  return (
    <SpView style={[styles.wrapper, isActive ? styles.activeWrapper : {}]}>
      <SpView
        style={[
          styles.dateCircle,
          isActive ? {} : styles.circleShadow,
          { borderColor: circleBorderColor },
        ]}
      >
        <SpText style={styles.dateText}>{dateNumber}</SpText>
      </SpView>
      <SpText style={[styles.dateText, styles.dayOfTheWeekText, isActive ? styles.dayOfTheWeekTextActive : {}]}>
        {dayOfTheWeek}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: 49.1,
    height: 88,
    borderRadius: 24.6,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  activeWrapper: {
    backgroundColor: '#03A2B1',
  },
  dateCircle: {
    marginTop: 5,
    width: 39,
    height: 39,
    backgroundColor: '#ffffff',
    borderWidth: 2.5,
    borderRadius: 19,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,

    elevation: 8,
  },
  dateText: {
    fontSize: 19.6,
    color: colors.lightBlack.color,
    textAlign: 'center',
  },
  dayOfTheWeekText: {
    marginBottom: 10,
  },
  dayOfTheWeekTextActive: {
    color: '#ffffff',
  },
});

export default DateItemMock;
