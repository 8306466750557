import i18n from '@utils/i18n';
import * as React from 'react';

export enum ReportRangeType {
  OneDay = 1,
  SevenDays = 7,
  TwentyEightDays = 28,
  SixMonths = 180,
  OneYear = 365,
}

export const ReportRangeTypeName = {
  [ReportRangeType.OneDay]: '{{value}}d',
  [ReportRangeType.SevenDays]: '{{value}}d',
  [ReportRangeType.TwentyEightDays]: '{{value}}d',
  [ReportRangeType.SixMonths]: '{{value}}m',
  [ReportRangeType.OneYear]: '{{value}}y',
};

export const ReportRangeLastName = {
  [ReportRangeType.OneDay]: 'today',
  [ReportRangeType.SevenDays]: 'last {{value}} days',
  [ReportRangeType.TwentyEightDays]: 'last {{value}} days',
  [ReportRangeType.SixMonths]: 'last {{value}} months',
  [ReportRangeType.OneYear]: 'last_year',
};
