import qs from 'qs';
import { TimezoneModel } from '@models/Timezone';
import Http from './Http';

class TimezoneApi {
  static readonly httpParams = {};

  static getTimezones(): Promise<TimezoneModel[]> {
    return Http.get(`/api/timezone`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static getTimezoneByID(id: number): Promise<TimezoneModel[]> {
    return Http.get(`/api/timezone/${id}`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default TimezoneApi;
