import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { StyleSheet } from 'react-native';

interface SpSuccessTickProps {
  text: string;
}

export const SpSuccessTick = ({ text }: SpSuccessTickProps) => {
  return (
    <SpView style={styles.container}>
      <SpView {...testProperties('success tick', 'icon')} style={styles.circle}>
        <FontAwesomeIcon color="white" size={36} icon={faCheck} />
      </SpView>
      <SpText size="xxl" style={styles.text}>
        {text}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Rubik_SemiBold',
    color: '#263a43',
    textAlign: 'center',
  },
  circle: {
    height: 56,
    width: 56,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.green.color,
    borderRadius: 50,
    marginBottom: 16,
  },
});
