import { SpView } from '@atoms/SpView';
import { StyleSheet } from 'react-native';
import React, { FC } from 'react';

interface ISlideIndicator {
  isActive?: boolean;
}

const SlideIndicator: FC<ISlideIndicator> = ({ isActive }) => {
  return <SpView style={[styles.indicator, isActive ? styles.active : {}]} />;
};

const styles = StyleSheet.create({
  indicator: {
    width: 40,
    height: 4,
    backgroundColor: '#dee2e3',
    borderRadius: 2,
  },
  active: {
    backgroundColor: '#929ca1',
  },
});

export default SlideIndicator;
