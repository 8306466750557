import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const PasswordEyeOpenedSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 16 16"
    {...props}
  >
    <G fill="#263A43" fillRule="evenodd">
      <Path d="M15.688 3.13 2.283 14.91a.36.36 0 0 1-.519-.041l-.388-.466a.385.385 0 0 1 .04-.533L14.82 2.09a.362.362 0 0 1 .519.042l.388.465a.384.384 0 0 1-.04.533M3.094 11.119l1.892-1.664a3.171 3.171 0 0 1-.344-1.658c.105-1.623 1.463-2.939 3.139-3.04a3.437 3.437 0 0 1 1.997.488l1.41-1.24A8.708 8.708 0 0 0 8 3.39c-3.057 0-5.829 1.62-7.875 4.252a.599.599 0 0 0 0 .732 12.01 12.01 0 0 0 2.969 2.745" />
      <Path d="M15.875 7.645c-1.057-1.361-2.31-2.445-3.694-3.18l-1.627 1.429a3.18 3.18 0 0 1 .804 2.328c-.108 1.62-1.466 2.935-3.138 3.04a3.418 3.418 0 0 1-2.642-.995L4.01 11.645c1.242.627 2.584.983 3.99.983 3.057 0 5.83-1.62 7.875-4.251a.599.599 0 0 0 0-.732" />
    </G>
  </Svg>
);
export default PasswordEyeOpenedSVG;
