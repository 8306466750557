import TimelineApi from '@api/TimelineApi';
import { lens } from '@dhmk/zustand-lens';
import {
  TimelineEventFullResponseModel,
  TimelineEventModel,
} from '@models/Timeline';
import { StateCreator } from 'zustand';
import { MergedInterfaces } from '../models';

export interface TimelineSliceModel {
  timelineStore: {
    loading: boolean;
    events: TimelineEventModel[];
    getByHousehold: (
      id: number,
      sinceId: number,
    ) => Promise<TimelineEventFullResponseModel>;
    reset: () => void;
  };
}

const createTimelineSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  TimelineSliceModel
> = (rootSet, rootGet) => {
  return {
    timelineStore: lens((set, get, api) => ({
      events: [],
      loading: false,
      getByHousehold: async (
        id: number,
        sinceId: number = null,
      ): Promise<TimelineEventFullResponseModel> => {
        set({ loading: true });
        try {
          const data = await TimelineApi.getEvents(id, null, 20, sinceId);

          set({ loading: false });

          return data;
        } catch (e) {
          set({ loading: false });
          throw e;
        }
      },
      reset: () => {
        set({
          loading: false,
          events: [],
        });
      },
    })),
  };
};

export default createTimelineSlice;
