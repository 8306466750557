import SpSentIcon from '@atoms/RoundIcons/SpSentIcon';
import { SpCenter } from '@atoms/SpCenter';
import { SpView } from '@atoms/SpView';
import SpTitle from '@molecules/SpTitle';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import persistStorage from '@utils/persistStorage';
import { useDeviceId } from '@hooks/useDeviceId';
import useBoundStore from '../../store/store';
import { Layout } from './Layout';

export const RemoveSuccess = () => {
  const { t } = useTranslation();
  const { logout } = useBoundStore(s => s.accountStore);
  const deviceId = useDeviceId();

  useEffect(() => {
    setTimeout(() => {
      logout(deviceId);
      persistStorage.clearAll();
    }, 5000);
  }, []);

  return (
    <Layout nextButtonText={t('continue')} onNext={() => logout(deviceId)}>
      <SpCenter paddingTop={120}>
        <SpView>
          <SpSentIcon />
        </SpView>
        <SpTitle text={t('ad_confirm_account_successfully_deleted')} />
      </SpCenter>
    </Layout>
  );
};
