import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { SvgProps } from 'react-native-svg/src/elements/Svg';

const DrinkingInactiveSVG = ({ opacity = '0.401535761', fill = '#263A43', ...props }: SvgProps) => (
  <Svg
    width="14"
    height="14"
    viewBox="0 0 24 24"
    {...props}
  >
    <G
      id="drinking-icon-inactive"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity={opacity}
    >
      <G
        id="Amount-Drunk-Icon-Copy"
        transform="translate(3, 0)"
        fill="#263A43"
        fill-rule="nonzero"
      >
        <G id="drops">
          <Path
            d="M9,0 C9,0 0,10.7368421 0,15.4105263 C0,20.0842105 3.98571429,24 9,24 C14.0142857,24 18,20.2105263 18,15.4105263 C18,10.6105263 9,0 9,0 Z M15.3,16.8 C15.3,17.1789474 15.0428571,17.5578947 14.9142857,17.9368421 C14.6571429,18.1894737 14.5285714,18.5684211 14.2714286,18.8210526 C14.0142857,19.0736842 13.7571429,19.3263158 13.5,19.4526316 C12.9857143,19.8315789 12.3428571,19.9578947 11.9571429,20.0842105 C11.4428571,20.2105263 11.0571429,20.0842105 10.8,20.0842105 C10.5428571,20.0842105 10.4142857,19.9578947 10.4142857,19.9578947 C10.4142857,19.9578947 11.0571429,19.3263158 11.3142857,19.0736842 C14.4,16.5473684 13.8857143,12.5052632 13.8857143,12.5052632 C13.8857143,12.5052632 14.0142857,12.6315789 14.2714286,12.7578947 C14.5285714,13.0105263 14.7857143,13.2631579 15.0428571,13.7684211 C15.3,14.2736842 15.5571429,14.9052632 15.5571429,15.5368421 C15.4285714,16.0421053 15.4285714,16.4210526 15.3,16.8 L15.3,16.8 Z"
            id="Shape"
          />
        </G>
      </G>
    </G>
  </Svg>
);
export default DrinkingInactiveSVG;
