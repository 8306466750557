import { SpView } from '@atoms/SpView';
import { SpMenuStack } from '@molecules/SpMenuStack';
import { StyleSheet, View } from 'react-native';
import React, { useLayoutEffect, useState } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import useBoundStore from 'src/store/store';
import { TimeUnits } from '@constants/DateFormat';
import { WeightUnits } from '@constants/WeightUnits';
import { useTranslation } from 'react-i18next';
import { AccountMenu } from '@constants/Menus';
import PreferencesSVG from '@atoms/SvgIcons/PreferencesSVG';
import colors from '@styles/colors';
import { MenuItem } from '@models/Menu';
import { SpEditMenuStack } from '../../../components/molecules/SpEditMenuStack';
import { AccountStackParamList } from '..';

export const PreferenceSettings = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<AccountStackParamList>>();
  const user = useBoundStore(state => state.accountStore.user);
  const { getLanguageByID } = useBoundStore(state => state.languageStore);
  const getCountryByID = useBoundStore(
    state => state.countryStore.getCountryByID,
  );
  const [actualCountry, setActualCountry] = useState(
    getCountryByID(user.country_id).name
  );
  useLayoutEffect(() => {
    setActualCountry(getCountryByID(user.country_id).name);
  }, [getCountryByID(user.country_id).name]);

  const settings = [
    {
      label: t('language'),
      value:
        getLanguageByID(user.language_id).native_name ??
        getLanguageByID(user.language_id).name,
      action: () =>
        navigation.navigate('AccountEditSetting', {
          label: t('language'),
          inputName: AccountMenu.Language,
          inputValue: user.language_id,
        }),
    },
    {
      label: t('units'),
      value: t(WeightUnits[user.weight_units]),
      action: () =>
        navigation.navigate('AccountEditSetting', {
          label: t('weight_units'),
          inputName: AccountMenu.WeightUnits,
          inputValue: user.weight_units,
        }),
    },
    {
      label: t('time_format'),
      value: t(TimeUnits[user.time_format]),
      action: () =>
        navigation.navigate('AccountEditSetting', {
          label: t('time_format'),
          inputName: AccountMenu.TimeFormat,
          inputValue: user.time_format,
        }),
    },
    {
      label: t('country'),
      value: actualCountry,
      action: () =>
        navigation.navigate('AccountEditSetting', {
          label: t('country'),
          inputName: AccountMenu.Country,
          inputValue: user.country_id,
        }),
    },
  ];

  const menu: MenuItem[] = [
    {
      label: t('marketing_preferences'),
      action: () =>
        navigation.navigate('AccountEditSetting', {
          label: t('marketing_preferences'),
          inputName: AccountMenu.MarketingOptIn,
          inputValue: user.marketing_opt_in,
        }),
      iconSVG: (
        <PreferencesSVG
          color={colors.greyText.color}
          width={20}
          height={20}
          viewBox="0 0 16 16"
        />
      ),
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <SpEditMenuStack buttons={settings} />
        <SpView style={styles.menuStackWrap}>
          <SpMenuStack buttons={menu} />
        </SpView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    color: '#f2f2f2',
    backgroundColor: '#ffffff',
  },
  inner: {
    flex: 1,
    width: '85%',
    marginTop: 47,
  },
  iconContainer: {
    marginLeft: 15,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 5,
  },
  menuStackWrap: {
    marginTop: 20,
  },
});
