import { WeightUnits } from '@constants/WeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceFeederBowlModel } from '@models/Device';
import { SpEditMenuStack } from '@molecules/SpEditMenuStack';
import React, { useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import SpTextButton from '@atoms/SpTextButton';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { getActions } from '../../constants/Portioning';
import { DeviceStackParamList } from '../..';

interface BowlSetupProps {
  data: DeviceFeederBowlModel;
  userWeightUnits: WeightUnits;
  onChangeTarget: ($event: number[]) => Promise<void>;
  onRemove: () => void;
}

export const BowlPortioning = ({
  data,
  userWeightUnits,
  onChangeTarget,
  onRemove,
}: BowlSetupProps) => {
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();

  const { getShortUnit } = useWeightConversion(userWeightUnits);
  const { t } = useTranslation();

  const [actions, portioniningVisible] = useMemo(() => {
    let emptyTimes = 0;
    const dataArray = getActions(
      data,
      (bowlData: DeviceFeederBowlModel, isLeft: boolean) =>
        navigation.navigate('DeviceSetPortioning', {
          bowlData,
          isLeft,
          onChangeTarget,
        }),
      getShortUnit(),
    ).map(elem => {
      if (elem.value === undefined) {
        emptyTimes++;
        return { ...elem, value: t('not_selected') };
      }
      return elem;
    });

    return [dataArray, emptyTimes !== dataArray.length];
  }, [data, onChangeTarget]);

  return (
    <>
      <SpEditMenuStack buttons={actions} />
      <View style={styles.container}>
        {portioniningVisible && (
          <SpTextButton
            text={t('remove_portioning')}
            onPress={onRemove}
            textStyle={styles.button}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 'auto',
    alignItems: 'center',
    marginBottom: 32,
  },
  button: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    color: colors.greyText.color,
  },
});
