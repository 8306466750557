import { SpButton } from '@atoms/SpButton';
import { SpView } from '@atoms/SpView';
import { UserModel } from '@models/User';
import { SpMenuStack } from '@molecules/SpMenuStack';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import CustomHeader from '@molecules/CustomHeader';
import { MenuItem } from '@models/Menu';
import { AccountOverview } from './AccountOverview';

interface AccountMenuProps {
  menuItems: MenuItem[];
  user: UserModel;
  logout: () => void;
  removeAccount: () => void;
  handleResetTour: () => void;
}

export const AccountMenu = ({
  menuItems,
  user,
  logout,
  removeAccount,
  handleResetTour,
}: AccountMenuProps) => {
  const { t } = useTranslation();
  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader withTitle={false} withArrowBack={false} />
      <ScrollView contentContainerStyle={styles.scrollWrap}>
        <View style={styles.inner}>
          <Text
            {...testProperties(t('account'), t('title'))}
            style={styles.title}
          >
            {t('account')}
          </Text>
          <AccountOverview user={user} />
          <View style={styles.menuStackOffset}>
            <SpMenuStack buttons={menuItems} />
          </View>
        </View>
        <SpView style={styles.footer}>
          <SpButton
            onPress={handleResetTour}
            color="transparent"
            title={t('reset_tour_big_t')}
            textStyle={[styles.actionButton, styles.rubikFont]}
          />
          <SpButton
            onPress={logout}
            color="transparent"
            textStyle={[styles.actionButton, styles.rubikFont]}
            title={t('logout')}
          />
          <SpButton
            color="transparent"
            textStyle={styles.actionButton}
            title={t('deleteAccount')}
            onPress={removeAccount}
          />
        </SpView>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: colors.white.color,
  },
  scrollWrap: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  inner: {
    flex: 1,
    backgroundColor: colors.white.color,
    paddingHorizontal: 19,
  },
  logout: {
    fontSize: 14,
    color: 'gray',
  },
  title: {
    fontSize: 32,
    lineHeight: 40,
    fontFamily: 'Rubik_Medium',
    color: colors.greyText.color,
    marginTop: 12,
    marginBottom: 40,
  },
  menuStackOffset: {
    marginTop: 48,
  },
  footer: {
    marginVertical: 26,
  },
  actionButton: {
    color: colors.greyText.color,
    fontSize: 16,
  },
  rubikFont: {
    fontFamily: 'Rubik_Medium',
  },
});
