/* eslint-disable consistent-return */
import { SpView } from '@atoms/SpView';
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-prop-types */
import { DayWeekMonthShortFormat } from '@constants/DateFormat';
import {
  GroupReportPointModel,
  MappedMovementReportDataModel,
} from '@models/ReportModel';
import { differenceInSeconds, endOfDay, format, formatISO } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, FlatList, StyleSheet, View } from 'react-native';
import { SpText } from '@atoms/SpText';
import { DurationLineChart } from '@molecules/DurationLineChart';
import { fillEmptyPoints } from '@utils/fillEmptyPoints';
import useTour from '@hooks/useTour';
import { useCustomToast } from '@hooks/useToast';
import { useIsFocused } from '@react-navigation/native';
import { useDivideMovementDay } from '@hooks/useDivideMovementDay';
import { useCurrentAndPreviousDays } from '../../hooks/useCurrentAndPreviousDays';
import { useGroupedDoorStats } from '../../hooks/useGroupedDoorStats';
import { MovementEventReport } from '../MovementEventReport';
import { LineGraphModalWrapper } from './LineGraphModalWrapper';
import { Header } from './Header';
import DateNavigation from '../DateNavigation';
import ExitsAndEntriesSlide from '../../../Tour/components/ExitsAndEntriesSlide/ExistsAndEntriesSlide';
import {
  ReportRangeLastName,
  ReportRangeType,
} from '../../constants/ReportRangeType';
import { PetAverageStatsMovement } from './PetAverageStatsMovement';
import useViewedDays from '../../hooks/useViewedDays';
import useBoundStore from "../../../../store/store";
import LoadingSpinner from "@atoms/Loader";

interface PetDashboardMovementProps {
  data: GroupReportPointModel<MappedMovementReportDataModel>[];
  onGeneratePDFReport: () => void;
  setViewedReportDays: (value: number) => void;
  setViewedActiveDate: (value: number) => void;
  rangeType: ReportRangeType;
}

export const PetDashboardMovement = ({
  data,
  onGeneratePDFReport,
  setViewedReportDays,
  setViewedActiveDate,
  rangeType,
}: PetDashboardMovementProps) => {

    const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(1);
  const [activeDate, setActiveDate] = useState(endOfDay(new Date()).getTime());
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const toast = useCustomToast();
  const isFocused = useIsFocused();
  const [viewedDays, setViewedDays] = useViewedDays(
    rangeType,
    setViewedReportDays,
  );
  const { loading } = useBoundStore(s => s.reportStore);

  const initTour = useTour({
    uniqueId: 'v0-graph-movements-dashboard',
    components: [<ExitsAndEntriesSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);
  const activityDetails =
    useCurrentAndPreviousDays<MappedMovementReportDataModel>(
      activeDate,
      data,
      viewedDays
    );
  const convertedActivityDetails = useMemo(
    () => fillEmptyPoints(activityDetails.rangeData, viewedDays, activeDate),
    [activityDetails, viewedDays, activeDate]
  );
  const stats = useGroupedDoorStats(
    data,
    activityDetails?.currentDay?.date
  );

  const pointDetailsRenderItem = useCallback(
    ($event: { item: MappedMovementReportDataModel }) => {
      return <MovementEventReport data={$event.item} index={$event.index} />;
    },
    [],
  );

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: GroupReportPointModel<MappedMovementReportDataModel>;
      index: number;
    }) => {
      if (!item) {
        return;
      }
      const isActive = index + 1 === activeItem;
      const isVisibleLeftTitle = () => {
        if (viewedDays === 7) {
          return format(item?.date, DayWeekMonthShortFormat);
        }
        if (viewedDays === 28) {
          if (index === 0 || isActive || index % 7 === 0) {
            return format(item?.date, DayWeekMonthShortFormat);
          }
          return null;
        }
      };

      return (
        <DurationLineChart
          isActive={item.points.length && isActive ? true : false}
          points={item.points}
          setActive={() => {
            item.points.length
              ? setActiveItem(index + 1)
              : toast.show({
                  description: t('no_events_on_this_day'),
                  isError: true,
                });
          }}
          lowState={viewedDays === 28}
          leftTitle={isVisibleLeftTitle()}
          rightTitle={`${item.points.length} ${t('point_visits')}`}
          pointDetailsRenderItem={pointDetailsRenderItem}
        />
      );
    },
    [activeItem, viewedDays],
  );
  const dateNavigationData = {
    name: t(ReportRangeLastName[viewedDays === 28 ? 28 : 7], {
      value: viewedDays === 28 ? 28 : 7,
    }),
    viewedDays,
  };
  const ListHeader = useCallback(
    () => (
      <View>
        {convertedActivityDetails.length > 0 &&
          convertedActivityDetails?.[0]?.date ===
            endOfDay(new Date()).getTime() && (
            <SpText style={{ marginBottom: -20, paddingHorizontal: 24 }} fontFamily="Rubik_Medium">
              {t('today')}
            </SpText>
          )}
      </View>
    ),
    [convertedActivityDetails],
  );

  return (
    <SpView style={styles.container}>
      {stats ? <LineGraphModalWrapper data={stats} modal="LineGraph" /> : null}
      <Header onGeneratePDFReport={onGeneratePDFReport} />
      <DateNavigation
        title={dateNavigationData.name}
        currentDate={new Date(activeDate)}
        setDate={date => {
          setActiveDate(date);
          setViewedActiveDate(date);
          setActiveItem(viewedDays === 28 ? null : 1);
        }}
        viewedDays={viewedDays - 1}
        setViewedDays={(days: number) => {
          setViewedDays(days);
          setViewedReportDays(days);
          setActiveItem(days === 28 ? null : 1);
        }}
        withButtons
      />

      {loading ?
          <SpView style={styles.spinner}>
            <LoadingSpinner/>
          </SpView> :
          <>
            <SpView style={styles.averageContainer}>
              <SpView style={styles.averageWrap}>
                <PetAverageStatsMovement data={convertedActivityDetails}/>
              </SpView>
            </SpView>
            <SpView style={styles.flatlistContainer}>
              <FlatList
                  style={styles.flatlist}
                  data={convertedActivityDetails}
                  renderItem={renderItem}
                  ListHeaderComponent={ListHeader}
                  keyExtractor={item =>
                      `${item.date.toString() + Math.random()}movement`
                  }
              />
            </SpView>
          </>
      }
    </SpView>
  );
};

const screenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flatlistContainer: {
    flex: 1,
    paddingTop: 56,
    paddingBottom: screenHeight < 810 ? 57 : 0,
    marginHorizontal: -24,
    paddingHorizontal: 24,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
  },
  flatlist: {
    flex: 1,
  },
  averageContainer: {
    marginTop: 8,
    position: 'relative',
    height: 48,
    zIndex: 9999,
  },
  averageWrap: {
    paddingHorizontal: 24,
    width: '100%',
    position: 'absolute',
    justifyContent: 'space-between',
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%'
  }
});
