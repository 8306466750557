import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import {Pressable, StyleSheet} from "react-native";
import {DefaultHitSlop} from "@constants/DefaultHitSlop";

interface SpBurgerMenuOpenIconProps {
  action: () => void;
}
export const SpBurgerMenuOpenIcon = ({ action }: SpBurgerMenuOpenIconProps) => (
  <Pressable
      style={styles.centerBurger}
    hitSlop={DefaultHitSlop}
    onPress={action}
    accessible={false}
   >
      <FontAwesomeIcon color={colors.greyText.color} size={25} icon={faBars} />
  </Pressable>
);
const styles = StyleSheet.create({
    centerBurger: {
        justifyContent: 'center'
    }
})
