import { Image, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';


export const WeHaveNotManageToConnectToCDB = () => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <Image
            style={{ maxWidth: 250, maxHeight: 200 }}
            source={AppImages.cdbWeHaventManageToConnect}
            resizeMode="contain"
          />
          <SpText style={[text.title]}>
            {t('there_seems_to_have_been_a_problem')}
          </SpText>
          <SpText style={[text.body, styles.text]}>
            {t('we_have_noticed_you_are_struggling')}
          </SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    // marginTop: '10%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
  },
});

export default WeHaveNotManageToConnectToCDB;
