import SpSentIcon from '@atoms/RoundIcons/SpSentIcon';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import colors from '@styles/colors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

export const WhatWillRemoved = ({ short }: { short: boolean }) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return short
      ? [
          'ad_explanation_data_will_be_deleted',
          'ad_explanation_data_will_not_retrievable',
        ]
      : [
          'ad_explanation_products_data_will_be_deleted',
          'ad_explanation_data_will_not_be_retrievable',
          'ad_explanation_data_is_not_retrievable',
        ];
  }, [short]);

  return (
    <SpView>
      <SpVStack space={12}>
        {data.map((item, index) => {
          return (
            <SpView key={index} style={styles.item}>
              <SpSentIcon iconSize={18} size={34} />

              <SpText color={colors.greyText.color} style={{ flex: 1 }}>
                {t(item)}
              </SpText>
            </SpView>
          );
        })}
      </SpVStack>
    </SpView>
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    width: '100%',
  },
});
