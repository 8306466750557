import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import React, { ReactNode, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';

interface SpCarouselWithStepsProps {
  slideWidth: number;
  sliderWidth: number;
  data: unknown[];
  height?: string;
  renderItem: (data: any) => ReactNode;
  onLastTemplate: (data: number) => void;
}

export const SpCarouselWithSteps = ({
  slideWidth,
  sliderWidth,
  data,
  renderItem,
  onLastTemplate,
  height = '100%',
}: SpCarouselWithStepsProps) => {
  const carouselRef = React.useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (onLastTemplate) {
      onLastTemplate(activeSlide);
    }
  }, [activeSlide]);

  return (
    <SpView style={{ height }}>
      <Carousel
        layout="default"
        vertical={false}
        ref={carouselRef}
        data={data}
        renderItem={renderItem}
        sliderWidth={sliderWidth}
        itemWidth={slideWidth}
        onSnapToItem={setActiveSlide}
      />
      <Pagination
        dotsLength={data.length}
        activeDotIndex={activeSlide}
        dotContainerStyle={styles.dotContainerStyle}
        carouselRef={carouselRef}
        tappableDots
        dotStyle={styles.dotStyle}
        renderDots={(activeIndex, length) => {
          return (
            <>
              {new Array(length).fill(true).map((_, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      carouselRef?.current?.snapToItem(index);
                    }}
                  >
                    <SpView
                      style={[
                        styles.dotStyle,
                        activeIndex === index ? styles.dotActive : null,
                      ]}
                    />
                  </TouchableOpacity>
                );
              })}
            </>
          );
        }}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  dotStyle: {
    width: 40,
    height: 4,
    borderRadius: 2,
    marginHorizontal: 3,
    backgroundColor: colors.greyAccent.color,
  },
  dotActive: {
    backgroundColor: colors.greyOpacity.color,
  },
  dotContainerStyle: {
    paddingVertical: 0,
  },
});
