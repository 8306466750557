import { FeederFoodType } from '@constants/Device';
import { Selector } from '@constants/Navigation';
import { lastScreenOptions } from '@constants/Screen';
import { DeviceFeederBowlModel, DeviceFeederBowlSettingModel } from '@models/Device';
import CustomHeader from '@molecules/CustomHeader';
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'src/services/ModalBox';
import { DeviceEventEmitter } from 'react-native';
import { SpView } from '@atoms/SpView';
import styles from '@styles/atoms';
import { RootStackParamList } from '../index';
import { DeviceAssignPet } from './DeviceAssignPet';
import { DeviceBowlSetup } from './DeviceBowlSetup';
import { DeviceSubstanceSetup } from './DeviceSubstanceSetup';
import { DeviceDetails } from './DeviceDetails';
import { DeviceLidDelay } from './DeviceLidDelay';
import { DeviceName } from './DeviceName';
import { DevicePortioning } from './DevicePortioning';
import DeviceSetPortioning from './DeviceSetPortioning';
import { DeviceSetting } from './DeviceSetting';
import DeviceWiFiSetup from './DeviceWiFiSetup';
import { SelectSSID } from '../Flows/DogBowl/SelectSSID';
import { DeviceFoodSetup } from './DeviceFoodSetup';
import DeviceBowlFoodSelect from './molecules/DeviceBowlSetup/DeviceBowlFoodSelect';
import DeviceBowlTypeSelect from './molecules/DeviceBowlSetup/DeviceBowlTypeSelect';
import { DeviceFirmware } from './molecules/DeviceFirmware';
import { navigationRef } from '../RootNavigation';
import { MultiplePet } from './MultiplePet';

export type DeviceStackParamList = {
  DeviceDetails: Selector;
  DeviceSetting: Selector;
  DeviceAssignPet: Selector;
  DeviceName: Selector;
  DeviceBowlSetup: Selector;
  DeviceWiFiSetup: Selector;
  DeviceFoodSetup: Selector;
  DeviceSubstanceSetup: Selector;
  MultiplePet: undefined;

  DeviceBowlTypeSelect: {
    confirmChangeModal: (callback: () => void) => void;
    bowlTypeValue: number;
    id: number;
  };
  DeviceBowlFoodSelect: {
    changeFoodTypeAction: (
      data: FeederFoodType,
      direction: 'left' | 'right' | 'double',
    ) => void;
    $event: DeviceFeederBowlSettingModel;
    direction: 'left' | 'right' | 'double';
    bowlTypeValue: number;
    id: number;
  };
  DevicePortioning: Selector;
  DeviceSetPortioning: {
    bowlData: DeviceFeederBowlModel | any;
    isLeft: boolean;
    onChangeTarget: ($event: number[]) => Promise<void>;
  };
  DeviceLidDelay: Selector;
  selectSSID: {
    label: string;
    inputName: string;
    inputValue: any;
  };
};

const Stack = createStackNavigator<DeviceStackParamList>();

export default function DeviceNavigation({
                                           route,
                                         }: StackScreenProps<RootStackParamList, 'DeviceNavigation'>) {
  const { t } = useTranslation();
  const [activeModalButtons, setActiveModalButtons] = React.useState({
    infoModal: false,
  });
  const currentDeviceId = useMemo(() => {
    // @ts-ignore
    return route.params.params.id || null;
  }, [route.params]);
  DeviceEventEmitter.addListener('deactivate_info_modal', () => {
    setActiveModalButtons({ infoModal: false });
  });

  return (
    <SpView style={styles.screenWrapper}>
      <Stack.Navigator>
        <Stack.Screen
          name='DeviceDetails'
          component={DeviceDetails}
          options={{
            // ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }),

            header: () =>
              CustomHeader({
                title: ' ',
                headerRightElement: <DeviceFirmware id={currentDeviceId} />,
                goBackFunc: () =>
                  navigationRef.navigate('DashboardNavigation', {
                    screen: 'Products',
                  }),
              }),
          }}
        />
        <Stack.Screen
          name='DeviceSetting'
          component={DeviceSetting}
          options={{

            header: () =>
              CustomHeader({
                title: t('settings'),

                goBackFunc: () =>
                  navigationRef.navigate('DeviceNavigation', {
                    screen: 'DeviceDetails',
                    params: {
                      id: currentDeviceId,
                    },
                  }),
              }),
          }}
        />
        <Stack.Screen
          name='DeviceWiFiSetup'
          component={DeviceWiFiSetup}
          options={{ header: () => CustomHeader({}) }}
        />
        <Stack.Screen
          name='selectSSID'
          component={SelectSSID}
          options={{ ...lastScreenOptions() }}
        />
        <Stack.Screen
          name='DeviceSubstanceSetup'
          component={DeviceSubstanceSetup}
          options={{ header: () => CustomHeader({ title: t('settings') }) }}
        />
        <Stack.Screen
          name='DeviceAssignPet'
          component={DeviceAssignPet}
          options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
        />
        <Stack.Screen
          name='DeviceName'
          component={DeviceName}
          options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
        />
        <Stack.Screen
          name='DeviceBowlSetup'
          component={DeviceBowlSetup}
          options={{ header: () => CustomHeader({ title: t('bowls') }) }}
        />
        <Stack.Screen
          name='DeviceBowlTypeSelect'
          component={DeviceBowlTypeSelect}
          options={{
            header: () =>
              CustomHeader({ withArrowBack: true, withTitle: false }),
          }}
        />
        <Stack.Screen
          name='DeviceBowlFoodSelect'
          component={DeviceBowlFoodSelect}
          options={{
            header: () => CustomHeader({ withCross: true, withTitle: false }),
          }}
        />

        <Stack.Screen
          name='DeviceFoodSetup'
          component={DeviceFoodSetup}
          options={{ ...lastScreenOptions() }}
        />
        <Stack.Screen
          name='DevicePortioning'
          component={DevicePortioning}
          options={{
            header: () =>
              CustomHeader({ title: t('portion'), withArrowBack: true }),
          }}
        />
        <Stack.Screen
          name='DeviceSetPortioning'
          component={DeviceSetPortioning}
          options={{
            header: () =>
              CustomHeader({ withTitle: false, withArrowBack: true }),
          }}
        />
        <Stack.Screen
          name='DeviceLidDelay'
          component={DeviceLidDelay}
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
              withRightButton: true,
              rightButtonText: t('info'),
              rightButtonAction: () => {
                openModal('InfoModal');
                setActiveModalButtons({
                  infoModal: true,
                });
              },
              rightButtonActive: activeModalButtons.infoModal,
            }),
          }}
        />
        <Stack.Screen
          name='MultiplePet'
          component={MultiplePet}
          options={{
            ...lastScreenOptions(),
          }}
        />
      </Stack.Navigator>
    </SpView>
  );
}
