import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import React from 'react';
import { testProperties } from '@utils/testProperties';

interface SpTextButton {
  text?: string;
  props?: any;
  textStyle?: any;
  containerStyle?: any;
  onPress: () => void;
  disabled?: boolean;
}

const SpTextButton = ({
  text,
  textStyle,
  containerStyle,
  ...props
}: SpTextButton) => {
  return (
    <TouchableOpacity
      {...props}
      {...testProperties(text, 'text', 'SpTextButton')}
      style={containerStyle}
    >
      <Text style={[styles.textButtonText, textStyle]}>{text}</Text>
    </TouchableOpacity>
  );
};

export default SpTextButton;

const styles = StyleSheet.create({
  textButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#71717a',
  },
});
