import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import { MergedInterfaces } from '../models';
import { RetrySlice } from '../models/RetrySlice';
import { AnalyticsService } from '../../services/AnalyticsService';

const createRetrySlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  RetrySlice
> = (set, get) => {
  return {
    RetryStore: lens((subSet, subGet, api) => ({
      retryCount: 0,
      retryMax: 10,
      interval: 5000,
      intervalRef: null,

      stopRetry: () => {
        AnalyticsService.logEvent('RetryStore - stopRetry');
        clearInterval(subGet().intervalRef);
        subSet({
          retryCount: 0,
          intervalRef: null,
        });
      },
      doRetry: (action, retryMax = 10) => {
        AnalyticsService.logEvent('RetryStore - doRetry');
        subSet({ retryCount: 1, retryMax });
        action(subGet().retryCount >= subGet().retryMax);
        const interval = setInterval(() => {
          if (subGet().retryCount >= subGet().retryMax) {
            action(true);
            subGet().stopRetry();
            // SpAlert("Max Retries Exceeded");
            return;
          }
          action();

          subSet({ retryCount: subGet().retryCount + 1 });
        }, subGet().interval);
        subSet({ intervalRef: interval });
      },
    })),
  };
};
export default createRetrySlice;
