import useBoundStore from 'src/store/store';
import { PetGender } from '@constants/Pet';
import { useTranslation } from 'react-i18next';

const useGetSpayedOrNeutered = (): string => {
  const { t } = useTranslation();
  const { activePet } = useBoundStore(state => state.petStore);

  if (activePet?.gender === PetGender.male) {
    return t('neutered');
  }
  if (activePet?.gender === PetGender.female) {
    return t('spayed');
  }
  return t('create_pet_spayed_title');
};

export default useGetSpayedOrNeutered;
