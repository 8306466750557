import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { TagModel } from '@models/Tag';
import { TimelineEventModel } from '@models/Timeline';
import { WeightFrameModel, WeightModel } from '@models/Weight';
import { map } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import { TimelinePetPhoto } from './TimelinePetPhoto';

export const PoseidonDrinking = ({ event, active }: { event: TimelineEventModel, active: boolean }) => {
  const { t } = useTranslation();
  const householdPets = usePetsByHousehold();
  const deviceName = useTimelineDeviceName(event.devices);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode,
  );

  const getSingleBowlText = (
    total: number,
    isIncrease: boolean,
    tags: any[],
  ): string => {
    let key = isIncrease
      ? `notification_drinking_increased`
      : `notification_drinking_decreased`;
    const known_pets = tags.filter(item => item.recognise);
    const known_pets_counter = known_pets.length;
    const unknown_pets = tags.filter(item => !item.recognise).length > 0;

    let translation: string;

    if (known_pets_counter === 0 && unknown_pets === false && !isIncrease) {
      key = `${key}_unkownremove`;
      return t(key, {
        devicename: deviceName,
        ml: convertWithUnits(total, false),
      });
    }

    key = `${key}${unknown_pets ? '_unkowned' : ''}`;

    switch (known_pets_counter) {
      case 0:
        translation = t(key, {
          devicename: deviceName,
          ml: convertWithUnits(total, false),
        });
        break;
      case 1:
        key = `${key}_known`;
        translation = t(key, {
          devicename: deviceName,
          pet_one: known_pets[0].name,
          ml: convertWithUnits(total, false),
        });
        break;
      case 2:
        key = `${key}_known_two`;
        translation = t(key, {
          devicename: deviceName,
          pet_one: known_pets[0].name,
          pet_two: known_pets[1].name,
          ml: convertWithUnits(total, false),
        });
        break;
      case 3:
        key = `${key}_known_three`;
        translation = t(key, {
          devicename: deviceName,
          pet_one: known_pets[0].name,
          pet_two: known_pets[1].name,
          pet_three: known_pets[2].name,
          ml: convertWithUnits(total, false),
        });
        break;
      case 4:
        key = `${key}_known_four`;
        translation = t(key, {
          devicename: deviceName,
          pet_one: known_pets[0].name,
          pet_two: known_pets[1].name,
          pet_three: known_pets[2].name,
          pet_four: known_pets[3].name,
          ml: convertWithUnits(total, false),
        });
        break;
      default:
        translation = '';
        break;
    }

    return translation;
  };

  const isMulti = (tags: TagModel[]): object[] => {
    if (!tags) {
      return [];
    }
    return map(tags, (tag: TagModel) => {
      const pet = householdPets.find(item => item.tag_id === tag.id);
      return {
        id: tag.id,
        recognise: !!pet,
        name: !pet ? false : pet.name,
      };
    });
  };

  const getTotalWeight = (frames: WeightFrameModel[]): number => {
    if (!frames) {
      return 0;
    }

    const totalInitial = map(frames, f => f.current_weight - f.change).reduce(
      (c, f) => c + f,
    );
    const totalFinal = map(frames, f => f.current_weight).reduce(
      (c, f) => c + f,
    );
    return Math.round(totalFinal - totalInitial);
  };

  const handleSingleBowl = (weight: WeightModel, tags: object[]) => {
    const total = getTotalWeight(weight?.frames);

    return getSingleBowlText(-total, total > 0, tags);
  };

  const text = useMemo(() => {
    const weight = event.weights ? event.weights[0] : null;
    const tags = isMulti(event.tags);

    return handleSingleBowl(weight, tags);
  }, [event, deviceName, userWeightUnits, householdPets, t]);

  return (
    <TimelineCard
      active={active}
      image={<TimelinePetPhoto active={active} event={event} />}
      text={text}
      date={event.created_at}
    />
  );
};
