import { LanguageModel } from '@models/Language';
import qs from 'qs';
import Http from './Http';

class LanguageApi {
  static readonly httpParams = {};

  static getLanguages(): Promise<LanguageModel[]> {
    return Http.get(`/api/language`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default LanguageApi;
