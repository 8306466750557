import { Dimensions, Platform, StyleSheet } from 'react-native';
import colors from '@styles/colors';

const atoms = StyleSheet.create({
  modal: {
    marginHorizontal: 12,
    borderRadius: 10,
    backgroundColor: 'white',
    flex: 1,
    marginTop: 50,
    overflow: 'hidden',
    height: '100%',
    paddingVertical: 12,
  },
  input: {
    backgroundColor: '#efefef',
    justifyContent: 'center',
    height: 50,
    borderColor: '#71717a',
    borderWidth: 1,
    paddingHorizontal: '5%',
    paddingVertical: '2%',
    borderRadius: 10,
    color: '#71717a',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 25,
  },
  errorWrapper: {
    height: 25,
    marginTop: 5,
  },
  line: {
    width: 4,
    height: 6,
    backgroundColor: colors.primary.color,
    opacity: 0.6,
    borderRadius: 10,
    marginTop: 6,
  },
  dashedLine: { flexDirection: 'column', marginTop: -2 },
  continueButton: { marginTop: 'auto', paddingHorizontal: 24 },
  pillContainer: {
    borderRadius: 16,
    minHeight: 80,
    paddingLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pillIcon: {
    paddingRight: 19,
    paddingVertical: 4,
    borderTopStartRadius: 20,
    borderBottomStartRadius: 20,
    paddingLeft: 5,
    width: 56,
    height: 40,
  },
  warningPillContainerBackground: {
    backgroundColor: colors.warningPillContainer.color,
  },
  warningPillIconBackground: {
    backgroundColor: colors.warningPill.color,
  },
  normalPillContainerBackground: {
    backgroundColor: colors.normalPillContainer.color,
  },
  normalPillIconBackground: {
    backgroundColor: colors.normalPillContainer.color,
  },
  statusCardContainer: {
    backgroundColor: colors.white.color,
    borderRadius: 16,
    justifyContent: 'center',
    flex: 1,
    height: 92,
    alignItems: 'center',
    shadowColor: colors.cardShadow.color,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
  rowContainer: {
    width: '83%',
    flexDirection: 'row',
    gap: 20,
  },
  screenWrapper:
    Platform.OS === 'android'
      ? {
          height: Dimensions.get('window').height,
        }
      : { flex: 1 },
  textScreenContainer: {
    alignItems: 'center',
    padding: 20,
  },
  alternativeCheckbox: {
    width: 36,
    height: 36,
    borderRadius: 18,
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  maxWidth: {
    width: '100%',
  },
  forgotText: {
    fontFamily: 'Rubik_Medium',
    textDecorationLine: 'underline',
    textDecorationColor: colors.primary.color,
    fontSize: 14,
    color: colors.primary.color,
  },
  flex1: {
    flex: 1,
  },
  disabledInsight: {
    display: 'none',
  },
  invisibleInsight: {
    opacity: 0,
  },
  disabledButton: {
    opacity: 0.5,
  },
});

export default atoms;
