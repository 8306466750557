import { SpText } from '@atoms/SpText';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

interface SelectOptionWhiteProps {
  active: boolean;
  item: { id: unknown; name: string };
  onChange: (data: any) => void;
}

// TODO: Denys Andrykevych Try reuse SelectButton component
const SelectOptionWhite = ({
  item,
  active,
  onChange,
}: SelectOptionWhiteProps) => {
  return (
    <Pressable
      onPress={() => onChange(item.id)}
      style={[styles.item, active ? styles.itemActive : null]}
    >
      <SpText style={[styles.itemText, active ? styles.itemTextActive : null]}>
        {item.name}
      </SpText>
      {active && (
        <View style={styles.circle}>
          <FontAwesomeIcon color="white" size={17} icon={faCheck} />
        </View>
      )}
    </Pressable>
  );
};

export default SelectOptionWhite;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 16,
  },
  itemActive: {
    backgroundColor: colors.white.color,
  },
  itemText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 16,
    color: colors.white.color,
  },
  itemTextActive: {
    fontSize: 16,
    right: -8,
    color: colors.greyText.color,
  },
  circle: {
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary.color,
    borderRadius: 50,
    marginRight: -8,
  },
});
