import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { SpText } from './SpText';

interface ISpSelectBadge {
  title: string;
  subtitle: string;
  isActive?: boolean;
  onPress: () => void;
  isLoading?: boolean;
}

const SpSelectBadge = ({
  title,
  subtitle,
  isActive = false,
  onPress,
  isLoading = false,
}: ISpSelectBadge) => {
  return (
    <Pressable
      accessible={false}
      onPress={onPress}
      style={[
        styles.container,
        isActive && styles.active,
        isLoading && { opacity: 0.5 },
      ]}
      {...testProperties(title, 'select badge')}
    >
      <View>
        <SpText style={styles.title}>{title}</SpText>
        <SpText componentName={title} style={styles.subtitle}>
          {subtitle}
        </SpText>
      </View>
      {isActive && (
        <View
          {...testProperties('faCheck', `icon_${title}`)}
          style={styles.iconWrap}
        >
          <FontAwesomeIcon
            color={colors.white.color}
            size={18}
            icon={faCheck}
          />
        </View>
      )}
    </Pressable>
  );
};

export default SpSelectBadge;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 14,
    borderColor: colors.greyBorder.color,
    paddingTop: 12,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    lineHeight: 24,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Rubik',
    lineHeight: 20,
    marginRight: 26,
  },
  iconWrap: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary.color,
    borderRadius: 50,
    position: 'absolute',
    right: 17,
  },
  active: {
    backgroundColor: colors.primaryBackgroundOpacity.color,
    borderColor: colors.primary.color,
    shadowColor: colors.primary.color,
    // shadowOffset: { width: -2, height: 4 },
    // shadowOpacity: 0.3,
    // shadowRadius: 3,
  },
});
