import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const HubNoneSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={88} height={66} {...props}>
    <G fill="none" fillRule="evenodd" stroke="#263A43" strokeWidth={3.902}>
      <Path d="m69.84 38.645 4.016-21.638a1.951 1.951 0 0 0-2.844-2.074L50.8 25.823M18.16 38.645l-4.016-21.638a1.951 1.951 0 0 1 2.844-2.074L37.2 25.823" />
      <Path
        fill="#FFF"
        d="M44 23.105c8.85 0 16.862 3.587 22.662 9.387 5.8 5.8 9.387 13.812 9.387 22.662v8.895H11.95v-8.895c0-8.85 3.587-16.862 9.387-22.662 5.8-5.8 13.812-9.387 22.662-9.387Z"
      />
    </G>
  </Svg>
);
export default HubNoneSVG;
