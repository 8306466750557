import { FlatList, FlatListProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { SpView } from './SpView';

interface SpFlatListProps extends FlatListProps<any> {
  renderItem: any;
  scroll?: boolean;
}

export const SpFlatList = ({
  data,
  renderItem,
  ListHeaderComponent,
  ListEmptyComponent,
  keyExtractor,
  scroll = false,
  ...props
}: SpFlatListProps) => {
  // const FLKey = Math.random().toString();
  const RenderItem = renderItem;
  const List = () => {
    // Less than 10
    if (Array.isArray(data) && data.length < 10) {
      return (
        <>
          {data.map((item, index) => (
            <RenderItem
              item={item}
              index={index}
              key={keyExtractor(item, index)}
            />
          ))}
        </>
      );
    }

    // More than 10
    if (Array.isArray(data)) {
      return (
        <FlatList
          data={data}
          renderItem={renderItem}
          ListHeaderComponent={<></>}
          {...props}
        />
      );
    }
  };

  return scroll ? (
    <ScrollView {...props}>
      <>
        {ListHeaderComponent}

        <List />

        {data.length === 0 && ListEmptyComponent}
      </>
    </ScrollView>
  ) : (
    <SpView {...props}>
      <>
        {ListHeaderComponent}

        <List />

        {data.length === 0 && ListEmptyComponent}
      </>
    </SpView>
  );
};
