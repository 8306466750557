import { SpProgressBar } from '@atoms/SpProgressBar';
import { SpView } from '@atoms/SpView';
import { DeviceFeederBowlType } from '@constants/Device';
import { DeviceFeederControlModel } from '@models/Device';
import React from 'react';
import { StyleSheet } from 'react-native';
import FeedingIconSVG from '@atoms/SvgIcons/FeedingIconSVG';

interface FeederStatusProps {
  fillPercentages: number[];
  data: DeviceFeederControlModel;
}

export const FeederStatus = ({ fillPercentages, data }: FeederStatusProps) => {
  return (
    <SpView flex={1} flexDirection="row">
      {data?.bowls?.type === DeviceFeederBowlType.Half ? (
        <SpView style={styles.indicatorWrapper}>
          <SpView style={styles.progressAndIconWrapper} flexDirection="column">
            <SpView style={styles.doubleLineInnerWrapper} flexDirection="row">
              <SpView>
                <SpProgressBar
                  value={fillPercentages[0] || 0}
                  width={10}
                  componentName="FeederLeft"
                  rotate
                />
              </SpView>
              <SpView marginLeft={12}>
                <SpProgressBar
                  value={fillPercentages[1] || 0}
                  width={10}
                  componentName="FeederRight"
                  rotate
                />
              </SpView>
            </SpView>
          </SpView>
          <SpView style={styles.iconWrapper}>
            <FeedingIconSVG color="#263a43" width={16} height={16} />
          </SpView>
        </SpView>
      ) : (
        <SpView style={styles.indicatorWrapper} marginRight={6}>
          <SpProgressBar
            value={fillPercentages[0] || 0}
            width={20}
            componentName="FeederSingle"
            rotate
          />
          <SpView style={styles.iconWrapper}>
            <FeedingIconSVG color="#263a43" width={16} height={16} />
          </SpView>
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  sideText: {
    color: '#78c96b',
    marginTop: 4,
    fontFamily: 'Rubik_Medium',
  },
  progressAndIconWrapper: {
    height: '70%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  indicatorWrapper: {
    rowGap: 8,
  },
  doubleLineInnerWrapper: {
    height: '100%',
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
