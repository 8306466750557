import { AppState, Linking, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { SpSuccessTick } from '@molecules/SpSuccessTick';
import { SpCenter } from '@atoms/SpCenter';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../Molecules/LoadingComponent';
import { SpText } from '@atoms/SpText';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { WORD_TO_COPY } from './InstructionStep3';
import * as Clipboard from 'expo-clipboard';


interface WiFiConnectToDeviceProps {
  connectedToWiFiDevice: boolean;
  setRetryConnectToDevice: any;
}

export const WiFiConnectToDevice = ({connectedToWiFiDevice, setRetryConnectToDevice}: WiFiConnectToDeviceProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [appState, setAppState] = useState(AppState.currentState);

  const copyAction = async (e) => {
      showPopup(e);
      await Clipboard.setStringAsync(WORD_TO_COPY);
  };

  const showPopup = (event) => {
    const { pageX, pageY } = event.nativeEvent;
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2000)
  };

  async function goToWiFiSettings() {
    if (Platform.OS === 'ios') {
      return await Linking.canOpenURL('App-Prefs:WIFI')
        .then(supported => {
          if (supported) {
            return Linking.openURL('App-Prefs:WIFI');
          }
          return false;
        })
        .catch(err => console.error('An error occurred', err));
    }
    return await Linking.sendIntent(`android.settings.WIFI_SETTINGS`);
  }

  const takeUserToSettings = () => {
    // We reset the param when going to the settings
    setRetryConnectToDevice(false);
    goToWiFiSettings();
  };

  useEffect(() => {
    const handleAppStateChange = (nextAppState) => {
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        // setting this param to start the search in the previous screen
        setRetryConnectToDevice(true);
      }
      setAppState(nextAppState);
    };
    AppState.addEventListener('change', handleAppStateChange);
  }, [appState]);


  return (
    <SpView style={[styles.container, styles.center]}>
      {connectedToWiFiDevice ? (
        <SpCenter paddingTop={80}>
          <SpSuccessTick text={t('app_connect_cdb')} />
        </SpCenter>
      ) : (
        <SpView>
          <SpView style={[styles.center, styles.loadingWrapper]}>
            <LoadingComponent
              searchMsg={t('looking_cdb')}
              connectedMsg=""
              isConnected={false}
              centralizedText={true}
              type={'insideCircleLine'}
            />
          </SpView>
          <SpView style={[styles.reminder, styles.center]}>
            <SpText style={styles.textReminder}>{t('just_reminder')}</SpText>
          </SpView>
          <SpView style={styles.tableDetails}>
            <SpView style={styles.upRowTableDetails}>
              <SpView style={styles.rowTableDetailsFirstCell}>
                <SpText style={[styles.rowTableTextFirstCell]} color={'#929ea2'}>{t('wifi_name')}</SpText>
              </SpView>
              <SpView style={styles.rowTableDetailsSecondCell}>
                <SpText style={[styles.rowTableTextSecondCell]} color={'#233a43'}>{t('setup_connect_cdb_text_grey_1')}</SpText>
              </SpView>
            </SpView>
            <SpView style={styles.downRowTableDetails}>
              <SpView style={styles.rowTableDetailsFirstCell}>
                <SpText style={[styles.rowTableTextFirstCell]} color={'#929ea2'}>{t('password')}</SpText>
              </SpView>
              <SpView style={styles.rowTableDetailsSecondCell}>
                <SpText style={[styles.rowTableTextSecondCell, styles.rightSpace]} color={'#233a43'}>{t('setup_connect_cdb_text_grey_2')}</SpText>
                <TouchableOpacity onPress={copyAction}>
                  <SpCenter style={[styles.rowTableTextSecondCell, styles.circle]}><FontAwesomeIcon icon={faCopy} color={'white'}/></SpCenter>
                </TouchableOpacity>
              </SpView>
            </SpView>
          </SpView>
          {visible && (
            <SpView style={[styles.popup,{ top: '82%', left: '70%' }]}>
              <SpText style={[styles.popupText, styles.center]} color={'white'}>{t('setup_connect_cdb_text_btn_copied_2')}</SpText>
            </SpView>
          )}
          <SpView style={[styles.wifiSettings, styles.center]}>
            <TouchableOpacity onPress={takeUserToSettings}>
              <SpText style={[styles.textReminder, styles.center]} color={'white'}>{t('wifi_settings')}</SpText>
            </TouchableOpacity>
          </SpView>
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '90%',
    width: '100%',
  },
  center: {
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  loadingWrapper: {
    width: '58%',
    /*borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'red',*/
  },
  reminder: {
    marginTop: '30%',
    marginBottom: '10%',
    width: '100%',
  },
  textReminder: {
    fontSize: 22,
  },
  tableDetails: {
    width: '100%',
  },
  upRowTableDetails: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderStyle: 'solid',
    borderColor: '#ced3d5',
    borderWidth: 1,
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 15,
  },
  downRowTableDetails: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderStyle: 'solid',
    borderColor: '#ced3d5',
    borderWidth: 1,
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 15,
  },
  rowTableDetailsFirstCell: {
    flex: 1,
  },
  rowTableDetailsSecondCell: {
    flex: 1,
    flexDirection: 'row',
  },
  rowTableTextFirstCell: {
    fontSize: 20,
    textAlign: 'left',
    marginLeft: 15,
    fontFamily: 'Rubik_SemiBold',
  },
  rowTableTextSecondCell: {
    fontSize: 18,
    textAlign: 'right',
    marginRight: 5,
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
    fontFamily: 'Rubik_SemiBold',
  },
  wifiSettings: {
    borderRadius: 10,
    backgroundColor: 'dodgerblue',
    padding: 12,
    width: '60%',
    fontSize: 16,
    marginTop: 30,
    fontFamily: 'Rubik_SemiBold',
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 28,
    backgroundColor: 'dodgerblue',
  },
  rightSpace: {
    marginRight: 20,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  popup: {
    position: 'absolute',
    backgroundColor: '#233a43',
    borderRadius: 10,
    padding: 10,
    elevation: 3,
    width: 100,
    height: 40,
  },
  popupText: {
    color: 'white',
    fontFamily: 'Rubik_SemiBold',
  },
});

export default WiFiConnectToDevice;
