import { WithFillPercent } from '@models/Device';

// function getFillingPercentage(
//   data: WithFillPercent,
//   isMultiple: true,
// ): number[];
// function getFillingPercentage(
//   data: WithFillPercent,
//   isMultiple: false,
// ): number[];

export function getFillPercentage(
  data: WithFillPercent,
  isMultiple: boolean,
): number[] {
  const bowlStatus = data?.status?.bowl_status || [];
  const results = bowlStatus.map(item => {
    const value = Math.round(item.fill_percent) || 0;

    return value > 100 ? 100 : value;
  });
  return isMultiple ? [results[0] || 0, results[1] || 0] : [results[0] || 0];
}
