import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleProp, StyleSheet } from 'react-native';

interface SpDeviceStatusProps {
  data: boolean;
  withLabel?: boolean;
  style?: StyleProp<unknown>;
}

export const SpDeviceStatus = ({
  data,
  withLabel = false,
  style = {},
}: SpDeviceStatusProps) => {
  const { t } = useTranslation();

  return (
    <SpView flexDirection="row" alignItems="center" style={style}>
      <Image
        source={data ? AppImages.wifi : AppImages.noWifi}
        {...testProperties(data ? 'wifi' : 'noWifi', 'image')}
        style={styles.image}
        resizeMode="contain"
      />
      {withLabel && (
        <SpView marginLeft={4}>
          <SpText size="xs">{t(data ? 'connected' : 'not_connected')}</SpText>
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 16,
    height: 16,
  },
});
