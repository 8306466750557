import React, { Suspense, useEffect, useState } from 'react';
import 'expo-dev-client';
import { applyInterceptors } from '@api/Http';
import LoadingSpinner from '@atoms/Loader';
import SpToastIcon from '@atoms/RoundIcons/SpToastIcon';
import { SpCenter } from '@atoms/SpCenter';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
// import remoteConfig from '@react-native-firebase/remote-config';
import { RemoteConfigDefault } from '@constants/RemoteConfig';
import {
  Rubik_400Regular,
  Rubik_500Medium,
  Rubik_600SemiBold,
  useFonts,
} from '@expo-google-fonts/rubik';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import useInAppMessage from '@hooks/useInAppMessage';
import { useIsExpoGoOrWeb } from '@hooks/useIsExpoGoOrWeb';
import { useReconfirmTermsAndConditions } from '@hooks/useReconfirmTermsAndConditions';
import { CommonActions } from '@react-navigation/native';
import colors from '@styles/colors';

import { DdLogs, initDdSdkReactNative } from './src/services/SPDataDogService';
import useBoundStore from './src/store/store';
import * as Updates from 'expo-updates';
import SpAlert from '@utils/alert';
import { init } from '@utils/i18n';
import * as Localization from 'expo-localization';
import * as SplashScreen from 'expo-splash-screen';
import { LogBox, StatusBar, StatusBarStyle, StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Toast, { ToastConfig } from 'react-native-toast-message';
import Navigation from 'src/pages';
import { navigationRef } from 'src/pages/RootNavigation';
import { ClickOutsideProvider } from './src/providers/ClickOutsideProvider';
import { SpRemoteConfig } from './src/services/RemoteConfigService';

const toastConfig: ToastConfig = {
  generalToast: ({ props }) => {
    return (
      <SpView style={styles.toastContainer}>
        <SpToastIcon isError={props.isError} />
        <SpText style={[styles.text, props.fontFamily]}>{props.description}</SpText>
      </SpView>
    );
  },
};

SplashScreen.preventAutoHideAsync();
export default function App() {
  const [fontsLoaded] = useFonts({
    Rubik_Medium: Rubik_500Medium,
    Rubik_SemiBold: Rubik_600SemiBold,
    Rubik: Rubik_400Regular,
  });
  const [appIsReady, setAppIsReady] = useState(false);
  useEffect(() => {
    LogBox.ignoreAllLogs(true);
    console.log('App is running');
    init();

    async function prepare() {
      try {
        if (isExpoGoOrWeb || SpRemoteConfig === null) {
          console.log('App is running in Expo Go or Web');
          // @ts-ignore
          return;
        }
        await SpRemoteConfig()
          .setDefaults(RemoteConfigDefault)
          .then(() => SpRemoteConfig().fetchAndActivate())
          .then(() => {
            console.log('remote config fetched and activated');
          });
        // await appInit();
      } catch (e) {
        console.warn(e);
      } finally {
        await initDdSdkReactNative();
        await DdLogs.info('App initialized');
        setAppIsReady(true);
      }
    }

    applyInterceptors({
      getStoreOptions: () => {
        return {
          token: useBoundStore.getState().accountStore.token,
          // locale if user is logged in, otherwise default
          colour: useBoundStore.getState().accountStore.user?.use_colour,
          locale:
            useBoundStore
              .getState()
              .languageStore.getLanguageByID(
                useBoundStore.getState().accountStore.user?.language_id || 37,
              )?.code || 'en',
        };
      },
    });
    prepare()
      .then(() => {
        return onFetchUpdateAsync();
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  async function onFetchUpdateAsync() {
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        // await Updates.fetchUpdateAsync().then(() => {
        //   alert("Update fetched");
        // });
        await DdLogs.info('Update Available');

        SpAlert('Update Available', 'The app has an available update and will now reload', [
          {
            text: 'Restart Now',
            onPress: async () => {
              await Updates.fetchUpdateAsync();
              await Updates.reloadAsync();
            },
            style: 'destructive',
          },
          {
            text: 'Do it Later',
            onPress: () => null,
            style: 'destructive',
          },
        ]);
        // await Updates.reloadAsync();
      } else {
        console.log('No updates');
        await DdLogs.info('No available Expo Update ');
      }
    } catch (error) {
      // await DdLogs.error('Expo Update Error', { error });
      // You can also add an alert() to see the error message in case of an error when fetching updates.
    }
  }

  useEffect(() => {
    if (appIsReady && fontsLoaded) {
      SplashScreen.hideAsync().then(() => {});
    }
  }, [appIsReady, fontsLoaded]);

  const isAuthenticated = useBoundStore((s) => s.accountStore.isAuthenticated());
  useInAppMessage();
  // const appInit = useAppInit();
  const isExpoGoOrWeb = useIsExpoGoOrWeb();

  //this is the code that handles showing a notification on android if its received when the app is in the foreground

  // useEffect(() => {
  //   const notificationRecievedSubscription =
  //     Notifications.addNotificationReceivedListener((notification: any) => {
  //       // return if we get a local notification or if we are on iOS (default behaviour works on apple)
  //       if (
  //         !notification ||
  //         !notification.request?.trigger.remoteMessage?.notification ||
  //         Device.brand === "Apple"
  //       )
  //         return;
  //
  //       // // if we get a remote notification, schedule it
  //       //
  //       // Notifications.scheduleNotificationAsync({
  //       //   content: {
  //       //     title:
  //       //       notification.request.trigger.remoteMessage.notification.title,
  //       //     body: notification.request.trigger.remoteMessage.notification.body,
  //       //     data: { data: notification.request.trigger.remoteMessage.data },
  //       //     sound: false,
  //       //   },
  //       //
  //       //   trigger: { seconds: 1 },
  //       // });
  //     });
  //
  //   return () => {
  //     notificationRecievedSubscription.remove();
  //   };
  // }, []);

  const [isTermsChanged, daysToExpire] = useReconfirmTermsAndConditions();
  useEffect(() => {
    if (navigationRef.isReady() && isAuthenticated && isTermsChanged) {
      setTimeout(() => {
        navigationRef.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [{ name: 'ReconfirmTermsAndConditions', params: { daysToExpire } }],
          }),
        );
      });
    }
  }, [isTermsChanged, isAuthenticated, daysToExpire, navigationRef.isReady()]);

  if (!appIsReady) {
    console.log('App is not ready');
    // alert('App is not ready')
    return null;
  }
  return (
    <GestureHandlerRootView style={{ flex: 1 }} key={Localization.locale}>
      <BottomSheetModalProvider>
        <ClickOutsideProvider>
          <StatusBar barStyle={'dark-content' as StatusBarStyle} backgroundColor="#FFFFFF" />
          <Suspense
            fallback={
              <SpCenter style={styles.spinnerContainer}>
                <LoadingSpinner />
              </SpCenter>
            }>
            <Navigation />
            {/* <SpText>Loaded</SpText> */}
            <Toast config={toastConfig} />
          </Suspense>
        </ClickOutsideProvider>
      </BottomSheetModalProvider>
    </GestureHandlerRootView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  spinnerContainer: {
    height: '100%',
  },
  text: {
    textAlign: 'center',
    color: colors.white.color,
  },
  toastContainer: {
    backgroundColor: colors.greyText.color,
    borderRadius: 10,
    width: 300,
    minHeight: 88,
    paddingRight: 54,
    paddingLeft: 54,

    alignItems: 'center',
    justifyContent: 'center',
    top: 64,
    position: 'relative',
  },
});
