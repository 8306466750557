import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import React, { PropsWithChildren, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { ReportRangeType } from '../../constants/ReportRangeType';

interface GraphLabelProps {
  top: number;
  calcLeftProps: {
    index: number;
    barWidth: number;
    barGap: number;
    marginLeft: number;
    marginRight: number;
    chartWidth: number;
    rangeType: ReportRangeType;
  };
}

const GAP_PADDING = 6;

export const GraphTooltip = ({
  top,
  children,
  calcLeftProps: {
    index,
    barGap,
    barWidth,
    marginLeft,
    marginRight,
    chartWidth,
  },
}: PropsWithChildren<GraphLabelProps>) => {
  const [left, setLeft] = useState(0);
  const [coordsSet, setCoordsSet] = useState(false);

  const onLayout = (eve: LayoutChangeEvent) => {
    const elementWidth = eve.nativeEvent.layout.width;
    const widthToGraphColumn = index * (barWidth + barGap);
    const widthToGraphIncludingPaddings =
      widthToGraphColumn + GAP_PADDING * 2 + barWidth / 2;
    let x = widthToGraphIncludingPaddings - elementWidth / 2;

    if (x + elementWidth > chartWidth) {
      x = chartWidth - elementWidth + marginRight + 8;
    } else if (x < 0) {
      x = marginLeft - 8;
    }

    setLeft(x);
    setCoordsSet(true);
  };

  return (
    <View
      style={[styles.containerWrap, { top, left, opacity: coordsSet ? 1 : 0 }]}
      onLayout={onLayout}
    >
      <SpView style={styles.container}>{children}</SpView>
    </View>
  );
};

const styles = StyleSheet.create({
  containerWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    position: 'absolute',
  },
  container: {
    backgroundColor: colors.greyText.color,
    borderRadius: 12,
    paddingHorizontal: GAP_PADDING,
    paddingVertical: 6,
  },
});
