import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { View } from 'react-native';

export interface SpSelectArrowProps {
  color: string;
  componentName?: string;
}

export const SpSelectArrow = ({ color, componentName }: SpSelectArrowProps) => (
  <View {...testProperties('faAngleRight', 'icon', componentName)}>
    <FontAwesomeIcon color={color} size={25} icon={faAngleRight} />
  </View>
);
