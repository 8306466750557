import SpAlert from '@utils/alert';
import * as ImagePicker from 'expo-image-picker';
import { useCamera } from './useCamera';

export const usePickerAndCameraImage = () => {
  const { takePhoto, requestPermissions } = useCamera();

  const cameraImage = async () => {
    try {
      const permissions = await requestPermissions();
      if (permissions.granted) {
        const photo = await takePhoto();
        return photo.assets[0].uri;
      }
      SpAlert('Access denied');
    } catch (err) {}
    return null;
  };

  const pickerImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsMultipleSelection: false,
        allowsEditing: true,
        aspect: [4, 3],
        base64: true,
        quality: 1,
      });
      if (!result.canceled && result.assets) {
        return result.assets[0].uri;
      }
    } catch (err) {}
    return null;
  };

  return {
    cameraImage,
    pickerImage,
  };
};
