import { SpCenter } from '@atoms/SpCenter';
import { Image, StyleSheet } from 'react-native';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../Molecules/LoadingComponent';

interface DeviceFoundStateProps {
  connected: boolean;
  maxRetriesError: boolean;
}

const DeviceFoundState = ({
  connected,
  maxRetriesError,
}: DeviceFoundStateProps) => {
  const { t } = useTranslation();

  const connectedMsg = useMemo(() => {
    if (maxRetriesError) {
      return t('hub_not_found');
    }

    return connected ? t('hub_found') : null;
  }, [connected, maxRetriesError]);

  return (
    <SpCenter
      style={{
        marginTop: 32,
      }}
    >
      <Image
        source={AppImages.pairHub}
        style={styles.image}
        resizeMode="contain"
        {...testProperties('pairHub', 'image')}
      />

      <SpText
        align="center"
        size="md"
        color={colors.greyText.color}
        style={styles.foundDeviseStep}
      >
        {t('setup_hub_found_step')}
      </SpText>
      <LoadingComponent
        searchMsg={t('looking_for_hub')}
        isConnected={connected || maxRetriesError}
        connectedMsg={connectedMsg}
        error={maxRetriesError}
      />
    </SpCenter>
  );
};

const styles = StyleSheet.create({
  foundDeviseStep: { marginTop: 32, marginBottom: 48, paddingHorizontal: 0 },
  image: { height: 264 },
});


export default DeviceFoundState;