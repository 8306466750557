import Constants, { ExecutionEnvironment } from 'expo-constants';
import { Platform } from 'react-native';

export const useIsExpoGoOrWeb = (): boolean => {
  // `true` when running in Expo Go.
  const isExpoGo =
    Constants.executionEnvironment === ExecutionEnvironment.StoreClient;
  const isWeb = Platform.OS === 'web';
  return isExpoGo || isWeb;
};
