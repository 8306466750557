import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import useTour from '@hooks/useTour';
import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { AnalyticsService } from '../../../services/AnalyticsService';
import useBoundStore from '../../../store/store';
import HistoricalDataDashboardSlide from '../../Tour/components/HistoricalDataDashboardSlide/HistoricalDataDashboardSlide';
import PetDataDashboardSlide from '../../Tour/components/PetDataDashboardSlide/PetDataDashboardSlide';
import TapForAdvancedDataDashboardSlide from '../../Tour/components/TapForAdvancedDataDashboardSlide/TapForAdvancedDataDashboardSlide';
import { PetStackParamList } from '../Pets';
import { Dashboard } from '../molecules/PetDashboardSummary/Dashboard';

export const PetDashboardSummaryWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetDashboardSummary'>) => {
  const [dateRange, setDateRange] = useState<number[]>([0, Date.now()]);
  const [selectedDate, setSelectedDate] = useState<number>(Date.now());
  const setActivePet = useBoundStore((state) => state.petStore.setActivePet);
  const petDashboardCount = useBoundStore((s) => s.tourStore.petDashboardCount);
  const pet = useBoundStore((state) => state.petStore.activePet);
  const { loadPetStatistics, getPetStatistics, setPetStatistics, petStatisticsLoading } =
    useBoundStore((state) => state.petStore);

  const isFocused = useIsFocused();
  const devices = useDevicesByHousehold();

  const petDashboardsTours = [
    {
      uniqueId: `v0-pet-dashboard`,
      countId: 1,
      components: [
        <PetDataDashboardSlide />,
        <HistoricalDataDashboardSlide />,
        <TapForAdvancedDataDashboardSlide />,
      ],
      devices: ['all'],
    },
  ];
  const initTour = useTour(petDashboardsTours[petDashboardCount - 1]);

  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  useEffect(() => {
    if (pet && selectedDate) {
      AnalyticsService.logEvent('PetDashboardSummaryWrapper - useEffect - loadPetStatistics', {
        selectedDate,
      });

      loadPetStatistics([pet], devices, new Date(selectedDate), false, 7);
    }
  }, [selectedDate, pet?.id]);

  useEffect(() => {
    const currPetStatistics = getPetStatistics(pet?.id);
    AnalyticsService.logEvent('PetDashboardSummaryWrapper - useEffect - getPetStatistics');
    return () => {
      AnalyticsService.logEvent('PetDashboardSummaryWrapper - useEffect - setPetStatistics');

      setPetStatistics(currPetStatistics);
    };
  }, [pet?.id]);

  if (!pet) {
    return null;
  }

  return (
    <Dashboard
      petStatistic={getPetStatistics(pet?.id)}
      petStatisticsLoading={petStatisticsLoading}
      pet={pet}
      dateRange={dateRange}
      selectedDate={selectedDate}
      onChangeDate={setSelectedDate}
    />
  );
};
