import { ScrollView, StyleSheet, Text, View } from 'react-native';
import React, { useMemo, useState } from 'react';
import { SpText } from '@atoms/SpText';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import text from '@styles/text';
import atoms from '@styles/atoms';
import utils from '@styles/utils';
import StepsNumCircle from '../Molecules/StepsNumCircle';

const SetupSteps = () => {
  const [dashesCount, setDashesCount] = useState<any>();
  const { t } = useTranslation();

  const steps = useMemo(() => {
    return [
      {
        title: t('add_hub'),
        subtitle: t('setup_hub_steps_1_subtitle'),
      },
      {
        title: t('setup_hub_steps_2_title'),
        subtitle: t('setup_hub_steps_2_subtitle'),
      },
      {
        title: t('setup_hub_steps_3_title'),
        subtitle: t('setup_hub_steps_3_subtitle'),
      },
    ];
  }, []);

  const dashedLine = (numOfDashes: number) => {
    return (
      <View style={atoms.dashedLine}>
        {new Array(numOfDashes > 0 ? numOfDashes : 8)
          .fill('')
          .map((_, index) => (
            <View key={index} style={atoms.line} />
          ))}
      </View>
    );
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <SpText align="center" style={styles.mainTitle}>
        {t('setup_hub_steps_main_title')}
      </SpText>

      <View style={styles.stepsWrap}>
        {steps.map((item, index) => {
          return (
            <View key={index} style={utils.rowStart}>
              <View
                onLayout={event => {
                  const { height } = event.nativeEvent.layout;
                  if (index !== steps.length - 1) {
                    setDashesCount((prevstate: any) => ({
                      ...prevstate,
                      [index]: Math.round((height - 42) / 12),
                    }));
                  }
                }}
                style={styles.stepRoad}
              >
                <StepsNumCircle num={index + 1} />
                {index !== steps.length - 1 && dashedLine(dashesCount?.[index])}
              </View>
              <View style={utils.textWrap}>
                <SpText
                  style={[text.title, { textAlign: 'left', fontSize: 20 }]}
                >
                  {item.title}
                </SpText>
                <SpText
                  size="md"
                  bold
                  style={styles.stepSubtitle}
                  color={colors.greyText.color}
                >
                  {item.subtitle}
                </SpText>
                <SpText
                  size="sm"
                  style={styles.stepMsg}
                  color={colors.primary.color}
                  componentName={item.title}
                >
                  {t('setup_hub_steps_time_msg_1')}
                  <Text style={{ fontFamily: 'Rubik_Medium' }}>
                    {t('setup_hub_steps_time_msg_2')}
                  </Text>
                </SpText>
              </View>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default SetupSteps;

const styles = StyleSheet.create({
  mainTitle: {
    paddingHorizontal: 20,
    marginTop: 24,
  },
  stepsWrap: {
    paddingHorizontal: 24,
    marginTop: 64,
  },
  stepRoad: { marginTop: 4, height: '100%', alignItems: 'center' },
  stepTitle: { lineHeight: 28, marginLeft: 1 },
  stepSubtitle: { lineHeight: 24, marginTop: 4 },
  stepMsg: { marginTop: 8 },
});
