import CustomBottomSheetModal from '@atoms/CustomBottomSheetModal';
import { SpView } from '@atoms/SpView';
import { DeviceFeederBowlType, DeviceFeederTareType } from '@constants/Device';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import {
  DeviceFeederControlModel,
  DeviceModel,
  DeviceFeederTypeUpdated,
} from '@models/Device';
import React, { useCallback, useMemo, useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { FeederCardModal } from '../../constants/FeederCard';
import { DeviceBottomSheetContainer } from '../DeviceBottomSheetContainer';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import { DeviceStatusHeader } from '../DeviceStatusHeader';
import { BowlDual } from './BowlDual';
import { BowlSingle } from './BowlSingle';
import { FeederFooterActions } from './FeederFooterActions';
import { ZeroScales } from './ZeroScales';
import { FeederInfo } from './FeederInfo';
import { getFillPercentage } from '../../utils/getFillingPercentage';

interface FeederCardProps {
  data: DeviceModel;
  userHasWriteAccess: boolean;
  onChangeZeroScales: (data: DeviceFeederTareType) => void;
}

export const FeederCard = ({
  data,
  onChangeZeroScales,
  userHasWriteAccess,
}: FeederCardProps) => {
  const [modal, setModal] = useState<FeederCardModal>(null);
  const snapPoints = useMemo(() => ['40%'], []);
  const control = useMemo(
    () => data?.control as DeviceFeederControlModel,
    [data],
  );
  const online = data?.status.online;

  const changeBowlTypeAction = useCallback(
    ($event: DeviceFeederTareType) => {
      onChangeZeroScales($event);
    },
    [onChangeZeroScales],
  );

  const percentages = getFillPercentage(data as any, true);
  const screenHeight = Dimensions.get('window').height;

  return (
    <>
      <SpView flex={1}>
        <DeviceStatusHeader data={data} />

        {online && (
          <>
            {userHasWriteAccess && <DeviceSettingMenuItem data={data?.id} />}
            <SpView marginTop={screenHeight < 810 ? 4 : 36}>
              {control.bowls?.type === DeviceFeederBowlType.Single ? (
                <BowlSingle data={control.bowls} percentage={percentages[0]} />
              ) : (
                <BowlDual data={control.bowls} percentages={percentages} />
              )}
            </SpView>
            <SpView style={styles.divider} marginTop={screenHeight < 810 ? 4 : 24} />
            <FeederInfo
              events={data.status as unknown as DeviceFeederTypeUpdated}
              trainingMode={control?.training_mode}
            />
            <SpView style={styles.divider} marginTop={screenHeight < 810 ? 4 : 24} />
            {userHasWriteAccess && online && (
              <FeederFooterActions onAction={setModal} />
            )}
          </>
        )}
      </SpView>
      {modal === FeederCardModal.BowlType && (
        <CustomBottomSheetModal
          opened
          backdropComponent={BottomSheetBackdrop}
          index={0}
          snapPoints={snapPoints}
          onDismiss={() => setModal(null)}
          inScrollView
        >
          <DeviceBottomSheetContainer>
            <ZeroScales
              type={control?.bowls?.type}
              onChange={changeBowlTypeAction}
              events={data.status as unknown as DeviceFeederTypeUpdated}
            />
          </DeviceBottomSheetContainer>
        </CustomBottomSheetModal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: 'rgba(38, 58, 67, 0.15)',
  },
});
