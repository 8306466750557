import { Image, ImageSourcePropType, Pressable, StyleSheet, Text } from 'react-native';
import React from 'react';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';

interface IProductButtonProps {
  name: string;
  image: ImageSourcePropType;
  action: () => void;
}

const connectPartOtherPlace = [
  'zh',
  'cs',
  'fi',
  'nl',
  'sv',
  'pt-BR',
  'ja'
];

const ProductButton = ({ name, image, action }: IProductButtonProps) => {
  const currLang = useCurrentLanguageCode();
  const { t } = useTranslation();
  return (
    <Pressable accessible={false} onPress={action} style={styles.container}>
      <Image
        source={image}
        style={[styles.image, name === t('cat_flap') && { marginBottom: -8 }]}
        resizeMode="contain"
        {...testProperties(name, 'image')}
      />
      <SpText size="xl" style={styles.text}>
        {name}
      {!connectPartOtherPlace.includes(currLang) && <Text style={{ color: '#919ba0' }}> {'Connect'}</Text>}
      </SpText>
    </Pressable>
  );
};

export default ProductButton;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 14,
    borderColor: colors.greyOpacity.color,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 17,
    gap: 20,
  },
  image: {
    width: 74,
    height: 80,
  },
  text: {
    fontFamily: 'Rubik_Medium',
    flex: 1,
  },
});
