import { useDeviceById } from '@hooks/useDeviceById';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { DeviceStackParamList } from './index';
import { NavigationList } from './molecules/DeviceSetting/NavigationList';

export const DeviceSetting = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceSetting'>) => {
  const { id } = route.params;
  const device = useDeviceById(id);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);
  const assignedPets = useMemo(() => {
    return petsWithTag.filter(item => item.isAssigned).map(item => item.pet);
  }, [petsWithTag]);
  return <NavigationList device={device} pets={assignedPets} />;
};
