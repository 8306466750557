/* eslint-disable no-useless-catch */
import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import HouseholdUserApi from '@api/HouseholdUserApi';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { HouseholdRemovedService } from '../../services/HouseholdRemovedService';
import { HouseholdUserSlice, MergedInterfaces } from '../models';

const createHouseholdUserSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  HouseholdUserSlice
> = (set, get) => {
  return {
    householdUserStore: lens((subSet, subGet) => ({
      loadingHouseholdUser: false,
      updateUser: async (userId: number, user: any) => {
        subSet({ loadingHouseholdUser: true });
        try {
          AnalyticsService.logEvent(`household_updateUser`);

          const householdId = get().householdStore.activeHousehold.id;
          await HouseholdUserApi.updateHouseholdUser(householdId, userId, user);
          await get().householdStore.getHouseholds();
        } catch (err) {
          throw err;
        } finally {
          subSet({ loadingHouseholdUser: false });
        }
      },
      deleteUser: async userId => {
        subSet({ loadingHouseholdUser: true });
        try {
          AnalyticsService.logEvent(`household_deleteUserFromHousehold`);

          const householdId = get().householdStore.activeHousehold.id;
          await HouseholdUserApi.deleteHouseholdUser(householdId, userId);
          await get().householdStore.getHouseholds();
          subSet({ loadingHouseholdUser: false });
          return true;
        } catch (err) {
          subSet({ loadingHouseholdUser: false });
          return false;
        }
      },
      deleteUserFromHousehold: async (userId: number, householdId: number) => {
        AnalyticsService.logEvent(`household_deleteUserFromHousehold`);

        subSet({ loadingHouseholdUser: true });
        try {
          await HouseholdUserApi.deleteHouseholdUser(householdId, userId);
          await HouseholdRemovedService.clear(userId);
          await get().householdStore.getHouseholds();
        } catch (err) {
          console.log(err);
        } finally {
          subSet({ loadingHouseholdUser: false });
        }
      },
    })),
  };
};
export default createHouseholdUserSlice;
