import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { SettingsOption } from '@constants/Settings';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';

export const dataConverter = (data: SettingsOption[]) => {
  return data?.map(item => {
    return {
      label: item.name,
      value: item?.id.toString(),
    };
  });
};

export interface SpSelectProps {
  label: string;
  selectedValue: number;
  onSelect: (arg1: number) => void;
  data: SettingsOption[];
  dropdownMaxHeight?: number;
  forceSet?: (data: string) => void;
}

const SpSelect = ({
  label,
  selectedValue,
  onSelect,
  data,
  dropdownMaxHeight = 190,
  forceSet,
}: SpSelectProps) => {
  const [isFocus, setIsFocus] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(dataConverter(data));

  const { t } = useTranslation();
  const renderItem = useCallback((item: any) => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (forceSet) forceSet(item.item.value);
          item.onPress(item);
        }}
        {...testProperties(`${item.label}`, 'TextWrapper', '', true)}
        style={[
          styles.selectedItem,
          item.isSelected && { backgroundColor: 'white' },
        ]}
      >
        <Text
          {...testProperties(`${item.label}`, 'Text')}
          style={styles.selectedItemTextStyle}
        >
          {item.label}
        </Text>
        {item.isSelected && (
          <View {...testProperties(`${item.label}`, 'FaCheck_Icon', '', true)}>
            <FontAwesomeIcon color="darkgrey" icon={faCheck} />
          </View>
        )}
      </TouchableOpacity>
    );
  }, []);

  return (
    <View
      style={{ zIndex: 9 }}
      {...testProperties('Wrapper', 'Language', 'Container', true)}
    >
      <Text style={[textStyles.label, styles.noMargin]}>{t('language')}</Text>
      <DropDownPicker
        style={[styles.dropdown]}
        placeholderStyle={styles.placeholderStyle}
        selectedItemLabelStyle={styles.selectedTextStyle}
        dropDownDirection={'AUTO'}
        zIndex={3000}
        zIndexInverse={1000}
        maxHeight={dropdownMaxHeight}
        listMode={'SCROLLVIEW'}
        scrollViewProps={{
          nestedScrollEnabled: true,
          decelerationRate: 'fast',
        }}
        dropDownContainerStyle={{
          backgroundColor: '#FFFFFF',
          position: 'relative',
          top: 0,
        }}
        open={open}
        value={selectedValue?.toString()}
        renderListItem={props => renderItem(props)}
        placeholder={data[selectedValue]?.name || label}
        items={items}
        setOpen={setOpen}
        setValue={valueItem => onSelect(valueItem)}
        textStyle={styles.selectedTextStyle}
        setItems={setItems}
        {...testProperties('DropDown', 'Component', '', true)}
      />
    </View>
  );
};

export default SpSelect;

const styles = StyleSheet.create({
  dropdown: {
    minHeight: 56,
    borderWidth: 1,
    borderRadius: 14,
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: 'rgba(38, 58, 67, 0.05)',
  },
  placeholderStyle: {
    fontSize: 13,
    color: '#71717a',
  },
  selectedTextStyle: {
    fontSize: 20,
    color: colors.black.color,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  selectedItemTextStyle: {
    fontSize: 12,
    color: '#71717a',
  },
  selectedItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
  },
  containerStyle: {
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 2,
  },
  noMargin: {
    margin: 0,
  },
});