import { Image, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import colors from '@styles/colors';

interface HowToFactoryResetYourCDBProps {
  getHelpAction: () => void;
}

export const HowToFactoryResetYourCDB = ({
  setEnum,
}: HowToFactoryResetYourCDBProps) => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <SpText style={[text.title]}>
            {t('how_to_factory_cdb')}
          </SpText>
          <Image
            style={{ maxWidth: 60, maxHeight: 60, marginTop: 30}}
            source={AppImages.resetImgCDBTop}
            resizeMode="contain"
          />
          <SpText style={[text.body, styles.text]}>
            {t('hold_button_CDB_paragraph_one')}
          </SpText>
          <SpText style={[text.body, styles.text]}>
            {t('hold_button_CDB_paragraph_two')}
          </SpText>
          <Image
            style={{ maxWidth: 200, maxHeight: 150 }}
            source={AppImages.resetImgCDBBottom}
            resizeMode="contain"
          />
          <SpView style={[styles.greyBackground]}>
            <SpText style={[text.title, styles.greyBackgroundTitle]}>
              {t('flashing_another_colour_title')}
            </SpText>
            <SpText style={[text.body, styles.greyBackgroundBody]}>
              {t('flashing_another_colour_description')}
            </SpText>
          </SpView>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  greyBackground: {
    backgroundColor: '#eee',
    width: '100%',
    borderRadius: 20,
    padding: 20,
  },
  greyBackgroundTitle: {
    fontSize: 16,
    textAlign: 'center',
    padding: 0,
    margin: 0,
    fontWeight: 'bold',
    lineHeight: 16,
  },
  greyBackgroundBody: {
    fontSize: 16,
    textAlign: 'center',
    padding: 0,
    margin: 0,
    fontWeight: 'bold',
    lineHeight: 16,
    paddingTop: 14,
  }
});

export default HowToFactoryResetYourCDB;
