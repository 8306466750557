import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const FeedingSVG = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <G fill="#03A2B1" fillRule="nonzero">
      <Path d="M11.25 8c0-4.418-2.017-8-4.5-8-2.484 0-4.5 3.582-4.5 8a4.49 4.49 0 0 0 2.908 4.195L4.75 22a2 2 0 0 0 4 0l-.408-9.804A4.49 4.49 0 0 0 11.25 8ZM21.75 7.25 21.5 0h-1.25L20 7.25h-1L18.75 0h-1.5L17 7.25h-1L15.75 0H14.5l-.25 7.25c0 1.525.914 2.832 2.223 3.418L16 22a2 2 0 0 0 4 0l-.473-11.332A3.745 3.745 0 0 0 21.75 7.25Z" />
    </G>
  </Svg>
);
export default FeedingSVG;
