import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import noop from '@utils/noop';
import useValidation from 'src/pages/validation';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import useBoundStore from 'src/store/store';
import { PetSpecie } from '@constants/Pet';
import { SpInput } from './SpInput';
import { SpView } from './SpView';

interface WeightInputProps {
  value: string;
  setValue: (arg: string) => void;
  setButtonDisabled?: Dispatch<SetStateAction<boolean>>;
  label?: string;
  placeholder?: string;
  setError?: Dispatch<any>;
  unit?: string;
  componentName?: string;
  additionalTestLabel?: string;
  inputType: 'single' | 'both' | 'pet';
}

const WeightInput = ({
  value,
  setValue,
  setButtonDisabled,
  label = 'weight',
  setError,
  unit,
  componentName,
  placeholder = 'type_weight',
  additionalTestLabel = null,
  inputType,
}: WeightInputProps) => {
  const { getWeightScheme } = useValidation();
  const activePet = useBoundStore(state => state.petStore.activePet);
  const { t } = useTranslation();
  const userWeightUnits = useUserWeightUnits();
  const initialValue = useMemo(() => value?.split(' ')[0], [value]);
  const weightUnit = useMemo(() => value?.split(' ')[1], [value]);
  const weightChange = useCallback(
    (num: string) => {
      num = num.replace(',', '.')
      formik.handleChange('weight')(num);
      setValue(num);
    },
    [value, setValue],
  );

  const formik = useFormik({
    validationSchema: getWeightScheme(
      inputType,
      activePet?.species_id || PetSpecie.dog,
      userWeightUnits,
    ),
    initialValues: { weight: value },
    onSubmit: noop,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (setButtonDisabled) {
      setButtonDisabled(Boolean(formik.errors.weight?.length));
    }
    if (setError) setError(formik.errors.weight);
  }, [formik.errors.weight,t]);

  return (
    <SpView paddingHorizontal={19} paddingBottom={30} marginTop={48}>
      <SpInput
        additionalTestLabel={additionalTestLabel}
        value={initialValue}
        keyboardType="numeric"
        onChangeText={weightChange}
        weightUnit={t(unit) || t(weightUnit)}
        label={t(label)}
        placeholder={t(placeholder)}
        error={t(formik.errors.weight)}
        componentName={componentName}
      />
    </SpView>
  );
};

export default WeightInput;
