import ProductApi from '@api/ProductApi';
import { lens } from '@dhmk/zustand-lens';
import { ProductModel } from '@models/Product';
import { StateCreator } from 'zustand';
import { MergedInterfaces } from '../models';

export interface ProductSliceModel {
  productStore: {
    loading: boolean;
    data: ProductModel[];
    loadAll: (force: boolean) => void;
    reset: () => void;
  };
}

const createProductSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  ProductSliceModel
> = (rootSet, rootGet) => {
  return {
    productStore: lens((set, get, api) => ({
      data: [],
      loading: false,
      loadAll: async (force = false) => {
        if (!force && get().data.length) {
          return;
        }

        set({ loading: true });
        try {
          const data = await ProductApi.getAll();
          set({ loading: false, data });
        } catch (e) {
          set({ loading: false, data: [] });
        }
      },
      reset: () => {
        set({
          loading: false,
          data: [],
        });
      },
    })),
  };
};

export default createProductSlice;
