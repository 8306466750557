import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { MovementReportDataModel } from '@models/ReportModel';
import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';

export function useTransformMovementEventToReport(
  data: MovementReportDataModel,
  timezone: string,
) {
  const userTimeFormat = useUserTimeUnits();
  const {
    timeToFormat,
    convertDateToTime,
    concatTwoDigitFormatToOne,
    convertSecondToTwoDigitFormat,
  } = useTimeTransform();
  return useMemo(() => {
    const duration = concatTwoDigitFormatToOne(
      convertSecondToTwoDigitFormat(data.duration),
    );
    const start = format(parseISO(data.from), 'HH:mm')
    const end = format(parseISO(data.to), 'HH:mm')

    return {
      start,
      end,
      duration,
    };
  }, [data, userTimeFormat]);
}
