import { SpView } from '@atoms/SpView';
import { StyleSheet, View } from 'react-native';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import MockLinechart from '../MockLinechart/MockLinechart';
import mockLinechartsData from './mockLinechartsData';
import TourSwipeSVG from '@atoms/SvgIcons/TourSwipeSVG';
import { ScrollView } from 'react-native-gesture-handler';

const ExitsAndEntriesSlide = () => {
  const { t } = useTranslation();
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <SpView style={styles.linechartsWrapper}>
        <MockLinechart type="square" items={mockLinechartsData} />
      </SpView>
      <SpTitle align="center" text={t('exits_and_entries')} />
      <SpText style={styles.contentText}>
        {t('exits_and_entries_tour_text')}
      </SpText>
      <View style={styles.iconWrapper}>
        <TourSwipeSVG />
      </View>
      <SpText style={[styles.contentText, styles.blueText]}>
        {t('exits_and_entries_tour_tex2')}
      </SpText>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  linechartsWrapper: {
    paddingHorizontal: 19,
    marginTop: 32,
    marginBottom: 12,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  blueText: {
    color: '#03A2B1',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    fontFamily: 'Rubik_SemiBold',
  },
});

export default ExitsAndEntriesSlide;
