/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-prop-types */
import { DayMonthFormat, DayWeekMonthShortFormat } from '@constants/DateFormat';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import {
  GroupReportPointModel,
  MappedPoseidonReportDataModel,
} from '@models/ReportModel';
import { endOfDay, format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, StyleSheet, View } from 'react-native';
import { SpText } from '@atoms/SpText';
import { VisitsLineChart } from '@molecules/VisitsLineChart';
import { fillEmptyPoints } from '@utils/fillEmptyPoints';
import { useIsFocused } from '@react-navigation/native';
import useTour from '@hooks/useTour';
import textStyles from '@styles/text';
import { SpView } from '@atoms/SpView';
import { meanBy, sumBy } from 'lodash-es';
import { useTimeTransform } from '@hooks/useTimeTransform';
import SpTransformStringToMultipleTexts from '@atoms/SpTransformStringToMultipleTexts';
import { useCurrentAndPreviousDays } from '../../hooks/useCurrentAndPreviousDays';
import { useGroupedConsumptionStats } from '../../hooks/useGroupedConsumptionStats';
import { DrinkingEventReport } from '../DrinkingEventReport';
import { Header } from './Header';
import { LineGraphModalWrapper } from './LineGraphModalWrapper';
import DateNavigation from '../DateNavigation';
import DrinkingVisitsSlide from '../../../Tour/components/DrinkingVisitsSlide/DrinkingVisitsSlide';
import {
  ReportRangeLastName,
  ReportRangeType,
} from '../../constants/ReportRangeType';
import AvgSatatisticsContainer from './AvgSatatisticsContainer';
import useAvarageGrouping from '../../hooks/useAvarageGrouping';
import useViewedDays from '../../hooks/useViewedDays';
import useBoundStore from "../../../../store/store";
import LoadingSpinner from "@atoms/Loader";

interface PetDashboardVisitsProps {
  data: GroupReportPointModel<MappedPoseidonReportDataModel>[];
  pet: PetModel;
  devices: DeviceModel[];
  onGeneratePDFReport: () => void;
  setViewedReportDays: (value: number) => void;
  setViewedActiveDate: (value: number) => void;
  rangeType: ReportRangeType;
}

export const PetDashboardDrinking = ({
  data,
  pet,
  devices,
  onGeneratePDFReport,
  setViewedReportDays,
  setViewedActiveDate,
  rangeType,
}: PetDashboardVisitsProps) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(null);
  const [activeDate, setActiveDate] = useState(endOfDay(new Date()).getTime());
  const [viewedDays, setViewedDays] = useViewedDays(
    rangeType,
    setViewedReportDays,
  );
  const isFocused = useIsFocused();
  const { loading } = useBoundStore(s => s.reportStore);

  const initTour = useTour({
    uniqueId: 'v0-graph-drinking-dashboard',
    components: [<DrinkingVisitsSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const activityDetails =
    useCurrentAndPreviousDays<MappedPoseidonReportDataModel>(
      activeDate,
      data,
      viewedDays,
    );

  const convertedActivityDetails = useMemo(
    () => fillEmptyPoints(activityDetails.rangeData, viewedDays, activeDate),
    [activityDetails, viewedDays, activeDate],
  );
  const stats = useGroupedConsumptionStats(
    data,
    pet,
    devices,
    activityDetails?.currentDay?.date,
    true,
  );

  const [averageActivity, duration] = useAvarageGrouping(
    convertedActivityDetails,
  );

  const pointDetailsRenderItem = useCallback(
    ($event: { item: MappedPoseidonReportDataModel }) => {
      return <DrinkingEventReport data={$event.item} />;
    },
    [],
  );

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: GroupReportPointModel<MappedPoseidonReportDataModel>;
      index: number;
    }) => {
      if (!item) {
        return;
      }
      const isActive = index + 1 === activeItem;
      const isVisibleLeftTitle = () => {
        if (viewedDays === 7) {
          return format(item?.date, DayWeekMonthShortFormat);
        }
        if (viewedDays === 28) {
          if (index === 0 || isActive || index % 7 === 0) {
            return format(item?.date, DayWeekMonthShortFormat);
          }
          return null;
        }
      };
      return (
        <VisitsLineChart
          isActive={item.points.length && isActive ? true : false}
          points={item.points}
          setActive={() => {
            item.points.length && setActiveItem(index + 1);
          }}
          lowState={viewedDays === 28}
          leftTitle={isVisibleLeftTitle()}
          rightTitle={`${item.points.length} ${t('visits')}`}
          pointDetailsRenderItem={pointDetailsRenderItem}
        />
      );
    },
    [activeItem, viewedDays],
  );

  const ListHeader = useCallback(
    () => (
      <View>
        {convertedActivityDetails.length > 0 &&
          convertedActivityDetails?.[0]?.date ===
            endOfDay(new Date()).getTime() && (
            <SpText style={{ marginBottom: -20 }} fontFamily="Rubik_Medium">
              {t('today')}
            </SpText>
          )}
      </View>
    ),
    [convertedActivityDetails],
  );
  const dateNavigationData = {
    name: t(ReportRangeLastName[viewedDays === 28 ? 28 : 7], {
      value: viewedDays === 28 ? 28 : 7,
    }),
    viewedDays,
  };

  return (
    <>
      {stats ? <LineGraphModalWrapper data={stats} modal="LineGraph" /> : null}
      <Header onGeneratePDFReport={onGeneratePDFReport} />
      <View style={styles.container}>
        <DateNavigation
          title={dateNavigationData.name}
          currentDate={new Date(activeDate)}
          setDate={date => {
            setActiveDate(date);
            setViewedActiveDate(date);

            setActiveItem(viewedDays === 28 ? null : 1);
          }}
          viewedDays={viewedDays - 1}
          setViewedDays={(days: number) => {
            setViewedDays(days);
            setViewedReportDays(days);

            setActiveItem(days === 28 ? null : 1);
          }}
          withButtons
        />
        {loading ?
            <SpView style={styles.spinner}>
              <LoadingSpinner/>
            </SpView> :
            <>
              <View style={styles.grayBackground}>
                <View style={styles.whiteLayout}/>
                <View style={styles.cardsContainer}>
                  <AvgSatatisticsContainer
                      type="multi"
                      leftLabel={`${t('average_short')} ${t('drinks')}`}
                      rightValue={
                        <SpTransformStringToMultipleTexts
                            data={[
                              duration.timeFirst,
                              duration.unitFirst,
                              duration.timeSecond,
                              duration.unitSecond,
                            ]}
                            mainStyle={{...textStyles.title, ...styles.bigText}}
                            prefixStyle={{...textStyles.title, ...styles.smallText}}
                        />
                      }
                      rightLabel={`${t('average_short')} ${t('duration')}`}
                      leftValue={`${averageActivity}`}
                  />
                </View>
              </View>
              <SpView style={[styles.flatlistContainer, styles.containerMargin]}>
                <FlatList
                    style={styles.flatlist}
                    data={convertedActivityDetails}
                    renderItem={renderItem}
                    ListHeaderComponent={ListHeader}
                    keyExtractor={item => item?.date.toString()}
                    ListFooterComponent={
                      <SpView height={Platform.OS === 'android' ? 30 : 0}/>
                    }
                />
              </SpView>
            </>}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    height: '100%',
  },
  grayBackground: {
    marginLeft: -24,
    marginRight: -24,
    backgroundColor: '#f2f3f4',
  },
  whiteLayout: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '35%',
    position: 'absolute',
  },
  flatlistContainer: {
    marginHorizontal: -24,
    paddingHorizontal: 24,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    paddingBottom: '100%',
  },
  flatlist: {
    marginHorizontal: -24,
    paddingHorizontal: 24,
  },
  cardsContainer: {
    paddingHorizontal: 24,
    marginTop: 28,
    marginBottom: 36,
  },
  bigText: {
    fontSize: 28,
    lineHeight: 28,
    textAlign: undefined,
  },
  smallText: {
    fontSize: 16,
    textAlign: undefined,
    lineHeight: 28,
  },
  containerMargin: {
    marginBottom: 140,
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '60%'
  }
});
