import { DayMonthFormat } from "@constants/DateFormat";
import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
  MappedReportModel,
} from "@models/ReportModel";
import months from "@molecules/CalendarModal";
import { useNavigation } from "@react-navigation/native";
import { format, getMonth } from "date-fns";
import { orderBy } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FlatList,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import colors from "@styles/colors";
import { VisitsLineChart } from "@molecules/VisitsLineChart";
import { ReportType } from "../../constants/ReportType";
import { useVisitHistoryData } from "../../hooks/useVisitHistoryData";
import { DrinkingEventReport } from "../DrinkingEventReport";
import { FeedingEventReport } from "../FeedingEventReport";
import { MovementEventReport } from "../MovementEventReport";
import { MonthCarousel } from "./MonthCarousel";

interface DashboardProps {
  data: MappedReportModel;
  type: ReportType;
}

export const Dashboard = ({ data, type }: DashboardProps) => {
  const navigation = useNavigation();
  const [activeMonth, setActiveMonth] = useState<number>(null);
  const [history, setHistory] = useState<GroupReportPointModel<unknown>>([]);
  const [activeItem, setActiveItem] = useState(null);
  const groupedByMonths = useVisitHistoryData(data, type);

  const convertedMonths = useMemo(() => {
    return orderBy(groupedByMonths.events || [], "date", "desc").map((item) => {
      const month = getMonth(item.date);
      return {
        name: `${months?.[month]} ${format(item.date, "yy")}`,
        date: item?.date,
      };
    });
  }, [groupedByMonths.events]);

  const pointDetailsRenderItem = useCallback(
    ($event: { item: unknown }) => {
      if (type === ReportType.Feeding) {
        return (
          <FeedingEventReport
            data={$event.item as MappedConsumptionReportDataModel}
          />
        );
      }

      if (type === ReportType.Drinking) {
        return (
          <DrinkingEventReport
            data={$event.item as MappedPoseidonReportDataModel}
          />
        );
      }

      if (type === ReportType.Movement) {
        return (
          <MovementEventReport
            data={$event.item as MappedMovementReportDataModel}
          />
        );
      }

      return null;
    },
    [type]
  );

  useEffect(() => {
    if (convertedMonths.length) {
      setActiveMonth(convertedMonths[0].date);
    }
  }, [convertedMonths]);

  useEffect(() => {
    setActiveItem(1);
    setHistory(
      // @ts-ignore
      groupedByMonths.events.find((item) => item.date === activeMonth)?.data ||
        []
    );
  }, [activeMonth, groupedByMonths.events]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Pressable
        onPress={() => {
          navigation.goBack();
        }}
        style={styles.backdrop}
      />
      <View style={styles.modal}>
        <View style={styles.wrapper}>
          <Text style={styles.title}>Visit History</Text>

          <View style={{ overflow: "hidden", marginHorizontal: 10 }}>
            <View style={[styles.mt, { marginLeft: "-70%" }]}>
              <MonthCarousel
                months={convertedMonths}
                activeMonth={activeMonth}
                onChange={setActiveMonth}
              />
            </View>
          </View>
          <View
            style={{
              marginTop: 10,
              flex: 1,
            }}
          >
            <FlatList
              data={history}
              style={styles.flatlist}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index }) => {
                const isActive = index + 1 === activeItem;
                return (
                  <VisitsLineChart
                    isActive={isActive}
                    points={item.points}
                    pointDetailsRenderItem={pointDetailsRenderItem}
                    setActive={() => {
                      setActiveItem(index + 1);
                    }}
                    leftTitle={format(item.date, DayMonthFormat)}
                    rightTitle={`${item.points.length} ${groupedByMonths.label}`}
                  />
                );
              }}
              keyExtractor={(item) => item?.date.toString()}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    paddingRight: 24,
  },
  title: {
    textAlign: "center",
    color: colors.placeholderGrey.color,
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 20,
  },
  flatlist: { paddingHorizontal: 24, height: "100%", flex: 1 },
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: "rgba(173,179,195,0.9)",
    flex: 1,
  },
  modal: {
    marginHorizontal: 12,
    borderRadius: 10,
    backgroundColor: "white",
    flex: 1,
    marginTop: 50,
    overflow: "hidden",
  },
  wrapper: { height: "100%", paddingVertical: 12 },
  mt: { marginTop: 40 },
});
