import { SpView } from '@atoms/SpView';
import { WINDOW_HEIGHT } from '@gorhom/bottom-sheet';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { ReactElement } from 'react';
import { Pressable, StyleSheet, ViewStyle } from 'react-native';

interface TabsProps {
  data: number[];
  renderItem: (data: number) => ReactElement;
  active: number;
  onChange: (data: number) => void;
  containerStyle?: ViewStyle;
}

export const Tabs = ({
  data = [],
  active,
  renderItem,
  onChange,
  containerStyle,
}: TabsProps) => {
  const itemWidth = data.length === 2 ? 120 : 80;

  return (
    <SpView style={[styles.container, containerStyle]}>
      <SpView style={styles.tabsWrap}>
        {data.map(item => {
          return (
            <Pressable
              style={[styles.item, { width: itemWidth }]}
              key={item}
              onPress={() => onChange(item)}
              {...testProperties(
                'button',
                `tabSwitcher${item}${active === item ? '_Active' : ''}`,
              )}
            >
              {renderItem(item)}
              {active === item ? <SpView style={styles.active} /> : null}
            </Pressable>
          );
        })}
      </SpView>
      <SpView style={styles.separator} />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white.color,
  },
  tabsWrap: {
    paddingHorizontal: 38,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: {
    paddingVertical: WINDOW_HEIGHT > 700 ? 16 : 8,
    position: 'relative',
    alignItems: 'center',
  },
  active: {
    position: 'absolute',
    width: '100%',
    height: 4,
    borderRadius: 3,
    bottom: -2,
    right: 0,
    zIndex: 999,
    backgroundColor: colors.greyText.color,
  },
  separator: {
    borderBottomColor: colors.greyBorder.color,
    borderBottomWidth: 1,
  },
});
