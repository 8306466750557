import * as Linking from 'expo-linking';

import { useEffect } from 'react';
import useBoundStore from 'src/store/store';

const useInAppMessage = () => {

    const {addMessage} = useBoundStore(state => state.notificationStore);

  const onReceiveURL = ({ url }: { url: string }) => {
    // if url include iam/ then its a deeplink, split and get everything after iam/ then open it
    if (url.includes("iam/")) {
      const splitUrl = url.split("/iam/");
      const newUrl = splitUrl[1];
      let title = "";
      // if splitUrl[0] contains /title/ then get the title for the deeplink
      if (splitUrl[0].includes("/iamtitle/")) {
        const splitTitle = splitUrl[0].split("/iamtitle/");
        title = splitTitle[1];
        addMessage(title, newUrl);
      }
      Linking.openURL(newUrl);
    }
  };
  useEffect(() => {
    const listenerInstance = Linking.addEventListener("url", onReceiveURL);
    return () => listenerInstance.remove();
  }, []);
};

export default useInAppMessage;
