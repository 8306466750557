import React, { PropsWithChildren, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { SpView, SpViewProps } from './SpView';

export const SpCenter = ({
  children,
  style = {},
  ...props
}: PropsWithChildren<SpViewProps>) => {
  const styles = useMemo(() => {
    return StyleSheet.compose(style, {
      alignItems: 'center',
      justifyContent: 'center',
    });
  }, [style]);

  return (
    <SpView {...props} style={styles}>
      {children}
    </SpView>
  );
};
