import { SpView } from '@atoms/SpView';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';
import DashSVG from '@atoms/SvgIcons/DashSVG';
import React from 'react';

const PetDataSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.imageWrapper}>
        <DashSVG
          color={colors.greyText.color}
          width={70}
          height={70}
          viewBox="0 0 50 30"
        />
      </SpView>
      <SpTitle align="center" text={t('pet_data_tour_title')} />
      <SpText style={styles.contentText}>{t('pet_data_tour_text')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40.2,
    marginTop: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 161,
    height: 80,
    backgroundColor: colors.primary.color,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    // elevation: 10,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    lineHeight: 24,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
});

export default PetDataSlide;
