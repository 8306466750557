import { Button, Dimensions, StyleSheet, TextInput, View } from 'react-native';
import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import { FormikProps } from 'formik';
import { FieldsObj } from '@models/FormikTypes';
import SpTitle from '@molecules/SpTitle';
import { SpInput } from '@atoms/SpInput';
import { useTranslation } from 'react-i18next';
import { emitContinueHandler } from '@molecules/StepFormCreator';
import { stepFormikValid } from '../../../utils/stepFormikValid';
import { testProperties } from '@utils/testProperties';

interface FormValues {
  firstName: string;
  surName: string;
}

interface INameFormProps {
  formik: FormikProps<FormValues>;
  submitErrors: FieldsObj;
  handleValidity?: (validity: boolean) => void;
  children?: React.ReactNode;
  fromRegister?: boolean;
}

const NameForm = ({
  formik,
  submitErrors,
  handleValidity,
  children,
  fromRegister = false,
}: INameFormProps) => {
  const surNameRef = useRef() as RefObject<TextInput>;
  const { t } = useTranslation();
  const firstNameError = useMemo(() => {
    return stepFormikValid('firstName', submitErrors, formik.errors);
  }, [formik.errors, submitErrors]);

  const surNameError = useMemo(() => {
    return stepFormikValid('surName', submitErrors, formik.errors);
  }, [formik.errors, submitErrors]);

  useEffect(() => {
    if (!handleValidity) return;
    if (!formik.touched.firstName && !formik.touched.surName) {
      handleValidity(false);
      return;
    }
    handleValidity(!(formik.errors.firstName || formik.errors.surName));
  }, [
    formik.errors,
    formik.errors,
    formik.touched.firstName,
    formik.touched.surName,
  ]);

  const registerFormHeight = Dimensions.get('screen').height - 200;
  
  return (
    <View
      style={
        fromRegister
          ? {
              height: registerFormHeight,
              flex: 1,
              justifyContent: 'space-between',
            }
          : { height: '100%' }
      }
      {...testProperties('formWrapper', 'Name')}
    >
      <View>
        <SpTitle text={t('register_name_title')} />
        <SpInput
          label={t('first_name')}
          value={formik?.values?.firstName || ''}
          onChangeText={text => {
            formik?.handleChange('firstName')(text);
            if (text)
              formik?.setTouched({ ...formik?.touched, firstName: true });
          }}
          error={t(firstNameError)}
          returnKeyType='next'
          onSubmitEditing={() => {
            surNameRef?.current?.focus();
          }}
          additionalTestLabelTail={t('first_name')}
        />

        <View
          {...testProperties('surname', 'wrapper')}
          style={styles.inputOffset}
        >
          <SpInput
            label={t('surname')}
            value={formik?.values?.surName || ''}
            onChangeText={text => {
              formik?.handleChange('surName')(text);
              if (text)
                formik?.setTouched({ ...formik?.touched, surName: true });
            }}
            error={t(surNameError)}
            ref={surNameRef}
            returnKeyType='done'
            onSubmitEditing={emitContinueHandler}
            additionalTestLabelTail={t('surname')}
          />
        </View>
      </View>
      {fromRegister && <View>{children}</View>}
    </View>
  );
};

export default NameForm;

const styles = StyleSheet.create({
  inputOffset: {
    // marginBottom: 28,
    // marginTop: 24,
    paddingTop: 24,
    paddingBottom: 28,
  },
});
