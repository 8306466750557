import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const DashSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={52} height={28} {...props}>
    <G fill="#FFF" fillRule="evenodd">
      <Path d="M42.5 9.623v8.845a1.333 1.333 0 0 0 2.245.973l4.717-4.423a1.333 1.333 0 0 0 0-1.945L44.745 8.65a1.333 1.333 0 0 0-2.245.973Z" />
      <G fillRule="nonzero" transform="translate(2 3)">
        <Rect width={6} height={11.87} y={9.13} rx={0.893} />
        <Rect width={6} height={16.304} x={18} y={4.696} rx={0.893} />
        <Rect width={6} height={21} x={9} rx={0.893} />
      </G>
    </G>
  </Svg>
);
export default DashSVG;
