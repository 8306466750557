import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { DeviceModel } from '@models/Device';
import { SpDeviceBattery } from '@molecules/SpDeviceBattery';
import { SpDeviceConnection } from '@molecules/SpDeviceConnection';
import { SpDeviceOffline } from '@molecules/SpDeviceOffline';
import { SpProductCardImage } from '@molecules/SpProductCardImage';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { StyleSheet } from 'react-native';

interface DeviceCardProps {
  data: DeviceModel;
  isPetFlow?: boolean;
}

export const DeviceCard = ({ data, isPetFlow }: DeviceCardProps) => {
  const isOnline = data?.status.online;
  return (
    <SpView
      {...testProperties(data.name, 'view', 'DeviceCard')}
      style={styles.container}
    >
      <SpProductCardImage data={data.product_id} height={60} width={60} />
      <SpView style={styles.content} marginLeft={4}>
        {isPetFlow ? (
          <SpText style={styles.name} size="xl">
            {data.name || data.serial_number || data.mac_address}
          </SpText>
        ) : (
          <>
            <SpText style={styles.name} size="xl">
              {data.name || data.serial_number || data.mac_address}
            </SpText>

            {isOnline ? (
              <SpView flexDirection="row" alignItems="center" marginTop={6}>
                <SpDeviceConnection data={data?.status?.signal?.device_rssi} />
                <SpView marginLeft={8}>
                  <SpDeviceBattery
                    type={data.product_id}
                    battery={data?.status.battery}
                  />
                </SpView>
              </SpView>
            ) : (
              <SpDeviceOffline deviceName={data.name} useImage={false} />
            )}
          </>
        )}
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    borderRadius: 24,
    backgroundColor: colors.white.color,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 12,
  },
  name: {
    color: colors.greyText.color,
    fontSize: 20,
    fontFamily: 'Rubik_SemiBold',
  },
});
