import * as React from 'react';
import Svg, { Defs, LinearGradient, Stop, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const HubBrightSVG = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={88} height={66} {...props}>
    <Defs>
      <LinearGradient id="a" x1="50%" x2="21.794%" y1="-14.557%" y2="115.438%">
        <Stop offset="0%" stopColor="#C2EA97" />
        <Stop offset="100%" stopColor="#5ED07D" />
      </LinearGradient>
      <LinearGradient id="b" x1="50%" x2="21.794%" y1="-14.557%" y2="115.438%">
        <Stop offset="75%" stopColor="#91D944" />
        <Stop offset="100%" stopColor="#5ED07D" />
      </LinearGradient>
    </Defs>
    <G fill="none" fillRule="evenodd" stroke="#263A43">
      <G strokeWidth={3.88}>
        <Path
          fill="url(#a)"
          d="M44.97 25.537 41.007 3.995A1.94 1.94 0 0 1 43.84 1.94l19.922 10.829"
          transform="matrix(-1 0 0 1 114.306 13.223)"
        />
        <Path
          fill="url(#b)"
          d="M8.054 25.537 4.091 3.995A1.94 1.94 0 0 1 6.925 1.94l19.923 10.829"
          transform="translate(10.271 13.223)"
        />
        <Path
          fill="#FFF"
          d="M43.83 23.283c8.732 0 16.637 3.539 22.36 9.26 5.721 5.723 9.26 13.628 9.26 22.36v9.157H12.21v-9.158c0-8.731 3.54-16.636 9.262-22.358 5.722-5.722 13.627-9.261 22.359-9.261Z"
        />
      </G>
      <G strokeLinecap="round" strokeWidth={2.931}>
        <Path d="M69.776 6.348 69.14 2M63.36 9.423l-2.947-3.247M77.39 7.8l2.424-3.66M81.927 14.261 86 12.66M81.078 21.598l4.299.811" />
      </G>
      <G strokeLinecap="round" strokeWidth={2.931}>
        <Path d="M18.224 6.348 18.86 2M24.64 9.423l2.947-3.247M10.61 7.8 8.185 4.14M6.073 14.261 2 12.66M6.922 21.598l-4.299.811" />
      </G>
    </G>
  </Svg>
);
export default HubBrightSVG;
