import { SpText } from '@atoms/SpText';
import SpTransformStringToMultipleTexts from '@atoms/SpTransformStringToMultipleTexts';
import { SpView } from '@atoms/SpView';
import { PetStatisticsModel } from '@models/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import atoms from '@styles/atoms';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import DropShadow from 'react-native-drop-shadow';

import { PetStackParamList } from '../../Pets';
import { ReportType } from '../../constants/ReportType';
import { ReportTabs } from '../../hooks/useReportTabs';
import { useSummaryDrinkingCard } from '../../hooks/useSummaryDrinkingCard';
import { useSummaryFeedingCard } from '../../hooks/useSummaryFeedingCard';
import { useSummaryMovementCard } from '../../hooks/useSummaryMovementCard';
import useTotalConsumption from '../../hooks/useTotalConsumption';
import PetDrinkingNotification from '../PetDrinkingNotification';
import { PetMovementNotification } from '../PetMovementNotification';

interface PetDashboardCardCarouselProps {
  activeTab: ReportType;
  petId: number;
  isNotAssigned: boolean;
  isAtThisDay: boolean;
  petStatistic: PetStatisticsModel;
}

const Card = ({
  activeTab,
  isNotAssigned,
  petId,
  isAtThisDay,
  petStatistic,
}: PetDashboardCardCarouselProps) => {
  const { t } = useTranslation();

  const drinking = useSummaryDrinkingCard(petStatistic?.drinking);
  const feeding = useSummaryFeedingCard(petStatistic?.feeding);
  const movement = useSummaryMovementCard(petStatistic?.movement);
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();

  const onReportsPress = useCallback(
    (tab: ReportTabs) => {
      navigation.navigate('PetDashboardReport', { type: activeTab, tab });
    },
    [activeTab],
  );

  const cartData = useMemo(() => {
    if (activeTab === ReportType.Drinking) {
      return drinking;
    }

    if (activeTab === ReportType.Feeding) {
      return feeding;
    }

    if (activeTab === ReportType.Movement) {
      return movement;
    }

    return {
      title: '',
      timeAgo: '',
      time: '',
      leftLabel: '0',
      leftValue: t('no_activity_yet'),
      rightLabel: '0',
      rightValue: t('no_activity_yet'),
    };
  }, [drinking, feeding, movement, activeTab]);

  const notification = useMemo(() => {
    if (activeTab === ReportType.Drinking) {
      return (
        <>
          <DrinkingFeedingInfo time={petStatistic?.drinking?.consumption_time || 0} />
          {isAtThisDay && (
            <PetDrinkingNotification
              description={t('drinking_normal')}
              isWarning
              disabledInsightStyle={atoms.disabledInsight}
            />
          )}
        </>
      );
    }

    if (activeTab === ReportType.Feeding) {
      // All meal related design items postponed.
      // if (activeTab === ReportType.Feeding) {
      //   return <PetMealsGivenNotification mealsGiven={1} />;
      // }
      return <DrinkingFeedingInfo time={petStatistic?.feeding?.consumption_time || 0} />;
    }

    if (activeTab === ReportType.Movement && isAtThisDay) {
      return (
        <PetMovementNotification
          locationSince={petStatistic?.movement?.since}
          location={petStatistic?.movement?.where}
        />
      );
    }

    return null;
  }, [activeTab, petStatistic, isAtThisDay]);

  const formattedTimeAgo = useMemo(() => {
    if (!cartData || !cartData.timeAgo) {
      return null;
    }
    const shortedTimeAgo = cartData.timeAgo
      .replace('hours', t('hours_unit_1'))
      .replace('minutes', t('minutes_unit_2'));
    return shortedTimeAgo.split(' ').map((item, i) => {
      const style = i === 0 ? styles.formattedTimeAgoFirst : styles.formattedTimeAgoOther;
      return (
        <SpText key={item + i} style={style}>
          {item}
        </SpText>
      );
    });
  }, [cartData]);

  const formattedLeftLabel = useMemo(() => {
    if (cartData) {
      const parts = cartData.leftLabel.split(' ').filter(Boolean);

      return (
        <SpTransformStringToMultipleTexts
          data={parts}
          offsetBetween={5}
          ignoreOpacity
          mainStyle={styles.cartLabel}
          prefixStyle={styles.leftLabelOther}
        />
      );
    }

    return null;
  }, [cartData]);

  const isCardsInRow = !(!isAtThisDay && activeTab === ReportType.Movement);

  return (
    <SpView style={styles.container}>
      {notification && <SpView style={styles.notificationWrap}>{notification}</SpView>}
      {isAtThisDay && (
        <SpView style={styles.textData}>
          <SpText color={colors.greyText.color} size="md">
            {cartData?.title || ''}
          </SpText>
          <SpView style={styles.timeAgo}>
            {formattedTimeAgo ? (
              <>
                <SpView flexDirection="row">{formattedTimeAgo}</SpView>
                <SpText style={styles.timeAgoTime} size="md" color={colors.greyOpacity.color}>
                  {cartData.time}
                </SpText>
              </>
            ) : (
              <SpView paddingVertical={20}>
                <SpText size="xxl" fontFamily="Rubik_Medium">
                  {t('no_activity_yet')}
                </SpText>
              </SpView>
            )}
          </SpView>
        </SpView>
      )}

      <SpView style={[styles.summary, isCardsInRow && styles.summaryRow]}>
        <TouchableOpacity
          style={isCardsInRow ? styles.cardRowContent : styles.cardColumnContent}
          disabled={isNotAssigned}
          onPress={() => onReportsPress(ReportTabs.Summary)}>
          <DropShadow style={styles.cardShadow}>
            <SpView style={styles.cart}>
              {formattedLeftLabel}
              <SpText
                style={[styles.greyColor, { fontSize: 16 }]}
                numberOfLines={1}
                adjustsFontSizeToFit>
                {cartData.leftValue}
              </SpText>
            </SpView>
          </DropShadow>
        </TouchableOpacity>
        <TouchableOpacity
          style={isCardsInRow ? styles.cardRowContent : styles.cardColumnContent}
          disabled={isNotAssigned}
          onPress={() => onReportsPress(ReportTabs.Detail)}>
          <DropShadow style={styles.cardShadow}>
            <SpView style={styles.cart}>
              <SpText style={[styles.cartLabel, { fontSize: 28 }]}>{cartData.rightLabel}</SpText>
              <SpText
                style={[styles.greyColor, { fontSize: 16 }]}
                numberOfLines={1}
                adjustsFontSizeToFit>
                {cartData.rightValue}
              </SpText>
            </SpView>
          </DropShadow>
        </TouchableOpacity>
      </SpView>
    </SpView>
  );
};
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: windowHeight < 810 ? 7 : 15,
  },
  notificationWrap: {
    width: '100%',
  },
  timeAgo: {
    marginTop: windowHeight < 810 ? '1%' : 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  formattedTimeAgoFirst: {
    fontSize: 24,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
  },
  formattedTimeAgoOther: {
    marginLeft: 4,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
  },
  timeAgoTime: {
    marginLeft: 8,
  },
  summaryRow: {
    flexDirection: 'row',
  },
  summaryColumn: {
    flexDirection: 'column',
  },
  summary: {
    width: '100%',
    gap: windowHeight < 810 ? 10 : 32,
    justifyContent: 'space-between',
  },
  cart: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: windowHeight < 810 ? '3%' : 18,
    paddingBottom: windowHeight < 810 ? '3%' : 18,
    paddingHorizontal: 0,
    borderRadius: 16,
    width: '100%',
    backgroundColor: colors.white.color,
    flexGrow: 1,
  },
  cardRowContent: {
    flexBasis: '44%',
    flexShrink: 0,
    flexDirection: 'column',
  },
  cardColumnContent: {
    flexBasis: '100%',
  },
  cardShadow: {
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    flexGrow: 1,
    flexDirection: 'column',
  },
  cartLabel: {
    fontSize: 28,
    lineHeight: 28,
    fontFamily: 'Rubik_SemiBold',
  },
  leftLabelOther: {
    fontSize: 20,
    lineHeight: 28,
    fontFamily: 'Rubik_SemiBold',
  },
  drinkingNormal: {
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(45, 181, 142, 0.2)',
    borderRadius: 16,
    paddingVertical: 20,
    paddingLeft: 16,
  },
  drinkingContainer: {
    padding: 13,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(38, 58, 67, 0.1)',
    width: '90%',
    height: 80,
    borderRadius: 16,
    alignSelf: 'center',
  },
  smallDrinkingContainer: {
    height: '100%',
    justifyContent: 'space-between',
    gap: 2,
    alignItems: 'center',
  },
  textContainer: {
    flexDirection: 'row',
  },
  bigText: {
    fontSize: 28,
    textAlign: undefined,
    marginLeft: 3,
  },
  smallText: {
    fontSize: 20,
    textAlign: undefined,
    marginLeft: 3,
  },
  textData: {
    alignItems: 'center',
    gap: 5,
  },
  greyColor: {
    color: colors.greyOpacity.color,
    lineHeight: 20,
  },
});

export default Card;

const DrinkingFeedingInfo: React.FC<{ time: number }> = ({ time }) => {
  const { t } = useTranslation();
  const data = useTotalConsumption(time);

  const separated = useMemo(() => {
    return data.split(' ').map((text, ind) => {
      if (ind % 2 === 0) {
        return (
          <SpText style={[textStyles.title, styles.bigText]} key={text}>
            {text}
          </SpText>
        );
      }
      return (
        <SpText style={[textStyles.title, styles.smallText]} key={text}>
          {text}
        </SpText>
      );
    });
  }, [data]);

  return (
    <SpView style={styles.drinkingContainer}>
      <SpView style={styles.smallDrinkingContainer}>
        <SpView style={styles.textContainer}>{separated}</SpView>
        <SpText style={textStyles.defaultText}>{t('time_at_bowl')}</SpText>
      </SpView>
    </SpView>
  );
};
