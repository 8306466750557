import { StyleSheet, Text, View } from 'react-native';
import React, { useMemo } from 'react';
import { SpView } from '@atoms/SpView';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useWeightConversion } from '@hooks/useWeightConversion';
import useBoundStore from 'src/store/store';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';

type PetInfoSectionProps = {
  icon?: any;
  svgIcon?: any;
  title: string;
  avarageMl?: number;
  data: string;
  fontSizeForNumbers?: number;
  fontSizeForText?: number;
  timeMarker?: string;
  type?: 'count' | 'time' | string | undefined;
  disablePadding?: boolean;
  rightSideFlexEnd?: boolean;
};

const PetInfoSection = ({
  icon,
  title,
  data,
  fontSizeForNumbers = 24,
  fontSizeForText = 16,
  avarageMl,
  timeMarker,
  type,
  svgIcon,
  disablePadding = false,
  rightSideFlexEnd = false
}: PetInfoSectionProps) => {
  const { t } = useTranslation();
  const [sizes, layoutHandler] = useAdaptiveFontSize(
    {
      numbers: fontSizeForNumbers,
      text: fontSizeForText,
    },
    [data, type],
  );
  const { user } = useBoundStore(state => state.accountStore);

  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode,
  );
  const formattedData = useMemo(() => {
    let splitData;
    if (data === '-') {
      splitData = [data];
    } else if (type === 'count' || type === 'time') {
      splitData = data?.split(' ');
    } else {
      const value = data?.split(' ')[0];
      const convertedData = convertWithUnits(
        Number(value),
        type === 'weight',
        false,
      );
      splitData = convertedData?.split(' ');
    }

    return splitData.map((item, index) => {
      if (Number.isNaN(Number(item))) {
        return (
          <React.Fragment key={`${item}${index}prefix`}>
            <Text
              style={[styles.data, { fontSize: sizes.text, lineHeight: size }]}
            >
              {item}
            </Text>
            <SpView width={5} />
          </React.Fragment>
        );
      }
      const size = item === '-' ? 30 : sizes.numbers;
      return (
        <React.Fragment key={`${item}${index}main`}>
          <SpText style={[styles.data, { fontSize: size, lineHeight: size }]}>
            {item}
          </SpText>
          <SpView width={5} />
        </React.Fragment>
      );
    });
  }, [data, user.weight_units, sizes]);

  const isOnlyText = avarageMl === undefined && timeMarker === undefined;

  return (
    <SpView flexDirection="row">
      <SpView flexDirection="row" width="100%" paddingVertical={disablePadding ? 0 : 10} alignItems={rightSideFlexEnd ? 'flex-end' : 'center'} >
        <SpView width={isOnlyText ? '100%' : undefined}>
          <SpView marginBottom={5} flexDirection="row" alignItems="center">
            <View {...testProperties(icon?.iconName, 'icon')}>
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  size={16}
                  color={colors.primary.color}
                />
              )}
              {svgIcon && svgIcon}
            </View>
            <SpText style={styles.title} numberOfLines={2}>{title}</SpText>
          </SpView>
          <SpView
            width={isOnlyText ? undefined : 180}
            style={{ flexWrap: 'wrap' }}
            alignItems="flex-end"
            flexDirection="row"
          >
            <Text onTextLayout={layoutHandler}>
              {formattedData}
            </Text>
          </SpView>
        </SpView>

        {avarageMl === 0 || avarageMl ? (
          <SpView marginLeft="auto" justifyContent='space-between'>
            <SpText align="right" style={styles.avarageStyles}>
              {convertWithUnits(avarageMl)}
            </SpText>
            <SpText align="right" style={styles.avarageStyles}>
              {t('7_days_avg')}
            </SpText>
          </SpView>
        ) : null}

        {timeMarker && (
          <SpView marginLeft="auto" maxWidth={90} justifyContent="flex-end">
            <SpText align="right" style={styles.avarageStyles}>
              {timeMarker}
            </SpText>
          </SpView>
        )}
      </SpView>
    </SpView>
  );
};

export default PetInfoSection;

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    marginLeft: 5,
    maxWidth: '90%',
  },
  data: {
    color: colors.greyText.color,
    textTransform: 'lowercase',
    fontSize: 24,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
  },
  avarageStylesContainer: {
    marginVertical: 20,
  },
  avarageStyles: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyOpacity.color,
  },
});
