import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { AppImages } from '@constants/AppImages';
import { SpCarouselWithSteps } from '@molecules/CarouselWithSteps';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ImageSourcePropType, StyleSheet } from 'react-native';

const HEIGHT = Dimensions.get('screen').height;
const IMAGE_HEIGHT = HEIGHT * 0.22;
export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = 280;

interface FirstFillProps {
  enableFirstFillContinue: (data: number) => void;
  setNumberItems: (data: number) => void;
}

type FirstFillCarouselItem = {
  text: string;
  image: ImageSourcePropType;
  testId:string;
};

export const FirstFill = ({
  enableFirstFillContinue,
  setNumberItems,
}: FirstFillProps) => {
  const { t } = useTranslation();

  const items = useMemo<FirstFillCarouselItem[]>(() => {
    return [
      {
        text: t('setup_felaqua_connect_instruction_5'),
        image: AppImages.felaquaFirstFill1,
        testId: 'RemoveTheReservoirBy',
      },
      {
        text: t('setup_felaqua_connect_instruction_6'),
        image: AppImages.felaquaFirstFill2,
        testId: 'TurnTheReservoirOver',
      },
      {
        text: t('setup_felaqua_connect_instruction_7'),
        image: AppImages.felaquaFirstFill3,
        testId: 'FillTheReservoirWith',
      },
      {
        text: t('setup_felaqua_connect_instruction_8'),
        image: AppImages.felaquaFirstFill4,
        testId: 'ReplaceTheReservoirMaking',
      },
      {
        text: t('setup_felaqua_connect_instruction_9'),
        image: AppImages.felaquaFirstFill5,
        testId: 'WaterShouldFlowInto',
      },
    ];
  }, []);

  setNumberItems(items.length);

  const renderCartItem = useCallback(
    ({ item }: { item: FirstFillCarouselItem }) => {
      return (
        <SpView accessible style={styles.item}>
          <Image
            style={{
              width: Math.min((IMAGE_HEIGHT * 4) / 5, 160),
              height: Math.min(IMAGE_HEIGHT, 200),
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            source={item.image}
            resizeMode="contain"
            {...testProperties(item.testId, 'Image')}
          />
          <SpText
            style={styles.text}
            size="md"
            {...testProperties(item.testId, 'Text')}
          >
            {item.text}
          </SpText>
        </SpView>
      );
    },
    [],
  );

  return (
    <SpView style={styles.container}>
      <SpText
        size="xxl"
        style={styles.title}
        {...testProperties('FirstFill', 'Text')}
      >
        {t('first_fill')}
      </SpText>
      <SpCarouselWithSteps
        renderItem={renderCartItem}
        data={items}
        sliderWidth={SLIDER_WIDTH}
        slideWidth={ITEM_WIDTH}
        onLastTemplate={enableFirstFillContinue}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '80%',
  },
  imageWrap: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: colors.black.color,
    marginVertical: 24,
    fontFamily: 'Rubik_SemiBold',
  },
  text: {
    color: colors.black.color,
    textAlign: 'center',
    marginTop: 16,
  },
  item: {
    flex: 1,
    padding: 20,
    paddingVertical: HEIGHT < 720 ? 10 : 20,
    borderRadius: 16,
    minHeight: Math.min(HEIGHT * 0.42, 325),
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
  },
});
