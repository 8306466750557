import GraphLinesMock from '../GraphLinesMock/GraphLinesMock';
import mockLine from '../GraphLinesMock/mockLineData';
import { AppImages } from '@constants/AppImages';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import colors from '@styles/colors';
import TourSwipeSVG from '@atoms/SvgIcons/TourSwipeSVG';
import { ScrollView } from 'react-native-gesture-handler';

const AmountDrunkGraphsSlide = () => {
  const { t } = useTranslation();
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <GraphLinesMock mockLine={mockLine} iconSource={AppImages.drinkingIcon} />
      <SpTitle align="center" text={t('amount_drunk_graphs')} />
      <SpText style={styles.contentText}>
        {t('amount_drunk_graphs_tour_text')}
      </SpText>
      <View style={styles.iconWrapper}>
        <TourSwipeSVG />
      </View>
      <SpText style={[styles.contentText, styles.blueText]}>
        {t('amount_drunk_graphs_tour_text2')}
      </SpText>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  blueText: {
    color: '#03A2B1',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    fontFamily: 'Rubik_SemiBold',
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export default AmountDrunkGraphsSlide;