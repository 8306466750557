import { TimeUnits } from '@constants/DateFormat';
import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useUserTimeUnits = (): TimeUnits => {
  const user = useBoundStore(s => s.accountStore.user);

  return useMemo(() => {
    return user?.time_format || TimeUnits.ISO;
  }, [user]);
};
