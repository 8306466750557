import { SpDivider } from '@atoms/SpDivider';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpText } from '@atoms/SpText';
import { HouseholdInviteModel } from '@models/HouseholdInvite';
import { HouseholdUserModel } from '@models/HouseholdUser';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { userPermissionLevelDefiner } from '@utils/permissionLevelDefiner';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RefreshControl,
  SafeAreaView,
  SectionList,
  StyleSheet,
  View,
} from 'react-native';
import useBoundStore from 'src/store/store';
import text from '@styles/text';
import { useCustomToast } from '@hooks/useToast';
import { HouseholdStackParamList } from '..';
import HouseholdUserThumbnail, {
  HouseHoldUsersProps,
} from '../molecules/HouseholdUserThumbnail';

const HouseholdUsers = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  const { show } = useCustomToast();
  const [refreshing, setRefreshing] = useState(false);

  const { activeHousehold, loadingHouseholds, getHouseholds } = useBoundStore(
    state => state.householdStore,
  );
  const { reset, successResendingInvite, errorResendingInvite } = useBoundStore(
    state => ({
      reset: state.householdInviteStore.reset,
      successResendingInvite: state.householdInviteStore.successResendingInvite,
      errorResendingInvite: state.householdInviteStore.errorResendingInvite,
    }),
  );
  const activeUser = useBoundStore(state => state.accountStore.user);
  const sectionArr = useMemo(() => {
    const usersInHousehold = (activeHousehold?.users || []).filter(item => {
      return item.id !== activeUser.id;
    });

    return [
      {
        title: '',
        data: usersInHousehold.map((user: HouseholdUserModel) => {
          return {
            name: user.user.name, // in userModel we have first_name and second_name
            permissionLevelId: userPermissionLevelDefiner(
              user.owner,
              user.write,
            ),
            img: user.user.photo?.location,
            role: 'user',
            // email: user.user.email_address
            email: '', // will add this when the backend is fixed
            id: user.id,
          } as HouseHoldUsersProps;
        }),
      },
      {
        title: t('invited_users'),
        data: activeHousehold?.invites?.reduce(
          (acc: HouseHoldUsersProps[], invite: HouseholdInviteModel) => {
            if (invite.status) {
              return acc;
            }

            acc.push({
              name: '',
              permissionLevelId: userPermissionLevelDefiner(
                invite.owner,
                invite.write,
              ),
              role: 'invite',
              email: invite.email_address,
              id: invite.id,
            });
            return acc;
          },
          [],
        ),
      },
    ];
  }, [activeHousehold, activeUser]);

  const renderItem = useCallback(({ item }: { item: HouseHoldUsersProps }) => {
    return <HouseholdUserThumbnail {...item} />;
  }, []);

  const renderSectionHeader = useCallback(
    ({
      section,
    }: {
      section: { title: string; data: HouseholdUserModel[] };
    }) => {
      if (!section.title || section.data.length === 0) return null;
      return (
        <SpText style={[text.title, styles.titlePadding]}>
          {section.title}
        </SpText>
      );
    },
    [],
  );

  const renderSectionFooter = useCallback(
    ({ section }: { section: { data: HouseholdUserModel[] } }) => {
      if (section.data.length === 0) return null;
      return <SpDivider />;
    },
    [],
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getHouseholds();
  }, [getHouseholds]);

  useEffect(() => {
    if (!loadingHouseholds) {
      setRefreshing(false);
    }
  }, [loadingHouseholds]);

  useEffect(() => {
    if (successResendingInvite) {
      show({
        description: t('success_resending_invite'),
        isError: false,
      });
      reset();
    }
    if (errorResendingInvite) {
      show({
        description: t('error_resending_invite'),
        isError: true,
      });
      reset();
    }
  }, [errorResendingInvite, successResendingInvite]);

  const button = (
    <SpRoundedHeaderButton
      onPress={() => navigation.navigate('HouseholdSendInvite')}
      title={t('invite_new_user')}
      backgroundColor={colors.primary.color}
      h={56}
      isLoading={loadingHouseholds && !refreshing}
      disabled={loadingHouseholds}
    />
  );

  const areNoUsersAndInvites =
    sectionArr[0].data.length === 0 && sectionArr[1].data.length === 0;
  return (
    <SafeAreaView style={styles.container} testID="HouseholdUsers_SafeAreaView">
      {areNoUsersAndInvites ? (
        <View
          style={styles.buttonWrapper}
          {...testProperties('view', 'nothingInHousehold')}
        >
          <SpText style={[text.defaultText, styles.nothingText]}>
            {t('no_users_and_invites')}
          </SpText>
          {button}
        </View>
      ) : (
        <>
          <SectionList
            refreshControl={
              <RefreshControl refreshing={false} onRefresh={onRefresh} />
            }
            renderSectionHeader={renderSectionHeader}
            renderSectionFooter={renderSectionFooter}
            testID="HouseholdUsers_SectionList"
            stickySectionHeadersEnabled={false}
            style={styles.sectionList}
            sections={sectionArr as any}
            keyExtractor={(item, index) =>
              item.name ? item.name + index : item.email + index
            }
            renderItem={renderItem}
          />
          <View style={styles.buttonWrapper}>{button}</View>
        </>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  sectionList: {
    paddingHorizontal: 19,
    paddingTop: 24,
  },
  titlePadding: {
    paddingTop: 47,
    marginBottom: 24,
    textAlign: 'left',
  },
  buttonWrapper: {
    paddingHorizontal: 19,
    paddingTop: 10,
    paddingBottom: 34,
  },
  nothingText: {
    textAlign: 'center',
    marginVertical: 23,
  },
});

export default HouseholdUsers;
