import React, { PropsWithChildren } from 'react';
import { SpText, SpTextProps } from './SpText';

export const SpErrorText = ({
  children,
  ...props
}: PropsWithChildren<SpTextProps>) => (
  <SpText {...props} color="red">
    {children}
  </SpText>
);
