import { Pressable, StyleSheet, View } from 'react-native';
import React, { ReactNode } from 'react';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { testProperties } from '@utils/testProperties';
import { SpView } from './SpView';
import { SpText } from './SpText';

type ISpHouseholdsSelect = {
  text: string;
  icon?: ReactNode;
  isActive: boolean;
  mainCard?: boolean;
  onPress?: (optionId: number) => void;
  marginTop?: number;
  option: {
    id: number;
    title: string;
  };
};

const SpHouseholdsSelect = ({
  text,
  icon,
  isActive,
  mainCard = false,
  onPress,
  marginTop,
  option,
}: ISpHouseholdsSelect) => {
  return (
    <Pressable
      onPress={() => onPress(option.id)}
      style={[
        styles.container,
        icon && styles.containerWithIcon,
        mainCard
          ? styles.mainCardHorizontalPadding
          : styles.defaultCardHorizontalPadding,
        isActive && styles.activeContainer,
        { marginTop },
      ]}
      {...testProperties(option.title, 'select button')}
      accessible={false}
    >
      {icon && <SpView style={styles.iconContainer}>{icon}</SpView>}
      {isActive && (
        <SpView style={styles.activeIconContainer}>
          <View {...testProperties('faCheckCircle', `icon_${option.title}`)}>
            <FontAwesomeIcon
              size={36}
              icon={faCheckCircle}
              color={colors.primary.color}
            />
          </View>
        </SpView>
      )}
      <SpText style={[styles.title, mainCard && styles.titleSizeMain]}>
        {option.title}
      </SpText>
      <SpText style={[styles.text, !mainCard && styles.textDefault]}>
        {text}
      </SpText>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white.color,
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    paddingBottom: 16,
    paddingTop: 12,
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    zIndex: 1,
  },
  activeContainer: {
    borderColor: colors.primary.color,
    backgroundColor: colors.primaryBackgroundOpacity.color,
  },
  activeIconContainer: {
    position: 'absolute',
    right: -10,
    top: -10,
    zIndex: 2,
    backgroundColor: colors.white.color,
    borderRadius: 18,
  },
  mainCardHorizontalPadding: {
    paddingHorizontal: 20,
  },
  defaultCardHorizontalPadding: {
    paddingHorizontal: 40,
  },
  containerWithIcon: {
    paddingTop: 76,
  },
  iconContainer: {
    position: 'absolute',
    top: -62,
  },
  title: {
    fontFamily: 'Rubik_Medium',
    textAlign: 'center',
  },
  titleSizeMain: {
    fontSize: 20,
    lineHeight: 28,
  },
  text: {
    marginTop: 8,
    textAlign: 'center',
    fontFamily: 'Rubik',
  },
  textDefault: {
    fontSize: 14,
  },
});

export default SpHouseholdsSelect;
