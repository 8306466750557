export enum DeviceStatusIDs {
  Success = 0,
  ServerError = 1,
  DeviceTimeout = 2,
  DeviceError = 3,
  NoChange = 4,
  Pending = 5,
}

export enum DeviceType {
  Hub = 1,
  Repeater = 2,
  PetDoorConnect = 3,
  FeederConnect = 4,
  CatFlapConnect = 6,
  FelaquaConnect = 8,
  Cerberus = 32,
}

export enum DeviceHubLedMode {
  Off = 0,
  Normal = 1,
  Dim = 4,
  Success = 0x80,
}

export enum DeviceHubPairingMode {
  Off = 0,
  On = 2,
}

export enum PetDoorLockingMode {
  normal,
  inside_only,
  outside_only,
  both,
  curfew,
}

export enum DeviceDualScanLockingMode {
  Normal,
  InOnly,
  OutOnly,
  Both,
}

export enum DeviceDualScanLearnMode {
  NotLearning,
  Learning,
  SafeLearning,
}

export enum DeviceFeederTareType {
  Unknown,
  Left,
  Right,
  Both,
}

export enum DeviceFeederBowlType {
  Half = 4,
  Single = 1,
}

export enum PetBowlFoodType {
  wet = 1,
  dry = 2,
  both = 3,
}

export enum FeederFoodType {
  wet = 1,
  dry = 2,
  both = 3,
}

export enum SubstanceType {
  water = 1,
  food = 2,
}

export enum DeviceControlResponseStatus {
  OK = 0, // no error
  ServerError = 1, // something went wrong and the server didn't send the message before the 15 second timeout (the command may still be sent later)
  DeviceTimeout = 2, // the device did not respond within 15 seconds (the device may respond later)
  DeviceError = 3, // the device responded with an error that means the command failed (permanent failure, i.e. lid is closed while trying to zero)
  Same = 4, // the request would not change any data on the device
}

export enum CloseDelays {
  Slow = 20,
  Normal = 4,
  Fast = 0,
}

export enum WeightContext {
  PetOpened,
  PetClosed,
  IntruderClosed,
  DubiousClosed,
  UserOpened,
  UserClosed,
  UserZeroed,
}
