import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import i18 from '@utils/i18n';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { Image, View } from 'react-native';
import { openModal } from 'src/services/ModalBox';
import * as Linking from 'expo-linking';
import FocusSVG from '@atoms/SvgIcons/FocusSVG';
import { SetupInstructionItem } from '../Molecules/SetupInstruction';

export const getDeviceImage = (productId: DeviceType): JSX.Element => {
  switch (productId) {
    case DeviceType.PetDoorConnect:
      return (
        <Image
          style={{ width: 112, height: 120 }}
          source={AppImages.setupProductPetDoor}
          {...testProperties('setupProductPetDoor', 'image')}
        />
      );
    case DeviceType.CatFlapConnect:
      return (
        <Image
          style={{ width: 122, height: 132 }}
          source={AppImages.setupProductCatFlap}
          {...testProperties('setupProductCatFlap', 'image')}
        />
      );
    case DeviceType.FeederConnect:
      return (
        <Image
          style={{ width: 112, height: 120 }}
          source={AppImages.setupProductPetFeeder}
          {...testProperties('setupProductPetFeeder', 'image')}
        />
      );
    case DeviceType.FelaquaConnect:
      return (
        <Image
          style={{ width: 107, height: 120 }}
          source={AppImages.setupProductFelaqua}
          {...testProperties('setupProductFelaqua', 'image')}
        />
      );
    case DeviceType.Hub:
      return (
        <Image
          style={{ width: 196, height: 160 }}
          source={AppImages.hubLights}
          {...testProperties('hubLights', 'image')}
        />
      );
    default:
      return null;
  }
};

export const getInstructions = (
  productId: DeviceType,
): SetupInstructionItem[] => {
  if (productId === DeviceType.PetDoorConnect) {
    return [
      {
        icon: (
          <Image
            style={{ width: 43, height: 23 }}
            source={AppImages.batteryX4}
            {...testProperties('batteryX4', 'image')}
          />
        ),
        title: i18.t('fit_4_batteries_with_big'),
        link: i18.t('show_me_how_to_do_this'),
        linkAction: () =>
          Linking.openURL(i18.t('pet_door_batteries_youtube_url')),
      },
      {
        icon: (
          <Image
            style={{ width: 36, height: 33 }}
            source={AppImages.addPetIcon}
            {...testProperties('addPetIcon', 'image')}
          />
        ),
        title: i18.t('setup_pet_door_connect_instruction'),
        link: i18.t('how_do_i_add_pets'),
        linkAction: () => openModal('BeforeYouContinue_HowAddPets'),
      },
      {
        icon: (
          <View {...testProperties('petPlaceholder', 'image')}>
            <FocusSVG />
          </View>
        ),
        title: i18.t('setup_instruction_1'),
        description: i18.t('setup_instruction_2'),
      },
    ];
  }

  if (productId === DeviceType.CatFlapConnect) {
    return [
      {
        icon: (
          <Image
            style={{ width: 43, height: 23 }}
            source={AppImages.batteryX4}
            {...testProperties('batteryX4', 'image')}
          />
        ),
        title: i18.t('fit_4_batteries'),
        link: i18.t('show_me_how_to_do_this'),
        linkAction: () =>
          Linking.openURL(i18.t('cat_flap_batteries_youtube_url')),
      },
      {
        icon: (
          <Image
            style={{ width: 36, height: 33 }}
            source={AppImages.addPetIcon}
            {...testProperties('addPetIcon', 'image')}
          />
        ),
        title: i18.t('setup_cat_flap_connect_instruction'),
        link: i18.t('how_do_i_add_pets'),
        linkAction: () => openModal('BeforeYouContinue_HowAddPets'),
      },
      {
        icon: (
          <View>
            <FocusSVG {...testProperties('petPlaceholder', 'image')} />
          </View>
        ),
        title: i18.t('setup_instruction_1'),
        description: i18.t('setup_instruction_2'),
      },
    ];
  }

  if (productId === DeviceType.FeederConnect) {
    return [
      {
        icon: (
          <Image
            style={{ width: 43, height: 23 }}
            source={AppImages.batteryX4}
            {...testProperties('batteryX4', 'image')}
          />
        ),
        title: i18.t('fit_4_batteries_with_big'),
        link: i18.t('show_me_how_to_do_this'),
        linkAction: () =>
          Linking.openURL(i18.t('feeder_connect_batteries_youtube_url')),
      },
      {
        icon: (
          <View {...testProperties('petPlaceholder', 'image')}>
            <FocusSVG />
          </View>
        ),
        title: i18.t('setup_instruction_1'),
        description: i18.t('setup_instruction_2'),
      },
    ];
  }

  if (productId === DeviceType.FelaquaConnect) {
    return [
      {
        icon: (
          <Image
            style={{ width: 43, height: 23 }}
            source={AppImages.batteryX4}
            {...testProperties('batteryX4', 'image')}
          />
        ),
        title: i18.t('fit_4_batteries_with_big'),
        link: i18.t('show_me_how_to_do_this'),
        linkAction: () =>
          Linking.openURL(i18.t('felaqua_batteries_youtube_url')),
      },
      {
        icon: (
          <Image
            style={{ width: 30, height: 30 }}
            source={AppImages.focusIcon}
            {...testProperties('petPlaceholder', 'image')}
          />
        ),
        title: i18.t('setup_instruction_1'),
        description: i18.t('setup_instruction_2'),
      },
    ];
  }

  if (productId === DeviceType.Hub) {
    return [
      {
        icon: (
          <Image
            style={{ width: 40, height: 34 }}
            source={AppImages.hubSetup}
            resizeMode="stretch"
            {...testProperties('hubSetup', 'image')}
          />
        ),
        title: i18.t('setup_hub_instruction_5'),
      },
      {
        icon: (
          <Image
            style={{ width: 36, height: 36 }}
            resizeMode="contain"
            source={AppImages.hubWifi}
            {...testProperties('hubWifi', 'image')}
          />
        ),
        title: i18.t('setup_hub_instruction_6'),
      },
      {
        icon: (
          <Image
            style={{ width: 34, height: 34 }}
            source={AppImages.hubPencil}
            {...testProperties('hubPencil', 'image')}
          />
        ),
        title: i18.t('setup_hub_instruction_7'),
      },
    ];
  }

  return [];
};
