import { HouseholdModel } from '@models/Household';
import { ConfirmRemoveHouseholdDialogModes } from '../constants/ConfirmRemoveHouseholdDialogModes';

export const getConfirmRemoveHouseholdMode = (
  viewers: HouseholdModel[],
  notOriginallyCreated: HouseholdModel[],
): ConfirmRemoveHouseholdDialogModes => {
  if (viewers.length && notOriginallyCreated.length) {
    return ConfirmRemoveHouseholdDialogModes.NotOriginallyCreatedAndPermission;
  }

  return viewers.length
    ? ConfirmRemoveHouseholdDialogModes.Permission
    : ConfirmRemoveHouseholdDialogModes.NotOriginallyCreated;
};
