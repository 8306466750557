import { SpProgressBar } from '@atoms/SpProgressBar';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { FeederFoodType } from '@constants/Device';
import { DeviceFeederBowlSettingModel } from '@models/Device';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { BowlTypeSide } from './BowlTypeSide';
import colors from '@styles/colors';

interface BowlTypeProps {
  type: 'right' | 'left';
  data: DeviceFeederBowlSettingModel;
  percentage: number;
}

export const BowlType = ({ data, type, percentage }: BowlTypeProps) => {
  const { t } = useTranslation();

  const alignmentText = useMemo(() => {
    return type;
  }, [type]);

  const alignmentBowls = useMemo(() => {
    return type === 'left' ? 'flex-start' : 'flex-end';
  }, [type]);

  return (
    <SpView width='100%'>
      <SpView style={styles.bowlWrapper} testID='foodRemainingBlock'>
        <SpView
          marginTop={4}
          marginBottom={6}
          minHeight={32}
          alignItems={alignmentBowls}
        >
          <SpProgressBar
            value={percentage}
            width='80%'
            borderRadius={16}
            componentName='BowlType'
          />
        </SpView>
        <SpText
          size='xxl'
          style={styles.foodRemainingTargetText}
          fontFamily='Rubik_SemiBold'
          align={alignmentText}
          componentName={type}
        >
          {percentage}%
        </SpText>
      </SpView>
      <SpView flexDirection='row' justifyContent={alignmentBowls} marginTop={9}>
        <BowlTypeSide label={t(FeederFoodType[data.food_type])} type={type} />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  foodRemainingTargetText: {
    color: colors.greyText.color,
  },
  bowlWrapper: {
    minHeight: 72,
  },
});
