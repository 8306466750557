import React from 'react';
import colors from '@styles/colors';
import ZeroSVG from '@atoms/SvgIcons/ZeroSVG';
import { SpRoundIcon } from './SpRoundIcon';

interface SpHouseIconProps {
  action?: any;
}

export const SpZeroIcon = ({ action }: SpHouseIconProps) => (
  <SpRoundIcon
    icon={
      // <FontAwesomeIcon color={colors.greyText.color} size={20} icon={faHouse} />
      <ZeroSVG
        color={colors.greyText.color}
        width={20}
        height={20}
        viewBox="0 0 28 28"
      />
    }
    action={action}
    size={12}
    color={colors.secondary2.color}
    componentName="SpZeroIcon"
  />
);
