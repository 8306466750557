import AsyncStorage from '@react-native-async-storage/async-storage';

const persistStorage: {
  setItem: (key: string, data: unknown) => Promise<void>;
  getItem: (key: string) => Promise<string>;
  removeItem: (key: string) => Promise<void>;
  clearAll: () => Promise<void>;
} = {
  setItem: async (key: string, data: unknown) => {
    return await AsyncStorage.setItem(key, JSON.stringify(data));
  },
  getItem: async (key: string) => {
    const data = await AsyncStorage.getItem(key);
    return data && JSON.parse(data);
  },
  removeItem: async (key: string) => {
    await AsyncStorage.removeItem(key);
  },
  clearAll: async () => await AsyncStorage.clear(),
};

export default persistStorage;
