import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import WiFiDeviceAPI from '@api/WifiDeviceConnector';
// import { WiFiDeviceSlice } from '../models/WiFiDeviceSlice';
import { AvailableNetwork } from '@models/WiFiDevice';
import DeviceApi from '@api/DeviceApi';
import { MergedInterfaces } from '../models';
import { AnalyticsService } from '../../services/AnalyticsService';
import { DdLogs } from '../../services/SPDataDogService';

export interface WiFiDeviceSlice {
  WiFiDeviceStore: {
    error: unknown;
    success: unknown;
    loading: boolean;
    settingUpWiFiDevice: boolean;
    connectedToWiFiDevice: boolean;
    WiFiDeviceConnectedToNetwork: boolean;
    WiFiDeviceConnectedToInternet: boolean;
    WiFiDeviceConnectedToServer: boolean;

    deviceProduct: string;
    deviceSSIDS: AvailableNetwork[];
    targetNetworkID: number;
    targetNetworkPassword: string;
    targetPairingCode: string;
    targetProduct: string;
    timeout: number;

    setTimeoutForWiFiProduct: (timeout: number) => void;
    setSettingUpWiFiDevice: (settingUpWiFiDevice: boolean) => void;
    startSearchForProduct: (product: string, maxRetries?: number) => void;
    startCheckDeviceStatus: () => void;
    stopSearch: (clearErrors?: boolean) => void;
    searchForDevice: (lastAttempt?: boolean) => void;
    connectDeviceToWifi: () => void;
    checkDeviceStatus: (isLastAttempt?: boolean) => void;
    startScanDevice: () => void;
    getNetworks: (lastAttempt?: boolean) => void;

    setTargetNetworkID: (targetNetworkID: number) => void;
    setTargetNetworkPassword: (targetNetworkPassword: string) => void;
    setTargetPairingCode: (code?: string) => string;
    startPairDeviceToHousehold: () => void;
    pairDeviceToHousehold: (isLastAttempt: boolean) => void;

    pairedDeviceDeviceID: number;
    reset: () => void;
  };
}

const createWiFiDeviceSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  WiFiDeviceSlice
> = (set, get) => {
  return {
    WiFiDeviceStore: lens((subSet, subGet, api) => ({
      error: null,
      success: null,
      loading: false,
      settingUpWiFiDevice: false,
      connectedToWiFiDevice: false,
      WiFiDeviceConnectedToInternet: false,
      WiFiDeviceConnectedToNetwork: false,
      WiFiDeviceConnectedToServer: false,
      deviceProduct: '',
      deviceSSIDS: [],
      targetPairingCode: 'f23',
      targetProduct: '',
      timeout: 3000,
      targetNetworkID: 0,
      targetNetworkPassword: '',
      pairedDeviceDeviceID: 0,
      setTimeoutForWiFiProduct: timeout => {
        subSet({ timeout });
      },
      setSettingUpWiFiDevice: settingUpWiFiDevice => {
        AnalyticsService.logEvent('WiFiDeviceStore - setSettingUpWiFiDevice');

        subSet({ settingUpWiFiDevice });
      },
      startSearchForProduct: (product, maxRetries = 5) => {
        AnalyticsService.logEvent('WiFiDeviceStore - startSearchForProduct');

        subGet().stopSearch();

        subSet({
          connectedToWiFiDevice: false,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          targetProduct: product,
          deviceSSIDS: [],
          targetNetworkID: 0,
          targetNetworkPassword: '',
          pairedDeviceDeviceID: 0,
          targetPairingCode: '',
          error: null,
          success: null,
          loading: true,
        });
        get().RetryStore.doRetry(subGet().searchForDevice, maxRetries);
      },
      startCheckDeviceStatus: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - startCheckDeviceStatus');

        subGet().stopSearch();
        subSet({
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          error: null,
          success: null,
          loading: true,
        });
        get().RetryStore.doRetry(subGet().checkDeviceStatus);
      },

      stopSearch: (clearErrors = true) => {
        AnalyticsService.logEvent('WiFiDeviceStore - stopSearch');

        get().RetryStore.stopRetry();
        subSet(
          clearErrors ? { loading: false, error: null } : { loading: false },
        );
      },

      searchForDevice: async (lastAttempt: boolean) => {

        try {
          AnalyticsService.logEvent('WiFiDeviceStore - searchForDevice');
          const product = await WiFiDeviceAPI.getProduct(subGet().timeout);
          subSet({ deviceProduct: product.product });
          if (product.product === subGet().targetProduct) {
            subSet({ connectedToWiFiDevice: true });
            subGet().stopSearch(true);
          } else {
            subSet({ error: 'wrong product' });
          }
        } catch (err) {
          subSet({
            error: 'searchForDevice err',
            loading: !lastAttempt,
          });
          DdLogs.error('WiFiDeviceStore - searchForDevice - error', 'searchForDeviceError', JSON.stringify(err));

        }
      },

      checkDeviceStatus: async (lastAttempt: boolean) => {
        try {

          const status = await WiFiDeviceAPI.checkStatus();
          AnalyticsService.logEvent('WiFiDeviceStore - checkDeviceStatus', { status });

          // @ts-ignore
          subSet({
            WiFiDeviceConnectedToNetwork:
              status.wifi_status === 'connected' || status.wifi_status === true,
            WiFiDeviceConnectedToInternet:
              status.internet === 'Pass' ||
              status.internet === 'true' ||
              status.internet === true,
            WiFiDeviceConnectedToServer:
              status.spc_server_status === 'Pass' ||
              status.spc_server_status === 'true' ||
              status.spc_server_status === true,
          });
          if (
            status.spc_server_status === 'Pass' ||
            status.spc_server_status === 'true' ||
            status.spc_server_status === true
          ) {
            subGet().stopSearch();
            subSet({ loading: false });
          }
        } catch (err: any) {
          DdLogs.error('WiFiDeviceStore - checkDeviceStatus', 'checkDeviceStatusError', JSON.stringify(err));
          if (err.code === 'ERR_BAD_REQUEST' && !lastAttempt) {
            subSet({ error: 'checkDeviceStatusErr', loading: false });
            subGet().stopSearch(false);
            return;
          }
          if (err.code === 'ECONNABORTED' && !lastAttempt) {
            subSet({ error: 'ECONNABORTED' });
            return;
          }
          subSet({
            error:
              err.code === 'ECONNABORTED' ? err.code : 'checkDeviceStatusErr',
            loading: false,
          });
          subGet().stopSearch(false);
        }
      },
      startScanDevice: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - startScanDevice');
        subGet().stopSearch();
        subSet({
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          deviceSSIDS: [],
          targetNetworkID: 0,
          error: null,
          success: null,
          loading: true,
        });

        get().RetryStore.doRetry(subGet().getNetworks, 3);
      },
      getNetworks: async (lastAttempt = false) => {
        try {
          AnalyticsService.logEvent('WiFiDeviceStore - getNetworks');
          const networks = await WiFiDeviceAPI.scanForWiFi();
          subSet({
            deviceSSIDS: networks.map((network, index) => ({
              ...network,
              id: index,
              name: network.ssid,
            })),
            loading: false,
          });
          if (networks.length > 0) {
            AnalyticsService.logEvent('WiFiDeviceStore - getNetworks - response', { numberOfNetworks: networks.length });
            subGet().stopSearch();
          }
        } catch (err) {
          DdLogs.error('WiFiDeviceStore - getNetworks', 'getNetworksError', JSON.stringify(err));
          subSet({
            error: 'getNetworks err',
            loading: !lastAttempt,
          });
        }
      },
      connectDeviceToWifi: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - connectDeviceToWifi');
        subSet({
          loading: true,
          error: null,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
        });
        try {
          const done = WiFiDeviceAPI.connectToWiFi({
            ssid: subGet().deviceSSIDS[subGet().targetNetworkID].ssid,
            password: subGet().targetNetworkPassword,
            pairing_code: subGet().setTargetPairingCode(),
          });
          subSet({
            loading: false,
            error: null,
            targetNetworkPassword: null,
            success: true,
          });
          // subGet().startCheckDeviceStatus();
        } catch (err) {
          DdLogs.error('WiFiDeviceStore - connectDeviceToWifi', 'connectDeviceToWifiError', JSON.stringify(err));
          subSet({
            loading: false,
            error: 'connectDeviceToWifi err',
            targetNetworkPassword: null,
          });
        }
      },
      setTargetNetworkID: targetNetworkID => {
        AnalyticsService.logEvent('WiFiDeviceStore - setTargetNetworkID');
        subSet({ targetNetworkID });
      },
      setTargetNetworkPassword: targetNetworkPassword => {
        AnalyticsService.logEvent('WiFiDeviceStore - setTargetNetworkPassword');
        subSet({ targetNetworkPassword });
      },
      setTargetPairingCode: (code?: string) => {
        AnalyticsService.logEvent('WiFiDeviceStore - setTargetPairingCode');
        if (code !== null && code !== undefined) {
          subSet({ targetPairingCode: code });
          return code;
        }
        let hex = '';
        for (let i = 0; i < 32; i++) {
          hex += Math.floor(Math.random() * 16).toString(16);
        }
        subSet({ targetPairingCode: hex });
        return hex;
      },
      startPairDeviceToHousehold: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - startPairDeviceToHousehold');
        // subGet().stopSearch();
        subSet({
          error: null,
          success: null,
          loading: true,
        });
        get().RetryStore.doRetry(subGet().pairDeviceToHousehold, 10);
      },
      pairDeviceToHousehold: async (lastAttempt: boolean) => {
        AnalyticsService.logEvent('WiFiDeviceStore - pairDeviceToHousehold');
        if (!subGet().targetPairingCode) {
          DdLogs.error('WiFiDeviceStore - pairDeviceToHousehold - no code', 'pairDeviceToHouseholdError', 'No code');
          subSet({
            error: 'Pair Device To Household Error, no code',
          });
          subGet().stopSearch(false);

          //  subSet({loading: false})
          return;
        }
        subSet({
          loading: true,
          error: null,
        });
        try {
          const done = await DeviceApi.pairWithCode(
            subGet().targetPairingCode,
            get().householdStore.activeHousehold.id,
          );

          subSet({
            loading: false,
            error: null,
            targetPairingCode: null,
            success: true,
            pairedDeviceDeviceID: done.id,
          });
          subGet().stopSearch();
        } catch (err) {
          DdLogs.error('WiFiDeviceStore - pairDeviceToHousehold', 'pairDeviceToHouseholdError', JSON.stringify(err));
          subSet({
            loading: !lastAttempt,
            error: err.data?.error.error[0] || 'Pair Device To Household Error',
          });
        }
      },
      reset: () => {
        AnalyticsService.logEvent('WiFiDeviceStore - reset');
        subSet({
          error: null,
          success: null,
          loading: false,
          connectedToWiFiDevice: false,
          WiFiDeviceConnectedToInternet: false,
          WiFiDeviceConnectedToNetwork: false,
          WiFiDeviceConnectedToServer: false,
          deviceProduct: '',
          deviceSSIDS: [],
          targetPairingCode: '',
          targetProduct: '',
          targetNetworkID: 0,
          targetNetworkPassword: '',
          pairedDeviceDeviceID: 0,
        });
      },
    })),
  };
};
export default createWiFiDeviceSlice;
