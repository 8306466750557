import { StyleSheet } from 'react-native';
import React, { ReactNode } from 'react';
import colors from '@styles/colors';
import { SpText } from '@atoms/SpText';
import { useTranslation } from 'react-i18next';
import { SpView } from '@atoms/SpView';
import { testProperties } from '@utils/testProperties';

interface LightSettingProps {
  text: string;
  svgIcon: ReactNode;
}

const HubLightSetting = ({ text, svgIcon }: LightSettingProps) => {
  const { t } = useTranslation();
  return (
    <SpView style={[styles.container]}>
      {svgIcon && (
        <SpView {...testProperties(text, 'svgIcon')}>{svgIcon}</SpView>
      )}
      <SpText>{t('light_level')}</SpText>
      <SpText
        color={colors.greyText.color}
        fontFamily="Rubik_Medium"
        size="xl"
        bold
        style={{
          marginTop: 7,
        }}
      >
        {t(text)}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    alignItems: 'center',
    flex: 1,
    paddingTop: 12,
  },
  activeIconContainer: {
    position: 'absolute',
    right: -10,
    top: -10,
    zIndex: 2,
    backgroundColor: colors.white.color,
    borderRadius: 18,
  },
  image: {
    height: 44,
  },
  activeContainer: {
    borderColor: colors.primary.color,
    backgroundColor: colors.primaryBackgroundOpacity.color,
  },
});

export default HubLightSetting;
