import React, { useMemo } from 'react';
import { SpView } from '@atoms/SpView';
import { SpText } from '@atoms/SpText';
import { Dimensions, Image, View } from 'react-native';
import { SpButton } from '@atoms/SpButton';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import styles from './OnboardingLetsStart.styled';
import { RootStackParamList } from '..';

const OnboardingLetsStart = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const windowWidth = Dimensions.get('window').width;

  const concaveBoxStyles = useMemo(() => {
    const roundingsValue = 6;
    return {
      width: windowWidth * roundingsValue,
      height: windowWidth * roundingsValue,
      borderRadius: (windowWidth * roundingsValue) / 2,
      left: -((windowWidth * roundingsValue) / 2 - windowWidth / 2),
    };
  }, [windowWidth]);

  const handlePressLetsStart = () => {
    navigation.navigate('RegisterNavigation');
  };
  const handlePressLogin = () => {
    navigation.navigate('LoginNavigation');
  };

  return (
    <SpView style={styles.container}>
      <SpView style={styles.imageWrapper}>
        <SpView style={styles.topSection}>
          <View style={styles.backgroundImageWrapper}>
            <Image
              {...testProperties('background onboarding', 'photo')}
              source={AppImages.onboardingLetsStartBackground}
              resizeMode="cover"
              style={styles.backgroundImage}
            />
          </View>
          <SpView style={[styles.concaveBox, { ...concaveBoxStyles }]} />
          <SpView style={styles.logoCicleWrapper}>
            <Image
              {...testProperties('logo onboarding', 'photo')}
              source={AppImages.logoGrey}
              style={styles.logoImage}
              resizeMode="contain"
            />
          </SpView>
        </SpView>
        <SpView style={styles.titleWrapper}>
          <SpText fontFamily="Rubik_SemiBold" style={styles.titleText}>
            {t('connect_with_your_pet')}
          </SpText>
          <SpText
            fontFamily="Rubik_SemiBold"
            color={colors.primary.color}
            style={styles.descriptionText}
          >
            {t('award_winning_pet')}
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.bottomSectionWrapper}>
        <SpButton
          onPress={handlePressLetsStart}
          borderRadius={14}
          textStyle={styles.buttonTextLetsStart}
          style={{ marginBottom: 36 }}
          color={colors.primary.color}
          title={t('lets_start_button')}
        />
        <SpView style={styles.loginWrapper}>
          <SpText size="md" color="#263a4380">
            {t('already_have_an_account')}
          </SpText>
          <SpButton
            onPress={handlePressLogin}
            color={colors.greyText.color}
            borderRadius={10}
            paddingVertical={6}
            textStyle={styles.buttonLoginTextStyle}
            style={{ minWidth: 72 }}
            title={t('login')}
            fontFamily="Rubik_SemiBold"
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

export default OnboardingLetsStart;
