import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useUserCountryCode = (): string => {
  const user = useBoundStore(s => s.accountStore.user);

  return useMemo(() => {
    return user?.country?.code || null;
  }, [user]);
};
