import { useEffect, useState } from 'react';
import { DeviceStatusIDs } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import useBoundStore from '../store/store';

const useDeviceStatusParse = (deviceStatus: DeviceModel[]) => {
  const [prevState, setPrevState] = useState({});
  const setLoadingControl = useBoundStore(
    state => state.deviceStore.setLoadingControl,
  );
  const loadingControl = useBoundStore(
    state => state.deviceStore.loadingControl,
  );

  function mergeObjects(obj1, obj2) {
    const merged = { ...obj1 };

    for (const key in obj2) {
      if (
        Array.isArray(obj2[key]) &&
        obj2.hasOwnProperty(key) &&
        obj1.hasOwnProperty(key)
      ) {
        merged[key] = [...obj2[key], ...obj1[key]];
      }
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        merged[key] = obj2[key];
      }
    }

    return merged;
  }

  function deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  useEffect(() => {
    let stateObject = loadingControl;
    if (!Array.isArray(deviceStatus)) {
      return;
    }

    if (!deviceStatus) {
      setLoadingControl({});
      return;
    }

    try {
      const newArray: any[] = deviceStatus?.filter(
        (item: any) => item.status_id === DeviceStatusIDs.Pending,
      );

      if (newArray.length > 0) {
        newArray.forEach(item => {
          stateObject = mergeObjects(stateObject, item.state);
        });
        setPrevState(stateObject);
        setLoadingControl(stateObject);
      } else {
        if (deepEqual(prevState, loadingControl)) {
          setLoadingControl({});
          setPrevState({});
          return;
        }
        setPrevState(loadingControl);
      }
    } catch (e) {
      console.log('Device status undefined', e);
    }

    return () => {
      setLoadingControl({});
    };
  }, [deviceStatus]);
};

export default useDeviceStatusParse;
