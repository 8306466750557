import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import React, { useMemo } from 'react';
import { Image } from 'react-native';

interface SpDeviceConnectionProps {
  data?: number;
}

export const SpDeviceConnection = ({ data = 0 }: SpDeviceConnectionProps) => {
  const image = useMemo(() => {
    // TODO Andrykevych: will be implement in next task
    if (data >= -45) {
      return AppImages.connectionFull;
    }

    if (data > -86 && data < -45) {
      return AppImages.connectionMedium;
    }

    return AppImages.connectionLow;
  }, [data]);

  if (!image) {
    return null;
  }

  return (
    <Image
      style={{ height: 20, width: 20 }}
      source={image}
      resizeMode="contain"
      {...testProperties('connection', 'image')}
    />
  );
};
