import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import colors from '@styles/colors';
import * as React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import ProductInactiveSVG from '@atoms/SvgIcons/ProductInactiveSVG';
import PetInactiveSVG from '@atoms/SvgIcons/PetInactiveSVG';
import PetActiveSVG from '@atoms/SvgIcons/PetActiveSVG';
import ProductActiveSVG from '@atoms/SvgIcons/ProductActiveSVG';
import TimelineActiveSVG from '@atoms/SvgIcons/TimelineActiveSVG';
import TimelineInactiveSVG from '@atoms/SvgIcons/TimelineInactiveSVG';
import { Pets } from './Pets/Pets';
import { Products } from './Products/Products';
import { TimelineWrapper } from './components/TimelineWrapper';
import { useTranslation } from 'react-i18next';

export type DashboardStackParamList = {
  Products: undefined;
  PetsNavigation: undefined;
  Timeline: undefined;
  Pets: undefined;
};

const Tabs = createBottomTabNavigator<DashboardStackParamList>();

const ActiveProductsTabBarIcon = ({ focused }: { focused: boolean }) => {
  return focused ? (
    <ProductActiveSVG width={30} height={30} viewBox='0 0 28 28' />
  ) : (
    <ProductInactiveSVG width={30} height={30} viewBox='0 0 28 28' />
  );
};

const ActivePetsTabBarIcon = ({ focused }: { focused: boolean }) => {
  return focused ? (
    <PetActiveSVG width={30} height={30} viewBox='0 0 28 28' />
  ) : (
    <PetInactiveSVG width={30} height={30} viewBox='0 0 28 28' />
  );
};

// TODO Add icon
const ActiveTimelineTabBarIcon = ({ focused }: { focused: boolean }) => {
  return focused ? (
    <TimelineActiveSVG width={30} height={30} viewBox='0 0 28 28' />
  ) : (
    <TimelineInactiveSVG width={30} height={30} viewBox='0 0 28 28' />
  );
};

interface IGetLabelText {
  text: string,
  focused: boolean
}

const getLabelText: React.FC<IGetLabelText> = (text, focused) => {
  return (
    <Text
      numberOfLines={2}
      style={[
        styles.bar,
        {
          color: focused ? colors.primary.color : colors.labelTextColor.color,
        },
      ]}
    >
      {text}
    </Text>
  );
};

export default function DashboardNavigation(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Tabs.Navigator
      initialRouteName='Pets'
      screenOptions={{
        headerShown: false,
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: {
          fontWeight: '700',
          fontSize: 15,
        },
        tabBarStyle: {
          backgroundColor: colors.white.color,
          borderTopColor: colors.headerBorderColor.color,
          borderTopWidth: 1,
          borderStyle: 'solid',
          marginBottom: 3,
          paddingTop: 7,
          height: Platform.OS === 'ios' ? 95 : 80,
        },
        ...Platform.select({
          android: {
            tabBarItemStyle: {
              height: 60,
            },
          },
        }),
      }}
    >
      <Tabs.Screen
        name='Pets'
        component={Pets}
        options={() => ({
          tabBarIcon: ActivePetsTabBarIcon,
          tabBarLabel: ({ focused }) => {
            return getLabelText(t('pets'), focused);
          },
        })}
      />

      <Tabs.Screen
        name='Products'
        component={Products}
        options={() => ({
          tabBarIcon: ActiveProductsTabBarIcon,
          tabBarLabel: ({ focused }) => {
            return getLabelText(t('products'), focused);
          },
        })}
      />
      <Tabs.Screen
        name='Timeline'
        component={TimelineWrapper}
        options={() => ({
          tabBarIcon: ActiveTimelineTabBarIcon,
          tabBarLabel: ({ focused }) => {
            return getLabelText(t('timeline'), focused);
          },
        })}
      />
    </Tabs.Navigator>
  );
}

const styles = StyleSheet.create({
  bar: {
    fontWeight: '700',
    fontSize: 15,
    textAlign: 'center',
  },
});
