import { SpView } from '@atoms/SpView';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import TourTapSVG from '@atoms/SvgIcons/TourTapSVG';

const TapForAdvancedDataDashboardSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.timeSquare}>
        <SpView style={styles.dateWrapper}>
          <SpText style={styles.number}>14</SpText>
          <SpText style={styles.units}>{t('hours_unit_1')} </SpText>
          <SpText style={styles.number}>55</SpText>
          <SpText style={styles.units}>{t('minutes_unit_2')}</SpText>
        </SpView>
        <SpText style={styles.timeOutsideText}>{t('time_outside')}</SpText>
        <View style={styles.tapIconWrapper}>
          <TourTapSVG />
        </View>
      </SpView>
      <SpTitle style={styles.titleText} align="center" text={t('tap_for_advanced_data')} />
      <SpText style={styles.contentText}>
        {t('tap_for_advanced_tour_text')}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    lineHeight: 24,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  timeSquare: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 36,
    width: '60%',
    height: 108,
    backgroundColor: '#ffffff',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 19.7,
    borderWidth: 1,
    borderColor: '#03A2B185',
  },
  timeOutsideText: {
    color: '#919ba0',
    fontSize: 19.7,
    // lineHeight: 1.25,
  },
  dateWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    // justifyContent: 'flex-end',
  },
  number: {
    fontSize: 34.4,
    fontWeight: '600',
    lineHeight: 34,
    fontFamily: 'Rubik_Medium',
  },
  units: {
    fontWeight: '600',
    fontFamily: 'Rubik_Medium',
    fontSize: 24.6,
    lineHeight: 24.6,
  },
  tapIconWrapper: {
    width: '100%',
    position: 'absolute',
    alignItems: 'center',
    top: 85,
  },
  titleText: {
    paddingTop: 64,
  },
});

export default TapForAdvancedDataDashboardSlide;
