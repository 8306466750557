import { StyleSheet } from 'react-native';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpText } from '@atoms/SpText';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import text from '@styles/text';
import { SpVStack } from '@atoms/SpVStack';
import { DeviceType } from '@constants/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import StepFormCreator from '../../../components/molecules/StepFormCreator';
import { FlowStackParamList } from '../index';
import useValidation from '../../validation';
import useBoundStore from '../../../store/store';
import { RootStackParamList } from '../../index';

type Props = NativeStackScreenProps<FlowStackParamList, 'IncorrectProduct'>;

const IncorrectProduct = ({ route }: Props) => {
  const { t } = useTranslation();
  const [submitValues, setSubmitValues] = useState<any>();
  const { loadDevice, updateNameRequest } = useBoundStore(s => s.deviceStore);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { commonNameSchema } = useValidation();

  const [step, setStep] = useState(1);

  const getDeviceProps = useMemo(() => {
    switch (route.params.foundDeviceType) {
      case DeviceType.PetDoorConnect:
        return {
          message: t('we_have_detected_that_your_product_is_a_pet_door'),
          title: t('name_petdoor_title'),
          subtitle: t('subtitle {{productName}} {{example}}', {
            example: t('name_catflap_example'),
            productName: t('pet_door'),
          }),
          nextStep: 3,
          screen: 'AddPetDoor',
        };
      case DeviceType.FeederConnect:
        return {
          message: t('we_have_detected_that_your_product_is_a_feeder'),
          title: t('name_feeder_title'),
          subtitle: t('subtitle {{productName}} {{example}}', {
            example: t('name_feeder_example'),
            productName: t('pet_feeder'),
          }),
          nextStep: 3,
          screen: 'AddFeeder',
        };
      case DeviceType.CatFlapConnect:
        return {
          message: t('we_have_detected_that_your_product_is_a_cat_flap'),
          title: t('name_catflap_title'),
          subtitle: t('subtitle {{productName}} {{example}}', {
            example: t('name_catflap_example'),
            productName: t('cat_flap'),
          }),
          nextStep: 3,
          screen: 'AddCatFlap',
        };
      case DeviceType.FelaquaConnect:
        return {
          message: t('we_have_detected_that_your_product_is_a_felaqua'),
          title: t('name_felaqua_title'),
          subtitle: t('subtitle {{productName}} {{example}}', {
            example: t('name_felaqua_example'),
            productName: t('felaqua_connect'),
          }),
          nextStep: 5,
          screen: 'AddFelaqua',
        };
      case DeviceType.Cerberus:
        return {
          message: t('we_have_detected_that_your_product_is_a_pet_bowl'),
          title: t('name_dogbowl_title'),
          subtitle: t('subtitle {{productName}} {{example}}', {
            example: t('name_dogbowl_example'),
            productName: t('dog_bowl_connect'),
          }),
          nextStep: 1,
          screen: 'AddDogBowl',
        };
      default:
        return null;
    }
  }, [route.params.foundDeviceType]);

  const IncorrectProductContent = useMemo(() => {
    return (
      <SpVStack space={20} style={{ ...styles.stepWrapper, ...styles.center }}>
        <SpText style={text.title}>{t('incorrect_product')}</SpText>
        <SpText style={{ ...text.body, ...styles.body }}>
          {getDeviceProps.message}
        </SpText>
        <SpText style={text.body}>
          {t('please_continue_to_set_up_your_product')}
        </SpText>
      </SpVStack>
    );
  }, [getDeviceProps.message]);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        await updateNameRequest(route.params.foundDevice, {
          name: submitValues.name,
        });
        await loadDevice(true);
        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, route.params.foundDevice],
  );

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t(DeviceType[route.params.foundDevice])}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      steps={{
        1: {
          ui: IncorrectProductContent,
          hideCustomHeader: true,
          hideProgressHeader: true,
          hideButton: false,
          interceptor: () => {
            navigation.navigate('FlowNavigation', {
              screen: getDeviceProps.screen,
              params: {
                deviceId: route?.params?.foundDevice,
                step: getDeviceProps.nextStep,
              },
            });
            return false;
          },
        } /* ,
        2: {
          ui: NameForm,
          props: {
            label: t('product_name'),
            subtitle2: t('subtitle2'),
            title: getDeviceProps.title,
            subtitle: getDeviceProps.subtitle,
          },
          formik: {
            schema: commonNameSchema(),
            names: ['name'],
          },
          hideCustomHeader: false,
          hideProgressHeader: false,
          handleAndroidBackPress: () => {
            setStep(prev => prev - 1)
            return true
          }
        },
        3: {
          ui: (
            <CompleteSetupProductWithLoading
              productId={route.params.foundDeviceType}
              onLoadedCallback={onSubmit}
              deviceId={route.params.foundDevice}
            />
          ),
          hideCustomHeader: true,
          hideProgressHeader: true,
          hideButton: true,
          handleAndroidBackPress: () => {
            navigation.reset({
              index: 0,
              routes: [{ name: 'DashboardNavigation', params: { screen: 'Products' } }],
            });
            return true
          }
        },
        }, */,
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  body: {
    marginTop: 15,
  },
  stepWrapper: {
    marginTop: '30%',
  },
  formWrapper: { paddingHorizontal: 24 },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'darkgrey',
    marginVertical: 90,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'darkgrey',
    marginBottom: 5,
    textAlign: 'center',
  },
  error: {
    fontSize: 12,
    color: 'red',
    textAlign: 'center',
  },
  errorWrapper: {
    height: 25,
  },
  center: {
    alignItems: 'center',
  },
});

export default IncorrectProduct;
