import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const DrinkingSVG = ({ color, ...props }: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path
      fill={color || '#03A2B1'}
      fillRule="nonzero"
      d="M12 0S3 10.737 3 15.41C3 20.085 6.986 24 12 24c5.014 0 9-3.79 9-8.59S12 0 12 0Zm6.3 16.8c0 .379-.257.758-.386 1.137-.257.252-.385.631-.643.884-.257.253-.514.505-.771.632-.514.379-1.157.505-1.543.631-.514.127-.9 0-1.157 0s-.386-.126-.386-.126l.9-.884c3.086-2.527 2.572-6.569 2.572-6.569s.128.127.385.253c.258.253.515.505.772 1.01.257.506.514 1.137.514 1.769-.128.505-.128.884-.257 1.263Z"
    />
  </Svg>
);
export default DrinkingSVG;
