import { Image, StyleSheet, View } from 'react-native';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import SpTitle from '@molecules/SpTitle';
import { SpText } from '@atoms/SpText';
import SelectButtonsWrapper from '@molecules/SelectButtonsWrapper';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import { AppImages } from '../../../constants/AppImages';
import { BowlOptions, resolveBowlType } from '../StepForms/Devices/AddFeeder';

interface ISelectMealsProps {
  bowlFoodType: BowlOptions;
  setBowlFoodType: Dispatch<SetStateAction<BowlOptions>>;
  isBowlSingle: boolean;
  isLeft?: boolean;
}

enum PetFeederFoodType {
  wet = 1,
  dry = 2,
}

const SelectMeals = ({
  bowlFoodType,
  setBowlFoodType,
  isBowlSingle,
  isLeft = false,
}: ISelectMealsProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.selectMealsContainer}>
      <SpTitle text={t('select_food_type')} />
      <SpText style={styles.selectMealsText1} align="center">
        {t(
          isBowlSingle
            ? 'select_food_type_single_bowl'
            : 'select_food_type_half_bowl',
        )}
      </SpText>
      <Image
        source={resolveBowlType(
          AppImages.bowl,
          AppImages.leftBowl,
          AppImages.rightBowl,
          isLeft,
          isBowlSingle,
        )}
        style={styles.selectMealsImage}
        resizeMode="contain"
        {...testProperties('selectMeals', 'image')}
      />
      <SpText
        color={colors.greyText.color}
        fontFamily="Rubik_Medium"
        size="xl"
        bold
        style={styles.selectMealsText2}
      >
        {t(
          resolveBowlType(
            'single_bowl',
            'left_bowl',
            'right_bowl',
            isLeft,
            isBowlSingle,
          ),
        )}
      </SpText>
      <SelectButtonsWrapper
        optionsEnum={PetFeederFoodType}
        handleChange={value => {
          if (isBowlSingle) {
            return setBowlFoodType({ ...bowlFoodType, single: value });
          }
          return isLeft
            ? setBowlFoodType({ ...bowlFoodType, left: value })
            : setBowlFoodType({ ...bowlFoodType, right: value });
        }}
        selectedOption={
          PetFeederFoodType[
            resolveBowlType(
              bowlFoodType.single,
              bowlFoodType.left,
              bowlFoodType.right,
              isLeft,
              isBowlSingle,
            )
          ]
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  selectMealsContainer: { alignItems: 'center' },
  selectMealsText1: { paddingHorizontal: 20, marginBottom: 49 },
  selectMealsText2: { marginTop: 12, marginBottom: 24 },
  selectMealsImage: {
    height: 50,
  },
});

export default SelectMeals;
