import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  topSection: {
    flex: 1,
  },
  backgroundImageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  logoImage: {
    width: 160,
    height: 160,
  },
  titleWrapper: {
    rowGap: 4,
    alignItems: 'center',
    marginTop: 54,
  },
  titleText: {
    fontSize: 24,
    lineHeight: 28,
    fontWeight: 'bold',
  },
  descriptionText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'bold',
  },
  imageWrapper: {
    height: '70%',
    justifyContent: 'space-between',
  },
  bottomSectionWrapper: {
    paddingLeft: 19,
    paddingRight: 19,
    marginBottom: 62,
  },
  loginWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 12,
  },
  logoCicleWrapper: {
    position: 'absolute',
    bottom: -50,
    width: '100%',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 7,
  },
  buttonTextLetsStart: {
    fontSize: 20,
    letterSpacing: -0.5,
    lineHeight: 24,
    fontWeight: 'bold',
  },
  buttonLoginTextStyle: {
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: -0.4,
    fontWeight: 'bold',
  },
  concaveBox: {
    bottom: -35,
    position: 'absolute',
    backgroundColor: 'transparent',
    borderWidth: 35,
    borderColor: '#ffffff',
  },
});
