import { SpLoading } from '@atoms/SpLoading';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { useGetAllPetReports } from '@hooks/useGetAllPetReports';
import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
  MappedReportModel,
} from '@models/ReportModel';
import { StackScreenProps } from '@react-navigation/stack';
import colors from '@styles/colors';
import { addDays, isWithinInterval, subDays } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Env } from '../../../../Env';
import useBoundStore from '../../../store/store';
import { PetStackParamList } from '../Pets';
import { PDFReportActions } from '../constants/PDFReport';
import { ReportRangeType } from '../constants/ReportRangeType';
import { ReportType } from '../constants/ReportType';
import { Dashboard } from '../molecules/PetDashboardReport/Dashboard';

const getInitialDaysToFetch = (value: ReportRangeType): number => {
  switch (value) {
    case ReportRangeType.OneDay:
      return 7;
    case ReportRangeType.SevenDays:
      return 14;
    case ReportRangeType.TwentyEightDays:
      return 61;
    case ReportRangeType.SixMonths:
      return 365;
    case ReportRangeType.OneYear:
      return 730;
    default:
      return 0;
  }
};

const getNextDaysToFetch = (value: ReportRangeType): number => {
  switch (value) {
    case ReportRangeType.OneDay:
      return 7;
    case ReportRangeType.SevenDays:
      return 14;
    case ReportRangeType.TwentyEightDays:
      return 61;
    case ReportRangeType.SixMonths:
      return 365;
    case ReportRangeType.OneYear:
      return 730;
    default:
      return 0;
  }
};

const getFetchEndpoint = (value: ReportRangeType): number => {
  const getDaysNum = (): number => {
    switch (value) {
      case ReportRangeType.OneDay:
        return 7;
      case ReportRangeType.SevenDays:
        return 21;
      case ReportRangeType.TwentyEightDays:
        return 60;
      case ReportRangeType.SixMonths:
        return 180;
      case ReportRangeType.OneYear:
        return 365;
      default:
        return 0;
    }
  };

  return (getDaysNum() - (value || 1)) * 24 * 60 * 60 * 1000;
};

export const PetDashboardReportWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetDashboardReport'>) => {
  const [data, setData] = useState<MappedReportModel>(null);
  const [type, setType] = useState<ReportType>(null);
  const household = useBoundStore((s) => s.householdStore.activeHousehold);
  const token = useBoundStore((s) => s.accountStore.token);
  const pet = useBoundStore((state) => state.petStore.activePet);
  const { loadDetailsReport, loading } = useBoundStore((s) => s.reportStore);
  const devices = useDevicesByHousehold();
  const types = useGetAllPetReports(pet) as ReportType[];
  const [currentDate, setCurrentDate] = useState(+new Date());
  const [viewedDays, setViewedDays] = useState(ReportRangeType.SevenDays);
  // Part of Cache Flow
  // const savedDate = useRef(+new Date());
  // const allSavedDates = useRef<number[]>([]);
  const loadReport = async (daysNum: number) => {
    // const fetchEndpoint = getFetchEndpoint(viewedDays);
    //   savedDate.current = currentDate - fetchEndpoint;
    // allSavedDates.current.push(savedDate.current);
    const values = await loadDetailsReport(household?.id, pet.id, daysNum, addDays(currentDate, 1));

    setData(values);
  };
  // const { deleteAllData } = useCache();
  // useEffect(() => {
  //   deleteAllData();
  // }, []);

  useEffect(() => {
    console.log('fetching data', viewedDays);
    loadReport(getInitialDaysToFetch(viewedDays));
  }, [household, pet, viewedDays, currentDate]);

  // useEffect(() => {
  //   loadReport(getNextDaysToFetch(viewedDays));
  // }, [currentDate]);

  // const checkAndLoadMoreData = useCallback((date: number) => {
  //   if (data) {
  //     const firstNonEmpty = Object?.values(data)?.find(innerObj => Object?.keys(innerObj)?.length > 0);
  //     if (!firstNonEmpty) {
  //       loadReport(getNextDaysToFetch(viewedDays));
  //       return;
  //     }
  //
  //     const keys = Object.keys(firstNonEmpty);
  //     console.log('checkAndLoadMoreData keys', keys);
  //     const dataDateRange = { start: firstNonEmpty[keys[0]]?.date, end: firstNonEmpty[keys[keys?.length - 1]]?.date };
  //     const myDateRange = { start: subDays(date, viewedDays), end: date };
  //     if (!isWithinInterval(myDateRange.start, dataDateRange) || !isWithinInterval(myDateRange.end, dataDateRange)) {
  //       loadReport(getNextDaysToFetch(viewedDays));
  //     }
  //   }
  //   // const isAlreadyCached = Math.min(...allSavedDates.current) < date;
  //   // if (date < savedDate.current && date < currentDate && !isAlreadyCached) {
  //   //   loadReport(getNextDaysToFetch(viewedDays));
  //   //   console.log('dont have this, will load more');
  //   // }
  // }, [currentDate, data, loadReport, viewedDays]);

  useEffect(() => {
    if (!type && types.length) {
      setType(route.params?.type || types[0]);
    }
  }, [route.params?.type]);

  const filterData = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    data: GroupReportPointModel<
      | MappedConsumptionReportDataModel
      | MappedMovementReportDataModel
      | MappedPoseidonReportDataModel
    >[],
    days: number,
  ) => {
    return data?.filter((item) => {
      const itemDate = new Date(item.date);
      const currentDateMinusx = subDays(currentDate, days * 2 < 14 ? 14 : days * 2);
      const currentDatePlusx = addDays(currentDate, 1);
      return isWithinInterval(itemDate, {
        start: currentDateMinusx,
        end: currentDatePlusx,
      });
    });
  };

  const filteredData = useMemo<MappedReportModel>(() => {
    return {
      feeding: filterData(
        data?.feeding ? data?.feeding : [],
        viewedDays,
      ) as GroupReportPointModel<MappedConsumptionReportDataModel>[],
      movement: filterData(
        data?.movement ? data?.movement : [],
        viewedDays,
      ) as GroupReportPointModel<MappedMovementReportDataModel>[],
      drinking: filterData(
        data?.drinking ? data?.drinking : [],
        viewedDays,
      ) as GroupReportPointModel<MappedPoseidonReportDataModel>[],
    };
  }, [data]);

  const generateReport = useCallback(() => {
    switch (Env.APP_BUNDLE_ID) {
      case 'com.sureflap.surehub.devstable':
        Linking.openURL(
          `https://reports.devstable.surehub.io/reports/${household?.id}/${pet?.id}/${PDFReportActions[type]}?token=${token}`,
        ).then();
        break;
      default:
        Linking.openURL(
          `https://master.d3cdm3x7bnatip.amplifyapp.com/reports/${household?.id}/${pet?.id}/${PDFReportActions[type]}?token=${token}`,
        ).then();
        break;
    }
  }, [household, token, pet, type]);

  if (!data && (loading || !type)) {
    return <SpLoading />;
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.white.color }}>
      <Dashboard
        pet={pet}
        types={types}
        devices={devices}
        stats={filteredData}
        type={type}
        onChangeType={setType}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        viewedDays={viewedDays}
        setViewedDays={setViewedDays}
        // checkAndLoadMoreData={checkAndLoadMoreData}
        initialActiveTab={route.params.tab}
        onGenerateReport={generateReport}
      />
    </SafeAreaView>
  );
};
