import { SpLoading } from '@atoms/SpLoading';
import { CloseDelays } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { useCustomToast } from '@hooks/useToast';
import { DeviceFeederControlModel } from '@models/Device';
import { StackScreenProps } from '@react-navigation/stack';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect } from 'react';

import useToastEffect from '@hooks/useToastEffect';
import { MergedInterfaces } from '../../store/models';
import useBoundStore from '../../store/store';
import { DeviceStackParamList } from './index';
import { LidDelay } from './molecules/DeviceLidDelay/LidDelay';
import { DeviceSettingContainer } from './molecules/DeviceSettingContainer';
import InfoModal from './components/InfoModal';

export const DeviceLidDelay = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceLidDelay'>) => {
  const { id } = route.params;
  const device = useDeviceById(id);
  const { loadingDevice, updateCloseDelayAsync } = useBoundStore(state => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateCloseDelayAsync: deviceStore.updateCloseDelayAsync,
    };
  });

  const changeDelayAction = useCallback(
    ($event: CloseDelays) => {
      updateCloseDelayAsync(device.id, $event);
    },
    [device, updateCloseDelayAsync],
  );

  if (loadingDevice || !device) {
    return <SpLoading />;
  }

  return (
    <DeviceSettingContainer>
      <InfoModal />
      <LidDelay
        data={(device?.control as DeviceFeederControlModel).lid}
        onChangeDelay={changeDelayAction}
      />
    </DeviceSettingContainer>
  );
};
