import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const PasswordEyeOpenedSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 16 16"
    {...props}
  >
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M8 3.5C4.943 3.5 2.17 5.078.125 7.642a.57.57 0 0 0 0 .713C2.171 10.922 4.943 12.5 8 12.5c3.057 0 5.83-1.578 7.875-4.142a.57.57 0 0 0 0-.713C13.829 5.078 11.057 3.5 8 3.5Zm.22 7.669c-2.03.12-3.706-1.458-3.578-3.376.105-1.581 1.463-2.863 3.139-2.962 2.029-.12 3.705 1.458 3.577 3.376-.108 1.578-1.466 2.86-3.139 2.962Zm-.102-1.464C7.025 9.77 6.12 8.92 6.193 7.889c.056-.853.79-1.541 1.692-1.597 1.094-.065 1.997.785 1.925 1.816-.059.856-.792 1.544-1.692 1.597Z"
    />
  </Svg>
);
export default PasswordEyeOpenedSVG;
