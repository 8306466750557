/* eslint-disable no-restricted-syntax */
import { SpButton } from '@atoms/SpButton';
import { SpErrorText } from '@atoms/SpErrorText';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { DeviceCatFlapCurfew } from '@models/Device';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import { format, setHours, setMinutes } from 'date-fns';

import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import SpDatePicker from '@atoms/SpDatePicker';
import { TimeService } from 'src/services/TimeService';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useMultipleCurfew } from '../../hooks/useMultipleCurfew';
import CurfewTitle from '../../components/CurfewTitle';
import CurfewTimeItem, { TimeValue } from './CurfewTimeItem';
import { SpFlatList } from '@atoms/SpFlatList';

interface MultipleCurfewProps {
  data: DeviceCatFlapCurfew[];
  error: string;
  onChangeCurfew: (data: DeviceCatFlapCurfew[]) => void;
}

type FieldType = {
  enabled: boolean;
  lock_time: string;
  unlock_time: string;
  id: string;
};

export const MultipleCurfew = ({
  data,
  error,
  onChangeCurfew,
}: MultipleCurfewProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    saveCurfewAction,
    toggleCurfewAction,
    addNewCurfewAction,
    enabled,
    fields,
    update,
    changeCurfewAction,
  } = useMultipleCurfew(data, onChangeCurfew);
  const userTimeFormat = useUserTimeUnits();
  const { timeToFormat } = useTimeTransform();
  const [isEditable, setIsEditable] = useState(false);
  const [isOverlapError, setIsOverlapError] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const timeForPicker = useMemo(() => {
    if (!selectedValue) return null;
    const [hours, minutes] = selectedValue.time.split(":").map(Number);

    const now = TimeService.convertOrUnconvertDateByUTC(new Date(), true);
    const date = setHours(setMinutes(now, minutes), hours);
    return date;
  }, [selectedValue]);

  const timeToDate = (time: string) => {
    const chunks = time.split(":");
    const date = new Date();
    date.setHours(Number(chunks[0]));
    date.setMinutes(Number(chunks[1]));
    return date;
  };

  const checkOverlap = useCallback(
    (item, index): boolean => {
      const newArray = isOverlapError;
      const itemLock = new Date(timeToDate(item.lock_time));
      const itemUnlock = new Date(timeToDate(item.unlock_time));
      if (fields.length > 1) {
        let result = false;
        for (const field of fields) {
          if (field.id === item.id) {
            result = false;
            break;
          }
          const itemFieldLock = new Date(timeToDate(field.lock_time));
          const itemFieldUnlock = new Date(timeToDate(field.unlock_time));

          if (itemLock <= itemFieldUnlock && itemFieldLock <= itemUnlock) {
            result = true;
            break;
          }
          if (itemFieldLock <= itemLock && itemLock <= itemFieldUnlock) {
            result = true;
            break;
          }
          if (itemFieldLock <= itemUnlock && itemUnlock <= itemFieldUnlock) {
            result = true;
            break;
          }
          if (itemLock <= itemFieldLock && itemFieldLock <= itemUnlock) {
            result = true;
            break;
          }

          if (itemLock > itemUnlock) {
            if (
              itemFieldLock > itemUnlock &&
              itemFieldLock < itemLock &&
              itemFieldUnlock > itemUnlock &&
              itemFieldUnlock < itemLock
            ) {
              result = false;
              break;
            } else {
              result = true;
              break;
            }
          }
        }
        newArray[index] = result;
        setIsOverlapError(newArray);
        return Boolean(result);
      }
      newArray[index] = false;
      setIsOverlapError(newArray);
      return false;
    },
    [data, fields]
  );

  return (
    <>
      <CurfewTitle
        disabled={isEditable}
        enabled={enabled}
        toggleCurfewAction={toggleCurfewAction}
      />
      <SpView
        alignItems="center"
        flex={1}
        marginTop={32}
        paddingHorizontal={32}
        style={[!enabled && { opacity: 0.5 }]}
      >
        <SpView
          alignItems="center"
          flex={1}
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
        >
          <SpView alignItems="center" flex={1}>
            <View {...testProperties("faLock", "icon")}>
              <FontAwesomeIcon
                icon={faLock}
                color={colors.primary.color}
                size={17}
              />
            </View>
            <SpText
              style={{ marginTop: 4 }}
              fontFamily="Rubik"
              color={colors.black.color}
            >
              {t("lock_time")}
            </SpText>
          </SpView>
          <SpView alignItems="center" flex={1}>
            <View {...testProperties("faUnlock", "icon")}>
              <FontAwesomeIcon
                color={colors.primary.color}
                icon={faUnlock}
                size={17}
              />
            </View>
            <SpText
              style={{ marginTop: 4 }}
              fontFamily="Rubik"
              color={colors.black.color}
            >
              {t("unlock_time")}
            </SpText>
          </SpView>
          {isEditable && <SpView width={82} />}
        </SpView>

        <SpFlatList
          data={fields as FieldType[]}
          scrollEnabled={false}
          keyExtractor={(item) => `${item.id}`}
          renderItem={({ item, index }) => (
            <CurfewTimeItem
              {...{
                item: item as TimeValue,
                index,
                setSelectedValue,
                isEditable,
                selectedValue,
                changeCurfewAction,
              }}
            />
          )}
        />
        {isOverlapError.includes(true) && (
          <SpView marginTop={12}>
            <SpErrorText>{t("curfew_times_cannot_overlap")}</SpErrorText>
          </SpView>
        )}
        {!isEditable && (
          <SpButton
            style={styles.primaryButton}
            disabled={!enabled}
            color="transparent"
            onPress={() => setIsEditable(true)}
            title={t("edit_times")}
          />
        )}

        {isEditable && fields.length < 4 && (
          <SpButton
            style={styles.primaryButton}
            color="transparent"
            onPress={addNewCurfewAction}
            title={t("add_curfew")}
          />
        )}

        {error && (
          <SpView marginTop={12}>
            <SpErrorText>{error}</SpErrorText>
          </SpView>
        )}
      </SpView>

      {isEditable && (
        <SpView width="100%">
          <SpRoundedHeaderButton
            h={56}
            disabled={isOverlapError.includes(true)}
            backgroundColor={colors.primary.color}
            stylesForContainer={styles.saveButton}
            onPress={handleSubmit(() => saveCurfewAction())}
            title={t("save")}
          />
        </SpView>
      )}

      {selectedValue && (
        <SpDatePicker
          modal
          mode="time"
          androidVariant="iosClone"
          testID="TimePicker"
          open
          date={timeForPicker}
          onConfirm={(date) => {
            update(
              selectedValue.index,
              selectedValue.isLockedTime
                ? { ...selectedValue.item, lock_time: format(date, "HH:mm") }
                : { ...selectedValue.item, unlock_time: format(date, "HH:mm") }
            );
            setSelectedValue(null);
          }}
          onCancel={() => {
            setSelectedValue(null);
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  column: {
    paddingHorizontal: 10,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "50%",
  },
  saveButton: {
    marginTop: 52,
  },
  primaryButton: {
    borderRadius: 10,
    backgroundColor: colors.greyText.color,
    paddingHorizontal: 19,
    paddingVertical: 2,
    marginTop: 36,
  },
  primaryButtonText: {
    fontFamily: "Rubik_Medium",
    color: colors.white.color,
    fontSize: 16,
  },
  largeMargin: {
    marginTop: 18,
  },
  smallMargin: {
    marginTop: 8,
    backgroundColor: "red.500",
  },
  textTime: {
    fontFamily: "Rubik",
    fontSize: 20,
  },
});
