/* eslint-disable react/destructuring-assignment */
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React, { memo, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import textStyles from '@styles/text';
import { useTranslation } from 'react-i18next';
import atomStyles from '@styles/atoms';
import colors from '@styles/colors';

type SingleAvgProps = {
  type: 'single';
  data: string;
};

type DoubleAvgProps = {
  type: 'double';
  visits: number;
  length: string;
};

type CustomStatisticsProps = {
  type: 'multi';
  leftLabel: string;
  leftValue: React.ReactElement | string;
  rightValue: React.ReactElement | string;
  rightLabel: string;
};

type Props = SingleAvgProps | DoubleAvgProps | CustomStatisticsProps;

const AvgSatatisticsContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  const leftLabelElement = useMemo(() => {
    const value = (() => {
      switch (props.type) {
        case 'double':
          return props.visits;
        case 'multi':
          return props.leftValue;
        case 'single':
          return props.data;
      }
    })();
    if (typeof value === 'string') {
      return (
        <SpText
          style={[textStyles.title, styles.mainDataText, { fontSize: 28 }]}
          adjustsFontSizeToFit
          numberOfLines={1}
        >
          {value}
        </SpText>
      );
    }
    return value;
  }, [props]);

  const rightLabelElement = useMemo(() => {
    const value = (() => {
      switch (props.type) {
        case 'double':
          return props.length;
        case 'multi':
          return props.rightValue;
        case 'single':
          return props.data;
      }
    })();
    if (typeof value === 'string') {
      return (
        <SpText
          style={[textStyles.title, styles.mainDataText, { fontSize: 28 }]}
          adjustsFontSizeToFit
          numberOfLines={1}
        >
          {value}
        </SpText>
      );
    }
    return value;
  }, [props]);

  return (
    <SpView style={styles.mainContainer}>
      {props.type !== 'single' ? (
        <SpView style={[atomStyles.rowContainer, styles.innerContainer]}>
          <SpView
            style={[atomStyles.statusCardContainer, styles.blockContainer]}
          >
            <SpText style={styles.title} numberOfLines={1} adjustsFontSizeToFit>
              {props.type === 'double'
                ? `${t('average_short')} ${t('visits')}`
                : props.leftLabel}
            </SpText>
            {leftLabelElement}
          </SpView>
          <SpView
            style={[atomStyles.statusCardContainer, styles.blockContainer]}
          >
            <SpText style={styles.title} numberOfLines={1} adjustsFontSizeToFit>
              {props.type === 'double'
                ? `${t('average_short')} ${t('length')}`
                : props.rightLabel}
            </SpText>
            {rightLabelElement}
          </SpView>
        </SpView>
      ) : (
        <SpView style={[atomStyles.statusCardContainer, styles.smallContainer]}>
          <SpText style={styles.title}>{t('average_short')}</SpText>
          <SpText style={[textStyles.title, styles.mainDataText]}>
            {props.data}
          </SpText>
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  mainDataText: {
    marginLeft: 0,
    lineHeight: undefined,
    marginTop: 8,
  },
  mainContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallContainer: {
    minWidth: '50%',
    flex: undefined,
    paddingHorizontal: 5,
  },
  title: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'center',
    color: colors.greySmallTitle.color,
    marginBottom: 4,
  },
  blockContainer: {
    paddingHorizontal: 15,
  },
  innerContainer: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default memo(AvgSatatisticsContainer);
