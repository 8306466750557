import { MappedMovementReportDataModel } from '@models/ReportModel';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getEndTimeDay,
  getSecondsDifference,
  getStartTimeDay,
} from '@utils/date';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useTransformMovementEventToReport } from '../hooks/useTransformMovementEventToReport';
import { EventDetailsCard } from './EventDetailsCard';
import { differenceInSeconds, endOfDay, isBefore, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import useBoundStore from 'src/store/store';

interface MovementPointReportProps {
  data: MappedMovementReportDataModel;
}

export const MovementEventReport = ({ data, index }: MovementPointReportProps) => {
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const item = useTransformMovementEventToReport(data, activeHousehold.timezone.timezone);
  const { t } = useTranslation();
  const convertToLocal = (date) => {
    const defaultDate = new Date(date);
    const toUTC = utcToZonedTime(defaultDate, 'UTC');
    const toHHTimeZone = utcToZonedTime(toUTC, activeHousehold.timezone.timezone);
    return toHHTimeZone;
  }

  const { concatTwoDigitFormatToOne, convertSecondToTwoDigitFormat } =
    useTimeTransform();

  const fullDay = useMemo(() => {
    const fullDayDiff = getSecondsDifference(
      new Date(getEndTimeDay(new Date(data.to).getTime())),
      new Date(getEndTimeDay(new Date(data.from).getTime())),
    );
    return fullDayDiff > 86400;
  }, []);

  const isSeveralDaysEnd = () => {
    return (isBefore(startOfDay(new Date(data.from)),startOfDay(new Date(data.to))))
  };

  const endDate = useMemo(() => {
    if (isSeveralDaysEnd() || item.duration === '23h 59m' || !data.entryDevice) return `- - -`;
    return item.end;
  }, []);

  const startDate = useMemo(() => {
    if (index === 0 && ( item.start === '00:00' || item.duration === '23h 59m')) return '- - -';
    return item.start
  }, []);

  const duration = useMemo(() => {
    if (fullDay) {
      return concatTwoDigitFormatToOne(convertSecondToTwoDigitFormat(86400));
    }

    if (isSeveralDaysEnd()) {
      const convertedFrom = new Date(data.from);
      const convertedEndDay = endOfDay(convertedFrom)
      const duration = differenceInSeconds(convertedEndDay, convertedFrom);
      return concatTwoDigitFormatToOne(convertSecondToTwoDigitFormat(duration));
    }
    return item.duration;
  }, []);

  return (
    <EventDetailsCard
      leftLabel={t('exit/entry')}
      leftValue={`${startDate} - ${endDate}`}
      rightLabel={t('duration')}
      rightValue={duration}
    />
  );
};
