import { dismissKeyboard } from '@utils/keyboardDismiss';
import { SpInput } from '@atoms/SpInput';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { useFormik } from 'formik';
import noop from '@utils/noop';
import { AccountMenu, PetBioMenu } from '@constants/Menus';
import { useTranslation } from 'react-i18next';
import useValidation from '../../validation';

interface AccountTextProps {
  label: string;
  inputName: string;
  currentString: string;
  handleChange: (text: SetStateAction<string>) => void;
  setButtonDisabled: Dispatch<SetStateAction<boolean>>;
  err?: string;
}

const AccountTextWrapper = ({
  label,
  inputName,
  currentString,
  handleChange,
  setButtonDisabled,
  err,
}: AccountTextProps) => {
  const { t } = useTranslation();
  const { emailSchema, commonNameSchema } = useValidation();
  const [value, setValue] = useState(currentString);

  const formikProps = useMemo(() => {
    if (
      inputName === AccountMenu.FirstName ||
      inputName === AccountMenu.LastName ||
      inputName === PetBioMenu.Name
    ) {
      return [() => commonNameSchema(label), { name: value }];
    }
    if (inputName === AccountMenu.EmailAddress) {
      return [emailSchema, { email: value }];
    }
    return null;
  }, [label, value, t]);

  const formik = useFormik({
    validationSchema: formikProps[0],
    initialValues: formikProps[1],
    onSubmit: noop,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const fieldName = useMemo(() => {
    return Object.keys(formikProps[1])[0];
  }, [formikProps]);

  const fieldError = useMemo(() => {
    return formik.errors[fieldName as keyof typeof formik.errors];
  }, [formik, fieldName]);

  useEffect(() => {
    setButtonDisabled(Boolean(fieldError));
  }, [fieldError]);

  const onChangeFirstName = useCallback(
    (text: string) => {
      setValue(text);
      handleChange(text);
      formik.handleChange(fieldName)(text);
    },
    [formikProps, fieldName],
  );

  return (
    <TouchableWithoutFeedback
      accessible={false}
      onPress={() => dismissKeyboard()}
    >
      <View style={styles.container}>
        <SpInput
          value={value}
          label={label}
          onChangeText={onChangeFirstName}
          error={t(fieldError) || t(err)}
          multilineError
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 48,
    paddingHorizontal: 19,
  },
});

export default AccountTextWrapper;
