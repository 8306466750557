import { SpHStack } from '@atoms/SpHStack';
import { SpProfileImage } from '@atoms/SpProfileImage';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { faDroplet, faLocationDot, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { PetModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';

import useBoundStore from '../../../../store/store';
import { RootStackParamList } from '../../../index';

interface PetCardHeaderProps {
  pet: PetModel;
  petActions: any;
}

const IMAGE_DIMENSIONS = Math.min(Dimensions.get('screen').height * 0.1, 92);

export const PetCardHeader = ({ pet, petActions }: PetCardHeaderProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { setActivePet } = useBoundStore((s) => s.petStore);

  const drinking = petActions?.drinking;
  const feeding = petActions?.feeding;
  const movement = petActions?.movement;

  const icons: JSX.Element = useMemo(() => {
    return (
      <>
        {movement && (
          <SpView {...testProperties('movement', 'icon')} marginLeft={6}>
            <FontAwesomeIcon icon={faLocationDot} color={colors.greyText.color} size={12} />
          </SpView>
        )}
        {drinking && (
          <SpView {...testProperties('drinking', 'icon')} marginLeft={6}>
            <FontAwesomeIcon icon={faDroplet} color={colors.greyText.color} size={12} />
          </SpView>
        )}
        {feeding && (
          <SpView {...testProperties('feeding', 'icon')} marginLeft={6}>
            <FontAwesomeIcon icon={faUtensils} color={colors.greyText.color} size={12} />
          </SpView>
        )}
      </>
    );
  }, [petActions, pet]);
  const handlePetProfileNavigation = useCallback(() => {
    setActivePet(pet.id);
    navigation.navigate('PetsNavigation', { screen: 'PetProfile', params: { id: pet.id } });
  }, [pet]);
  return (
    <SpHStack space={8} alignItems="center">
      <TouchableOpacity onPress={handlePetProfileNavigation}>
        <SpProfileImage
          width={IMAGE_DIMENSIONS}
          height={IMAGE_DIMENSIONS}
          imageUrl={pet?.photo?.location}
        />
      </TouchableOpacity>

      <SpView>
        <SpText
          componentName="PetCardHeaderPetName"
          numberOfLines={1}
          fontFamily="Rubik_SemiBold"
          size="xxl"
          style={styles.petName}>
          {pet.name ?? ''}
        </SpText>
        {!!(feeding || drinking || movement) && (
          <SpView flexDirection="row" alignItems="center">
            <SpText style={styles.monitoringText}>{t('monitoring')}</SpText>
            {icons}
          </SpView>
        )}
      </SpView>
    </SpHStack>
  );
};

const styles = StyleSheet.create({
  petName: {
    display: 'flex',
    maxWidth: 160,
    flexWrap: 'wrap',
  },
  monitoringText: {
    color: colors.greyOpacity.color,
    fontSize: 14,
    fontFamily: 'Rubik',
  },
});
