import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel } from '@models/ReportModel';
import colors from '@styles/colors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import AveragePetStatusService from 'src/services/AveragePetStatusService';
import DropShadow from 'react-native-drop-shadow';
import { endOfDay, formatISO, getDate, getTime, isAfter, parseISO, startOfDay } from 'date-fns';
import { SpView } from '@atoms/SpView';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { ReportType } from '../../constants/ReportType';
import { ReportTabs } from '../../hooks/useReportTabs';
import { ReportHeader } from './ReportHeader';
import { SummaryTab } from './SummaryTab';
import { TabsWrap } from './TabsWrap';
import { ReportRangeType } from '../../constants/ReportRangeType';

import { useReportDashboardCalculations } from '../../hooks/useReportDashboardCalculations';
import { PetDashboardDrinking } from './PetDashboardDrinking';
import { PetDashboardMovement } from './PetDashboardMovement';
import { PetDashboardFeeding } from './PetDashboardFeeding';
import { useDivideMovementDay } from '@hooks/useDivideMovementDay';
import useBoundStore from 'src/store/store';
import { utcToZonedTime } from 'date-fns-tz';
import { useConvertDateToHousehold } from '@hooks/useConvertDateToHousehold';

interface PetDashboardSummaryProps {
  stats: MappedReportModel;
  devices: DeviceModel[];
  pet: PetModel;
  type: ReportType;
  types: ReportType[];
  onChangeType: (data: ReportType) => void;
  currentDate: number;
  setCurrentDate: (value: number) => void;
  viewedDays: ReportRangeType;
  initialActiveTab: ReportTabs;
  setViewedDays: (value: ReportRangeType) => void;
  // checkAndLoadMoreData: (date: number) => void;
  onGenerateReport: () => void;
}

const averagePetStatusService = new AveragePetStatusService();

export const Dashboard = ({
                            stats,
                            devices,
                            pet,
                            type,
                            types,
                            onChangeType,
                            currentDate,
                            setCurrentDate,
                            viewedDays,
                            setViewedDays,
                            // checkAndLoadMoreData,
                            initialActiveTab,
                            onGenerateReport,
                          }: PetDashboardSummaryProps) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const { getShortUnit, convertWithUnits } = useWeightConversion(
    userWeightUnits,
    userCountryCode,
  );
  const onSetActiveTab = useCallback((tab: ReportTabs) => {
    changeViewedDate(endOfDay(new Date()).getTime());
    setActiveTab(tab);
  }, []);



  const activeStatsArray = useMemo(() => {
    switch (type) {
      case ReportType.Drinking:
        return stats?.drinking || [];
      case ReportType.Feeding:
        return stats?.feeding || [];
      case ReportType.Movement:
        return stats?.movement || [];
      default:
        return [];
    }
  }, [type, stats, currentDate, viewedDays]);
  const isMovement = type === ReportType.Movement;
  const { useCachedAmounts, useCachedDrinkingAndFeedingStats } =
    useReportDashboardCalculations(
      pet,
      activeStatsArray,
      viewedDays,
      isMovement,
      currentDate,
      type,
    );
  const amountsCache = useCachedAmounts();
  const drinkingAndFeedingStatsCache =
    useCachedDrinkingAndFeedingStats(amountsCache);
  const changeViewedDate = (date: number) => {
    setCurrentDate(+date);
  };

  const changeViewedDaysValue = useCallback((value: ReportRangeType) => {
    setCurrentDate(+new Date());
    setViewedDays(value);
  }, []);

  useConvertDateToHousehold(stats?.movement);
  useDivideMovementDay(stats?.movement);

  const intervals = useMemo(
    () =>
      averagePetStatusService.getDateIntervals(
        viewedDays,
        new Date(currentDate),
      ),
    [viewedDays, currentDate],
  );

  const dataType = useMemo(() => {
    if (type === ReportType.Drinking) {
      return getShortUnit(false);
    }
    if (type === ReportType.Feeding) {
      return getShortUnit(true);
    }
    return '';
  }, [type, getShortUnit]);

  return (
    <SpView testID='PetDashboardSummary' style={styles.container}>
      <ReportHeader
        types={types}
        activePet={pet}
        type={type}
        onChangeType={onChangeType}
        onGenerateReport={onGenerateReport}
      />
      <DropShadow>
        <TabsWrap activeTab={activeTab} onChange={onSetActiveTab} type={type} />
      </DropShadow>

      {activeTab === ReportTabs.Summary && (
        // Bar Chart
        <SummaryTab
          devices={devices}
          stats={stats}
          type={type}
          pet={pet}
          amounts={amountsCache}
          intervals={intervals}
          dataType={dataType}
          drinkingAndFeedingStats={drinkingAndFeedingStatsCache}
          setCurrentDate={changeViewedDate}
          rangeType={viewedDays}
          currentDate={currentDate}
          setRangeType={changeViewedDaysValue}
        />
      )}
      {/* TODO Vitalii Bazyvoliak Reuse it or remove */}
      {activeTab === ReportTabs.Detail && type === ReportType.Drinking && (
        <PetDashboardDrinking
          pet={pet}
          devices={devices}
          data={stats?.drinking || []}
          onGeneratePDFReport={null}
          setViewedActiveDate={changeViewedDate}
          setViewedReportDays={changeViewedDaysValue}
          rangeType={viewedDays}
        />
      )}
      {activeTab === ReportTabs.Detail && type === ReportType.Feeding && (
        <PetDashboardFeeding
          pet={pet}
          devices={devices}
          data={stats?.feeding || []}
          setViewedActiveDate={changeViewedDate}
          setViewedReportDays={changeViewedDaysValue}
          rangeType={viewedDays}
        />
      )}

      {activeTab === ReportTabs.Detail && isMovement && (
        <PetDashboardMovement
          data={stats?.movement || []}
          onGeneratePDFReport={null}
          setViewedActiveDate={changeViewedDate}
          setViewedReportDays={changeViewedDaysValue}
          rangeType={viewedDays}
        />
      )}
      {/* TODO Vitalii Bazyvoliak Reuse it or remove */}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    backgroundColor: colors.white.color,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  cardShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -20,
    },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  },
});
