import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { SpVStack } from '@atoms/SpVStack';
import InvalidSVG from '@atoms/SvgIcons/InvalidSVG';
import {
  CommonActions,
  StackActions,
  useNavigation,
} from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmRemoveHouseholdDialogModes } from './constants/ConfirmRemoveHouseholdDialogModes';
import { ConfirmRemoveHouseholdDialogContext } from './contexts/ConfirmRemoveHouseholdDialogContext';
import { Layout } from './Layout';
import { PlaceholderText } from './molecules/PlaceholderText';

export const ConfirmRemoveHouseholdDialog = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { dialogConfig } = useContext(ConfirmRemoveHouseholdDialogContext);

  const permissionButton = useMemo(() => {
    return (
      <SpView paddingVertical={12}>
        <SpRoundedHeaderButton
          title={t('ad_change_permissions')}
          backgroundColor={colors.primary.color}
          onPress={() => {
            navigation.dispatch(() => {
              return CommonActions.reset({
                routes: [{ name: 'PreviewStep' }],
                index: 0,
              });
            });

            navigation.dispatch(
              StackActions.push('HouseholdNavigation', {
                screen: 'HouseholdUsers',
              }),
            );
          }}
        />
      </SpView>
    );
  }, [navigation]);

  return (
    <Layout nextButtonText={t('delete_anyway')} onNext={dialogConfig.onConfirm}>
      <SpView marginVertical={28} alignItems="center">
        <InvalidSVG width={56} height={56} />
      </SpView>

      <SpView marginBottom={28}>
        <SpVStack space={8}>
          {dialogConfig.households.map(item => {
            return <PlaceholderText key={item.id} text={item.name} />;
          })}
        </SpVStack>
      </SpView>
      {dialogConfig.mode ===
        ConfirmRemoveHouseholdDialogModes.NotOriginallyCreated && (
        <SpVStack space={8}>
          <SpText align="center">
            {t('ad_warning_you_didnt_originally_create_1')}
          </SpText>
          <SpText align="center">
            {t('ad_warning_you_didnt_originally_create_2')}
          </SpText>
        </SpVStack>
      )}
      {dialogConfig.mode === ConfirmRemoveHouseholdDialogModes.Permission && (
        <SpVStack space={8}>
          <SpText align="center" color={colors.errorRed.color}>
            {t('ad_warning_action_remove_household_for_all_users')}
          </SpText>
          <SpText align="center">
            {t('ad_warning_change_permissions_for_users')}
          </SpText>
          {permissionButton}
        </SpVStack>
      )}
      {dialogConfig.mode ===
        ConfirmRemoveHouseholdDialogModes.NotOriginallyCreatedAndPermission && (
        <SpVStack space={8}>
          <SpText align="center" color={colors.errorRed.color}>
            {t('ad_warning_action_remove_household_for_all_users')}
          </SpText>
          <SpText align="center">
            {t('ad_warning_change_permissions_for_users')}
          </SpText>
          {permissionButton}
          <SpText align="center">
            {t('ad_warning_you_didnt_originally_create_1')}
          </SpText>
          <SpText align="center">
            {t('ad_warning_you_didnt_originally_create_2')}
          </SpText>
        </SpVStack>
      )}
    </Layout>
  );
};
