import { PetDoorLockingMode } from '@constants/Device';
import { DeviceCatFlapControlModel, DevicePetDoorControlModel } from '@models/Device';

export const useDoorLockedStatus = (
  data: DeviceCatFlapControlModel | DevicePetDoorControlModel,
): boolean => {
  return [
    PetDoorLockingMode.inside_only,
    PetDoorLockingMode.outside_only,
    PetDoorLockingMode.both,
  ].includes(data?.locking);
};
