import React from 'react';
import { StyleSheet } from 'react-native';
import { SpView } from '@atoms/SpView';
import SpDeviceCardSkeleton from '@atoms/SpDeviceCardSkeleton';

const MOCK_DEVICES_ARR = new Array<number>(5).fill(Math.random());

const SpDevicesSkeletonLoader = () => {
  return (
    <SpView paddingVertical={24} paddingHorizontal={24}>
      <SpDeviceCardSkeleton />

      {MOCK_DEVICES_ARR.map((_, index) => {
        const last = index + 1 === MOCK_DEVICES_ARR.length;
        return (
          <SpView key={index} flexDirection="row" paddingLeft={10}>
            <SpView style={styles.lineSection}>
              <SpView style={styles.horizontalLine} />
              <SpView
                style={[
                  styles.verticalLine,
                  last ? styles.verticalLineLast : null,
                ]}
              />
            </SpView>
            <SpView width="90%" marginBottom={12} marginTop={12}>
              <SpDeviceCardSkeleton />
            </SpView>
          </SpView>
        );
      })}
    </SpView>
  );
};

export default SpDevicesSkeletonLoader;

const styles = StyleSheet.create({
  lineSection: {
    position: 'relative',
    minWidth: '10%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verticalLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 1,
    backgroundColor: '#DFDFE7',
  },
  verticalLineLast: {
    height: '50%',
  },

  horizontalLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#DFDFE7',
  },
});
