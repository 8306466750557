import { HouseholdModel } from '@models/Household';
import { UserModel } from '@models/User';

export const findHouseholdsWithViewersAndOtherCreated = (
  households: HouseholdModel[],
  user: UserModel,
) =>
  households.reduce(
    (acc, household) => {
      if (household.users.some(item => !item.owner || !item.write)) {
        acc.viewers.push(household);
      }

      if (household?.created_user_id !== user.id) {
        acc.notOriginallyCreated.push(household);
      }

      return acc;
    },
    { viewers: [], notOriginallyCreated: [] },
  );
