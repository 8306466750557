import { DeviceType } from '@constants/Device';
import SpModal from '@molecules/SpModal';
import colors from '@styles/colors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { closeModal } from 'src/services/ModalBox';
import { getDeviceImage, getInstructions } from '../Constants/BeforeYouContinueInstruction';
import { SetupInstruction } from '../Molecules/SetupInstruction';
import { SetupInstructionHeader } from '../Molecules/SetupInstructionHeader';
import AddPetsContent from './AddPets/AddPetsContent';

interface BeforeYouContinueProps {
  productId: DeviceType;
}

export const BeforeYouContinue = ({ productId }: BeforeYouContinueProps) => {
  const { t } = useTranslation();

  const image = useMemo(() => getDeviceImage(productId), [productId]);
  const items = useMemo(() => getInstructions(productId), [productId]);
  const closeModalHandler = () => closeModal('BeforeYouContinue_HowAddPets');

  return (
    <View style={styles.container}>
      <SpModal
        backdropColor={colors.white.color}
        modalName="BeforeYouContinue_HowAddPets"
        disabledPress
        onRequestClose={() => closeModal('BeforeYouContinue_HowAddPets')}
      >
        <AddPetsContent
          productId={productId}
          withStepNums
          handler={closeModalHandler}
          addAllpetBtn
        />
      </SpModal>
      <SetupInstruction
        header={
          <SetupInstructionHeader
            title={
              productId === DeviceType.Hub
                ? t('setup_hub_before_you_continue')
                : t('setup_before_you_continue')
            }
            image={image}
          />
        }
        items={items}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 36,
    marginBottom: 80,
  },
});
