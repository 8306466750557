import { useEffect, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';
import { AnalyticsService } from '../services/AnalyticsService';
import useBoundStore from '../store/store';

export const useSendAnalyticsDataAfterLogin = (): void => {
  const [firstInit, setFirstInit] = useState(true);

  const { user, languages, getLanguageByID, countries, getCountryByID } =
    useBoundStore(s => ({
      user: s.accountStore.user,
      languages: s.languageStore.languages,
      getLanguageByID: s.languageStore.getLanguageByID,
      countries: s.countryStore.countries,
      getCountryByID: s.countryStore.getCountryByID,
    }));

  const setUserProperties = async () => {
    await AnalyticsService.setUserId(user.id);
    await AnalyticsService.setUserProperties({
      userLanguage: getLanguageByID(user.language_id).code,
      userCountry: getCountryByID(user.country_id).code,
    });
  };

  useEffect(() => {
    if (user && countries.length && languages.length && firstInit) {
      setFirstInit(false);
      setUserProperties().then();
      DeviceEventEmitter.emit('refreshAnalyticsData');
    }
  }, [user, countries, languages, firstInit]);

  useEffect(() => {
    if (!user) {
      setFirstInit(true);
    }
  }, [user]);
};
