import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomToast } from './useToast';

type Props = {
  resetter: () => void;
  successCondition: boolean;
  errorCondition: boolean;
  successToastMessage: string;
  errorToastMessage: string;
  translate?: boolean;
};

const useToastEffect = ({
  resetter,
  successCondition,
  errorCondition,
  successToastMessage,
  errorToastMessage,
  translate = false,
}: Props) => {
  const isFocused = useIsFocused();
  const { show } = useCustomToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFocused && (successCondition || errorCondition)) {
      resetter();
      return;
    }
    if (successCondition && successToastMessage) {
      show({
        description: translate ? t(successToastMessage) : successToastMessage,
      });
      resetter();
    } else if (errorCondition && errorToastMessage) {
      show({
        description: translate ? t(errorToastMessage) : errorToastMessage,
        isError: true,
      });
      resetter();
    }
  }, [isFocused, successCondition !== errorCondition]);
};

export default useToastEffect;
