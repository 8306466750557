import { StyleSheet, ViewStyle } from 'react-native';
import React from 'react';
import { SpView } from '@atoms/SpView';
import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import atoms from '@styles/atoms';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { testProperties } from '@utils/testProperties';

type PetDrinkingNotificationProps = {
  description: string;
  isWarning: boolean;
  disabledInsightStyle?: ViewStyle;
};

const PetDrinkingNotification = ({
  description,
  isWarning,
  disabledInsightStyle,
}: PetDrinkingNotificationProps) => {
  return (
    <SpView
      style={[
        atoms.pillContainer,
        isWarning
          ? atoms.warningPillContainerBackground
          : atoms.normalPillContainerBackground,
        styles.container,
        disabledInsightStyle,
      ]}
    >
      <SpView
        {...testProperties(description, 'TextWrappper')}
        style={styles.textWrap}
      >
        <SpText {...testProperties(description, 'Text')} style={[styles.text]}>{description}</SpText>
      </SpView>
      <SpView
        {...testProperties(description, 'IconWrapper')}
        style={[
          styles.iconWrap,
          atoms.pillIcon,
          isWarning
            ? atoms.warningPillIconBackground
            : atoms.normalPillIconBackground,
        ]}
      >
        <SpView
          {...testProperties(description, 'Icon')}
          width={32}
          height={32}
          borderRadius={16}
          backgroundColor={colors.white.color}
        >
          <FontAwesomeIcon
            color={isWarning ? colors.orangeWarning.color : colors.green.color}
            icon={faCircleExclamation}
            size={32}
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

export default PetDrinkingNotification;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    minHeight: 82,
  },
  textWrap: {
    flexShrink: 1,
  },
  text: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    color: colors.greyText.color,
    textAlign: 'left',
  },
  iconWrap: {
    flexShrink: 0,
  },
});
