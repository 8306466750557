const DdLogs = {
  info: (...any) => Promise.resolve(),
  error: (...any) => Promise.resolve(),
  warn: (...any) => Promise.resolve(),
};
const initDdSdkReactNative = async () => Promise.resolve();
const DdRumReactNavigationTracking = null;

const setUser = (userId: string) => {}
export {
  DdLogs,
  initDdSdkReactNative,
  DdRumReactNavigationTracking,
  setUser
};