import { useMemo } from 'react';
import { useDeviceProductById } from './useDeviceProductById';

export const useDeviceProductNameById = (id: number): string => {
  const product = useDeviceProductById(id);

  return useMemo(() => {
    return product?.name || '';
  }, [product, id]);
};
